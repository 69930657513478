import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import Utils from "../../../utils";
import ModalWrapper from "../../../components/modalWrapper";
import { useState } from "react";
import { filterData } from "../../../utils/commonFunctions";
import FormField, {
  duration,
  food,
  formulation,
  frequency,
  repetitionCycleOptions,
  supplyfrequency,
} from "../../../schema/formField";
import { useSelector } from "react-redux";
import { ReducersModal } from "../../../modal";

function AddMedicineList({ medicineList, handleOpenAddMedicine }: any) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState<any>({});

  const { onlyRead } = useSelector(
    (state: ReducersModal) =>
      state.createClaimStepTwoDoctorAndHospitalAndICDDataReducer
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditMedicine = (editData: any) => {
    handleOpenAddMedicine(editData);
    dispatch({
      type: Utils.ActionName.ADD_MEDICINE_FORMIK,
      payload: editData,
    });
  };

  const deleteMedicine = () => {
    if (deleteItem?.unqiueId) {
      const updatedValue = medicineList.filter(
        (item: any) => item.unqiueId !== deleteItem.unqiueId
      );
      dispatch({
        type: Utils.ActionName.DELETE_MEDICINE_DETAILS_LIST,
        payload: updatedValue,
      });
      dispatch({
        type: Utils.ActionName.ADD_MEDICINE_FORMIK,
        payload: { ...FormField.addMedicine },
      });
      setDeleteItem({});
      handleClose();
    }
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              {[
                "#",
                "Drug",
                "Dosage",
                "Formulation",
                "Frequency(per day)",
                "Duration(days)",
                "Food Instructions",
                "Quantity",
                "Supplying Frequency",
                "Repetition Cycle",
                "Total Price",
                "Action",
              ].map((item: any, index: any) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {medicineList?.length > 0 &&
              medicineList.map((row: any, index: any) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {row.medicineName ? row.medicineName?.name : "N/A"}
                    </TableCell>
                    <TableCell>{row.dosage ? row.dosage : "N/A"}</TableCell>
                    <TableCell>
                      {row.formulation
                        ? ` ${filterData(row.formulation, formulation)}`
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {row.frequency
                        ? filterData(row.frequency, frequency)
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {row.duration
                        ? filterData(row.duration, duration)
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {row.foodInstractions
                        ? filterData(row.foodInstractions, food)
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {row.totalQuantity ? row.totalQuantity : "N/A"}
                    </TableCell>
                    <TableCell>
                      {row.supplyfrequency
                        ? filterData(row.supplyfrequency, supplyfrequency)
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {row.repetitionCycle
                        ? filterData(
                            row.repetitionCycle,
                            repetitionCycleOptions
                          )
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {row.totalPrice ? row.totalPrice : "N/A"}
                    </TableCell>
                    <TableCell>
                      <Box>
                        <IconButton
                          // disabled={onlyRead}
                          size="small"
                          color="primary"
                          onClick={() => {
                            // dispatch({
                            //   type: Utils.ActionName.ADD_MEDICINE_FORMIK,
                            //   payload: row,
                            // });
                            handleEditMedicine(row);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          // disabled={onlyRead}
                          size="small"
                          color="error"
                          onClick={() => {
                            setDeleteItem(row);
                            handleOpen();
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalWrapper open={open} handleClose={handleClose}>
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Are you sure you want to delete this item from prescription?
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: 0.5 }}
              onClick={deleteMedicine}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ ml: 0.5 }}
              onClick={handleClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </ModalWrapper>
    </>
  );
}

export default AddMedicineList;
