import { Theme, AppBar, Toolbar } from "@mui/material";
import Container from "@mui/material/Container";
import { createStyles, makeStyles } from "@mui/styles";
// import { useNavigate } from "react-router-dom";
import Utils from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      "&.MuiAppBar-root": {
        backgroundColor: "var(--white)",
        boxShadow: "var(--app-bar-box-shadow)",
      },
    },
    toolbar: {
      "&.MuiToolbar-root": {
        display: "flex",
        justifyContent: "space-between",
        minHeight: theme.spacing(5),
      },
    },
    imgDiv: {},
    img: {
      width: "100%",
      height: "auto",
    },
    login: {
      "&.MuiTypography-root": {
        font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(
          1.4
        )} Poppins, sans-serif`,
        color: theme.palette.primary.main,
        cursor: "pointer",
      },
    },
  })
);

function Header() {
  const classes = useStyles();
  // const navigate = useNavigate();
  return (
    <AppBar position="static" className={classes.appBar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters className={classes.toolbar}>
          <div className={classes.imgDiv}>
            <img className={classes.img} src={Utils.images.Logo} alt="logo" />
          </div>
          {/* <Typography
            className={classes.login}
            onClick={() => {
              Utils.constants.removeSession();
              navigate("/");
            }}
          >
            Logout
          </Typography> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
