import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { filterData } from "../../../utils/commonFunctions";
import {
  duration,
  food,
  formulation,
  frequency,
} from "../../../schema/formField";

function MedicalInformation({ sentClaim, medicineList }: any) {
  let total_price = 0;
  const getFormulation = (form_id: any) => {
    let form = formulation.find((item: any) => item.value == form_id);

    return form?.text;
  };

  const displayQuantityMeasuring = (
    mesuring_qatagory: any,
    quantity_per_category: any,
    form_id: any,
    quantity_measuring: any
  ) => {
    let quantity_measuring_val;
    if ((!mesuring_qatagory || mesuring_qatagory == null) && form_id == null) {
      quantity_measuring_val = quantity_measuring;
    } else {
      if (mesuring_qatagory == null || mesuring_qatagory == "") {
        quantity_measuring_val = getFormulation(form_id);
      } else {
        quantity_measuring_val =
          mesuring_qatagory +
          "(" +
          quantity_per_category +
          " " +
          getFormulation(form_id) +
          ")";
      }
    }
    quantity_measuring_val = quantity_measuring_val
      ? quantity_measuring_val
      : "N/A";
    return quantity_measuring_val;
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              {[
                "Drug Id",
                "Brand Name",
                "API Name",
                "Quantity",
                "Quantity Measuring",
                "Total Price",
              ].map((item: any, index: any) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {medicineList?.length > 0 ? (
              medicineList.map((row: any, index: any) => {
                if (row?.Price) {
                  total_price = parseFloat(row?.Price) + total_price;
                }
                if (!row?.Price) {
                  return;
                }
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row?.drug_id ? row.drug_id : "N/A"}</TableCell>
                    <TableCell>{row?.name ? row.name : "N/A"}</TableCell>
                    <TableCell>
                      {row?.drug_name ? row.drug_name : "N/A"}
                    </TableCell>

                    <TableCell>
                      {row?.Quantity ? row.Quantity : "N/A"}
                    </TableCell>
                    <TableCell>
                      {displayQuantityMeasuring(
                        row?.quantity_measuring_category,
                        row?.quantity_per_category,
                        row?.form_id,
                        row?.quantity_measuring
                      )}
                    </TableCell>

                    <TableCell>{row?.Price ? row.Price : "N/A"}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableCell colSpan={8}>
                <Typography>No medicine available</Typography>
              </TableCell>
            )}

            <TableRow>
              <TableCell
                colSpan={8}
                style={{ textAlign: "end", padding: "100px" }}
              >
                <Typography>
                  <b>Total:</b> {total_price.toFixed(2)}
                </Typography>
              </TableCell>
            </TableRow>
           
          </TableBody>
        </Table>
      </TableContainer>
      {medicineList.length > 0 &&
            (medicineList[0]?.OrderStatus == 5 ||
              medicineList[0]?.OrderStatus == 6) ? (
              <div> <br /> 
                <div>
                  <Typography>
                    {medicineList.length > 0 &&
                    (medicineList[0]?.OrderStatus == 5 ||
                      medicineList[0]?.OrderStatus == 6) ? (
                      <b>
                        {medicineList[0]?.OrderStatus == 5
                          ? "Cancellation "
                          : "Declined"}{" "}
                        Reason :{" "}
                        <span style={{ color: "red" }}>
                          {medicineList[0]?.Reason}
                        </span>
                      </b>
                    ) : null}
                  </Typography>
                </div>
              </div>
            ) : null}
    </>
  );
}

export default MedicalInformation;
