import { Button, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import WorkTime from './editWorkingTime/workTime';
import { Form, Formik } from 'formik';
// import FormField from "../../schema/formField";
import { convertPayloadData } from '../../utils/commonFunctions';
import Schema from '../../schema';
import { getWorkTime, setEditWorkTime } from './action';
// import { setEditWorkTime } from "./action";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backDiv: {
      display: 'flex',
      flexBasis: '15%',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        flexBasis: 'auto',
      },
    },
    arrowBackIcon: {
      color: theme.palette.secondary.main,
    },
    back: {
      '&.MuiTypography-root': {
        font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(
          2
        )} Poppins, sans-serif`,
        color: theme.palette.secondary.main,
        paddingLeft: theme.spacing(0.5),
      },
    },
    stepperDiv: {
      flexBasis: '85%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: 'auto',
        marginTop: theme.spacing(1),
      },
    },
    nextDiv: {
      flexBasis: '15%',
    },
    nextBtn: {
      '&.MuiButton-root': {
        float: 'right',
        color: 'var(--white)',
        padding: theme.spacing(1, 2.5),
        font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(
          1.5
        )} Poppins, sans-serif`,
      },
    },
    mainFormCard: {},
    modalStyle: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      backgroundColor: 'var(--white)',
      boxShadow: '24',
    },
  })
);

function WorkingData() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { body } = useSelector((state: any) => state.WorkTimeReducer);
  const { id } = useSelector(
    (state: any) => state.MyProfilePersonalInfoReducer
  );
  useEffect(() => {
    dispatch(getWorkTime(id));
  }, [dispatch]);
  var myDate = moment().format('MMMM DD, YYYY');

  const sunday = body?.length > 0 && body[0];
  const monday = body?.length > 0 && body[1];
  const tuesday = body?.length > 0 && body[2];
  const wednesday = body?.length > 0 && body[3];
  const thursday = body?.length > 0 && body[4];
  const friday = body?.length > 0 && body[5];
  const saturday = body?.length > 0 && body[6];

  // <------------------------------ Work changes into hours ----------------------------------------------------------------------->

  const mondayEndWork = moment.duration(monday.end_work / 60, 'hours');
  const mondayStartWork = moment.duration(monday.start_work / 60, 'hours');
  const mondayWorkTime = monday.day_type;

  const tuesdayEndWork = moment.duration(tuesday.end_work / 60, 'hours');
  const tuesdayStartWork = moment.duration(tuesday.start_work / 60, 'hours');
  const tuesdayWorkTime = tuesday.day_type;

  const wednesdayEndWork = moment.duration(wednesday.end_work / 60, 'hours');
  const wednesdayStartWork = moment.duration(
    wednesday.start_work / 60,
    'hours'
  );
  const wednesdayWorkTime = wednesday.day_type;

  const thursdayEndWork = moment.duration(thursday.end_work / 60, 'hours');
  const thursdayStartWork = moment.duration(thursday.start_work / 60, 'hours');
  const thursdayWorkTime = thursday.day_type;

  const fridayEndWork = moment.duration(friday.end_work / 60, 'hours');
  const fridayStartWork = moment.duration(friday.start_work / 60, 'hours');
  const fridayWorkTime = friday.day_type;

  const saturdayEndWork = moment.duration(saturday.end_work / 60, 'hours');
  const saturdayStartWork = moment.duration(saturday.start_work / 60, 'hours');
  const saturdayWorkTime = saturday.day_type;

  const sundayEndWork = moment.duration(sunday.end_work / 60, 'hours');
  const sundayStartWork = moment.duration(sunday.start_work / 60, 'hours');
  const sundayWorkTime = sunday.day_type;
  // console.log("thursdayWorkTime",sunday.day_type,"tuesdayWorkTime",tuesdayWorkTime,"fridayWorkTime",fridayWorkTime);

  // <----------------------------Lunch changes into hours ------------------------------------------------------------------------->

  const mondayEndLunch = moment.duration(monday.end_lunch / 60, 'hours');
  const mondayStartLunch = moment.duration(monday.start_lunch / 60, 'hours');

  const tuesdayEndLunch = moment.duration(tuesday.end_lunch / 60, 'hours');
  const tuesdayStartLunch = moment.duration(tuesday.start_lunch / 60, 'hours');

  const wednesdayEndLunch = moment.duration(wednesday.end_lunch / 60, 'hours');
  const wednesdayStartLunch = moment.duration(
    wednesday.start_lunch / 60,
    'hours'
  );

  const thursdayEndLunch = moment.duration(thursday.end_lunch / 60, 'hours');
  const thursdayStartLunch = moment.duration(
    thursday.start_lunch / 60,
    'hours'
  );

  const fridayEndLunch = moment.duration(friday.end_lunch / 60, 'hours');
  const fridayStartLunch = moment.duration(friday.start_lunch / 60, 'hours');

  const saturdayEndLunch = moment.duration(saturday.end_lunch / 60, 'hours');
  const saturdayStartLunch = moment.duration(
    saturday.start_lunch / 60,
    'hours'
  );

  const sundayEndLunch = moment.duration(sunday.end_lunch / 60, 'hours');
  const sundayStartLunch = moment.duration(sunday.start_lunch / 60, 'hours');
  // console.log('body', body, sundayEndLunch, sundayStartLunch);
  // <--------------------------------------------------------------------------------->

  const initialValue = {
    lunchSwitchNameSun: sundayWorkTime === '0' ? false : sunday.start_lunch === "0" && sunday.end_lunch === "0" ? false :true,
    lunchSwitchNameMon: mondayWorkTime === '0' ? false : monday.start_lunch === "0" && monday.end_lunch === "0" ? false :true,
    lunchSwitchNameTue: tuesdayWorkTime === '0' ? false : tuesday.start_lunch === "0" && tuesday.end_lunch === "0" ? false :true,
    lunchSwitchNameWed: wednesdayWorkTime === '0' ? false : wednesday.start_lunch === "0" && wednesday.end_lunch === "0" ? false :true,
    lunchSwitchNameThu: thursdayWorkTime === '0' ? false : thursday.start_lunch === "0" && thursday.end_lunch === "0" ? false :true,
    lunchSwitchNameFri: fridayWorkTime === '0' ? false : friday.start_lunch === "0" && friday.end_lunch === "0" ? false :true,
    lunchSwitchNameSat: saturdayWorkTime === '0' ? false : saturday.start_lunch === "0" && saturday.end_lunch === "0" ? false :true,

    // <----------Lunch changes into hh mm ss------------------------------------------------------------------------------------------->

    lunchTimeNameFromFri:
      myDate +
      ' ' +
      fridayEndLunch.hours() +
      ':' +
      fridayEndLunch.minutes() +
      ':' +
      fridayEndLunch.seconds(),
    lunchTimeNameFromMon:
      myDate +
      ' ' +
      mondayEndLunch.hours() +
      ':' +
      mondayEndLunch.minutes() +
      ':' +
      mondayEndLunch.seconds(),
    lunchTimeNameFromSat:
      myDate +
      ' ' +
      saturdayEndLunch.hours() +
      ':' +
      saturdayEndLunch.minutes() +
      ':' +
      saturdayEndLunch.seconds(),
    lunchTimeNameFromSun:
      myDate +
      ' ' +
      sundayEndLunch.hours() +
      ':' +
      sundayEndLunch.minutes() +
      ':' +
      sundayEndLunch.seconds(),
    lunchTimeNameFromThu:
      myDate +
      ' ' +
      thursdayEndLunch.hours() +
      ':' +
      thursdayEndLunch.minutes() +
      ':' +
      thursdayEndLunch.seconds(),
    lunchTimeNameFromTue:
      myDate +
      ' ' +
      tuesdayEndLunch.hours() +
      ':' +
      tuesdayEndLunch.minutes() +
      ':' +
      tuesdayEndLunch.seconds(),
    lunchTimeNameFromWed:
      myDate +
      ' ' +
      wednesdayEndLunch.hours() +
      ':' +
      wednesdayEndLunch.minutes() +
      ':' +
      wednesdayEndLunch.seconds(),
    lunchTimeNameToFri:
      myDate +
      ' ' +
      fridayStartLunch.hours() +
      ':' +
      fridayStartLunch.minutes() +
      ':' +
      fridayStartLunch.seconds(),
    lunchTimeNameToMon:
      myDate +
      ' ' +
      mondayStartLunch.hours() +
      ':' +
      mondayStartLunch.minutes() +
      ':' +
      mondayStartLunch.seconds(),
    lunchTimeNameToSat:
      myDate +
      ' ' +
      saturdayStartLunch.hours() +
      ':' +
      saturdayStartLunch.minutes() +
      ':' +
      saturdayStartLunch.seconds(),
    lunchTimeNameToSun:
      myDate +
      ' ' +
      sundayStartLunch.hours() +
      ':' +
      sundayStartLunch.minutes() +
      ':' +
      sundayStartLunch.seconds(),
    lunchTimeNameToThu:
      myDate +
      ' ' +
      thursdayStartLunch.hours() +
      ':' +
      thursdayStartLunch.minutes() +
      ':' +
      thursdayStartLunch.seconds(),
    lunchTimeNameToTue:
      myDate +
      ' ' +
      tuesdayStartLunch.hours() +
      ':' +
      tuesdayStartLunch.minutes() +
      ':' +
      tuesdayStartLunch.seconds(),
    lunchTimeNameToWed:
      myDate +
      ' ' +
      wednesdayStartLunch.hours() +
      ':' +
      wednesdayStartLunch.minutes() +
      ':' +
      wednesdayStartLunch.seconds(),
    workingSwitchNameSun: sundayWorkTime === '0' ? false : true,
    workingSwitchNameMon: mondayWorkTime === '0' ? false : true,
    workingSwitchNameTue: tuesdayWorkTime === '0' ? false : true,
    workingSwitchNameWed: wednesdayWorkTime === '0' ? false : true,
    workingSwitchNameThu: thursdayWorkTime === '0' ? false : true,
    workingSwitchNameFri: fridayWorkTime === '0' ? false : true,
    workingSwitchNameSat: saturdayWorkTime === '0' ? false : true,
    // <----------Work time changes into hh mm ss--------------------------------------------------------------------------------------->

    workingTimeNameFromFri:
      myDate +
      ' ' +
      fridayEndWork.hours() +
      ':' +
      fridayEndWork.minutes() +
      ':' +
      fridayEndWork.seconds(),
    workingTimeNameFromMon:
      myDate +
      ' ' +
      mondayEndWork.hours() +
      ':' +
      mondayEndWork.minutes() +
      ':' +
      mondayEndWork.seconds(),
    workingTimeNameFromSat:
      myDate +
      ' ' +
      saturdayEndWork.hours() +
      ':' +
      saturdayEndWork.minutes() +
      ':' +
      saturdayEndWork.seconds(),
    workingTimeNameFromSun:
      myDate +
      ' ' +
      sundayEndWork.hours() +
      ':' +
      sundayEndWork.minutes() +
      ':' +
      sundayEndWork.seconds(),
    workingTimeNameFromThu:
      myDate +
      ' ' +
      thursdayEndWork.hours() +
      ':' +
      thursdayEndWork.minutes() +
      ':' +
      thursdayEndWork.seconds(),
    workingTimeNameFromTue:
      myDate +
      ' ' +
      tuesdayEndWork.hours() +
      ':' +
      tuesdayEndWork.minutes() +
      ':' +
      tuesdayEndWork.seconds(),
    workingTimeNameFromWed:
      myDate +
      ' ' +
      wednesdayEndWork.hours() +
      ':' +
      wednesdayEndWork.minutes() +
      ':' +
      wednesdayEndWork.seconds(),
    workingTimeNameToFri:
      myDate +
      ' ' +
      fridayStartWork.hours() +
      ':' +
      fridayStartWork.minutes() +
      ':' +
      fridayStartWork.seconds(),
    workingTimeNameToMon:
      myDate +
      ' ' +
      mondayStartWork.hours() +
      ':' +
      mondayStartWork.minutes() +
      ':' +
      mondayStartWork.seconds(),
    workingTimeNameToSat:
      myDate +
      ' ' +
      saturdayStartWork.hours() +
      ':' +
      saturdayStartWork.minutes() +
      ':' +
      saturdayStartWork.seconds(),
    workingTimeNameToSun:
      myDate +
      ' ' +
      sundayStartWork.hours() +
      ':' +
      sundayStartWork.minutes() +
      ':' +
      sundayStartWork.seconds(),
    workingTimeNameToThu:
      myDate +
      ' ' +
      thursdayStartWork.hours() +
      ':' +
      thursdayStartWork.minutes() +
      ':' +
      thursdayStartWork.seconds(),
    workingTimeNameToTue:
      myDate +
      ' ' +
      tuesdayStartWork.hours() +
      ':' +
      tuesdayStartWork.minutes() +
      ':' +
      tuesdayStartWork.seconds(),
    workingTimeNameToWed:
      myDate +
      ' ' +
      wednesdayStartWork.hours() +
      ':' +
      wednesdayStartWork.minutes() +
      ':' +
      wednesdayStartWork.seconds(),
  };
  // <-----------------------------------------------END------------------------------------------------------------------------------>

  // console.log("initialValue", initialValue, "friday.start_work / 60",friday.start_work / 60,"friday.end_work",friday.end_work/60, "body",body);

  const workTime = () => {
    return (
      <>
        {body && (
          <Formik
            initialValues={initialValue}
            validationSchema={Schema.workTimeSchema}
            onSubmit={workTimeHandleSubmit}
            enableReinitialize
          >
            {() => {
              return (
                <Form>
                  <WorkTime />
                  <div className="justify-end bg-white mb-20">
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        color: 'var(--white)',
                        fontWeight: 'bold',
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </>
    );
  };

  const workTimeHandleSubmit = (value: any) => {
    // console.log("workTimeHandleSubmitValue", value);
    const payload = convertPayloadData(value);
    console.log('workTimeHandleSubmitPayload', payload);
    dispatch(setEditWorkTime(payload));
  };

  return (
    <div className="page-working-data">
      <div className={classes.mainFormCard}>{workTime()}</div>
    </div>
  );
}

export default WorkingData;
