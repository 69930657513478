import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Theme,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, createStyles } from "@mui/styles";
import { LocalImages } from "../../utils/images";
import { browserName, browserVersion } from "react-device-detect";
import { claimCreateErrMsg, userDeniedLocationErrMsg } from "../../utils/commonFunctions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogActions: {
      "&.MuiDialogActions-root": {
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      },
    },
    dialogTitle: {
      display: "flex",
      alignItems: "center",
      justtifyContent: "space-between",
      "&.MuiDialogTitle-root": {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    dialogContent: {
      "&.MuiDialogContent-root": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "red",
        fontSize: "22px",
      },
    },
    buttonPrimary: {
      color: "white !important",
      textTransform: "capitalize",
    },
  })
);

function LocationPopupModal(props: any) {
  const {
    title,
    content,
    open,
    handleClose,
    handleButtonClick,
    isApiLoading,
    addCustomActions,
    actionItems,
    FRRegisterErrMsg
  } = props;

  const theme = useTheme();
  const classes = useStyles({ theme });

  const getBrowserSupportIMG = () => {
    let guidGIF = ''
    if (browserName == 'Firefox') {
      guidGIF = LocalImages.FirefoxGIF
    } else if (browserName == 'Chrome') {
      guidGIF = LocalImages.ChromeGIF
    } else if (browserName == 'Safari') {
      guidGIF = LocalImages.SafariGIF
    } else if (browserName == 'Opera') {
      guidGIF = LocalImages.OperaGIF
    } else if (browserName == 'Edge') {
      guidGIF = LocalImages.EdgeGIF
    }
    else {
      guidGIF = LocalImages.ChromeGIF
    }
    return guidGIF
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>

        <DialogTitle
          className={classes.dialogTitle}
          style={{ fontSize: "25px" }}
          sx={{ m: 0, p: 2 }} id="customized-dialog-title"
        >
          <span>{!content.isLocationEnabled ? 'Location not enabled' : 'Please check your current location'}</span>
          {handleClose && (
            <CloseIcon
              sx={{
                // position: "absolute",
                // top: 5,
                ml: 2,
                color: (theme) => theme.palette.grey[500],
                cursor: "pointer",
                
              }}
              onClick={handleClose}
            />
          )}
        </DialogTitle>
        <DialogContent className={classes.dialogContent} style={{ fontSize: "18px" }}>
          {!content.isLocationEnabled ? userDeniedLocationErrMsg : FRRegisterErrMsg ? FRRegisterErrMsg : claimCreateErrMsg}
          <br />
          {!content.isLocationEnabled ? <>
            <img
              style={{ width: 'auto', height: '100%' }}
              src={getBrowserSupportIMG()}
              alt="image"
            />
            <br /></> : null}


        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {!addCustomActions && (
            <Button
              variant="contained"
              className={classes.buttonPrimary}
              onClick={handleClose}
            >
              OK
            </Button>
          )}
          {addCustomActions && actionItems}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LocationPopupModal;
