import axios from "axios";
import {
  setSession,
  getAccessToken,
  getUserIdToken,
  setAuthorizationToken,
  removeSession,
  manageUserSession,
  setAccessToken,
  getUserData,
} from "./session";

/**
 * setup axios instance
 */
const $axios = axios.create({
  timeout: 100000,
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    // "LiviaApp-Token": "22e9438557b7c035b09f079c5298952c1782ad8e",
    // "LiviaApp-Token": "ee58136da7e8b8d5dd651e67f01b2564273cd18f",
    "LiviaApp-language": "en",
    "LiviaApp-timezone": "330",
    "LiviaApp-APIVersion": "3.0",
    "LiviaApp-country": "ke",
  },
});

const api_header_code = {
  alreadyEnabled2fa: 2003,
};
const api_error_code = {
  unauthorized: 401,
  accessDenied: 430,
  sessionExpired: 440,
  validationError: 400,
  emailNotVerified: 403,
};

const api_success_code = {
  postSuccess: 201,
  success: 200,
};

const insuranceCompany = {
  MAD: process.env.REACT_APP_MAD,
  UAP: process.env.REACT_APP_UAP,
  EABL:process.env.REACT_APP_EABL,
  KQ:process.env.REACT_APP_KQ,
  JUBILEE:process.env.REACT_APP_JUBILEE,
  FIRST_ASSURANCE: process.env.REACT_APP_FA
  
};

const response_alert = {
  INFORMATION_ALERT_WITHOUT_ACTION: 1,
  ALERT_WITH_ACTION: 2,
  ALERT_WITHOUT_ACTION: 3,
  CONNECTION_ERROR: 4,
  CONTACT_SUPPORT: 5,
};

const user_status = {
  ACTIVE: "1",
  NOT_ACTIVE: "2",
  MODERATION: "3",
  REFUSAL_TO_REGISTRATION: "4",
  BAN: "5",
  IN_REGISTRATION_PROCESS: "6",
};

const policy_status = {
  ACTIVE: "1",
  EXPIRED: "2",
  BALANCE_INSUFFICIENT: "3",
  BENEFITS_UPDATING: "4",
  BENEFITS_CHECK_FROM_LOCAL: "1",
  CLAIM_NOT_ALLOWED: "5",
};

export const roles = {
  PARENT: "0",
  BRANCH: "1",
};

export const bankFieldsConstant = {
  bankName: "",
  branchName: "",
  accNo: "",
  cityName: "",
  bankPayBill: "",
  swiftCode: "",
};

const API_URL = process.env.REACT_APP_API_URL;

/**
 * function to slugify url entered by user for business link
 * @param url
 */
// const slugifyUrl = (url: string) => {
//   var slugify = require("slugify");
//   return slugify(url, { remove: /[*+~.()'"!:@#$%!]/g, lower: true });
// };

// Retrieving the bucket name from env variable
const Bucket = process.env.REACT_APP_BUCKET_NAME;
const BASE_S3_URL = process.env.REACT_APP_S3_BASE_PATH;

/**
 * constant variable for the website
 */
const constant = {
  API_URL,
  Bucket,
  BASE_S3_URL,
  apiUrl: "",
  insuranceCompany,
  setSession,
  // slugifyUrl,
  getUserData,
  axios: $axios,
  removeSession,
  getUserIdToken,
  getAccessToken,
  api_error_code,
  setAccessToken,
  api_header_code,
  api_success_code,
  response_alert,
  user_status,
  policy_status,
  roles,
  manageUserSession,
  setAuthorizationToken,
  hasConnection: navigator.onLine,
  mainMenuLinksArray: ["/dashboard"],
  spaceRegex: /^\S*$/,
  // eslint-disable-next-line
  passwordRegex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9!_@./#&+-\d]{8,}$/,
  // eslint-disable-next-line
  emailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
export default constant;
