import ReactFileReader from "react-file-reader";
import { makeStyles, createStyles } from "@mui/styles";
import { Button, Theme, Avatar, FormHelperText } from "@mui/material";
import { useField } from "formik";

type InputFilePros = {
  handleFiles: any;
  name: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      "&.MuiAvatar-root": {
        width: theme.spacing(10),
        height: theme.spacing(10),
        backgroundColor: theme.palette.secondary.light,
        position: "relative",
      },
    },
    lineOne: {
      width: theme.spacing(1),
      height: theme.spacing(7),
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(1.5),
      position: "absolute",
    },
    lineTwo: {
      transform: "rotate(90deg)",
    },
  })
);

function CustomFileUpload({ handleFiles, name }: InputFilePros) {
  const classes = useStyles();
  const [field, mata] = useField(name);
  return (
    <div>
      <ReactFileReader
        handleFiles={handleFiles}
        base64={true}
        multipleFiles={false}
        fileTypes={[".jpeg", ".jpg", ".png"]}
      >
        <Button className="btn" title="Please upload  image" {...field} sx={{ padding: "0px", margin: "3px" }}>
          <Avatar className={classes.avatar} variant="square">
            <div className={classes.lineOne}></div>
            <div className={[classes.lineOne, classes.lineTwo].join(" ")}></div>
            <div></div>
          </Avatar>
        </Button>
      </ReactFileReader>
      {mata && mata.touched && mata.error && <FormHelperText sx={{ color: "error.main" }}>{mata.error}</FormHelperText>}
    </div>
  );
}

export default CustomFileUpload;
