import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { filterData } from "../../../utils/commonFunctions";
import {
  duration,
  food,
  formulation,
  frequency,
} from "../../../schema/formField";

function MedicalInformation({ sentClaim, medicineList }: any) {
  console.log(medicineList);
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              {[
                "Id",
                "Drug",
                "Dosage",
                "Formulation",
                "Frequency(per day)",
                "Duration(days)",
                "Food Instructions",
                "Total Price",
              ].map((item: any, index: any) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {medicineList?.length > 0 ? (
              medicineList.map((row: any, index: any) => {
                if (!row?.price) {
                  return;
                }
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row?.drug_id ? row.drug_id : "N/A"}</TableCell>
                    <TableCell>
                      {row?.drug_name ? row.drug_name : "N/A"}
                    </TableCell>
                    {sentClaim ? (
                      <TableCell>
                        {row?.doctor_dosage ? row.doctor_dosage : "N/A"}
                      </TableCell>
                    ) : (
                      <TableCell>{row?.dosage ? row.dosage : "N/A"}</TableCell>
                    )}
                    <TableCell>
                      {row?.form
                        ? `${filterData(row?.form, formulation)}`
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {row?.frequency
                        ? filterData(row.frequency, frequency)
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {row?.duration
                        ? filterData(row.duration, duration)
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {row?.food_instruction
                        ? filterData(row.food_instruction, food)
                        : "N/A"}
                    </TableCell>
                    {sentClaim ? (
                      <TableCell>{row?.price ? row.price : "N/A"}</TableCell>
                    ) : (
                      <TableCell>
                        {row?.quantity_measuring?.toLowerCase() === "bottle" &&
                        row?.new_quantity
                          ? Number(row.price) * Number(row?.new_quantity)
                          : row?.price && row?.quantity
                          ? Number(row.price) * Number(row.quantity)
                          : "N/A"}
                        {/* {row?.price && row?.quantity
                          ? Number(row.price) * Number(row.quantity)
                          : "N/A"} */}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            ) : (
              <TableCell colSpan={8}>
                <Typography>No medicine available</Typography>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default MedicalInformation;
