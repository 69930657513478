export const steps = ["Create Profile", "Work Time"];
export const steps2 = ["Edit Profile", "Work Time"];

export const claimSteps = [
  "Patient Information",
  "Transcribe Prescription",
  "Patient Verification",
  "Upload Prescription",
];

export const statusArray = [
  { id: 2, name: "Awaiting Pre-authorisation" },
  { id: 3, name: "Accepted" },
  { id: 4, name: "Declined" },
  { id: 6, name: "In Process" },
  { id: 7, name: "Loaded" },
  { id: 8, name: "Payment Ready" },
  { id: 9, name: "Settled" },
];

export const OrderStatus = [
  { id: "1", name: "Pending Quotation" },
  { id: "2", name: "Quote Received" },
  { id: "3", name: "Prepare order" },
  { id: "4", name: "Completed Order" },
  { id: "5", name: "Cancelled Order" },
  { id: "6", name: "Declined Order" },
  

]

export const payment_status = [
  { id: 3, name: "Loaded" },
  { id: 6, name: "Refund" },
  { id: 8, name: "Blocked funds" },
  { id: 9, name: "Fund in withdrawal process" },
  { id: 10, name: "Payment done" },
  { id: 7, name: "Ready for Payment" },
];

export const paymentStatusArr = [
  { id: "", name: "All" },
  { id: "paid", name: "Paid" },
  { id: "pending", name: "Pending" },
  { id: "cancel", name: "Cancel" },

]

export const subscriptionStatusArr = [
  { id: "", name: "All" },
  { id: "active", name: "Active" },
  { id: "cancel", name: "Cancel" },
  { id: "pending", name: "Pending" },

]
