import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import ClearIcon from "@mui/icons-material/Clear";
import Utils from "../../../utils";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import { useSelector } from "react-redux";
import { ReducersModal } from "../../../modal";

function FinalTextField({ name, setFinalDiagnosis }: any) {
  const [value, setValue] = useState<any>();
  const [inputValue, setInputValue] = useState<any>("");
  const [clearInputValue, setClearInputValue] = useState<any>();
  const [options, setOptions] = useState<any[]>([]);
  const [chipData, setChipData] = useState<any[]>([]);
  const [mata]: any = useField(name);
  const { setFieldValue } = useFormikContext();
  const debouncedSearchTerm: string = useDebounce<string>(
    inputValue && inputValue,
    500
  );
  const { medicineList } = useSelector(
    (state: ReducersModal) => state.createMedicineDetailsListReducer
  );
  const { patientInsurance } = useSelector(
    (state: ReducersModal) => state.InsuranceCompanyReducer
  );

  const { onlyRead, finalDiagnosisDetail } = useSelector(
    (state: ReducersModal) =>
      state.createClaimStepTwoDoctorAndHospitalAndICDDataReducer
  );

  useEffect(() => {
    if (finalDiagnosisDetail) {
      setValue(finalDiagnosisDetail);
      setFieldValue(name, finalDiagnosisDetail);
      setChipData(finalDiagnosisDetail);
    }
  }, [finalDiagnosisDetail]);

  useEffect(() => {
    let actives = true;

    if (debouncedSearchTerm === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    let params = `?offset=${0}&limit=${20}&code=${debouncedSearchTerm}&insurance_company_id=${patientInsurance}`;

    if (debouncedSearchTerm?.length > 0) {
      Utils.constants.getAccessToken();

      Utils.api.getApiCall(
        Utils.endPoints.search,
        params,
        (response: any) => {
          if (response.status === 200) {
            const results: any = [...response?.data.body];
            if (actives) {
              let newOptions: any = [];

              if (value) {
                newOptions = [value];
              }

              if (results) {
                newOptions = [...newOptions, ...results];
              }

              setOptions(newOptions);
            }
          }
        },
        (err: any) => {
          console.error(err);
        }
      );
    }

    return () => {
      actives = false;
    };
  }, [value, debouncedSearchTerm]);

  useEffect(() => {
    if (chipData?.length > 0) {
      setFinalDiagnosis(chipData);
    } else {
      setFinalDiagnosis(null);
    }
  }, [chipData]);

  const onHighlightChange = async (event: any, newInputValue: any) => {
    setOptions(newInputValue ? [newInputValue, ...options] : options);
    setValue(newInputValue);
    if (newInputValue && newInputValue.code) {
      if (newInputValue) {
        setFieldValue(name, newInputValue);
        setChipData((chips) => {
          const findPreChip = chips.find(
            (element) => element.code === newInputValue.code
          );
          if (findPreChip) {
            return [...chipData];
          } else {
            return [...chipData, newInputValue];
          }
        });
        // setClearInputValue(true);
      }
    }
    if (newInputValue === null) {
      setFieldValue(name, null);
    }
  };
  // console.log("value", value);
  const handleDelete = (chipToDelete: any) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.code !== chipToDelete.code)
    );
  };
  // console.log(inputValue, "<===inputValue", clearInputValue, "<---clear");
  // console.log("options", options);
  return (
    <Box>
      <Autocomplete
        sx={{
          mr: 1,
        }}
        disabled={medicineList?.length > 0}
        id="autocompleteLocations"
        fullWidth
        size="small"
        // loading={options.length > 0 ? false : true}
        // loadingText="Final Diagnosis searching..."
        clearIcon={<ClearIcon color="primary" fontSize="small" />}
        options={clearInputValue ? options : []}
        getOptionLabel={(option: any) => option?.display_result}
        autoHighlight={true}
        autoSelect={true}
        clearOnEscape
        // value={value}
        // inputValue={inputValue}
        inputValue={clearInputValue ? inputValue : ""}
        onInputChange={(event: any, newInputValue) => {
          // console.log(
          //   "newinputvalue ===>",
          //   newInputValue,
          //   "event ===>",
          //   event?.target?.value
          // );
          setClearInputValue(event?.target?.value);
          setInputValue(newInputValue);
        }}
        onChange={onHighlightChange}
        renderOption={(props, option: any) => {
          return (
            <li {...props} key={Math.random()}>
              {option.display_result}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            label="Final Diagnosis (ICD10)"
            error={Boolean(mata && mata.touched && mata.error)}
            helperText={mata && mata.touched && mata.error}
            fullWidth
          />
        )}
      />
      {medicineList?.length > 0 ? (
        <Box>
          {chipData.map((data: any) => {
            return (
              <Box key={data.code} sx={{ my: 0.5 }}>
                <Chip
                  sx={{ color: "var(--white)" }}
                  color="primary"
                  label={`${data?.code}-(${data?.code_description})`}
                />
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box>
          {chipData.map((data: any) => {
            return (
              <Box key={data.code} sx={{ my: 0.5 }}>
                <Chip
                component='div'
                  sx={{ color: "var(--white)", cursor:"pointer"  }}
                  color="primary"
                  label={`${data?.code}-(${data?.code_description})`}
                  onDelete={handleDelete(data)}
                  title={`${data?.code}-(${data?.code_description})`}
                />
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

export default FinalTextField;
