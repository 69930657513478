import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Avatar, Icon, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import ReactFileReader from "react-file-reader";

import { toast } from "react-toastify";

//import "./ETIMSDocument.css";

import b64toBlob from "b64-to-blob";
import { LocalImages } from "../../utils/images";
import { deleteFromS3, uploadToS3 } from "../upload";
import {
  checkFileSize,
  checkEFileType,
  generateId,
} from "../../utils/commonFunctions";
const { PdfIcon, CloseIcon } = LocalImages;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    topFormCard: {
      padding: theme.spacing(1.5),
      backgroundColor: "var(--white)",
      marginBottom: theme.spacing(1.5),
    },
    uploadPrescriptionCover: {
      // textAlign: 'center',
      // padding: '15px',
      // boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2)',
      // borderRadius: '5px',
    },
    avatar: {
      "&.MuiAvatar-root": {
        width: theme.spacing(10),
        height: theme.spacing(10),
        backgroundColor: theme.palette.secondary.light,
        position: "relative",
      },
    },
    avatarDoc: {
      "&.MuiAvatar-root": {
        width: theme.spacing(10),
        height: theme.spacing(10),
        backgroundColor: theme.palette.secondary.light,
        position: "relative",
      },
    },
    closeIcon: { position: "absolute", top: -4, left: 77, cursor: "pointer" },
  })
);

const ETIMSDocument = (props) => {
  const classes = useStyles();
  const { setETIMSDoc, disabled, eTIMSDoc } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [preViewImg, setPreViewImg] = useState("");
  const [isModalOpen, setOpenModal] = useState(false);
  const imageIcon = (image) => {
    if (image.includes(".pdf") || image.includes(".PDF")) {
      return PdfIcon;
    } else {
      return `${image}`;
    }
  };

  const b64ToBlob = (data, type) => {
    const contentType = type;
    var blob = b64toBlob(data, contentType);
    return blob;
  };

  const uploadDocument = (files, fileType) => {
    if (checkFileSize(files.fileList[0])) {
      this.toastId4 = toast.error("File size should be less than 8 MB", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    if (checkEFileType(files)) {
      this.toastId4 = toast.error(
        "Please upload the file in one of the following format - JPG,PNG or PDF",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return false;
    }

    if (files.base64) {
      
      let fileExt = files.fileList[0].name;
			var n = fileExt.lastIndexOf(".");
			fileExt = fileExt.substring(n + 1);
      const fileName = generateId() + "_eTIMS." + fileExt;
      const actualFIle = b64ToBlob(
        files.base64.split(",")[1],
        files.fileList[0].type
      );
      setIsLoading(true);
      uploadToS3(actualFIle, fileName, files.fileList[0].type)
        .then((res) => {
          

          setETIMSDoc(res);
          setIsLoading(false);
        })
        .catch((err) => {
          
          setIsLoading(false);
        });
    }
  };

  const deleteSupportingDocument = (valueName, deleteIndex) => {
    setIsLoading(true);
    deleteFromS3(valueName).then((res) => {
      setETIMSDoc("");

      setIsLoading(false);
    });
  };
  const viewImage = (img) => {
    
    const imgArr = [".jpg", ".png", ".jpeg", ".gif", ".JPG", ".PNG", ".JPEG"];
    const imgType = img.split(".")[1];

    if (imgArr.includes(img)) {
      setPreViewImg(`${img}`);
      setOpenModal(true);
    } else {
      window.open(`${img}`, "_blank");
    }
  };
  return (
    <div className="supporting-document-cover bg-white-with-padding border mb-20 cover">
      <h3 style={{paddingBottom:"15px"}}>Upload ETIMS Invoice</h3>
      <div
        className=""
        style={{
          marginBottom: "50px",
          alignItems: "flex-start",
          flexWrap: "inherit",
        }}
      >
        {eTIMSDoc ? (
          <>
            <div
              key={`test-detail-${eTIMSDoc}`}
              style={{
                alignItems: "center",
                marginBottom: "10px",
                width: "100px",
                height: "100px",
                background: "#d3d3d3",
                position: "relative",
              }}
            >
              <Avatar
                className="uploaded-photo"
                alt="test-img"
                style={{ width: "100px", height: "100px", borderRadius: "0" }}
                src={imageIcon(eTIMSDoc)}
                onClick={() => viewImage(eTIMSDoc)}
              />
              {!disabled && (
                <Avatar
                  className="remove-photo"
                  style={{ marginTop: "-110px", marginLeft: "98px" }}
                  src={CloseIcon}
                  onClick={() =>
                    !disabled ? deleteSupportingDocument(eTIMSDoc) : ""
                  }
                  title="Remove photo"
                />
              )}
              <div
                style={{
                  fontSize: "13px",
                  bottom: "-25px",
                  position: "absolute",
                }}
              >
                <a
                  className="pointer"
                  style={{ fontWeight: "500" }}
                  onClick={() => viewImage(eTIMSDoc)}
                ></a>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {!eTIMSDoc ? (
          <div className="upload-image-cover">
            <ReactFileReader
              style={{ width: "100px" }}
              fileTypes={[
                ".jpg",
                ".png",
                "pdf",
                ".jpeg",
                ".PNG",
                ".JPEG",
                ".PDF",
              ]}
              handleFiles={(files) =>
                uploadDocument(files).then((res) => {
                  if (res.status === 200) {
                    setIsLoading(false);
                    let updateSupportingDoc = [...props.supportedDocument];
                    updateSupportingDoc.push(res.data.image);
                    props.setSupportingDoc(updateSupportingDoc);
                  }
                })
              }
              base64={true}
            >
              {!disabled && (
                <div style={{ textAlign: "justify", textAlignLast: "left" }}>
                  <Button
                    style={{
                      display: "block",
                      width: "100px",
                      height: "100px",
                    }}
                    className="btn-with-loader"
                  >
                    <Avatar
                      className={classes.avatar}
                      variant="square"
                      style={{ boxShadow: "0px 1px 2px 1px rgba(0,0,0,0.2)" }}
                    >
                      <UploadFileIcon color="primary" fontSize="large" />
                    </Avatar>
                  </Button>
                </div>
              )}
            </ReactFileReader>
          </div>
        ) : (
          ""
        )}
        <h6
          style={{
            margin: "10px 0px 0px",
            fontSize: "16px",
          }}
        >
          <p
            style={{
              color: "red",
              fontSize: "15px",
              fontWeight: "bold",
            }}
          >
            Claims submitted without a valid eTIMS invoice will not be honored.
          </p>
        </h6>
      </div>
    </div>
  );
};
export default ETIMSDocument;
