import React, { useEffect, useState } from "react";
import { Input, Button, FormControl } from "@mui/material";
import { phoneNoValidator } from "../../../utils/commonFunctions";
import CustomModal from "../../../components/modal/customModal";

function EditPaymentPopup(props: any) {
	const {
		popFor,
		onHide,
		addPm,
		isApiLoadingOnAddPayment,
		payBillNo,
		tillNo,
		phone,
		areWeEditing,
		priority,
	} = props;
	const [fieldValue, setFieldValue] = useState("");
	const [country, setCountry] = useState("");
	const [countryValue, setCountryValue] = useState("");
	const [invalidPhone, setInvalidPhone] = useState("");
	const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);

	useEffect(() => {
		if (payBillNo) {
			setFieldValue(payBillNo);
		} else if (tillNo) {
			setFieldValue(tillNo);
		} else if (phone) {
			setFieldValue(phone);
		}
	}, [payBillNo, tillNo, phone]);

	const changeHandler = (event: any) => {
		if (/^\d*$/.test(event.target.value)) setFieldValue(event.target.value);
	};

	const onPhoneBlur = () => {
		setInvalidPhone(phoneNoValidator(fieldValue));
	};

	const confirmBtnHandler = () => {
		let payload = null;
		setIsConfirmBtnClicked(true);
		onPhoneBlur();
		if (popFor === "payBill" && fieldValue) {
			payload = {
				number: fieldValue,
				pay_type: "paybill",
				system: "mpesa",
			};
		} else if (popFor === "tillNo" && fieldValue) {
			payload = {
				number: fieldValue,
				pay_type: "tillnumbers",
				system: "mpesa",
			};
		} else if (popFor === "mobileNumber" && fieldValue && !invalidPhone) {
			payload = {
				number: fieldValue,
				pay_type: "phone",
				system: "mpesa",
			};
		}
		if (payload) {
			if (areWeEditing) {
				payload = {
					...payload,
					priority: priority,
				}
			}

			addPm(payload);
			onHide();
		}
	};

	return (		
		<CustomModal    //PaymentConfirm
			title={popFor === "payBill"
					? "Pay Bill"
					: popFor === "tillNo"
					? "Till Number"
					: "Mobile Number"}
			content={popFor === "payBill" ? (
				<>
					<h4 className="mt-20 mb-15">
						Please enter your mPesa registered paybill number:
					</h4>
					<FormControl>
							<Input
								placeholder="Paybill Number"
								value={fieldValue}
								onChange={changeHandler}
								name="paybillModal"
								id="paybillModal"
								// maxLength="20"
							/>
							{fieldValue.length === 0 && isConfirmBtnClicked && (
								<p style={{color: "red", fontSize: "14px", marginTop: "10px"}}>* Please enter your pay bill number</p>
							)}
					</FormControl>{" "}
				</>
			) : popFor === "tillNo" ? (
				<>
					<h4 className="mt-20 mb-15">
						Please enter your mPesa registered till number:
					</h4>
					<FormControl>
							<Input
								placeholder="Till Number"
								value={fieldValue}
								onChange={changeHandler}
								name="tillModal"
								id="tillModal"
								// maxLength="30"
							/>
							{fieldValue.length === 0 && isConfirmBtnClicked && (
								<p style={{color: "red", fontSize: "14px", marginTop: "10px"}}>* Please enter your till number</p>
							)}
					</FormControl>
				</>
			) : (
				<>
					<h4 className="mt-20 mb-15">Please enter your Mobile Number.</h4>
					<FormControl>
								<Input
									type="text"
									placeholder="Enter Phone No."
									name="phone_no"
									id="phone_no"
									value={fieldValue}
									// maxLength="15"
									onChange={changeHandler}
									onBlur={onPhoneBlur}
								/>
								{isConfirmBtnClicked && invalidPhone ? (
									<p style={{color: "red", fontSize: "14px", marginTop: "10px"}}>{"* " + invalidPhone}</p>
								) : (
									invalidPhone === "" &&
									fieldValue.length > 9 && (
										<p style={{color: "red", fontSize: "14px", marginTop: "10px"}}>
											* Phone no can not more than 9 digits{" "}
										</p>
									)
								)}
					</FormControl>
				</>
			)}
			open={popFor}
			handleClose={onHide}
			addCustomActions={true}
			actionItems={
				<>
					<Button
						variant="outlined"
						className={`capitalize`}
						sx={{ width: "auto" }}
						onClick={onHide}
						disabled={isApiLoadingOnAddPayment}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						className={`capitalize color-white`}
						sx={{ width: "auto" }}
						onClick={confirmBtnHandler}
						disabled={isApiLoadingOnAddPayment}
					>
						Confirm
					</Button>
				</>
			}
		/>
	);
}

export default EditPaymentPopup;
