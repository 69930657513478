import { useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Chip,
  Typography,
  IconButton,
} from "@mui/material";
import { Formik, Form } from "formik";
import Schema from "../../../schema";
import { useSelector, useDispatch } from "react-redux";
import CustomButton from "../../../components/formsUi/button/customButton";
import { useEffect } from "react";
import { ReducersModal } from "../../../modal";
//@ts-ignore
import FormField from "../../../schema/formField";
import Utils from "../../../utils";
// import HospitalSearch from "./search/hospitalSearch";
// import FinalTextField from "./search/finalDiagnosis";
// import DoctorSearch from "./search/doctorSearch";
import { calculateTotalAmount, convertTranscribeHospitalPrescriptionPayload } from "../../../utils/commonFunctions";
import AddMedicineList from "./addMedicineList";
import AddMedicinePopUp from "../../myClaims/sentClaims/addMedicinePopUp";
import { addTranscribePrescriptions, getClaimBalance } from ".././action";
import { getCountry } from "../../login/action";
import { getClaimDetails } from "../../myClaims/action";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

function TranscribePrescription(props:any) {
  const [open, setOpen] = useState(false);
  const [formikInitialValue, setFormikInitialValue] = useState<any>(FormField.transcribe);
  const [finalDiagnosis, setFinalDiagnosis] = useState<any>({});
  const [editAddMedicineData, setEditAddMedicineData] = useState<any>({});
  // const [medicineList, setmedicineList] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { draftedClaimId } = useParams();
  const {hospitalOrDoctorPrescription} = props
  // const {
  //   // claimId,
  //   employeeNumber,
  // } = useSelector(
  //   (state: ReducersModal) => state.InsurancePatientProfileReducer
  // );
  // const { patientInsurance } = useSelector(
  //   (state: ReducersModal) => state.InsuranceCompanyReducer
  // );
  const initialValuesFormik = useSelector((state: ReducersModal) => state.addmidicineFormitModalReduce);

  const { medicineList } = useSelector((state: ReducersModal) => state.createMedicineDetailsListReducer);

  const claimDetail = useSelector((state: ReducersModal) => state.claimDetailReducer);
  const { balanceClaim } = useSelector((state: ReducersModal) => state.InsurancePatientProfileReducer);
  // const { draftedClaimId } = useSelector(
  //   (state: ReducersModal) => state.DoctorPrescriptionsReducer
  // );
  const {
    doctor_id,
    doctor_name,
    doctor_phone,
    hospital_id,
    hospital_name,
    phone_list,
    private_practice,
    icd10_list,
    beneficiary,
    order_data,
    user_claim_id,
  } = claimDetail;

  // const claimIdSession = localStorage.getItem("draftedClaimId");

  console.log(
    "ClaimId",
    draftedClaimId,
    "claimDetail",
    claimDetail,
    "balanceClaim",
    balanceClaim
    // "formikInitialValuekk",
    // formikInitialValue
  );

  useEffect(() => {
    if (user_claim_id) {
      dispatch(getClaimBalance(user_claim_id));
    }
  }, [user_claim_id, dispatch]);

  useEffect(() => {
    if (draftedClaimId) {
      dispatch(getClaimDetails(draftedClaimId, "doctorPres"));
    }
  }, [draftedClaimId, dispatch]);

  useEffect(() => {
    dispatch(getCountry());
  }, [dispatch]);

  useEffect(() => {
    if ((doctor_name && doctor_phone) || hospital_name || phone_list) {
      setFormikInitialValue({
        ...formikInitialValue,
        doctor: doctor_name,
        docphone: doctor_phone,
        hospital: hospital_name,
        hosphone: phone_list,
        checked: private_practice,
      });
    }
  }, [doctor_name, doctor_phone, hospital_name, phone_list, private_practice]);

  useEffect(() => {
    if (icd10_list.length > 0) {
      setFinalDiagnosis(icd10_list);
    }
  }, [icd10_list]);

  // useEffect(() => {
  //   if (order_data?.drugs?.length > 0) {
  //     let drugArr: any[] = [];
  //     for (const med of order_data?.drugs) {
  //       var {
  //         doctor_comment,
  //         dosage,
  //         drug_id,
  //         drug_name,
  //         duration,
  //         food_instruction,
  //         form,
  //         frequency,
  //         pharmacy_dosage,
  //         pharmacy_quantity,
  //         price,
  //         quantity,
  //         type,
  //       } = med;
  //       const drug = {
  //         medicineName: {
  //           name: drug_name,
  //           id: drug_id,
  //         },
  //         dosage,
  //         pricePerUnit: price,
  //         repetitionCycle: "",
  //         supplyfrequency: "",
  //         totalPrice: String(Number(pharmacy_quantity) * Number(price)),
  //         totalQuantity: pharmacy_quantity,
  //         formulation: form,
  //         frequency,
  //         duration,
  //         foodInstractions: food_instruction,
  //         unqiueId: initialValuesFormik?.unqiueId || uid(),
  //       };
  //       dispatch({
  //         type: Utils.ActionName.CREATE_MEDICINE_DETAILS_LIST,
  //         payload: drug,
  //       });
  //       drugArr.push(drug);
  //     }
  //     console.log(order_data?.drugs, "order_data?.drugs", drugArr);
  //     // setmedicineList(drugArr);
  //   }
  // }, [order_data?.drugs]);

  const validateForm = ({ checked, doctor, hosphone }: any) => {
    // if (!checked) {
    //   if (!doctor || !hosphone) {
    //     Utils.showAlert(2, "Please checked private practice");
    //     return false;
    //   }
    // }
    if (!finalDiagnosis || !(finalDiagnosis.length > 0)) {
      Utils.showAlert(2, "Please add Final Diagnosis");
      return false;
    }

    if (calculateTotalAmount(medicineList) > balanceClaim) {
      Utils.showAlert(2, "Insufficient balance");
      return false;
    }

    return true;
  };

  const handleClose = () => {
    dispatch({
      type: Utils.ActionName.ADD_MEDICINE_FORMIK,
      payload: FormField.addMedicine,
    });
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClearClose = () => {
    // dispatch({
    //   type: Utils.ActionName.ADD_MEDICINE_FORMIK,
    //   payload: FormField.addMedicine,
    // });
    handleClose();
  };

  const handleOpenAddMedicine = (editData: any) => {
    setEditAddMedicineData(editData);
    handleOpen();
  };

  const handleTranscribePrescription = (values: any) => {
    
    const validate = validateForm(values);
    console.log("values", values, validate);

    if (validate) {
      const payload = convertTranscribeHospitalPrescriptionPayload({
        medicineList,
        doctor_id,
        hospital_id,
        finalDiagnosis,
        values,
        employeeNumber: beneficiary?.employee_number,
        otp_not_send:1,
        medicine_deliver_message:1,
        hospital_prescription_order:1
      });
      //console.log("payload", payload);
      if (!draftedClaimId) {
        Utils.showAlert(2, "Claim id not provided");
      }
      if (payload && draftedClaimId) {
        
        dispatch(addTranscribePrescriptions(payload, draftedClaimId));
      }
    }
  };

  return (
    <div className="page-transcribe-prescription">
      <Box className="bg-white mb-20">
        <Formik
          initialValues={formikInitialValue}
          validationSchema={Schema.TranscribeSchema}
          onSubmit={handleTranscribePrescription}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid item xs={12} sm={6} md={12}>
                    <Box sx={{ display: "flex" }}>
                      {/* <DoctorSearch
                        name="doctor"
                        setDoctorData={setDoctorData}
                        label="Prescribing Doctor's Name"
                        size="small"
                      /> */}
                      <TextField
                        name="doctor"
                        label="Prescribing Doctor's Name"
                        size="small"
                        autoComplete="off"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.doctor}
                        error={Boolean(errors.doctor && touched.doctor && errors.doctor)}
                        helperText={errors.doctor && touched.doctor && errors.doctor}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Grid item xs={12} sm={6} md={12}>
                    <TextField
                      name="docphone"
                      label="Doc Phone"
                      size="small"
                      autoComplete="off"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.docphone && `+${values.docphone}`}
                      error={Boolean(errors.docphone && touched.docphone && errors.docphone)}
                      helperText={errors.docphone && touched.docphone && errors.docphone}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid item xs={12} sm={6} md={12}>
                    <Box sx={{ display: "flex" }}>
                      {/* <HospitalSearch
                        name="hospital"
                        label="Institution/Hospital/Clinic Name"
                        setHospitalData={setHospitalData}
                        size="small"
                      /> */}
                      <TextField
                        disabled={values.checked}
                        name="hospital"
                        label="Institution/Hospital/Clinic Name"
                        size="small"
                        autoComplete="off"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.hospital}
                        error={Boolean(errors.hospital && touched.hospital && errors.hospital)}
                        helperText={errors.hospital && touched.hospital && errors.hospital}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Grid item xs={12} sm={6} md={12}>
                    <TextField
                      disabled={values.checked}
                      className="w-100"
                      name="hosphone"
                      label="Hospital Phone"
                      size="small"
                      autoComplete="off"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.hosphone && `+${values.hosphone}`}
                      error={Boolean(errors.hosphone && touched.hosphone && errors.hosphone)}
                      helperText={errors.hosphone && touched.hosphone && errors.hosphone}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="bg-gray mt-20 mb-20" style={{ maxWidth: "500px" }}>
                    <h4 style={{ color: "red" }}>
                      * Vitamins, Minerals, Supplements, Nutritional Products, Pregnancy Care Products, Baby Foods and
                      Medical Devices are not covered by the insurance policy. Seek Pre-authorization for these
                      medications by calling 0740869442 before dispensing the mentioned products.
                      {/* Final Diagnosis (ICD10) */}
                    </h4>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {/* <FormControlLabel
                    sx={{
                      mt: 2,
                    }}
                    control={
                      <Checkbox
                        name="checked"
                        checked={values.checked}
                        onChange={handleChange}
                        inputProps={{
                          readOnly: true,
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Private Practice"
                  /> */}
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  {/* <FinalTextField
                    name="finalDiagnosis"
                    setFinalDiagnosis={setFinalDiagnosis}
                    label="Final Diagnosis (ICD10)"
                    size="small"
                  /> */}
                  {/* <TextField
                    name="finalDiagnosis"
                    label="Final Diagnosis (ICD10)"
                    size="small"
                    autoComplete="off"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.finalDiagnosis}
                    error={Boolean(
                      errors.finalDiagnosis &&
                        touched.finalDiagnosis &&
                        errors.finalDiagnosis
                    )}
                    helperText={
                      errors.finalDiagnosis &&
                      touched.finalDiagnosis &&
                      errors.finalDiagnosis
                    }
                  /> */}
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: (theme) => theme.spacing(1.6),
                    }}
                  >
                    Final Diagnosis (ICD10)
                  </Typography>
                  <Box>
                    {finalDiagnosis.length > 0 &&
                      finalDiagnosis.map((data: any) => {
                        return (
                          <Box key={data.code} sx={{ my: 0.5 }}>
                            <Chip
                              sx={{ color: "var(--white)" }}
                              color="primary"
                              label={data?.icd10}
                              // onDelete={handleDelete(data)}
                            />
                          </Box>
                        );
                      })}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={7} className="center">
                  <h3 className="mt-10">Total Amount: {calculateTotalAmount(medicineList)} KES </h3>
                </Grid>
                <Grid item xs={12} sm={3} md={2} className="right"></Grid>
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "end", py: 1.5 }}>
                
              </Box>
              <Box>
                <AddMedicineList
                  doctorPrescriptions
                  medicineList={medicineList}
                  handleOpenAddMedicine={handleOpenAddMedicine}
                />
              </Box>

              <div style={{ marginTop: 30 }} className="justify-end">
                <CustomButton
                  // isSubmitting={isSubmitting}
                  className="capitalize color-white"
                  sx={{ width: "auto" }}
                >
                  Proceed
                </CustomButton>
              </div>
            </Form>
          )}
        </Formik>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          // className={classes.customContentStyle}
        >
          <Box sx={{ display: "flex" }}>
            <DialogTitle
              sx={{
                padding: 0,
                marginTop: "10px",
                textAlign: "center",
                fontWeight: "bold",
                position: "relative",
                flexBasis: "100%",
              }}
              id="responsive-dialog-title"
            >
              {initialValuesFormik?.unqiueId ? "Edit" : "Add"} Medicines
              <Box
                sx={{
                  position: "absolute",
                  right: "20px",
                  top: "-3px",
                  width: "25px",
                  cursor: "pointer",
                }}
              >
                <IconButton sx={{ p: 0 }} color="primary" aria-label="close" onClick={handleClearClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
          </Box>
          <AddMedicinePopUp doctorPrescriptions handleClose={handleClose} />
        </Dialog>
      </Box>
    </div>
  );
}

export default TranscribePrescription;
