import { MobileTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { FormLabel, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { useEffect } from "react";

function TimePickerWrapper({ name, defaultValue, ...otherProps }: any) {
  const [field, mata] = useField(name);
  const { setFieldValue } = useFormikContext();
  // console.log("otherProps",otherProps);

  useEffect(() => {
    if (!field.value) {
      setFieldValue(name, defaultValue);
    }
  }, [defaultValue, field.value, name, setFieldValue]);

  const handleChange = (value: any) => {
    setFieldValue(name, value);
    // console.log("defaultValue",value.toString());
  };

  const configTextfield = {
    ...field,
    ...otherProps,
    onChange: handleChange,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileTimePicker
        toolbarTitle
        {...configTextfield}
        renderInput={(params) => <TextField {...params} size="small" />}
      />
      <FormLabel error sx={{ fontSize: "14px" }}>
        {mata && mata.touched && mata.error && "Invaild time"}
      </FormLabel>
    </LocalizationProvider>
  );
}

export default TimePickerWrapper;
