import { Box } from "@mui/material";
import Switch from "../../components/formsUi/switch";
import TimePicker from "../../components/formsUi/timePicker";

type InputTimeCardProps = {
  switchLabel: string;
  switchName: string;
  timeNameTo: string;
  timeNameFrom: string;
  defaultTimeTo?: any;
  defaultTimeForm?: any;
  disabledTime?: boolean;
  disabledSwitchLun?: boolean;
};

function InputTimeCard({
  switchLabel,
  switchName,
  timeNameTo,
  timeNameFrom,
  defaultTimeTo,
  defaultTimeForm,
  disabledTime,
  disabledSwitchLun,
}: InputTimeCardProps) {
  return (
    <div>
      <Box sx={{ width: "40%" }}>
        <Switch
          disabled={disabledSwitchLun}
          name={switchName}
          label={switchLabel}
          className="justify-end bold"
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "48%" }}>
          <TimePicker
            disabled={disabledTime}
            name={timeNameTo}
            defaultValue={defaultTimeTo}
          />
        </Box>
        <Box sx={{ width: "48%" }}>
          <TimePicker
            disabled={disabledTime}
            name={timeNameFrom}
            defaultValue={defaultTimeForm}
          />
        </Box>
      </Box>
    </div>
  );
}

export default InputTimeCard;
