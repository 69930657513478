import PrivateHeader from "../components/privateHeader";
import BottomHeader from "../components/privateHeader/bottomHeader";
import { makeStyles, createStyles } from "@mui/styles";
import { Backdrop, CircularProgress, Theme } from "@mui/material";
import { useSelector } from "react-redux";
import { ReducersModal } from "../modal";

type PrivateContainerProps = {
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light,
      minHeight: "100vh",
    },
    mainWrapper: {
      padding: "20px 120px",
      backgroundColor: "#edf0f2",
      //height: "calc(100vh - 165px)",
      //height: subscriptionErrorMessage.subscriptionErrorMessage ? 'calc(100vh - 165px)' : 'calc(100vh - 125px)',
      overflow: "auto",
      width: "100%",
      margin: "auto",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "var(--white)",
    },
  })
);

function PrivateContainer({ children }: PrivateContainerProps) {
  const classes = useStyles();
  const { isLoading } = useSelector(
    (state: ReducersModal) => state.globalLoaderReducer
  );

  const { subscriptionErrorMessage }: any = useSelector(
    (state: any) => state.SubscriptionStatusReducer
  );

  const loader = () => {
    return (
      <Backdrop
        className={classes.backdrop}
        open={isLoading ? isLoading : false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };
  return (
    <div className="page-private-container">
      {loader()}
      {/* header-cover has-subscription-strip */}
      <div
        className={
          subscriptionErrorMessage.subscriptionErrorMessage
            ? "header-cover has-subscription-strip"
            : "header-cover"
        }
      >
        <PrivateHeader />
        <BottomHeader />
      </div>
      <div
        className={`mainWrapper ${classes.mainWrapper}`}
        style={{
          height: subscriptionErrorMessage.subscriptionErrorMessage
            ? "calc(100vh - 165px)"
            : "calc(100vh - 125px)",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default PrivateContainer;
