import { makeStyles, createStyles } from "@mui/styles";
import {
  Avatar,
  Grid,
  Theme,
  MenuItem,
  Typography,
  Backdrop,
  CircularProgress,
  ThemeProvider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Paper,
  Box,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Edit, Close } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  formatSubbenefitsToDD,
  fundedBySomeone,
  benefitList,
  getUserBenefits,
  isSubscriptionActive,
  ManufactureChange,
} from "../../utils/commonFunctions";

import { globalLoaderTrue } from "../../reducer/rootReducer";

import { Formik, Form, useFormikContext, ErrorMessage, useField } from "formik";
import { getManufacturer, onSubmitMember, onSendOtp } from "./action";
import Schema from "../../schema";
import { useSelector, useDispatch } from "react-redux";
import TextField from "../../components/formsUi/textFiled";
import Select from "../../components/formsUi/select/customSelect";
import { useEffect, useState } from "react";

import { ManufacturerMedicineModel, ReducersModal } from "../../modal";

import { useTheme } from "@mui/material";
import Utils from "../../utils";

import ClaimAuthentication from "../../components/modal/claimAuthentication";

import { Navigate, useNavigate } from "react-router-dom";

import moment from "moment";
import { getManufacturerMedicineList } from "../myClaims/action";
import useDebounce from "../../hooks/useDebounce";
import Chip from "@mui/material/Chip";
import { formulation } from "../../schema/formField";

const API_URL = Utils.constants.API_URL;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftSideFilters: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: "10px",
      width: "44%",
    },
    gridItem: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
    },
    avatarDiv: {
      paddingLeft: theme.spacing(3),
    },
    typography: {
      "&.MuiTypography-root": {
        marginTop: theme.spacing(2),
      },
    },
    circularProgressDiv: {
      textAlign: "center",
      "&.MuiAvatar-root": {
        width: "150px",
        margin: "auto",
      },
    },
    gridItemTwo: {
      height: "100%",
      justifyContent: "space-between",
    },
    textFiledDiv: {
      width: "90%",
    },
    flexBox: {
      display: "flex",
      "& .MuiAvatar-root": {
        height: "25px",
        width: "20px",
      },
    },
    flagIcon: {
      width: 24,
      height: 24,
    },
    marginFlag: {
      marginLeft: "10px",
      textTransform: "uppercase",
      fontSize: "14px",
    },
    radioContainer: {
      "& .MuiFormControlLabel-root": {
        alignItems: "flex-start",
      },
    },
    test: {
      borderRadius: "12px",
      [theme.breakpoints.down("md")]: {
        margin: "unset",
        padding: "0 5px",
        width: "100%",
      },
      alignItems: "center !important",
      "& .MuiTypography-root": {
        width: "100%",
        fontSize: "14px",
      },
    },
    radioColor: {
      color: "#009bde !important",
    },
    fadeTitle: {
      color: "rgb(194, 191, 191)",
    },
    errorMessage: {
      color: "red",
      opacity: "0.8",
      fontSize: "16px",
    },
    imgCover: {
      position: "relative",
      width: "150px",
      margin: "5px auto 0px",
      boxShadow: "0px 1px 3px 1px rgba(0,0,0,.2)",
      borderRadius: "5px",
      "&.MuiAvatar-root": {
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
    },
    closeIcon: {
      position: "absolute",
      top: -10,
      right: -10,
      cursor: "pointer",
      width: "20px",
      height: "20px",
    },
    benefitChip: {
      margin: "5px",
      height: "auto",
      backgroundColor: "#53c392 !important",
      color: "white",
      "&.MuiChip-label": {
        padding: "5px 12px",
        whiteSpace: "initial",
        color: "white",
      },
    },
    benefitChipRed: {
      margin: "5px",
      height: "auto",
      backgroundColor: "red !important",
      color: "white",
      "& .MuiChip-label": {
        padding: "5px 12px",
        whiteSpace: "initial",
        color: "white",
      },
    },
    avatarDoc: {
      "&.MuiAvatar-root": {
        width: theme.spacing(15),
        height: theme.spacing(10),
        backgroundColor: theme.palette.secondary.light,
        position: "relative",
      },
    },
    backdrop: ({ theme }: any) => ({
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    }),
  })
);

function PatientProfile({ name }: any) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setValues, setFieldError } = useFormikContext();
  const [value, setValue] = useState<any>();
  const [searchValue, setSearchValue] = useState("");
  const [fundedBy, setFundedBy] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const medicineList = [];
  const [inputValue, setInputValue] = useState<any>("");
  const [clearInputValue, setClearInputValue] = useState<any>();
  const [options, setOptions] = useState<any[]>([]);
  const [chipData, setChipData] = useState<any[]>([]);
  const [mata]: any = useField(name);
  const { setFieldValue } = useFormikContext();
  const debouncedSearchTerm: string = useDebounce<string>(
    inputValue && inputValue,
    500
  );

  // const [page, setPage] = useState(1);
  //const [limit, setLimit] = useState(10);
  const [cartDrugsIds, setCartDrugs] = useState<any>([]);
  const { isLoading } = useSelector(
    (state: ReducersModal) => state.globalLoaderReducer
  );
  const { data: ProviderDetails } = useSelector(
    (state: ReducersModal) => state.ProviderDetailsReducer
  );

  const loader = () => {
    return (
      <Backdrop
        className={classes.backdrop}
        open={isLoading ? isLoading : false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const { data, orderManufacturer } = useSelector(
    (state: ReducersModal) => state.ManufacturerReducer
  );
  const { manufacturerMedicineList, totalRecords,limit,page } = useSelector(
    (state: ReducersModal) => state.ManufacturerMedicineReducer
  );

  const { data: cartData } = useSelector(
    (state: ReducersModal) => state.AddToCardReducer
  );

  const {
    authType,
    authTypeSelected,
    patientInsuranceMemberDataList,
    entityId,
    insurance_member_dependents,
    patientInsuranceMember,
    gender,
    patientName,
    patientDoB,
    phoneNumber,
    claimDate,
    familyMemberPhoneNumber,
    showOtpVerification,
    checked,
    principalPhoneNumber,
    termsConditionChecked,
    claimId,
    policyStatus,
    benefits,
    cardType,
  } = useSelector(
    (state: ReducersModal) => state.InsurancePatientProfileReducer
  );

  const { subscriptionDetails } = useSelector(
    (state: ReducersModal) => state.SubscriptionStatusReducer
  );

  useEffect(() => {
    if (cartData.length > 0) {
      let medicineId = cartData.map((item: any) => item.medicineId);
      setCartDrugs([...medicineId]);
    } else {
      setCartDrugs([]);
    }
  }, [cartData]);

  useEffect(() => {
    if (benefits?.length > 0) checkIsFundedBy();
  }, [benefits]);

  useEffect(() => {
    const isActiveSubscription = isSubscriptionActive(subscriptionDetails);
    if (isActiveSubscription.isActive == false) {
      navigate(Utils.routes.manageSubscription);
    }
  }, []);

  const handleChangePage = (value: any) => {
    dispatch({
      type: Utils.ActionName.MANUFACTURER_MEDICINE,
      payload: {
        page: value + 1,
      },
    });
    // setPage(value + 1);
    //dispatch(getManufacturerMedicineList(orderManufacturer));
  };

  const handleChangeRowsPerPage = (evt: any) => {
    
    dispatch({
      type: Utils.ActionName.MANUFACTURER_MEDICINE,
      payload: {
        limit: +evt.target.value,
        offset: 0,
      },
    });
    dispatch(getManufacturerMedicineList(orderManufacturer, 1));
    //dispatch(getManufacturerMedicineList(orderManufacturer));
    // setRowsPerPage(10)
  };

  const insuranceData: any = data.find((a: any) => a.id === orderManufacturer);

  const policyCurrentStatus = (policyStatus: any) => {
    let SUPPORT_NUMBER = "0114081492";
    if (fundedBy) {
      return (
        "E-Claim not available. Seek email preauthorization on preauth@liviaapp.com. The scheme is " +
        fundedBy +
        "."
      );
    } else {
      switch (policyStatus) {
        case 1:
          return "Policy active";
        case 2:
          return "Policy expired. Please call " + SUPPORT_NUMBER;
        case 3:
          return "Insufficient balance. Please call " + SUPPORT_NUMBER;
        case 4:
          return "Balance not retrieved. Please call " + SUPPORT_NUMBER;
        case 5:
          return "Sorry, the patient's scheme can not be used to create a Doctor claim.";
        case 8:
          return "Time out error. Unable to retrieve benefit details. Try again later.";
        default:
          return "CLAIM NOT ALLOWED";
      }
    }
  };

  const authText = authType.reduce((res: any, item: any) => {
    if (item === "OTP") {
      res = { ...res, [item]: "Phone OTP authentication" };
    }
    if (item === "FR") {
      res = { ...res, [item]: "Face biometric authentication" };
    }
    return res;
  }, []);

  const INITIAL_VALUE = {
    patientDob: patientDoB,
    gender: gender,
    patientName: patientName,
    phoneNumber: phoneNumber,
    claimDate: claimDate,
    termsConditionChecked: termsConditionChecked,
  };

  const chooseCardType = (event: any) => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        insuranceCardType: event.target.value,
        showSearchMemberNo: true,
      },
    });
    setFieldError("search", "");
  };

  const chooseInsuranceMemberOptions = (event: any) => {
    const memberData: any = patientInsuranceMemberDataList.find(
      (a: any) => a.entity_id === event.target.value
    );

    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        subBenefits: [],
        benefit_used_Balance: 0,
        benefit_name: "",
        benefit_id: "",
        sub_benefit_id: "",
        benefitBalance: 0,
        patientBenefitList: [],
        benefit_pool_number: 0,
        subbenefit_name: "",
        entityId: event.target.value,
        finalEntityId:
          insurance_member_dependents === null ||
          insurance_member_dependents === undefined
            ? memberData.ent_dep_parent_on_policy
            : event.target.value,
        search:
          memberData.card_type === 1 || memberData.card_type === "1"
            ? memberData.entity_id
            : memberData.employee_number,
        insuranceMemberOptionSelect: false,
        claimDate: moment(new Date()).format("yyyy-MM-DD"),
      },
    });
    dispatch(globalLoaderTrue());
    dispatch(onSubmitMember(memberData));
  };

  const [initialValue, setInitialValue] = useState(INITIAL_VALUE);

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.GET_MANUFACTURER,
      payload: {
        orderManufacturer: "",
      },
    });

    dispatch(getManufacturer());
  }, [dispatch]);

  useEffect(() => {
    if (checked) {
      setInitialValue({
        ...initialValue,
        phoneNumber: familyMemberPhoneNumber,
      });
    }
    if (
      patientInsuranceMember !== undefined &&
      patientInsuranceMember !== null &&
      patientInsuranceMember.length > 0 &&
      patientInsuranceMember[0].phone !== null &&
      patientInsuranceMember[0].phone !== "" &&
      patientInsuranceMember[0].phone !== undefined
    ) {
      var phone = patientInsuranceMember[0].phone;
      if (
        patientInsuranceMember[0].phone !== null &&
        patientInsuranceMember[0].phone !== undefined
      ) {
        phone = patientInsuranceMember[0].phone.slice(3, 12);
      }
      //
      // if (checked) {
      setInitialValue({
        ...initialValue,
        phoneNumber: phone,
      });
      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          phoneNumber: phone,
        },
      });
      // }
    }

    setValues(INITIAL_VALUE);
  }, [checked, familyMemberPhoneNumber, patientInsuranceMember]);

  useEffect(() => {
    if (
      orderManufacturer &&
      policyStatus == 1 &&
      authTypeSelected === "1" &&
      entityId &&
      (patientInsuranceMember[0]?.face_id === "" ||
        patientInsuranceMember[0]?.face_id === null ||
        patientInsuranceMember[0]?.face_id === undefined)
    ) {
      setShowInfoPopup(true);
    }
  }, [
    policyStatus,
    authTypeSelected,
    entityId,
    patientInsuranceMember[0]?.face_id,
  ]);

  useEffect(() => {
    if (
      principalPhoneNumber !== null &&
      principalPhoneNumber !== undefined &&
      principalPhoneNumber !== "" &&
      checked
    ) {
      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          phoneNumber: principalPhoneNumber,
        },
      });
      setInitialValue({
        ...initialValue,
        phoneNumber: principalPhoneNumber,
      });
    }
  }, [principalPhoneNumber, checked]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    if (familyMemberPhoneNumber !== "" && familyMemberPhoneNumber !== null) {
      if (event.target.checked) {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            phoneNumber: familyMemberPhoneNumber,
            termsConditionChecked: termsConditionChecked,
          },
        });
        setFieldValue("phoneNumber", familyMemberPhoneNumber);
        setInitialValue({
          ...initialValue,
          phoneNumber: familyMemberPhoneNumber,
          termsConditionChecked: termsConditionChecked,
        });
      } else {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            phoneNumber: "",
            termsConditionChecked: termsConditionChecked,
          },
        });
        setFieldValue("phoneNumber", "");
        setInitialValue({
          ...initialValue,
          phoneNumber: "",
          termsConditionChecked: termsConditionChecked,
        });
      }
    } else if (
      principalPhoneNumber !== null &&
      principalPhoneNumber !== undefined &&
      principalPhoneNumber !== ""
    ) {
      if (event.target.checked) {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            phoneNumber: principalPhoneNumber,
            termsConditionChecked: termsConditionChecked,
          },
        });
        setInitialValue({
          ...initialValue,
          phoneNumber: principalPhoneNumber,
          termsConditionChecked: termsConditionChecked,
        });
        setFieldValue("phoneNumber", familyMemberPhoneNumber);
      } else {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            phoneNumber: "",
            termsConditionChecked: termsConditionChecked,
          },
        });
        setInitialValue({
          ...initialValue,
          phoneNumber: "",
          termsConditionChecked: termsConditionChecked,
        });
        setFieldValue("phoneNumber", familyMemberPhoneNumber);
      }
    }
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        checked: event.target.checked,
      },
    });
  };

  const handleSearchChange = (event: any) => {
    dispatch({
      type: Utils.ActionName.MANUFACTURER_MEDICINE,
      payload: {
        search: event.target.value,
        limit: 10,
        offset: 0,
        page: 1,
      },
    });

    dispatch(getManufacturerMedicineList(orderManufacturer, 1));
  };

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.MANUFACTURER_MEDICINE,
      payload: {
        offset: page * limit - limit,
      },
    });
    dispatch(getManufacturerMedicineList(orderManufacturer));
  }, [page]);

  const handleSearchEmpty = () => {
    dispatch({
      type: Utils.ActionName.MANUFACTURER_MEDICINE,
      payload: {
        search: "",
      },
    });
    setSearchValue("");
    dispatch(getManufacturerMedicineList(orderManufacturer, 1));
  };

  // const handleMenuItem = (event: any, number: any) => {
  //   setFamilyPhoneNumber(event.target.innerText);
  // };
  useEffect(() => {
    if (authType.length === 1) {
      if (authType[0] === "OTP") {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            authTypeSelected: "0",
          },
        });
      }
      if (authType[0] === "FR") {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            authTypeSelected: "1",
          },
        });
      }
    }
  }, [authType]);

  useEffect(() => {
    let actives = true;

    if (debouncedSearchTerm === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    let params = `?offset=${0}&limit=${20}&get_manufacturer_list=1&search=${debouncedSearchTerm}`;

    if (debouncedSearchTerm?.length > 0) {
      Utils.constants.getAccessToken();

      Utils.api.getApiCall(
        Utils.endPoints.manufacturer,
        params,
        (response: any) => {
          if (response.status === 200) {
            const results: any = [...response?.data.body];
            if (actives) {
              let newOptions: any = [];

              if (value) {
                newOptions = [value];
              }

              if (results) {
                newOptions = [...newOptions, ...results];
              }

              setOptions(newOptions);
            }
          }
        },
        (err: any) => {
          console.error(err);
        }
      );
    }

    return () => {
      actives = false;
    };
  }, [value, debouncedSearchTerm]);

  const onHighlightChange = async (event: any, newInputValue: any) => {
    if (newInputValue == null) {
      dispatch({
        type: Utils.ActionName.MANUFACTURER_MEDICINE,
        payload: {
          limit: 10,
          offset: 0,
          page: 1,
        },
      });
      dispatch(getManufacturerMedicineList(0));
    }
    setOptions(newInputValue ? [newInputValue, ...options] : []);
    setValue(newInputValue);
    if (newInputValue && newInputValue.id) {
      if (newInputValue) {
        // console.log("cartData", cartData);
        if (cartData?.length > 0 && cartData[0].ManufacturerId !== newInputValue.id) {
          Utils.showAlert(2, ManufactureChange);
          return false;
        }
        dispatch(getManufacturerMedicineList(newInputValue.id, 1));

        dispatch({
          type: Utils.ActionName.GET_MANUFACTURER,
          payload: { orderManufacturer: newInputValue.id },
        });

        setChipData((chips) => {
          const findPreChip = chips.find(
            (element) => element.id === newInputValue.id
          );
          if (findPreChip) {
            return [...chipData];
          } else {
            return [...chipData, newInputValue];
          }
        });
        setClearInputValue(true);
      }
    }
    if (newInputValue === null) {
      //setFieldValue(name, null);
    }
  };
  const handleDelete = (chipToDelete: any) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.code !== chipToDelete.code)
    );
  };

  function AuthType() {
    const authTypeSelected: any =
      authText[
        authType.find(
          (a: any, index: any) => index === Number(showOtpVerification)
        )
      ];

    return (
      <div style={{ display: "flex" }}>
        <Typography sx={{ fontSize: 14, display: "flex" }}>
          <span>{"Authentication Type :"} </span>
          <span style={{ fontSize: 14, fontWeight: "bold", paddingLeft: 5 }}>
            {"  "}
            {showOtpVerification !== "" ? authTypeSelected : ""}
          </span>
          {authType.length > 1 &&
          (claimId === "" || claimId === null || claimId === undefined) ? (
            <Edit
              onClick={() =>
                dispatch({
                  type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                  payload: {
                    showAuthenticationType: true,
                  },
                })
              }
              style={{
                cursor: "pointer",
                fontSize: "18px",
                marginLeft: "10px",
              }}
            />
          ) : (
            ""
          )}
        </Typography>
        <ClaimAuthentication authType={authType} authText={authText} />
      </div>
    );
  }

  const manufacturerList = () => {
    return (
      <Grid container spacing={2} className="mb-30">
        <Grid item xs={3}>
          <Autocomplete
            sx={{
              mr: 1,
            }}
            // disabled={cartData?.length > 0}
            id="autocompleteLocations"
            fullWidth
            size="small"
            clearIcon={<ClearIcon color="primary" fontSize="small" />}
            options={clearInputValue ? options : []}
            getOptionLabel={(option: any) => option?.name}
            autoHighlight={true}
            autoSelect={true}
            clearOnEscape
            inputValue={clearInputValue ? inputValue : ""}
            onInputChange={(event: any, newInputValue) => {
              setClearInputValue(event?.target?.value);
              setInputValue(newInputValue);
            }}
            onChange={onHighlightChange}
            renderOption={(props, option: any) => {
              return (
                <li {...props} key={Math.random()}>
                  {option.name}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="name"
                label="Search Manufacturer"
                error={Boolean(mata && mata.touched && mata.error)}
                helperText={mata && mata.touched && mata.error}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={8}>
          {orderManufacturer !== undefined && orderManufacturer !== "" ? (
            <p>
              <div className={classes.leftSideFilters}>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 500,
                    boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by Brand Name or API Name"
                    onChange={(e: any) => {
                      handleSearchChange(e);
                      setSearchValue(e.target.value);
                    }}
                    value={searchValue}
                  />
                  {searchValue.length > 0 ? (
                    <Button>
                      <ClearIcon onClick={handleSearchEmpty} />
                    </Button>
                  ) : (
                    ""
                  )}
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
            </p>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    );
  };

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };

  const previewImgOpen = () => {
    handleOpenPreview();
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const geoTagErrorPopupManage = () => {
    dispatch({
      type: Utils.ActionName.GET_PROVIDER_DETAILS,
      payload: { data: { ...ProviderDetails, geoTagErrorPopup: false } },
    });
  };

  const getBenefitName = (type: any = "Outpatient") => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        subBenefits: [],
        benefit_used_Balance: 0,
        benefit_name: "",
        benefit_id: "",
        sub_benefit_id: "",
        benefitBalance: 0,
        patientBenefitList: [],
        benefit_pool_number: 0,
      },
    });

    setFundedBy("");
    const benefitRes =
      benefits?.length > 0 &&
      benefits.filter((benefit: any) => {
        if (benefit) {
          return getUserBenefits(type, benefit, cardType, orderManufacturer);
        }
      });

    const patientBenefits = benefitList(benefits, cardType, orderManufacturer);

    if (benefitRes) {
      const subBenefitBalace =
        benefitRes?.[0]?.subbenefit?.length > 0
          ? benefitRes?.[0]?.subbenefit
          : [];
      let formatedSubBenefits = [];

      if (subBenefitBalace?.length > 0) {
        formatedSubBenefits = formatSubbenefitsToDD(subBenefitBalace);
      }

      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          subBenefits: formatedSubBenefits,
          benefit_used_Balance: benefitRes?.[0]?.amount,
          benefit_name: benefitRes?.[0]?.name,
          benefit_id: benefitRes?.[0]?.benefit_id,
          benefitBalance: benefitRes?.[0]?.amount,
          patientBenefitList: patientBenefits,
          benefit_pool_number: benefitRes?.[0]?.pool_number,
        },
      });

      if (cardType == 2) {
        const benefitFundedBy = fundedBySomeone(
          benefitRes?.[0]?.name,
          orderManufacturer
        );
        setFundedBy(benefitFundedBy);
      }

      return benefitRes?.[0]?.name;
    } else {
      return false;
    }
  };

  const checkIsFundedBy = () => {
    getBenefitName();
  };

  const addToCart = (data: any) => {
    const payload = {
      orderManufacturer: orderManufacturer,
      medicineId: data.BrandId,
      quantity: 1,
      rrp: data.rrp,
      quantity_measuring: data.quantity_measuring,
      brandName: data.BrandName,
      API: data.API,
      ManufacturerId: data.ManufacturerId,
    };
    dispatch({
      type: Utils.ActionName.ADD_TO_CART,
      payload: {
        data: [...cartData, payload],
        OrderCreated: false,
      },
    });
  };

  const updateBulkQuantity = (e: any, data: any) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      let cartDataCopy = [...cartData];
      let index = cartDataCopy.findIndex(
        (item: any) => item.medicineId === data.BrandId
      );
      cartDataCopy[index].quantity = e.target.value;

      dispatch({
        type: Utils.ActionName.ADD_TO_CART,
        payload: {
          data: cartDataCopy,
        },
      });
    }
  };

  const updateQuantity = (data: any, option: any) => {
    let cartDataCopy = [...cartData];
    let index = cartDataCopy.findIndex(
      (item: any) => item.medicineId === data.BrandId
    );

    if (option === 3) {
      cartDataCopy.splice(index, 1);
    }

    if (option === 1) {
      if (cartDataCopy[index].quantity == 1) {
        cartDataCopy.splice(index, 1);
      } else {
        if (cartDataCopy[index].quantity > 1) {
          cartDataCopy[index].quantity = cartDataCopy[index].quantity - 1;
        }
      }
    } else if (option != 3) {
      cartDataCopy[index].quantity = cartDataCopy[index].quantity + 1;
    }
    dispatch({
      type: Utils.ActionName.ADD_TO_CART,
      payload: {
        data: cartDataCopy,
      },
    });
  };

  const getQuantity = (data: any) => {
    let cartDataCopy = [...cartData];
    let index = cartDataCopy.findIndex(
      (item: any) => item.medicineId === data.BrandId
    );
    return cartDataCopy[index]?.quantity;
  };
  const getFormulation = (form_id: any) => {
    let form = formulation.find((item: any) => item.value == form_id);
    return form?.text;
  };

  const displayQuantityMeasuring = (
    mesuring_qatagory: any,
    quantity_per_category: any,
    form_id: any,
    quantity_measuring: any
  ) => {
    let quantity_measuring_val;
    if ((!mesuring_qatagory || mesuring_qatagory == null) && form_id == null) {
      quantity_measuring_val = quantity_measuring;
    } else {
      if (mesuring_qatagory == null || mesuring_qatagory == "") {
        quantity_measuring_val = getFormulation(form_id);
      } else {
        quantity_measuring_val =
          mesuring_qatagory +
          "(" +
          quantity_per_category +
          " " +
          getFormulation(form_id) +
          ")";
      }
    }
    quantity_measuring_val = quantity_measuring_val
      ? quantity_measuring_val
      : "N/A";
    return quantity_measuring_val;
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {loader()}
        <div className="page-patient-profile">
          <div className="bg-white mb-10">
            {manufacturerList()}
            <Grid container spacing={3} className="mb-30">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ backgroundColor: "", width: "100%" }}
              >
                {orderManufacturer !== undefined && orderManufacturer !== "" ? (
                  <Grid item xs={15}>
                    <TableContainer>
                      <Table sx={{ maxWidth: "100%" }} size="medium">
                        <TableHead>
                          <TableRow>
                            <TableCell>Brand Name</TableCell>
                            <TableCell>API Name</TableCell>
                            <TableCell>RRP</TableCell>
                            <TableCell>Quantity Measure</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {manufacturerMedicineList?.data?.length > 0 ? (
                            manufacturerMedicineList.data?.map((data: any) => {
                              return (
                                <TableRow
                                  hover
                                  key={data.Id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  style={{
                                    backgroundColor:
                                      data?.status == 2 ? "#ffcccb" : "",
                                  }}
                                >
                                  <TableCell>
                                    {data.BrandName ? data.BrandName : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {data.API ? data.API : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {data.rrp ? data.rrp : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {displayQuantityMeasuring(
                                      data.quantity_measuring_category,
                                      data.quantity_per_category,
                                      data.form_id,
                                      data.quantity_measuring
                                    )}
                                    {/* {data.quantity_measuring} */}
                                  </TableCell>

                                  <TableCell>
                                    {cartDrugsIds.includes(data.BrandId) ? (
                                      <div className="quantity">
                                        <a
                                          onClick={() =>
                                            updateQuantity(data, 1)
                                          }
                                          className="quantity__minus"
                                        >
                                          <span>-</span>
                                        </a>
                                        <input
                                          name="quantity"
                                          type="text"
                                          onChange={(e) => {
                                            updateBulkQuantity(e, data);
                                          }}
                                          className="quantity__input"
                                          value={getQuantity(data)}
                                        />
                                        <a
                                          onClick={() =>
                                            updateQuantity(data, 2)
                                          }
                                          className="quantity__plus"
                                        >
                                          <span>+</span>
                                        </a>
                                        <a
                                          style={{
                                            color: "red",
                                            paddingLeft: "10px",
                                          }}
                                          onClick={() =>
                                            updateQuantity(data, 3)
                                          }
                                          className="remove"
                                        >
                                          Remove
                                        </a>
                                      </div>
                                    ) : (
                                      <Button
                                        title="Add to Cart"
                                        sx={{ minWidth: "unset" }}
                                        onClick={() => {
                                          addToCart(data);
                                        }}
                                      >
                                        {" "}
                                        Add to Cart
                                      </Button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow
                              key={1}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              {" "}
                              <TableCell>{"No Medicine Found"}</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {manufacturerMedicineList?.data?.length > 0 && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={totalRecords}
                            rowsPerPage={limit}
                            page={page - 1}
                            onPageChange={(e, value): any =>
                              handleChangePage(value)
                            }
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={<span>Rows:</span>}
                            sx={{
                              ".MuiTablePagination-selectLabel, .MuiTablePagination-input":
                                {
                                  fontWeight: "bold",
                                  color: "primary",
                                },
                            }}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
}

export default PatientProfile;
