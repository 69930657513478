import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { LocalImages } from "../../../utils/images";

const useStyle = makeStyles({
  paper: {
    backgroundColor: "#53c392",
    height: "100%",
    display: "flex",
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
  },
  img: {
    margin: "auto",
  },
});
function RightScreen() {
  const classes = useStyle();

  return (
    <>
      <Box className={classes.paper}>
        <Box className={classes.img}>
          <img
            src={LocalImages.PersonIcon}
            alt="loading"
            width="100%"
            height="auto"
          />
        </Box>
      </Box>
    </>
  );
}

export default RightScreen;
