import Utils from "../../utils";
import {
  TermsModal,
} from "../../modal";



export const TermsReducer = (
  state: TermsModal = new TermsModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GET_TERMS_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

