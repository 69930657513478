import {
  Box,
  Grid,
  FormControl,
  Typography,
  MenuItem,
  Theme,
  Avatar,
} from "@mui/material";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { makeStyles, createStyles } from "@mui/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "../../components/formsUi/dateTimePicker";
import CustomSelect from "../../components/formsUi/select/customSelect";
import Utils from "../../utils";
import { getDoctorPrescriptions } from "./action";
import Schema from "../../schema"
const API_URL = Utils.constants.API_URL;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterActiveButton: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "20px",
    },
    datePicker: {
      marginTop: "5px",
    },
    insuranceCompany: {
      marginTop: "29px",
    },
    status: {
      marginTop: "29px",
    },
    customContentStyle: {
      width: "100%",
      maxWidth: "none",
    },
    typography: {
      fontSize: "1.2rem",
      "@media (min-width:600px)": {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2.4rem",
      },
    },
    flexBox: {
      display: "flex",
      "& .MuiAvatar-root": {
        height: "25px",
        width: "20px",
      },
    },
    marginFlag: {
      marginLeft: "10px",
      textTransform: "uppercase",
      fontSize: "14px",
    },
  })
);

function FilterComponent({ data }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialValues = {
    startDate: "",
    endDate: "",
    patient_prescription: "",
    status: "",
  };
  const handleClear = () => {
    dispatch({
      type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
      payload: {
        claim_start_date: "",
        claim_end_date: "",
        insurance_company_id: "",
        status: "",
      },
    });
    dispatch(getDoctorPrescriptions());
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema.sentClaimFilterSchema}
        onSubmit={(values) => {
          dispatch({
            type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
            payload: {
              claim_start_date: values.startDate,
              claim_end_date: values.endDate,
              insurance_company_id: values.patient_prescription,
              status: values.status,
            },
          });
          dispatch(getDoctorPrescriptions());
        }}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <Box className={classes.filterActiveButton}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4.8}>
                  <Typography sx={{ marginBottom: '5px' }}>Date</Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <DateTimePicker
                          name="startDate"
                          size="small"
                          label="From"
                        // value={}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <DateTimePicker
                          name="endDate"
                          size="small"
                          label="To"
                        // value={}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box className={classes.insuranceCompany}>
                    <FormControl fullWidth>
                      <CustomSelect
                        name="patient_prescription"
                        label="Select Patient Insurance"
                        size="small"
                        alue={values.patient_prescription}
                        // value={insurance_company_id}
                        options={data.map((option: any, index: any) => (
                          <MenuItem key={index} value={option.id}>
                            <div className={classes.flexBox}>
                              <Avatar
                                src={`${API_URL}${option.avatar}`}
                                variant="square"
                              ></Avatar>

                              <div className={classes.marginFlag}>
                                {option.name}
                              </div>
                            </div>
                          </MenuItem>
                        ))}
                      />
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4.2}>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      // onClick={handleClick}
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        minWidth: "auto",
                        marginTop: "26px",
                        marginRight: "16px",
                      }}
                    >
                      Apply
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="reset"
                      size="large"
                      sx={{
                        fontWeight: "bold",
                        minWidth: "auto",
                        marginTop: "26px",
                      }}
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FilterComponent;
