import { Box, Button, Modal, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import Cropper from "react-cropper";
import Resizer from "react-image-file-resizer";

type imageCropProps = {
  src: any;
  open: boolean;
  handleClose: () => void;
  uploadImage: any;
  type?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      backgroundColor: "var(--white)",
      boxShadow: "24",
      padding: theme.spacing(0.5),
      textAlign: "center",
    },
  })
);

const Index = ({ src, open, handleClose, uploadImage, type }: imageCropProps) => {
  const classes = useStyles();
  const [imageProcessing, setImageProcessing] = useState(false);
  const [cropper, setCropper] = useState<any>("");
  const [image, setImage] = useState<any>("");
  const [extension, setExtension] = useState("jpeg");

  useEffect(() => {
    if (type === "logoImg") setExtension("png");
    else setExtension("jpeg");
  }, [type]);
  useEffect(() => {
    if (src) {
      let file = src?.fileList[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }, [src]);
  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setImageProcessing(true);
      const newFileName = new Date().getTime();
      const urlimg = () => {
        return fetch(cropper.getCroppedCanvas().toDataURL())
          .then(function (res) {
            return res.arrayBuffer();
          })
          .then(function (buf) {
            return new File([buf], `${newFileName}.${extension}`, {
              type: `image/${extension}`,
            });
          });
      };
      urlimg().then((file) => {
        try {
          Resizer.imageFileResizer(
            file,
            1000,
            1000,
            type === "logoImg" ? "PNG" : "JPEG",
            100,
            0,
            (url: any) => {
              uploadImage(url, type)
                .then((response: any) => {
                  console.log(response);
                  setImageProcessing(false);
                  setImage("");
                  handleClose();
                })
                .catch((error: any) => {
                  setImageProcessing(false);

                  console.log(error);
                });
            },
            "base64",
            200,
            200
          );
        } catch (err) {
          setImageProcessing(false);
          console.log(err);
        }
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: (theme) => theme.zIndex.drawer }}
    >
      <Box className={classes.modalStyle}>
        <>
          <Cropper
            style={{ height: 400, width: "100%" }}
            // zoomTo={0.}
            initialAspectRatio={type == "banner" ? 16 / 9 : 1 / 1}
            aspectRatio={type == "banner" ? 16 / 9 : 1 / 1}
            preview=".img-preview"
            src={image && image}
            viewMode={2}
            minCropBoxHeight={type === "profilePhoto" ? 512 : 10}
            minCropBoxWidth={type === "profilePhoto" ? 512 : 10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
          <Box sx={{ mt: 0.5 }}>
            <Button
              size="small"
              variant="contained"
              sx={{
                color: "var(--white)",
                fontWeight: "bold",
              }}
              onClick={() => {
                setImage("");
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={imageProcessing}
              size="small"
              variant="contained"
              sx={{
                color: "var(--white)",
                fontWeight: "bold",
              }}
              // className={`btn btn-primary ${imageProcessing ? "on-load" : ""}`}
              onClick={getCropData}
              style={{ marginLeft: 15 }}
            >
              Upload
            </Button>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default Index;
