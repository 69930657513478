import { useState, useEffect, useRef } from "react";
import {
  Paper,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InputBase from "@mui/material/InputBase";
import { useSelector, useDispatch, batch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles, createStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import "./../../../styles/table.css";
import "./../../../styles/listing-filters.css";
import {
  getInsuranceCompany,
  getManufacturer,
  getMedicineOrders,
  getSubscriptionStatus,
} from "../action";
import FilterComponent from "./filter";
import TableComponent from "./table";
import PopUp from "./popUp";
import { useNavigate } from "react-router";
import Utils from "../../../utils";
import { ReducersModal } from "../../../modal";
import { CSVLink } from "react-csv";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { styled } from "@mui/system";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../../reducer/rootReducer";
import { statusArray } from "../../../utils/appData";
import {
  checkEClaimStatus,
  checkOrderStatus,
  isSubscriptionActive,
  yyyyMMddToDate,
} from "../../../utils/commonFunctions";
import moment from "moment";

const useStyles = makeStyles(() =>
  createStyles({
    leftSideFilters: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: "10px",
      width: "44%",
    },
    rightSideFilters: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: "10px",
      marginLeft: "10px",
      width: "54.9%",
    },
    customContentStyle: {
      width: "100%",
      maxWidth: "none",
    },
    listingFilter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: "10px",
    },
    csv: {
      color: "var(----light-green)",
    },
  })
);

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: "var(--white)";
  -webkit-tap-highlight-color: transparent;
`;
const style = {
  width: 450,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 2,
  px: 4,
  pb: 3,
};

const headers = [
  { label: "Order ID", key: "id" },
  { label: "Manufacturer user name", key: "manufacturer_user_name" },
  { label: "Manufacturer company", key: "manufacturer_company" },
  { label: "Order Date", key: "CreatedAt" },
  { label: "Status", key: "Status" },
];

function SentClaims(value: any) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [filter, setFiletr] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [fileData, setFileData] = useState<any>([]);
  const [fileName, setFileName] = useState<any>(".csv");

  const handleFilterClick = () => {
    setFiletr(!filter);
  };

  const debounceCount = useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  const handleSearchEmpty = () => {
    dispatch({
      type: Utils.ActionName.MEDICINE_ORDERS,
      payload: {
        search: "",
      },
    });
    setSearchValue("");
    dispatch(getMedicineOrders());
  };

  useEffect(()=>{
    dispatch({
      type: Utils.ActionName.MEDICINE_ORDERS,
      payload: {
        search: "",
      },
    });
    dispatch(getMedicineOrders());

  },[])

  const handleSearchChange = (event: any) => {
    dispatch({
      type: Utils.ActionName.MEDICINE_ORDERS,
      payload: {
        search: event.target.value,
        limit: 10,
        offset: 0,
        page: 1,
      },
    });
    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      dispatch(getMedicineOrders());
    });
    setSearchValue(event.target.value);
  };
  const handleCreateClickOpen = () => {
    const isActiveSubscription = isSubscriptionActive(subscriptionDetails);
    if (isActiveSubscription.isActive == false) {
      navigate(Utils.routes.manageSubscription);
    } else {
      batch(() => {
        dispatch({
          type: Utils.ActionName.CLEAR_MEDICINE_DETAILS_LIST,
        });
        dispatch({
          type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
          payload: {
            doctorDetail: {},
            hospitalDetail: {},
            finalDiagnosisDetail: [],
            onlyRead: false,
          },
        });

        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            showSearchMemberNo: false,
          },
        });
        dispatch({
          type: Utils.ActionName.GET_INSURANCE_COMPANY,
          payload: { patientInsurance: "" },
        });
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            cardListArray: [],
            authType: [],
            insuranceData: [],
            showAuthenticationType: false,
            showOtpVerification: "",
            phoneNumber: "",
            search: "",
            insurance_member_dependents: [],
            patientInsuranceMember: [],
            patientInsuranceMemberDataList: [],
            insuranceMemberOptionSelect: false,
            patientFamilyMemberData: [],
            termsConditionChecked: false,
            showSearchMemberNo: false,
            claimId: "",
            benefits: [],
          },
        });
        dispatch({
          type: Utils.ActionName.GET_PATIENT_DATA,
          payload: {
            activeStep: 0,
          },
        });
      });
      navigate(Utils.routes.createMedicineOrderRequest);
      // setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleArrow = (filter: Boolean) => {
    return filter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  };

  useEffect(() => {
    dispatch(getSubscriptionStatus());
    dispatch(getInsuranceCompany());
    dispatch(getManufacturer());
  }, [dispatch]);

  const { data, patientInsurance } = useSelector(
    (state: ReducersModal) => state.InsuranceCompanyReducer
  );

  const { subscriptionDetails } = useSelector(
    (state: ReducersModal) => state.SubscriptionStatusReducer
  );

  const {
    limit,
    offset,
    hospital_name,
    status,
    search,
    insurance_company_id,
    claim_start_date,
    claim_end_date,
    // is_from_desktop,
    // is_count_needed,
  } = useSelector((state: ReducersModal) => state.SentClaimsReducer);

  const fetchCsvData = async () => {
    let param = `?limit=${limit}&offset=${offset || 0}&hospital_name=${
      hospital_name || ""
    }&status=${status || 0}${
      search.length > 0 ? `&search=${search}` : ""
    }&insurance_company_id=${insurance_company_id || ""}&claim_start_date=${
      claim_start_date || ""
    }&claim_end_date=${
      claim_end_date || ""
    }&is_from_desktop=${1}&is_count_needed=${1}&is_export=${true}`;
    dispatch(globalLoaderTrue());
    await Utils.constants.axios
      .get(Utils.endPoints.medicineOrders + param)
      .then(({ status, data }) => {
        if (!(status === 200 && data?.body?.length > 0)) return;
        let csvData: any = [];
        data?.body.map((elem: any) => {
          let rowData = {
            id: elem?.OrderId,
            manufacturer_user_name: elem?.manufacturer_user_name
              ? elem?.manufacturer_user_name
              : "N/A",
            manufacturer_company: elem?.manufacturer_company
              ? elem?.manufacturer_company
              : "N/A",
            CreatedAt: moment(elem?.CreatedAt).format("DD/MM/YYYY"),
            Status: checkOrderStatus(elem?.OrderStatus),
          };
          csvData.push(rowData);
        });
        let filename = `Medicine_Orders-${yyyyMMddToDate()}.csv`;
        setFileName(filename);
        setFileData(csvData);
        dispatch(globalLoaderFalse());
      })
      .catch(() => {
        dispatch(globalLoaderFalse());
      });
  };

  const handleOpenExport = () => {
    fetchCsvData();
    setOpenExport(true);
  };

  const handleExportDownload = () => {
    //fetchCsvData();
    setOpenExport(false);
  };

  const handleCloseExport = () => {
    setOpenExport(false);
  };

  return (
    <>
      <div className="page-sent-claims bg-white">
        <div className="listing-filter">
          <div className={classes.leftSideFilters}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 500,
                boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by Order id / Manufacturer name"
                onChange={(e: any) => {
                  handleSearchChange(e);
                  setSearchValue(e.target.value);
                }}
                value={searchValue}
              />
              {searchValue.length > 0 ? (
                <Button>
                  <ClearIcon onClick={handleSearchEmpty} />
                </Button>
              ) : (
                ""
              )}
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <div
            // className={classes.rightSideFilters}
            className="right-side-filters"
          >
            <Button
              size="large"
              variant="contained"
              sx={{
                color: "var(--white)",
                maxWidth: "180px",
                minWidth: "80px",
              }}
              onClick={handleOpenExport}
            >
              Export
            </Button>
            <div className="dropdown-cover">
              <Button
                onClick={handleFilterClick}
                variant="outlined"
                size="large"
                sx={{
                  maxWidth: "180px",
                  minWidth: "80px",
                  bgcolor: "var(--white)",
                }}
              >
                <FilterListIcon />
                &nbsp;
                <span>Filters</span>&nbsp;
                {toggleArrow(filter)}
              </Button>
            </div>
            <Button
              size="large"
              variant="contained"
              sx={{
                color: "var(--white)",
                maxWidth: "180px",
                minWidth: "80px",
              }}
              onClick={handleCreateClickOpen}
            >
              Create Order
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              className={classes.customContentStyle}
            >
              <DialogTitle
                sx={{
                  padding: 0,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                id="responsive-dialog-title"
              >
                {"Add Medicines"}
              </DialogTitle>
              <PopUp />
            </Dialog>
          </div>
        </div>
        {filter ? (
          <FilterComponent data={data} patientInsurance={patientInsurance} />
        ) : (
          " "
        )}

        <div className="table-cover">
          <TableComponent />
        </div>
      </div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={openExport}
        onClose={handleCloseExport}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to Export?
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: 6, ml: 19 }}
            onClick={handleCloseExport}
          >
            No
          </Button>
          {fileData.length > 0 ? (
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 6, ml: 4, color: "var(--white)" }}
              onClick={handleExportDownload}
            >
              <CSVLink
                data={fileData}
                headers={headers}
                filename={fileName}
                className={classes.csv}
                asyncOnClick={true}
              >
                Yes
              </CSVLink>
            </Button>
          ) : (
            <Box sx={{ mt: -3.5, ml: 30 }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </StyledModal>
    </>
  );
}

export default SentClaims;
