import Box from "@mui/material/Box";
import { makeStyles, createStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { ReducersModal } from "../../modal";
import { Chip,Theme } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Utils from "../../utils";
import { useTheme } from "@mui/material";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: '90vh',
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: '10px',
  p: 2,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    
    benefitChip:{
      margin: "5px",
      height: "auto",
      backgroundColor: "#53c392 !important",
      color: "white",
      "&.MuiChip-label":{
        padding: "5px 12px",
        whiteSpace: "initial",
        color: "white",
      }
    },
    benefitChipRed:{
      margin: "5px",
      height: "auto",
      backgroundColor: "red !important",
      color: "white",
      "& .MuiChip-label":{
        padding: "5px 12px",
        whiteSpace: "initial",
        color: "white",
      }
    },
   
  })
);
export default function BasicModal({patientFamilyMemberData}: any) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const handleClose = () => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        showMoreBenefits: false,
      },
    });
  };
  const { showMoreBenefits,policyStatus } = useSelector(
    (state: ReducersModal) => state.InsurancePatientProfileReducer
  );

  return (
    <div>
      <Modal
        open={showMoreBenefits}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>
            <div className="space-between mb-20">
              <h4 className="semi-bold">
                Member Benefits
              </h4>
              <CloseIcon
                onClick={() => handleClose()}
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="benefit-chips-list">
              {patientFamilyMemberData !== undefined &&
              patientFamilyMemberData?.length > 0 ? (
                patientFamilyMemberData.map(
                  (option: any, index: any) => (
                    <Chip className={`${policyStatus===1?classes.benefitChip:classes.benefitChipRed}`}   label={`${option.name}${"-"}${option.amount!==null?option.amount:"()"}`} />
                  )
                )
              ) : (
                <Chip className="benefit-chip" label="No Benefits" />
              )}
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
}
