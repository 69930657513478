import Utils from "../../utils";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";
export const getTermsConditions = () => {
    return (dispatch: Function, getState: Function) => {
      if (!navigator.onLine) {
        Utils.showAlert(3, "PLEASE CHECK INTERNET");
        return;
      }
      dispatch(globalLoaderTrue());
  
    
      let data = `?language=en&country=ke&role_id=3`;
      // ;
  
      Utils.constants.getAccessToken();
      Utils.api.getApiCall(
        Utils.endPoints.termsCondition,
        data,
        (respData: any) => {
  

          dispatch({
            type: Utils.ActionName.GET_TERMS_DETAILS,
            payload: {
              data: respData,
            },
          });
          // ;
          dispatch(globalLoaderFalse());
        },
        (error: any) => {
          dispatch(globalLoaderFalse());
          let { data } = error;
          Utils.showAlert(2, data?.messages[0]);
        }
      );
    };
  };

 