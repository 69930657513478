import TextareaAutosize, {
  TextareaAutosizeProps,
} from "@mui/material/TextareaAutosize";
import { styled } from "@mui/material/styles";
import { useField } from "formik";

type TextAreaProps = {
  name: string;
} & TextareaAutosizeProps;

const StyledTextArea = styled(TextareaAutosize)<TextareaAutosizeProps>(
  () => ({})
);

function TextArea({ name, ...otherProps }: TextAreaProps) {
  const [field] = useField(name);

  const configInputBase = {
    ...field,
    ...otherProps,
  };

  return <StyledTextArea {...configInputBase} />;
}

export default TextArea;
