import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { ReducersModal } from "../../modal";
import { Chip } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Utils from "../../utils";
import PatientVerification from "../../screens/doctorPrescriptions/verification";
import HospitalPrescriptionVerification from "../../screens/hospitalPrescription/verification";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 0,
};

export default function HospitalPrescriptionVerificationModal({
  userId,
  phoneNumber,
  orderId,
  userName,
}: any) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({
      type: Utils.ActionName.HOSPITAL_PRESCRIPTIONS,
      payload: {
        showVerification: false,
      },
    });
  };
  const { showVerification } = useSelector(
    (state: ReducersModal) => state.HospitalPrescriptionsReducer
  );
   
  return (
    <div>
      <Modal
        open={showVerification}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="page-verification-modal"
      >
        <Box sx={style}>
          <div className="close-icon-cover">
            <CloseIcon
              onClick={() => handleClose()}
              style={{ cursor: "pointer" }}
            />
          </div>
          <HospitalPrescriptionVerification
            userId={userId}
            phoneNumber={phoneNumber}
            orderId={orderId}
            userName={userName}
          />
        </Box>
      </Modal>
    </div>
  );
}
