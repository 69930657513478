import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Theme,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  MenuItem,
  Select,
  Input,
} from "@mui/material";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, createStyles } from "@mui/styles";
import { LocalImages } from "../../utils/images";
import { browserName, browserVersion } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import {
  claimCreateErrMsg,
  numberCommaFormat,
  userDeniedLocationErrMsg,
} from "../../utils/commonFunctions";
import { sub } from "date-fns";
import SelectWrapper from "../formsUi/select";
import { toastIdsConstant } from "../../utils/toastIdsConstant";
import Utils from "../../utils";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogActions: {
      "&.MuiDialogActions-root": {
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      },
    },
    dialogTitle: {
      display: "flex",
      alignItems: "center",
      justtifyContent: "space-between",
      "&.MuiDialogTitle-root": {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    dialogContent: {
      "&.MuiDialogContent-root": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "black",
        fontSize: "22px",
      },
    },
    buttonPrimary: {
      color: "white !important",
      textTransform: "capitalize",
    },
  })
);
const PaymentType: any = [
  { key: 1, text: "Payment From Claim/Order", value: 1 },
  { key: 2, text: "MPESA", value: 2 },
];

function SubscriptionPayModal(props: any) {
  const {
    title,
    content: selectedSubscription,
    open,
    handleClose,
    handlePayNowModal,
    subscriptionSetting,
    payNowModal,
    subscriptionId,
    handleSubscriptionStatus,
  } = props;

  const dispatch = useDispatch();
  const [paymentType, setPaymentType] = useState();
  const [OtherPhoneMpesa, setOtherPhoneMpesa] = useState(false);
  const [phone_no_mpesa, setPhoneNoMpesa] = useState("");
  const [subscription_id, setSubscriptionId] = useState(
    payNowModal || open == 2
      ? selectedSubscription.subscription_plan_id
      : selectedSubscription.id
  );
  const [disablePayNow, setDisablePayNow] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  console.log("selectedSubscription", selectedSubscription);
  const theme = useTheme();
  const classes = useStyles({ theme });
  let totalSub: any = 0;
  totalSub =
    open == 2 || open == 3
      ? selectedSubscription?.plan_price
      : selectedSubscription?.price;
  const calculateSubscriptionAmountDetails = (
    subscriptionDetails: any,
    linkage_fee: any = false
  ) => {
    const portal_fee =
      open == 2 || open == 3
        ? subscriptionDetails?.plan_price
        : subscriptionDetails?.price;
    const timeline = subscriptionDetails?.timeline;
    const duration = subscriptionDetails?.duration;
    //let linkage_fee = subscriptionSetting?.linkage_fee;
    const vat = subscriptionSetting?.vat;
    const insurance_companies_count =
      subscriptionSetting?.insurance_company_count;

    //Needs to calculat months form timeline
    let months = 1;
    if (timeline == "years") {
      months = 12;
    }
    const subscription_months = duration * months;

    linkage_fee = subscription_months * linkage_fee;
    const total_linkage_fee = insurance_companies_count * linkage_fee;
    const sub_total = parseFloat(portal_fee) + total_linkage_fee;
    const total = sub_total + (sub_total * vat) / 100;

    let returndata = {
      total: numberCommaFormat(total),
      vat_amount: numberCommaFormat((sub_total * vat) / 100),
      linkage_fee: numberCommaFormat(total_linkage_fee),
      sub_total: numberCommaFormat(sub_total),
      duration: subscription_months,
      var_amount_value: (sub_total * vat) / 100,
    };

    return returndata;
  };

  const getDuration = () => {
    let duration = selectedSubscription?.duration;
    let timeline = selectedSubscription?.timeline;
    let months = 1;
    if (timeline == "years") {
      months = 12;
    }
    return duration * months;
  };

  const handlePaymentTypeChange = (event: any, data: any) => {
    setPaymentType(event.target.value);
  };

  const handlePayNowProceed = (payLater: any = null) => {
    if (selectedSubscription?.transaction_in_process == 1) {
      if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
        toast.error(
          "You have already initiated a payment request for a Plan which is in Pending state. Wait for the existing payment to be completed before initiating any new request",
          {
            toastId: toastIdsConstant.loginApiFailure,
            className: "toast-error",
          }
        );
      }
      return false;
    }

    if (!paymentType) {
      if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
        toast.error("Please select payment type", {
          toastId: toastIdsConstant.loginApiFailure,
          className: "toast-error",
        });
      }
      return false;
    }
    let IsPayLater = paymentType == 2 ? 1 : "";
    const payload = {
      subscription_id: subscription_id,
      paymentType: paymentType,
      start_date: selectedSubscription.start_date,
      end_date: selectedSubscription.end_date,
      amount: selectedSubscription.price,
      payLater: IsPayLater,
      payment_option: "create_invoice",
    };
    handleApiCall(payload, payLater);
  };

  const handleOtherPhoneMpesa = () => {
    setOtherPhoneMpesa(!OtherPhoneMpesa);
    setPhoneNoMpesa("");
  };

  const validatePhoneNo = (phone_no: any) => {
    if (
      !phone_no ||
      phone_no == "" ||
      phone_no == null ||
      phone_no == undefined
    ) {
      if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
        toast.error("Please enter phone no", {
          toastId: toastIdsConstant.loginApiFailure,
          className: "toast-error",
        });
      }

      return false;
    }
    let phoneLength = phone_no.length;
    if (phoneLength == 10) {
      let validPhone = phone_no.charAt(0);
      let validPhoneAt1 = phone_no.charAt(1);
      if (validPhone != "0" || validPhoneAt1 != "7") {
        if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
          toast.error("Invalid phone no", {
            toastId: toastIdsConstant.loginApiFailure,
            className: "toast-error",
          });
        }

        return false;
      } else {
        phone_no = phone_no.substring(1);
      }
    } else if (phoneLength == 9) {
      let validPhoneAt1 = phone_no.charAt(0);
      if (validPhoneAt1 != "7") {
        if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
          toast.error("Invalid phone no", {
            toastId: toastIdsConstant.loginApiFailure,
            className: "toast-error",
          });
        }

        return false;
      }
    } else {
      if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
        toast.error("Invalid phone no", {
          toastId: toastIdsConstant.loginApiFailure,
          className: "toast-error",
        });
      }

      return false;
    }
    return true;
  };

  const handleUpcomingSubscription = () => {
    const payload = {
      subscription_id:
        subscriptionId && subscriptionId != undefined
          ? subscriptionId
          : subscription_id,
      payment_option: "pay_for_invoice",
      paymentType: 2,
      other_phone_no_added: OtherPhoneMpesa ? true : false,
      phone_no: phone_no_mpesa ? "254" + phone_no_mpesa : "",
    };
    handleApiCall(payload);
  };

  const handleApiCall = (payload: any, payLater: any = false) => {
    dispatch(globalLoaderTrue());
    setDisablePayNow(true);
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      `${Utils.endPoints.subscription}`,
      payload,
      (respData: any) => {
        dispatch(globalLoaderFalse());
        setDisablePayNow(false);
        let { data } = respData;
        if (data?.code == 200) {
          if (payload.payment_option == "pay_for_invoice") {
            //setSubscriptionId(data.subscriptionInvoiceId);
          } else {
            setSubscriptionId(data.subscriptionInvoiceId);
          }
          if (paymentType == 2 && payLater != 1) {
            handleClose(false);
            handlePayNowModal(data?.subscriptionInvoiceId);
            setOtherPhoneMpesa(false);
            handleSubscriptionStatus(true);
          } else {
            let successMessage =
              "Update Subscription request sent successfully";
            Utils.showAlert(1, successMessage);
            setSubscriptionId(data.subscriptionInvoiceId);
            handleClose(false);
            handleSubscriptionStatus(true);
          }
          //Utils.showAlert(1, data?.messages[0]);
        } else {
          Utils.showAlert(2, data?.messages[0]);
        }
      },
      (error: any) => {
        setDisablePayNow(false);
        dispatch(globalLoaderFalse());
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth={payNowModal ? "md" : "lg"}
        onClose={handleClose}
      >
        <DialogTitle
          className={classes.dialogTitle}
          style={{ fontSize: "25px" }}
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
          <span>
            {payNowModal
              ? "Payment Confirmation"
              : `Subscription Plan "${selectedSubscription?.subscription_name}" Details`}
          </span>
          {handleClose && (
            <CloseIcon
              sx={{
                ml: 2,
                color: (theme) => theme.palette.grey[500],
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          )}
        </DialogTitle>

        <DialogContent
          className={classes.dialogContent}
          style={{ fontSize: "18px", display: "block" }}
        >
          {!payNowModal && (
            <p className="planOfferOnModal">
              {selectedSubscription?.description &&
                selectedSubscription?.description}
            </p>
          )}

          {!payNowModal && open != 3 && open != 2 && (
            <div className="space-between mb-20">
              <Select
                sx={{
                  //   marginTop: 35,
                  width: 250,
                  height: 50,
                  //color: !paymentType ? "white" : "",
                  animation: !paymentType ? "glowingWB 2500ms infinite" : "",
                  minWidth: "300px !important",
                }}
                displayEmpty={true}
                onChange={(event, data) => {
                  handlePaymentTypeChange(event, data);
                }}
              >
                <MenuItem>Select Payment Type</MenuItem>;
                {PaymentType.map((item: any, index: number) => {
                  return <MenuItem value={item.key}>{item.text}</MenuItem>;
                })}
              </Select>
              <div className="flex-end">
                <Button
                  variant="contained"
                  color="error"
                  className={classes.buttonPrimary}
                  onClick={handleClose}
                  style={{ marginLeft: 10 }}
                >
                  Cancel
                </Button>
                {paymentType && (
                  <>
                    {paymentType == 2 ? (
                      <>
                        <Button
                          variant="contained"
                          disabled={disablePayNow}
                          color="success"
                          className={classes.buttonPrimary}
                          onClick={() => handlePayNowProceed()}
                          style={{ marginLeft: 10 }}
                        >
                          Pay Now
                        </Button>
                        <Button
                          variant="contained"
                          color="success"
                          className={classes.buttonPrimary}
                          onClick={() => handlePayNowProceed(1)}
                          disabled={disablePayNow}
                          style={{ marginLeft: 10 }}
                        >
                          Pay Later
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        color="success"
                        className={classes.buttonPrimary}
                        onClick={() => handlePayNowProceed()}
                        style={{ marginLeft: 10 }}
                        disabled={disablePayNow}
                      >
                        Update Subscription
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {payNowModal ? (
            <div style={{ padding: "10px", fontSize: "20px" }}>
              <p>
                You will receive a request from mPesa for this invoice payment.
                Please complete the mPesa from your registered mobile number.
              </p>
              <p>
                <b>
                  {OtherPhoneMpesa
                    ? `Do you want to
											use registered Phone number for mPesa transaction`
                    : `Do you want to
											change the Phone number for mPesa transaction`}{" "}
                  ?{" "}
                  <a
                    style={{ cursor: "pointer", color: "green" }}
                    onClick={handleOtherPhoneMpesa}
                  >
                    {" "}
                    Click here{" "}
                  </a>
                  <br />
                  <br />
                </b>
                {OtherPhoneMpesa && (
                  <div className="field desk-25 country-code-cover dynamic-cc">
                    <p
                      className="country-code-value"
                      style={{ top: "12px", left: "4px" }}
                    >
                      +254
                    </p>
                    <div className="ui input input-value">
                      <Input
                        placeholder="7XXXXXXXX"
                        type="number"
                        value={phone_no_mpesa}
                        style={{
                          marginTop: "8.5px",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                        onChange={(e) => {
                          if (e.target.value?.length < 11) {
                            setPhoneNoMpesa(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </p>
              <div style={{ textAlign: "end" }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleClose(false)}
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  disabled={disablePayNow}
                  onClick={() => {
                    if (OtherPhoneMpesa) {
                      if (validatePhoneNo(phone_no_mpesa)) {
                        handleUpcomingSubscription();
                      }
                    } else {
                      handleUpcomingSubscription();
                    }
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          ) : (
            <div className="eclaim-list-content">
              <div className="list-cover">
                <div className="table-content">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell>Qty</TableCell>
                          <TableCell>Unit Price</TableCell>
                          <TableCell>Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Portal Access Fee</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>
                            {numberCommaFormat(
                              open == 2 || open == 3
                                ? selectedSubscription?.plan_price
                                : selectedSubscription?.price
                            )}{" "}
                            KES
                          </TableCell>
                          <TableCell>
                            {numberCommaFormat(
                              open == 2 || open == 3
                                ? selectedSubscription?.plan_price
                                : selectedSubscription?.price
                            )}{" "}
                            KES
                          </TableCell>
                        </TableRow>
                        {selectedSubscription?.insurance_company_data?.length >
                          0 &&
                          selectedSubscription?.insurance_company_data.map(
                            (item: any, index: number) => {
                              totalSub =
                                parseInt(totalSub) +
                                parseInt(item.linkage_fee) * getDuration();
                              return (
                                <TableRow key={index}>
                                  <TableCell>
                                    Linkage Fee for {item.name}
                                  </TableCell>
                                  <TableCell>
                                    {
                                      calculateSubscriptionAmountDetails(
                                        selectedSubscription,
                                        item.linkage_fee
                                      )?.duration
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {item.linkage_fee > 0
                                      ? numberCommaFormat(item.linkage_fee) +
                                        " KES"
                                      : "No Charge"}
                                  </TableCell>
                                  <TableCell>
                                    {item.linkage_fee > 0
                                      ? numberCommaFormat(
                                          item.linkage_fee *
                                            calculateSubscriptionAmountDetails(
                                              selectedSubscription,
                                              item.linkage_fee
                                            )?.duration
                                        ) + " KES"
                                      : "No Charge"}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <b>Sub Total</b>
                          </TableCell>
                          <TableCell>
                            {" "}
                            {/* {
                              calculateSubscriptionAmountDetails(
                                selectedSubscription
                              )?.sub_total
                            } */}
                            {numberCommaFormat(totalSub)} KES
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <b>Vat ({subscriptionSetting?.vat}%)</b>
                          </TableCell>
                          <TableCell>
                            {numberCommaFormat(
                              (totalSub * subscriptionSetting?.vat) / 100
                            )}{" "}
                            KES
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <b>Total</b>
                          </TableCell>
                          <TableCell>
                            {" "}
                            {numberCommaFormat(
                              parseInt(totalSub) +
                                (totalSub * subscriptionSetting?.vat) / 100
                            )}{" "}
                            KES
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              {/* <hr className="mb-10" />

              {selectedSubscription?.description && (
                <div className="">
                  <p className="">
                    Description: {selectedSubscription?.subscription_name}
                  </p>
                 
                </div>
              )} */}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SubscriptionPayModal;
