import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Input, FormControl, Autocomplete, TextField, Button } from "@mui/material";
import { bankFieldsConstant } from "../../../utils/constants";
import { getCountryCodeFromLS, isObjectEmpty } from "../../../utils/commonFunctions";
import Utils from '../../../utils';
import { globalLoaderFalse, globalLoaderTrue } from '../../../reducer/rootReducer';
import CustomModal from "../../../components/modal/customModal";
import "./bankAccountDetail.css"

function BankAccountDetail(props: any) {
	const {
		onHide,
		popFor,
		addBankAcccount,
		isApiLoadingOnAddPayment,
		bankDetail,
		areWeEditing,
	} = props;
	const [bankFields, setBankFields] = useState(bankFieldsConstant);
	const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);
	const [cities, setCities] = useState<Array<any>>([]);
	const [citySearchQuery, setCitySearchQuery] = useState("");
	const [cityOffset, setCityOffset] = useState(0);
	const [cityLimit, setCityLimit] = useState(100);
	const [makeApiCallOnSearchCity, setMakeApiCallOnSearchCity] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (cities.length === 0) {
			getCities();
		}
	}, []);

	useEffect(() => {
		if (bankDetail && !isObjectEmpty(bankDetail)) {
			setBankFields((prevState) => ({
				...prevState,
				accNo: bankDetail.account_number,
				bankName: bankDetail.bank_name,
				bankPayBill: bankDetail.bank_paybill,
				branchName: bankDetail.branch,
				cityName: bankDetail.city,
				swiftCode: bankDetail.iban_code,
			}));
		}
	}, [bankDetail]);

	useEffect(() => {
		if (makeApiCallOnSearchCity) {
			getCities();
		}
	}, [makeApiCallOnSearchCity, citySearchQuery]);

	const changeHandler = (e: any) => {
		const { id, value } = e.target;
		setBankFields((prevState) => ({
			...prevState,
			[id]: value ? value?.replace("  ", " ") : "",
		}));
	};

	const confirmBtnHandler = () => {
		let payload = null;
		setIsConfirmBtnClicked(true);
		if (
			bankFields?.bankName &&
			bankFields?.bankName?.trim() &&
			bankFields?.cityName &&
			bankFields?.cityName?.trim() &&
			bankFields?.accNo &&
			bankFields?.accNo?.trim() &&
			bankFields?.bankPayBill &&
			bankFields?.bankPayBill?.trim() &&
			bankFields?.swiftCode &&
			bankFields?.swiftCode?.trim()
		) {
			payload = {
				account_number: bankFields?.accNo ? bankFields?.accNo?.trim() : "",
				bank_name: bankFields?.bankName ? bankFields?.bankName?.trim() : "",
				bank_paybill: bankFields?.bankPayBill
					? bankFields?.bankPayBill?.trim()
					: "",
				branch: bankFields?.branchName ? bankFields?.branchName?.trim() : "",
				city: bankFields?.cityName ? bankFields?.cityName?.trim() : "",
				iban_code: bankFields?.swiftCode ? bankFields?.swiftCode?.trim() : "",
				pay_type: "bankaccount",
				system: "mpesa",
			};
			if (areWeEditing) {
				payload = {
					...payload,
					priority: 1,
				}
			}
			addBankAcccount(payload);
			onHide();
		}
	};

	const handleSearchCityChange = (event: any, value: any) => {
		setCitySearchQuery(value);
		
		const searchedCity = cities.find(
			(city) => city === value
		);

		if (!searchedCity) {
			setTimeout(() => {
				setMakeApiCallOnSearchCity(true);
			}, 1000);
		} else setMakeApiCallOnSearchCity(false);
		
		if (searchedCity) {
			setBankFields((prevState) => ({
				...prevState,
				cityName: searchedCity,
			}));
		}
	};

	const getCities = () => {
		let params = `?limit=${cityLimit}&offset=${cityOffset}&search=${citySearchQuery}&country_code=${"ke"}`;

		if (citySearchQuery === null) return;

		dispatch(globalLoaderTrue());
		Utils.api.getApiCall(
			Utils.endPoints.city,
			params,
			(respData: any) => {
			  	const { data } = respData;
			  	dispatch(globalLoaderFalse());
			  	// const option_cities = data.body.map((item: any) => ({
				// 	key: item.id,
				// 	value: item.name,
				// 	text: item.name,
				// }));
				const option_cities = data.body.map((item: any) => item.name);
				setCities([...option_cities]);
			},
			(error: any) => {
			  let { data } = error;
			  dispatch(globalLoaderFalse());
			  Utils.showAlert(2, data?.messages[0]);
			}
		  );
	};
	

	return (
		<CustomModal    //PaymentConfirm
			title={"Bank Account Detail"}
			content={
				<div>
					<h4>Please enter your mPesa registered bank account details.</h4>					
					<div style={{margin: "0 10px"}}>
						<FormControl className="mt-20">
									<label>Bank Name</label>
									<Input
										placeholder="Bank Name"
										required
										value={bankFields.bankName}
										name="bankName"
										id="bankName"
										onChange={(e) => {
											if (/^[a-zA-Z0-9 ]*$/.test(e.target.value))
												changeHandler(e);
										}}
									/>
									{isConfirmBtnClicked &&
										bankFields?.bankName?.trim().length === 0 && (
											<p className="red">Bank name is required</p>
										)}
							</FormControl>
							<FormControl className="mt-20">
									<label>Hospital Branch Name</label>
									<Input
										placeholder="Hospital Branch Name"
										required
										value={bankFields.branchName}
										name="branchName"
										id="branchName"
										onChange={(e) => {
											if (/^[a-zA-Z0-9 ]*$/.test(e.target.value))
												changeHandler(e);
										}}
									/>
									{/* {isConfirmBtnClicked && bankFields.branchName.length === 0 && <p style={{color:'red'}}>Please enter a branch name</p>} */}

							</FormControl>
							<FormControl className="mt-20">
									<label>Account Number</label>
									<Input
										placeholder="Account Number"
										required
										value={bankFields.accNo}
										name="accNo"
										id="accNo"
										onChange={(e) => {
											if (/^[0-9]*$/.test(e.target.value)) changeHandler(e);
										}}
										// maxLength="100"
									/>
									{isConfirmBtnClicked &&
										bankFields?.accNo?.trim().length === 0 && (
											<p className="red">
												Account number is required
											</p>
										)}
							</FormControl>
							<FormControl className="mt-20">
									<label>City</label>
									<Autocomplete
										id="city"
										options={cities}
										// getOptionLabel={(option: any) => option.text}
										noOptionsText="No result found"
										onChange={handleSearchCityChange}						
										value={bankFields.cityName}
										// isOptionEqualToValue={(option, value) => 
										// 	option.key === value.key										
										// }
										renderInput={(params) => <TextField {...params} label="Select a city" />}
										sx={{ mt: 1 }}
									/>
									{isConfirmBtnClicked &&
										bankFields?.cityName?.trim().length === 0 && (
											<p className="red">City is required</p>
										)}									
							</FormControl>
							<FormControl className="mt-20">
									<label>Bank Pay Bill</label>
									<Input
										placeholder="Bank Pay Bill"
										required
										value={bankFields.bankPayBill}
										name="bankPayBill"
										id="bankPayBill"
										onChange={(e) => {
											if (/^[a-zA-Z0-9 ]*$/.test(e.target.value))
												changeHandler(e);
										}}
									/>
									{isConfirmBtnClicked &&
										bankFields?.bankPayBill?.trim()?.length === 0 && (
											<p className="red">
												Pay bill number is required
											</p>
										)}
							</FormControl>
							<FormControl className="mt-20">
									<label>Swift/IBAN Code</label>
									<Input
										placeholder="Swift/IBAN Code"
										required
										value={bankFields.swiftCode}
										name="swiftCode"
										id="swiftCode"
										onChange={(e) => {
											if (/^[a-zA-Z0-9 ]*$/.test(e.target.value))
												changeHandler(e);
										}}
									/>
									{isConfirmBtnClicked && 
										bankFields.swiftCode.trim().length === 0 && (
											<p className="red">Swift/IBAN code is required</p>
										)}

							</FormControl>
					</div>
				</div>
			}
			open={popFor}
			handleClose={onHide}
			addCustomActions={true}
			actionItems={
				<>
					<Button
						variant="outlined"
						className={`capitalize`}
						sx={{ width: "auto" }}
						onClick={onHide}
						disabled={isApiLoadingOnAddPayment}
					>
						Cancel
					</Button>
					<Button  //LoadingButton
						variant="contained"
						className={`capitalize color-white`}
						sx={{ width: "auto" }}
						onClick={confirmBtnHandler}
						disabled={isApiLoadingOnAddPayment}
					>
						Confirm
					</Button>
				</>
			}
		/>	

	);
}

export default BankAccountDetail;