import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { createStyles, makeStyles } from "@mui/styles";
import SentClaims from "./sentClaims/sentClaims";
import TemplateClaim from "./templateClaim";
import { useDispatch, useSelector } from "react-redux";
import { ReducersModal } from "../../modal";
import Utils from "../../utils";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: "100%",
      typography: "body1",
    },
    tabLabel: {
      textTransform: "none",
      "& .css-mgilup-MuiButtonBase-root-MuiTab-root.Mui-selected": {
        color: "var(--black)",
        fontWeight: 550,
      },
      heading: {},
    },
  })
);

function MyProfile() {
  const classes = useStyles();
  // const [value, setValue] = useState("2");
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // setValue(newValue)
    dispatch({
      type: Utils.ActionName.Tab_DETAILS,
      payload: {
        value: newValue,
      },
    });
    dispatch({
      type: Utils.ActionName.SENT_CLAIMS,
      payload: {
        claim_start_date: "",
        claim_end_date: "",
        insurance_company_id: "",
        status: "",
        search: "",
        limit:10,
        offset:0,
        page:1
      },
    });
    dispatch({
      type: Utils.ActionName.GET_TEMPLATE_CLAIMS,
      payload: {
        claim_start_date: "",
        claim_end_date: "",
        insurance_company_id: "",
        status: "",
        search: "",
        limit:10,
        offset:0,
        page:1
      },
    });
    console.log("newValue", newValue);
  };

  const { value } = useSelector(
    (state: ReducersModal) => state.TabModelReducer
  );
  // const { value } = useSelector(
  //   (state: ReducersModal) => state.sentClaimReducer
  // );;
  return (
    <div className="page-my-claims">
      {/* <h2 className="color-blue semi-bold mb-15">My Claims</h2> */}
      {value && (
        <TabContext value={value}>
          <Box className={classes.tabLabel}>
            <TabList onChange={handleChange} className="bg-white p-0 mb-20">
              <Tab
                label={<Box className={classes.tabLabel}>Sent Claims</Box>}
                value="1"
              />
              <Tab
                label={<Box className={classes.tabLabel}>Template Claim</Box>}
                value="2"
              />
            </TabList>
            <TabPanel value="1" sx={{ padding: "0px" }}>
              <SentClaims />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "0px" }}>
              <div className="bg-white">
                <TemplateClaim />
              </div>
            </TabPanel>
          </Box>
        </TabContext>
      )}
    </div>
  );
}

export default MyProfile;
