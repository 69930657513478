import React from "react";
import Utils from "../../utils";
import { ReducersModal } from "../../modal";
import { useDispatch, useSelector } from "react-redux";

export default function Timer({ parentCallback }: any) {
  const dispatch = useDispatch();
  const { starts, enableResend } = useSelector(
    (state: ReducersModal) => state.signInReducer
  );

  React.useEffect(() => {
    const time = setTimeout(() => {
      if (starts !== 0) {
        dispatch({
          type: Utils.ActionName.SIGN_IN,
          payload: { starts: starts - 1 },
        });
      }
    }, 1000);
    // }
    parentCallback(starts);
    return () => clearTimeout(time);
  }, [dispatch, starts, parentCallback]);

  if (starts === 0 && enableResend) {
    dispatch({
      type: Utils.ActionName.SIGN_IN,
      payload: { enableResend: false },
    });
  }
  return <div style={{ display: "none" }}>00:{starts} Sec</div>;
}
