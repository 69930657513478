import React, { useState, useEffect } from "react";
import { Icon, Button, Typography } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LocalImages } from "../../../utils/images"
import CustomModal from "../../../components/modal/customModal";
import "./addPaymentMethod.css"

function AddPaymentMethod(props: any) {
	const { selectModal, onHide, pmList, showAddPmModal } = props;
	const [selectedModalId, setSelectedModal] = useState(null);
	const [noMethodRemaining, setNoMethodRemaining] = useState(false);
	const clickHandler = (event: any) => {
		setSelectedModal(event.target.id);
		selectModal(event.target.id)
	};
	
	useEffect(() => {
		if(pmList !== null) {
			"bankaccount" in pmList && 
			"paybill" in pmList && 
			"phone" in pmList &&
			"tillnumbers" in pmList ? setNoMethodRemaining(true) : setNoMethodRemaining(false)
		} else {
			setNoMethodRemaining(false);
		}
	}, [pmList])
	

	return (
		<CustomModal    //PaymentConfirm
			title={"Select your preferred payment method"}
			content={
				noMethodRemaining ? (
					<Typography>You have added all payment methods available to you</Typography>
				) : (
				<div>
					{!pmList?.paybill && (
						<div className="single-payment-method">
							<label
								className={`radio-label ${
									selectedModalId === "payBill" ? "selected" : ""
								}`}
								htmlFor="payBill"
							>
								<input
									type="radio"
									name="payBill"
									value="US"
									id="payBill"
									onClick={clickHandler}
								/>
								<img src={LocalImages.PaymentImage} alt="payment"/>
								Pay Bill
								<ArrowForwardIosIcon className="icon"/>
								{/* <Icon name="angle right" /> */}
							</label>
						</div>
					)}
					{!pmList?.tillnumbers && (
						<div className="single-payment-method">
							<label
								className={`radio-label ${
									selectedModalId === "tillNo" ? "selected" : ""
								}`}
								htmlFor="tillNo"
							>
								<input
									type="radio"
									name="tillNo"
									value="US"
									id="tillNo"
									onClick={clickHandler}
								/>
								<img src={LocalImages.PaymentImage} alt="payment"/>
								Till Number
								<ArrowForwardIosIcon className="icon"/>
								{/* <Icon name="angle right" /> */}
							</label>
						</div>
					)}
					{!pmList?.bankaccount && (
						<div className="single-payment-method">
							<label
								className={`radio-label ${
									selectedModalId === "bankAccount" ? "selected" : ""
								}`}
								htmlFor="bankAccount"
							>
								<input
									type="radio"
									name="bankAccount"
									value="US"
									id="bankAccount"
									onClick={clickHandler}
								/>
								<img src={LocalImages.PaymentImage} alt="payment"/>
								Bank Account
								<ArrowForwardIosIcon className="icon"/>
								{/* <Icon name="angle right" /> */}
							</label>
						</div>
					)}
					{!pmList?.phone && (
						<div className="single-payment-method">
							<label
								className={`radio-label ${
									selectedModalId === "mobileNumber" ? "selected" : ""
								}`}
								htmlFor="mobileNumber"
							>
								<input
									type="radio"
									name="mobileNumber"
									value="US"
									id="mobileNumber"
									onClick={clickHandler}
								/>
								<img src={LocalImages.PaymentImage} alt="payment"/>
								Mobile Number
								<ArrowForwardIosIcon className="icon"/>
								{/* <Icon name="angle right" /> */}
							</label>
						</div>
					)}
				</div>
				)
			}
			open={showAddPmModal}
			handleClose={onHide}
			addCustomActions={true}
			actionItems={
				<>
					<Button
						variant="contained"
						className={`capitalize color-white`}
						sx={{ width: "auto" }}
						onClick={onHide}
					>
						Cancel
					</Button>
				</>
			}
		/>
	);
}

export default AddPaymentMethod;
