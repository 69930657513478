import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { createStyles, makeStyles } from "@mui/styles";
import ExclusionList from "./exclusionList";
import './exclusion.css'

import { getRoleIdFromLS } from "../../utils/commonFunctions";


const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: "100%",
      typography: "body1",
    },
    tabLabel: {
      textTransform: "none",
      color: "black",
      "& .css-mgilup-MuiButtonBase-root-MuiTab-root.Mui-selected": {
        fontWeight: 550,
        backgroundColor: "red",
      },
    },
  })
);

function Exclusions() {
  const classes = useStyles();
  const [value, setValue] = useState("1");

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box className="page-my-profile">
      <TabContext value={value}>
      
        <TabPanel value="1" sx={{ padding: "0px" }}>
          <ExclusionList />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default Exclusions;
