import Utils from "../../utils";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";

export const getDashBoard = () => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());

    let params = "";
    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      Utils.endPoints.dashboard,
      params,
      (respData: any) => {
        let { data } = respData;

        dispatch({
          type: Utils.ActionName.GET_DASHBOARD,
          payload: {
            ...data.body,
          },
        });
        // ;
        dispatch(globalLoaderFalse());
      },
      (error: any) => {
        dispatch(globalLoaderFalse());
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};
