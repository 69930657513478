import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Button,
  Box,
  Typography,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import { useDispatch, useSelector } from "react-redux";
import { ReducersModal } from "../../../modal";
import Utils from "../../../utils";
import { getMedicineOrders, orderMarkComplete } from "../action";
import { OrderStatus } from "../../../utils/appData";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import moment from "moment";
import { isSubscriptionActive } from "../../../utils/commonFunctions";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: "#fff";
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 450,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 2,
  px: 4,
  pb: 3,
};

function TableComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [isHide, setIsHide] = useState(0);
  const { data, limit, count, page } = useSelector(
    (state: ReducersModal) => state.MedicineOrdersReducer
  );
  

  const { subscriptionDetails } = useSelector(
    (state: ReducersModal) => state.SubscriptionStatusReducer
  );

  // const [page, setPage] = useState(1);
  const handleOpen = (e: any, id: any) => {
    setOrderId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOrderId("");
  };

  const handleChangePage = (value: any) => {
    dispatch({
      type: Utils.ActionName.MEDICINE_ORDERS,
      payload: {
        page: value + 1,
      },
    });
    // setPage(value + 1);
  };
  const handleCompleteOrder = () => {
    if (orderId) {
      const params = { order_id: orderId };
      setIsHide(1);
      const elemId = `order_${orderId}`;
      const elem = document.getElementById(elemId);
      if (elem) {
        elem.style.display = "none";
      }
      dispatch(orderMarkComplete(params));
      dispatch(getMedicineOrders());
    }

    setOpen(!open);
  };

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.MEDICINE_ORDERS,
      payload: {
        offset: page * limit - limit,
      },
    });
    dispatch(getMedicineOrders());
  }, [page]);

  const handleChangeRowsPerPage = (evt: any) => {
    
    dispatch({
      type: Utils.ActionName.MEDICINE_ORDERS,
      payload: {
        limit: +evt.target.value,
        offset: 0,
      },
    });
    dispatch(getMedicineOrders());
    // setRowsPerPage(10)
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Order Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((data: any) => {
                return (
                  <TableRow
                    hover
                    key={data?.OrderId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{
                      backgroundColor: data.status == 2 ? "#ffcccb" : "",
                    }}
                  >
                    <TableCell>{data.OrderId ? data.OrderId : "N/A"}</TableCell>
                    <TableCell>
                      {data.manufacturer_company
                        ? data.manufacturer_company
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {data.CreatedAt
                        ? moment(data.CreatedAt).format("DD/MM/YYYY")
                        : "N/A"}
                    </TableCell>

                    {OrderStatus.map((option: any, index: any) => {
                      if (option.id == data.OrderStatus)
                        return (
                          <TableCell key={index}>
                            {option.name ? option.name : "N/A"}
                          </TableCell>
                        );
                      return null;
                    })}
                    <TableCell>
                      {data.show_complete_button ? (
                        <Button
                          id={`order_${data?.order_id}`}
                          title="Mark Order Complete"
                          onClick={(e) => {
                            const isActiveSubscription =
                              isSubscriptionActive(subscriptionDetails);
                            if (isActiveSubscription.isActive == false) {
                              navigate(Utils.routes.manageSubscription);
                            } else {
                              handleOpen(e, data?.order_id);
                            }
                          }}
                        >
                          <CheckCircleOutlineIcon
                            style={{
                              animation: "glowingEff 2500ms infinite",
                              color: "white",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        title="View Detail"
                        sx={{ minWidth: "unset" }}
                        onClick={() => {
                          if (data.OrderId) {
                            navigate(
                              `${Utils.routes.medicineOrderDetail}/${data.OrderId}`
                            );
                          }
                        }}
                      >
                        {" "}
                        <PreviewIcon color="info" />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                key={1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {" "}
                <TableCell>{"No Orders Found"}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length > 0 && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={count}
              rowsPerPage={limit}
              page={page - 1}
              onPageChange={(e, value): any => handleChangePage(value)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={<span>Rows:</span>}
              sx={{
                ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
                  fontWeight: "bold",
                  color: "primary",
                },
              }}
            />
          </TableRow>
        </TableFooter>
      )}
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to complete the associated Order?
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: 6, ml: 19 }}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 6, ml: 4, color: "#fff" }}
            onClick={() => handleCompleteOrder()}
          >
            Yes
          </Button>
        </Box>
      </StyledModal>
    </>
  );
}

export default TableComponent;
