import { makeStyles, createStyles } from "@mui/styles";
import {
  Avatar,
  Grid,
  Theme,
  MenuItem,
  Radio,
  FormControlLabel,
  Typography,
  CardMedia,
  Link,
  CardContent,
  Card,
  Backdrop,
  CircularProgress,
  Box,
  ThemeProvider,
  Chip,
  InputAdornment,
  Button,
  Modal,
  // Paper,
} from "@mui/material";
import { Edit, Close } from "@mui/icons-material";
import {
  checkFileSize,
  checkFileType,
  // convertTranscribePrescriptionPayload,
  deleteDocumentImage,
  getBenefitLiaisonBalance,
  getSubBenefitName,
  formatSubbenefitsToDD,
  ifphoneTenDigitThenTrim,
  searchBenefitWithType,
  searchBenefitWithPreAuthTypes,
  searchSmartBenefitWithType,
  uploadDocumentImage,
  fundedBySomeone,
  policyCurrentStatus,
  benefitList,
  subBenefitRequiredCondition,
  getUserBenefits,
  userDeniedLocationErrMsg,
  isSubscriptionActive,
} from "../../utils/commonFunctions";
import CustomModal from "../../components/modal/customModal";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";
import { toastIdsConstant } from "../../utils/toastIdsConstant";
import { toast } from "react-toastify";
// import LoadingButton from "@mui/lab/LoadingButton";
import Checkbox from "@mui/material/Checkbox";
import CustomButton from "../../components/formsUi/button/customButton";
import { Formik, Form, useFormikContext, ErrorMessage } from "formik";
import {
  // addUserDocumentClaim,
  getInsuranceCompany,
  getInsuredMember,
  onSubmitFirstStep,
  onSubmitMember,
  syncFromAppImage,
  uploadImageFromApp,
  onSendOtp,
  onSubmitEthical,
} from "./action";
import Schema from "../../schema";
import { useSelector, useDispatch } from "react-redux";
import TextField from "../../components/formsUi/textFiled";
import Select from "../../components/formsUi/select/customSelect";
import { useEffect, useState } from "react";
import DateTimePicker from "../../components/formsUi/dateTimePicker/index";
import { ReducersModal } from "../../modal";
import RadioSelect from "../../components/formsUi/radio/RadioButton";
import { useTheme } from "@mui/material";
import Utils from "../../utils";
import { LocalImages } from "../../utils/images";
//@ts-ignore
import InputFile from "../../components/formsUi/inputFile/customFileUpload";
import CustomButtonWrapper from "../../components/formsUi/button/customButton";
import BasicModal from "../../components/modal";
import ClaimAuthentication from "../../components/modal/claimAuthentication";
import PreviewImg from "../../components/previewImg";
import { Navigate, useNavigate } from "react-router-dom";
import VerificationEthicalModal from "../../components/modal/verificationEthicalModal";
import moment from "moment";
import LocationPopupModal from "../../components/modal/LocationPopupModal";
const API_URL = Utils.constants.API_URL;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
    },
    avatarDiv: {
      paddingLeft: theme.spacing(3),
    },
    typography: {
      "&.MuiTypography-root": {
        marginTop: theme.spacing(2),
      },
    },
    circularProgressDiv: {
      textAlign: "center",
      "&.MuiAvatar-root": {
        width: "150px",
        margin: "auto",
      },
    },
    gridItemTwo: {
      height: "100%",
      justifyContent: "space-between",
    },
    textFiledDiv: {
      width: "90%",
    },
    flexBox: {
      display: "flex",
      "& .MuiAvatar-root": {
        height: "25px",
        width: "20px",
      },
    },
    flagIcon: {
      width: 24,
      height: 24,
    },
    marginFlag: {
      marginLeft: "10px",
      textTransform: "uppercase",
      fontSize: "14px",
    },
    radioContainer: {
      "& .MuiFormControlLabel-root": {
        alignItems: "flex-start",
      },
    },
    test: {
      borderRadius: "12px",
      [theme.breakpoints.down("md")]: {
        margin: "unset",
        padding: "0 5px",
        width: "100%",
      },
      alignItems: "center !important",
      "& .MuiTypography-root": {
        width: "100%",
        fontSize: "14px",
      },
    },
    radioColor: {
      color: "#009bde !important",
    },
    fadeTitle: {
      color: "rgb(194, 191, 191)",
    },
    errorMessage: {
      color: "red",
      opacity: "0.8",
      fontSize: "16px",
    },
    imgCover: {
      position: "relative",
      width: "150px",
      margin: "5px auto 0px",
      boxShadow: "0px 1px 3px 1px rgba(0,0,0,.2)",
      borderRadius: "5px",
      "&.MuiAvatar-root": {
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
    },
    closeIcon: {
      position: "absolute",
      top: -10,
      right: -10,
      cursor: "pointer",
      width: "20px",
      height: "20px",
    },
    benefitChip: {
      margin: "5px",
      height: "auto",
      backgroundColor: "#53c392 !important",
      color: "white",
      "&.MuiChip-label": {
        padding: "5px 12px",
        whiteSpace: "initial",
        color: "white",
      },
    },
    benefitChipRed: {
      margin: "5px",
      height: "auto",
      backgroundColor: "red !important",
      color: "white",
      "& .MuiChip-label": {
        padding: "5px 12px",
        whiteSpace: "initial",
        color: "white",
      },
    },
    avatarDoc: {
      "&.MuiAvatar-root": {
        width: theme.spacing(15),
        height: theme.spacing(10),
        backgroundColor: theme.palette.secondary.light,
        position: "relative",
      },
    },
    backdrop: ({ theme }: any) => ({
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    }),
  })
);

function PatientProfile() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setValues, setFieldError } = useFormikContext();
  const [openPreview, setOpenPreview] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [previewImgUrl, setPreviewImgUrl] = useState("");
  const [fundedBy, setFundedBy] = useState("");
  const [isNotValid, setNotValid] = useState<any>("");
  const { isLoading } = useSelector(
    (state: ReducersModal) => state.globalLoaderReducer
  );
  const { data: ProviderDetails } = useSelector(
    (state: ReducersModal) => state.ProviderDetailsReducer
  );

  const {countryCodeLength } = useSelector(
    (state: ReducersModal) => state.signInReducer
  );

  const [notValidEthical, setNotValidEthical] = useState<any>("");
  const [claim_type, setClaimType] = useState<any>("Outpatient");

  const loader = () => {
    return (
      <Backdrop
        className={classes.backdrop}
        open={isLoading ? isLoading : false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const { data, patientInsurance } = useSelector(
    (state: ReducersModal) => state.InsuranceCompanyReducer
  );

  const {
    patientCountryCode,
    cardListArray,
    authType,
    authTypeSelected,
    // insuranceData,
    patientInsuranceMemberDataList,
    insuranceCardType,
    entityId,
    insurance_member_dependents,
    patientInsuranceMember,
    gender,
    patientName,
    patientDoB,
    phoneNumber,
    patientFamilyMemberData,
    search,
    showSearchMemberNo,
    claimDate,
    familyMemberPhoneNumber,
    showOtpVerification,
    checked,
    principalPhoneNumber,
    termsConditionChecked,
    insuranceMemberOptionSelect,
    card1,
    card2,
    card3,
    card4,
    userId,
    finalEntityId,
    employeeNumber,
    age,
    claimId,
    isPrincipal,
    // beneficiaryId,
    policyStatus,
    userPrincipalId,
    card1Added,
    card2Added,
    card3Added,
    card4Added,
    benefits,
    cardType,
    subBenefits,
    benefit_name,
    subbenefit_name,
    benefitBalance,
    subBenefitBalance,
    patientBenefitList,
    benefit_pool_number,
    benefit_used_Balance,
    ethicalSubstitue,
    ethicalSubsVerify,
    ethicalSubstitueDone,
    syncForApp,
    sub_benefit_id,
    benefit_id,
  } = useSelector(
    (state: ReducersModal) => state.InsurancePatientProfileReducer
  );

  const { subscriptionDetails } = useSelector(
    (state: ReducersModal) => state.SubscriptionStatusReducer
  );

  useEffect(() => {
    if (benefits?.length > 0) checkIsFundedBy();
  }, [benefits]);

  useEffect(() => {
    const isActiveSubscription = isSubscriptionActive(subscriptionDetails);
    if (isActiveSubscription.isActive == false) {
      navigate(Utils.routes.manageSubscription);
    }
  }, []);

  const insuranceData: any = data.find((a: any) => a.id === patientInsurance);

  const policyCurrentStatus = (policyStatus: any) => {
    let SUPPORT_NUMBER = "0114081492";
    if (fundedBy) {
      return (
        "E-Claim not available. Seek email preauthorization on preauth@liviaapp.com. The scheme is " +
        fundedBy +
        "."
      );
    } else {
      switch (policyStatus) {
        case 1:
          return "Policy active";
        case 2:
          return "Policy expired. Please call " + SUPPORT_NUMBER;
        case 3:
          return "Insufficient balance. Please call " + SUPPORT_NUMBER;
        case 4:
          return "Balance not retrieved. Please call " + SUPPORT_NUMBER;
        case 5:
          return "Sorry, the patient's scheme can not be used to create a Doctor claim.";
        case 8:
          return "Time out error. Unable to retrieve benefit details. Try again later.";
        default:
          return "CLAIM NOT ALLOWED";
      }
    }
  };

  const authText = authType.reduce((res: any, item: any) => {
    if (item === "OTP") {
      res = { ...res, [item]: "Phone OTP authentication" };
    }
    if (item === "FR") {
      res = { ...res, [item]: "Face biometric authentication" };
    }
    return res;
  }, []);

  const INITIAL_VALUE = {
    patientDob: patientDoB,
    gender: gender,
    patientName: patientName,
    phoneNumber: phoneNumber,
    claimDate: claimDate,
    termsConditionChecked: termsConditionChecked,
  };

  const chooseCardType = (event: any) => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        insuranceCardType: event.target.value,
        showSearchMemberNo: true,
      },
    });
    setFieldError("search", "");
  };

  const chooseInsuranceMemberOptions = (event: any) => {
    const memberData: any = patientInsuranceMemberDataList.find(
      (a: any) => a.entity_id === event.target.value
    );

    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        subBenefits: [],
        benefit_used_Balance: 0,
        benefit_name: "",
        benefit_id: "",
        sub_benefit_id: "",
        benefitBalance: 0,
        patientBenefitList: [],
        benefit_pool_number: 0,
        subbenefit_name: "",
        entityId: event.target.value,
        finalEntityId:
          insurance_member_dependents === null ||
          insurance_member_dependents === undefined
            ? memberData.ent_dep_parent_on_policy
            : event.target.value,
        search:
          memberData.card_type === 1 || memberData.card_type === "1"
            ? memberData.entity_id
            : memberData.employee_number,
        insuranceMemberOptionSelect: false,
        claimDate: moment(new Date()).format("yyyy-MM-DD"),
      },
    });
    dispatch(globalLoaderTrue());
    dispatch(onSubmitMember(memberData));
  };

  const [initialValue, setInitialValue] = useState(INITIAL_VALUE);

  useEffect(() => {
    dispatch(getInsuranceCompany());
  }, [dispatch]);

  useEffect(() => {
    if (checked) {
      setInitialValue({
        ...initialValue,
        phoneNumber: familyMemberPhoneNumber,
      });
    }
    if (
      patientInsuranceMember !== undefined &&
      patientInsuranceMember !== null &&
      patientInsuranceMember.length > 0 &&
      patientInsuranceMember[0].phone !== null &&
      patientInsuranceMember[0].phone !== "" &&
      patientInsuranceMember[0].phone !== undefined
    ) {
      var phone = patientInsuranceMember[0].phone;
      if (
        patientInsuranceMember[0].phone !== null &&
        patientInsuranceMember[0].phone !== undefined
      ) {
        phone = patientInsuranceMember[0].phone.slice(3, 12);
      }
      //
      // if (checked) {
      setInitialValue({
        ...initialValue,
        phoneNumber: phone,
      });
      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          phoneNumber: phone,
        },
      });
      // }
    }

    setValues(INITIAL_VALUE);
  }, [checked, familyMemberPhoneNumber, patientInsuranceMember]);

  useEffect(() => {
    if (
      patientInsurance &&
      policyStatus == 1 &&
      authTypeSelected === "1" &&
      entityId &&
      (patientInsuranceMember[0]?.face_id === "" ||
        patientInsuranceMember[0]?.face_id === null ||
        patientInsuranceMember[0]?.face_id === undefined)
    ) {
      setShowInfoPopup(true);
    }
  }, [
    policyStatus,
    authTypeSelected,
    entityId,
    patientInsuranceMember[0]?.face_id,
  ]);

  useEffect(() => {
    if (
      principalPhoneNumber !== null &&
      principalPhoneNumber !== undefined &&
      principalPhoneNumber !== "" &&
      checked
    ) {
      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          phoneNumber: principalPhoneNumber,
        },
      });
      setInitialValue({
        ...initialValue,
        phoneNumber: principalPhoneNumber,
      });
    }
  }, [principalPhoneNumber, checked]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    if (familyMemberPhoneNumber !== "" && familyMemberPhoneNumber !== null) {
      if (event.target.checked) {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            phoneNumber: familyMemberPhoneNumber,
            termsConditionChecked: termsConditionChecked,
          },
        });
        setFieldValue("phoneNumber", familyMemberPhoneNumber);
        setInitialValue({
          ...initialValue,
          phoneNumber: familyMemberPhoneNumber,
          termsConditionChecked: termsConditionChecked,
        });
      } else {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            phoneNumber: "",
            termsConditionChecked: termsConditionChecked,
          },
        });
        setFieldValue("phoneNumber", "");
        setInitialValue({
          ...initialValue,
          phoneNumber: "",
          termsConditionChecked: termsConditionChecked,
        });
      }
    } else if (
      principalPhoneNumber !== null &&
      principalPhoneNumber !== undefined &&
      principalPhoneNumber !== ""
    ) {
      if (event.target.checked) {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            phoneNumber: principalPhoneNumber,
            termsConditionChecked: termsConditionChecked,
          },
        });
        setInitialValue({
          ...initialValue,
          phoneNumber: principalPhoneNumber,
          termsConditionChecked: termsConditionChecked,
        });
        setFieldValue("phoneNumber", familyMemberPhoneNumber);
      } else {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            phoneNumber: "",
            termsConditionChecked: termsConditionChecked,
          },
        });
        setInitialValue({
          ...initialValue,
          phoneNumber: "",
          termsConditionChecked: termsConditionChecked,
        });
        setFieldValue("phoneNumber", familyMemberPhoneNumber);
      }
    }
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        checked: event.target.checked,
      },
    });
  };

  // const handleMenuItem = (event: any, number: any) => {
  //   setFamilyPhoneNumber(event.target.innerText);
  // };
  useEffect(() => {
    if (authType.length === 1) {
      if (authType[0] === "OTP") {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            authTypeSelected: "0",
          },
        });
      }
      if (authType[0] === "FR") {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            authTypeSelected: "1",
          },
        });
      }
    }
  }, [authType]);

  function AuthType() {
    const authTypeSelected: any =
      authText[
        authType.find(
          (a: any, index: any) => index === Number(showOtpVerification)
        )
      ];

    return (
      <div style={{ display: "flex" }}>
        <Typography sx={{ fontSize: 14, display: "flex" }}>
          <span>{"Authentication Type :"} </span>
          <span style={{ fontSize: 14, fontWeight: "bold", paddingLeft: 5 }}>
            {"  "}
            {showOtpVerification !== "" ? authTypeSelected : ""}
          </span>
          {authType.length > 1 &&
          (claimId === "" || claimId === null || claimId === undefined) ? (
            <Edit
              onClick={() =>
                dispatch({
                  type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                  payload: {
                    showAuthenticationType: true,
                  },
                })
              }
              style={{
                cursor: "pointer",
                fontSize: "18px",
                marginLeft: "10px",
              }}
            />
          ) : (
            ""
          )}
        </Typography>
        <ClaimAuthentication authType={authType} authText={authText} />
      </div>
    );
  }

  const PatientInfo = () => {
    return (
      <>
        <Grid container spacing={2}>
          {patientInsurance !== undefined && patientInsurance !== "" ? (
            <Grid item xs={3} sm={6} md={3}>
              {/* <div style={{ display: "flex" }}> */}
              <RadioSelect
                data={cardListArray.map((item: any, index: any) => {
                  return (
                    <div key={index} className={classes.radioContainer}>
                      <FormControlLabel
                        value={`${item.id}`}
                        className={`w-100 ${classes.test}`}
                        control={<Radio className={`${classes.radioColor}`} />}
                        disabled={
                          claimId !== null &&
                          claimId !== undefined &&
                          claimId !== ""
                        }
                        label={
                          <div className="space-between w-100 h-100">
                            <Typography
                              component="div"
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                              }}
                            >
                              {item.name}
                            </Typography>
                            <CardMedia
                              component="img"
                              sx={{
                                width: 70,
                                boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
                              }}
                              image={`${API_URL}${item.image}`}
                              alt="Live from space album cover"
                            />
                          </div>
                        }
                      />
                    </div>
                  );
                })}
                label={""}
                value={insuranceCardType}
                onSelect={(event: any) => chooseCardType(event)}
              />
              {/* </div> */}
            </Grid>
          ) : (
            ""
          )}
          {showSearchMemberNo ? (
            <Grid item xs={5} sm={6} md={6}>
              <Formik
                enableReinitialize
                initialValues={{
                  search: search,
                }}
                validationSchema={Schema.memberSearchSchema(
                  insuranceCardType,
                  insuranceData
                )}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(
                    getInsuredMember(patientInsurance, setSubmitting, values)
                  );
                }}
              >
                {({ isSubmitting, isValid }) => (
                  <Form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item sm={9} md={6}>
                          <TextField
                            sx={{ width: "100%" }}
                            name="search"
                            placeholder={
                              insuranceCardType === "1"
                                ? "Search with Smart Card"
                                : insuranceData !== undefined &&
                                  insuranceData.id === "13"
                                ? "Search with LCT Member No."
                                : "Search with Member No."
                            }
                            label={
                              insuranceCardType === "1"
                                ? "Search with Smart Card"
                                : insuranceData !== undefined &&
                                  insuranceData.id === "13"
                                ? "Search with LCT Member No."
                                : "Search with Member No."
                            }
                            size="small"
                            value={search}
                            // onChange={(event:any)=> setSearch(event,"search",setFieldValue)}
                            // *defaultValue={search}
                            disabled={
                              isSubmitting ||
                              (claimId !== null &&
                                claimId !== undefined &&
                                claimId !== "")
                            }
                            setInitialValue={setInitialValue}
                            initialValue={initialValue}
                          />
                          {patientFamilyMemberData !== undefined &&
                          patientFamilyMemberData !== null &&
                          patientFamilyMemberData?.length > 0 ? (
                            <div
                              style={
                                policyStatus === 1 && fundedBy == ""
                                  ? { color: "green", marginTop: "5px" }
                                  : { color: "red", marginTop: "5px" }
                              }
                            >
                              {policyCurrentStatus(policyStatus)}
                            </div>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item sm={3} md={6}>
                          {" "}
                          <CustomButtonWrapper
                            variant="contained"
                            style={{ width: "100%" }}
                            sx={{
                              color: "var(--white)",
                              bgcolor: "var(--light-green)",
                            }}
                            type="submit"
                            disabled={
                              (claimId !== null &&
                                claimId !== undefined &&
                                claimId !== "") ||
                              !isValid
                            }
                            isSubmitting={isSubmitting}
                          >
                            {isSubmitting ? "Searching..." : "Search"}
                          </CustomButtonWrapper>
                        </Grid>
                      </Grid>
                    </div>
                    {patientInsurance !== undefined &&
                    patientInsurance !== "" &&
                    patientInsuranceMemberDataList.length > 0 &&
                    !insuranceMemberOptionSelect
                      ? insuranceMemberOptions()
                      : ""}
                  </Form>
                )}
              </Formik>
            </Grid>
          ) : (
            ""
          )}
          {insuranceMemberOptionSelect && (
            <Grid item xs={6} sm={6} md={3}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <TextField
                  fullWidth
                  name="employeename"
                  label="Employer Name"
                  size="small"
                  // autoFocus={true}
                  disabled={true}
                  //@ts-ignore
                  value={
                    patientInsuranceMember !== undefined &&
                    patientInsuranceMember.length > 0
                      ? patientInsuranceMember[0].policy_holder_name
                      : ""
                  }
                />
              </div>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  const insuranceMemberOptions = () => {
    return (
      <div style={{ display: "flex", width: "65%" }}>
        <Card sx={{ minWidth: 10 }}>
          <CardContent>
            <RadioSelect
              data={
                patientInsuranceMemberDataList.length > 0 &&
                patientInsuranceMemberDataList.map((item: any, index: any) => {
                  //
                  return (
                    <div key={index} className={classes.radioContainer}>
                      <FormControlLabel
                        value={item.entity_id}
                        className={classes.test}
                        control={<Radio className={`${classes.radioColor}`} />}
                        label={
                          <div style={{ display: "flex" }}>
                            <Typography component="div" variant="h6">
                              {/* {`${item.family_code} - ${item.employee_number}`} */}
                              {item.card_type === 1 || item.card_type === "1"
                                ? `${item.beneficiary_name}${" | "}${
                                    item.entity_id
                                  }`
                                : `${item.beneficiary_name}${" | "}${
                                    item.employee_number
                                  }`}
                            </Typography>
                          </div>
                        }
                      />
                    </div>
                  );
                })
              }
              label={"Insurance Member Options"}
              value={entityId}
              onSelect={(event: any) => chooseInsuranceMemberOptions(event)}
            />
          </CardContent>
        </Card>
      </div>
    );
  };

  const insuranceList = () => {
    return (
      <Grid container spacing={3} className="mb-30">
        <Grid item xs>
          <Select
            name="patientInsurance"
            label="Select Patient Insurance"
            size="small"
            value={patientInsurance}
            disabled={claimId !== ""}
            options={
              data?.length === 0
                ? ["No Insurance company available"].map(
                    (option: any, index: any) => {
                      return <MenuItem key={index}>{option}</MenuItem>;
                    }
                  )
                : data.map((option: any, index: any) => {
                    return (
                      <MenuItem key={index} value={option.id}>
                        <div className={classes.flexBox}>
                          <Avatar
                            src={`${API_URL}${option.avatar}`}
                            variant="square"
                          ></Avatar>

                          <div className={classes.marginFlag}>
                            {option.name}
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })
            }
          />
        </Grid>
        <Grid item xs={4}>
          {patientInsurance !== undefined && patientInsurance !== ""
            ? AuthType()
            : ""}
        </Grid>
        <Grid item xs={5}>
          {patientFamilyMemberData !== undefined &&
          patientFamilyMemberData?.length > 0 ? (
            <>
              <h4 className="semi-bold mb-10" style={{ marginTop: "-20px" }}>
                Member Benefits
              </h4>
              <div className="benefit-chips-list">
                {patientFamilyMemberData !== undefined &&
                patientFamilyMemberData?.length > 0 &&
                benefits !== undefined &&
                benefits?.length > 0 ? (
                  benefits.map((option: any, index: any) => {
                    if (index < 2)
                      return (
                        <Chip
                          className={`${
                            policyStatus === 1
                              ? classes.benefitChip
                              : classes.benefitChipRed
                          }`}
                          label={`${option.name}${"-"}${
                            option.amount !== null ? option.amount : "()"
                          }`}
                        />
                      );
                  })
                ) : (
                  <Chip className="benefit-chip" label="No Benefits" />
                )}
                <Link
                  onClick={() =>
                    dispatch({
                      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                      payload: {
                        showMoreBenefits: true,
                      },
                    })
                  }
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    marginLeft: "10px",
                  }}
                  title="Click to see all benefits"
                >
                  Show more
                </Link>
              </div>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    );
  };

  const addProfilePicture = (files: any, fileType: string) => {
    if (claimId !== null && claimId !== undefined && claimId !== "") return;
    if (checkFileSize(files.fileList[0])) return;
    if (checkFileType(files)) return;
    if (files.base64) {
      dispatch(globalLoaderTrue());
      uploadDocumentImage(files.base64.split(",")[1])
        .then((res: any) => {
          if (res?.status === 200) {
            if (fileType === "card1") {
              dispatch({
                type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                payload: {
                  card1: res?.data?.image,
                  card1Added: true,
                },
              });
            }
            if (fileType === "card2") {
              dispatch({
                type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                payload: {
                  card2: res?.data?.image,
                  card2Added: true,
                },
              });
            }
            if (fileType === "card3") {
              dispatch({
                type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                payload: {
                  card3: res?.data?.image,
                  card3Added: true,
                },
              });
            }
            if (fileType === "card4") {
              dispatch({
                type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                payload: {
                  card4: res?.data?.image,
                  card4Added: true,
                },
              });
            }
            dispatch(globalLoaderFalse());
          }
        })
        .catch((err: any) => {
          if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
            toast.error(
              err?.response?.data?.messages && err?.response?.data?.messages[0],
              {
                toastId: toastIdsConstant.loginApiFailure,
                className: "toast-error",
              }
            );
          }
          dispatch(globalLoaderFalse());
        });
    }
  };

  const deleteProfilePhoto = (item: string, fileType: string) => {
    if (!item) return;
    if (claimId !== null && claimId !== undefined && claimId !== "") return;
    dispatch(globalLoaderTrue());
    deleteDocumentImage(item)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          // ;
          if (fileType === "card1") {
            dispatch({
              type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
              payload: {
                card1: "",
              },
            });
          }
          if (fileType === "card2") {
            dispatch({
              type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
              payload: {
                card2: "",
              },
            });
          }
          if (fileType === "card3") {
            dispatch({
              type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
              payload: {
                card3: "",
              },
            });
          }
          if (fileType === "card4") {
            dispatch({
              type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
              payload: {
                card4: "",
              },
            });
          }

          dispatch(globalLoaderFalse());
        }
      })
      .catch((err: any) => {
        if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
          toast.error(
            err?.response?.data?.messages && err?.response?.data?.messages[0],
            {
              toastId: toastIdsConstant.loginApiFailure,
              className: "toast-error",
            }
          );
        }
        dispatch(globalLoaderFalse());
      });
  };

  const handleTerm = (e: any, setFieldValue: any) => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        termsConditionChecked: e.target.checked,
      },
    });
    setFieldValue("termsConditionChecked", e.target.checked);
  };

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };

  const previewImgOpen = () => {
    handleOpenPreview();
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const geoTagErrorPopupManage = () => {
    dispatch({
      type: Utils.ActionName.GET_PROVIDER_DETAILS,
      payload: { data: { ...ProviderDetails, geoTagErrorPopup: false } },
    });
  };

  const getBenefitName = (type: any = "Outpatient") => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        subBenefits: [],
        benefit_used_Balance: 0,
        benefit_name: "",
        benefit_id: "",
        sub_benefit_id: "",
        benefitBalance: 0,
        patientBenefitList: [],
        benefit_pool_number: 0,
      },
    });

    setFundedBy("");
    const benefitRes =
      benefits?.length > 0 &&
      benefits.filter((benefit: any) => {
        if (benefit) {
          return getUserBenefits(type, benefit, cardType, patientInsurance);
        }
      });

    const patientBenefits = benefitList(benefits, cardType, patientInsurance);

    if (benefitRes) {
      const subBenefitBalace =
        benefitRes?.[0]?.subbenefit?.length > 0
          ? benefitRes?.[0]?.subbenefit
          : [];
      let formatedSubBenefits = [];

      if (subBenefitBalace?.length > 0) {
        formatedSubBenefits = formatSubbenefitsToDD(subBenefitBalace);
      }

      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          subBenefits: formatedSubBenefits,
          benefit_used_Balance: benefitRes?.[0]?.amount,
          benefit_name: benefitRes?.[0]?.name,
          benefit_id: benefitRes?.[0]?.benefit_id,
          benefitBalance: benefitRes?.[0]?.amount,
          patientBenefitList: patientBenefits,
          benefit_pool_number: benefitRes?.[0]?.pool_number,
        },
      });

      if (cardType == 2) {
        const benefitFundedBy = fundedBySomeone(
          benefitRes?.[0]?.name,
          patientInsurance
        );
        setFundedBy(benefitFundedBy);
      }

      // let isrequiredCondition = subBenefitRequiredCondition(patientInsurance, cardType, formatedSubBenefits, subbenefit_name)

      // if (isrequiredCondition) {
      //   Utils.showAlert(2, isrequiredCondition);
      //   return;
      // }
      return benefitRes?.[0]?.name;
    } else {
      return false;
    }
  };

  const getOPSubBenefitName = () => {
    const benefitRes =
      benefits?.length > 0 &&
      benefits.filter((benefit: any) => {
        if (benefit) {
          return getUserBenefits(
            "Outpatient",
            benefit,
            cardType,
            patientInsurance
          );
          // return cardType == 3
          //   ? getBenefitLiaisonBalance("Outpatient", benefit)
          //   : cardType == 2 ? searchSmartBenefitWithType("Outpatient", benefit) : searchBenefitWithPreAuthTypes("Outpatient", benefit);
        }
      });
    return getSubBenefitName(benefitRes?.[0]?.subbenefit);
  };

  const getSubBenefit = (val: any) => {
    const type = getTypeOfBenefit(val.benefitType);
    setClaimType(type);
    getBenefitName(type);
  };

  const getTypeOfBenefit = (val: any) => {
    if (
      val.toLowerCase().includes("dental") ||
      val.toLowerCase().includes("rider")
    ) {
      return "Dental";
    } else {
      return "Outpatient";
    }
  };
  const handleChangeEthical = (val: any) => {
    setNotValidEthical("");
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        ethicalSubstitue: val,
      },
    });
    if (phoneNumber == "" || phoneNumber == undefined) {
      Utils.showAlert(
        2,
        "Empty phone number is not allowed. Please enter/select phone number."
      );
      return;
    }
    const fullPhone = `${patientCountryCode}${ifphoneTenDigitThenTrim(
      phoneNumber
    )}`;
    const FinalEntityId = checked ? finalEntityId : entityId;
    const ethicalSubstitue = val ? 1 : 0;
    dispatch(
      onSendOtp(
        fullPhone,
        patientInsurance,
        FinalEntityId,
        ethicalSubstitue,
        "send_otp",
        false,
        entityId
      )
    );
  };
  const checkIsFundedBy = () => {
    getBenefitName();
  };
  const patientBenefitSubBenefitList = () => {
    return (
      <Grid container spacing={3} className="mb-30">
        <Grid item xs={3}>
          {patientInsurance !== undefined && patientInsurance !== "" ? (
            <Select
              name="userBenefits"
              label="Benefit used"
              size="small"
              value={benefit_name}
              disabled={claimId !== ""}
              options={
                patientBenefitList?.length === 0
                  ? ["No Benefits available"].map((option: any, index: any) => {
                      return <MenuItem key={index}>{option}</MenuItem>;
                    })
                  : patientBenefitList.map((option: any, index: any) => {
                      return (
                        <MenuItem
                          key={index}
                          value={option.name}
                          onClick={() => getSubBenefit(option)}
                        >
                          <div className={classes.flexBox}>
                            <div className={classes.marginFlag}>
                              {option.name}
                            </div>
                          </div>
                        </MenuItem>
                      );
                    })
              }
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={3}>
          <Select
            name="userSubBenefits"
            label="Select Sub-benefits"
            size="small"
            value={subbenefit_name}
            disabled={claimId !== ""}
            options={
              subBenefits?.length === 0
                ? ["No Sub-benefit available"].map(
                    (option: any, index: any) => {
                      return <MenuItem key={index}>{option}</MenuItem>;
                    }
                  )
                : subBenefits.map((option: any, index: any) => {
                    return (
                      <MenuItem
                        key={index}
                        value={option.key}
                        onClick={() => setSubBenefitBalance(option)}
                      >
                        <div className={classes.flexBox}>
                          <div>{option.text}</div>
                        </div>
                      </MenuItem>
                    );
                  })
            }
          />
        </Grid>

        <VerificationEthicalModal />
      </Grid>
    );
  };

  const setSubBenefitBalance = (val: any) => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        benefitBalance: val.amount,
        sub_benefit_id: val?.sub_benefit_id,
      },
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {loader()}
        <div className="page-patient-profile">
          <div className="bg-white mb-20">
            {insuranceList()}
            <Grid container spacing={3} className="mb-30">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ backgroundColor: "", width: "50%" }}
              >
                {patientInsurance && <>{PatientInfo()}</>}
              </Grid>
            </Grid>
            {insuranceMemberOptionSelect && (
              <Formik
                enableReinitialize
                initialValues={{ ...initialValue }}
                validationSchema={Schema.PatientInsuranceSchema(initialValue,countryCodeLength)}
                onSubmit={(values, { setSubmitting }) => {
                  setNotValid("");
                  if (!termsConditionChecked) {
                    Utils.showAlert(2, "Please check Terms and condition");
                    return;
                  }

                  if (
                    (!ethicalSubsVerify || ethicalSubsVerify == undefined) &&
                    (!ethicalSubstitueDone ||
                      ethicalSubstitueDone == undefined ||
                      ethicalSubstitueDone == 0)
                  ) {
                    setNotValidEthical(1);
                    Utils.showAlert(
                      2,
                      "Please select Ethical Substitution options"
                    );
                    setNotValid(1);
                    return;
                  }

                  let isrequiredCondition = subBenefitRequiredCondition(
                    patientInsurance,
                    cardType,
                    subBenefits,
                    subbenefit_name
                  );

                  if (isrequiredCondition) {
                    Utils.showAlert(2, isrequiredCondition);
                    setNotValid(1);
                    return;
                  }
                  console.log("benefitBalance", benefitBalance);
                  if (benefitBalance == 0 || benefitBalance < 0) {
                    Utils.showAlert(2, "Insufficient balance");
                    setNotValid(1);
                    return;
                  }

                  let dataToSend = {
                    pool_number: benefit_pool_number,
                    claim_type: claim_type,
                    benefit_name: benefit_name,
                    subbenefit_name: subbenefit_name,
                    benefit_id: benefit_id,
                    sub_benefit_id: sub_benefit_id,
                    benefitBalance: benefit_used_Balance,
                    sub_benefitBalance: benefitBalance,
                    auth_type_selected: authTypeSelected,
                    ...(checked && { beneficiary_id: entityId }),
                    claim_date: claimDate,
                    dob: patientDoB,
                    employee_number: employeeNumber,
                    entity_id: checked ? finalEntityId : entityId,
                    form_step: "1",
                    full_phone_number: `${patientCountryCode}${ifphoneTenDigitThenTrim(
                      phoneNumber
                    )}`,
                    gender: gender,
                    insurance_company_id: patientInsurance,
                    is_search_by_smartcard:
                      insuranceCardType === "1" ? "1" : "0",
                    os_type: "3",
                    insuranceCardType: insuranceCardType,
                    patient_name: patientName,
                    phone_id: localStorage.getItem("phoneId"),
                    // user_id: userId!==null?userId:userPrincipalId,
                    user_id: userId,
                    insurance_company_member_card: card4,
                    insurance_company_selfie: card3,
                    photo_id_number_backend: card2,
                    photo_id_number_frontend: card1,
                    ...((insuranceCardType !== "1" ||
                      insuranceCardType !== "2") && {
                      benefit_details: benefits?.[0]?.serviceId,
                    }),
                    ...(insuranceCardType !== "1" && {
                      subbenefit_name: subbenefit_name,
                    }),
                    ...(getBenefitName() && { benefit_name: benefit_name }),
                  };
                  if (
                    claimId !== null &&
                    claimId !== undefined &&
                    claimId !== ""
                  ) {
                    dispatch({
                      type: Utils.ActionName.GET_PATIENT_DATA,
                      payload: {
                        activeStep: 1,
                      },
                    });
                  } else {
                    dispatch(onSubmitFirstStep(dataToSend, setSubmitting));
                  }
                }}
              >
                {({ isSubmitting, setFieldValue, errors, touched, values }) => (
                  <Form>
                    <Box>
                      <Grid container spacing={2} className="mb-20">
                        <Grid item xs={12} sm={4} md={12}>
                          {patientBenefitSubBenefitList()}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} className="mb-20">
                        <Grid item xs={12} sm={6} md={3} >
                          <Typography>Phone No.</Typography>
                          <TextField
                          style={{backgroundColor: (ethicalSubsVerify &&
                            ethicalSubsVerify != undefined) ||
                          age < 15 ||
                          claimId !== "" ||
                          (patientInsuranceMember !== undefined &&
                            patientInsuranceMember !== null &&
                            patientInsuranceMember.length > 0 &&
                            patientInsuranceMember[0].phone !== null &&
                            patientInsuranceMember[0].phone !== "" &&
                            patientInsuranceMember[0].phone !==
                              undefined) ||
                          checked
                            ? "rgb(0 0 0 / 10%)":""}}
                            type="tel"
                            className="w-100"
                            name="phoneNumber"
                            size="small"
                            placeholder={"Phone Number"}
                            disabled={
                              (ethicalSubsVerify &&
                                ethicalSubsVerify != undefined) ||
                              age < 15 ||
                              claimId !== "" ||
                              (patientInsuranceMember !== undefined &&
                                patientInsuranceMember !== null &&
                                patientInsuranceMember.length > 0 &&
                                patientInsuranceMember[0].phone !== null &&
                                patientInsuranceMember[0].phone !== "" &&
                                patientInsuranceMember[0].phone !==
                                  undefined) ||
                              checked
                                ? true
                                : false
                            }
                            value={phoneNumber}
                            inputProps={{
                              maxLength: 10,
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  style={{
                                    background: "rgba(0, 0, 0, .1) !important"
                                  }}
                                  position="start"
                                >
                                  +{patientCountryCode}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        {patientFamilyMemberData !== null &&
                        patientFamilyMemberData !== undefined &&
                        patientFamilyMemberData?.length > 0 &&
                        patientInsuranceMember !== undefined &&
                        patientInsuranceMember !== null &&
                        patientInsuranceMember.length > 0 &&
                        (patientInsuranceMember[0].phone === null ||
                          patientInsuranceMember[0].phone === "" ||
                          patientInsuranceMember[0].phone === undefined) ? (
                          <>
                            {insurance_member_dependents !== null &&
                            insurance_member_dependents !== undefined &&
                            insurance_member_dependents.length > 0 ? (
                              <Grid item xs={12} sm={6} md={3}>
                                {insurance_member_dependents !== undefined &&
                                insurance_member_dependents !== null &&
                                insurance_member_dependents.length > 0 ? (
                                  <Typography>
                                    {"Family Member Phone Number"}
                                  </Typography>
                                ) : !isPrincipal ? (
                                  <Typography>
                                    {"Principal Member Phone Number"}
                                  </Typography>
                                ) : (
                                  <div style={{ display: "none" }}></div>
                                )}
                                <Select
                                  name="familyMemberPhoneNumber"
                                  // label="Family Member phone number"
                                  size="small"
                                  value={userPrincipalId}
                                  options={
                                    insurance_member_dependents !== null &&
                                    insurance_member_dependents !== undefined &&
                                    insurance_member_dependents.length > 0 &&
                                    insurance_member_dependents.map(
                                      (option: any, index: any) => (
                                        <MenuItem
                                          key={index}
                                          value={option.user_id}
                                          // onClick={(event: any) =>
                                          //   handleMenuItem(event,option.login_phone)
                                          // }
                                        >
                                          <div className={classes.marginFlag}>
                                            {`${"+"}${option.login_phone} (${
                                              option.beneficiary_first_name
                                            } ${" "} ${
                                              option.beneficiary_last_name
                                            })`}
                                          </div>
                                        </MenuItem>
                                      )
                                    )
                                  }
                                />
                                &nbsp;&nbsp;
                                {patientFamilyMemberData !== null &&
                                patientFamilyMemberData !== undefined &&
                                patientFamilyMemberData?.length > 0 &&
                                patientInsuranceMember !== undefined &&
                                patientInsuranceMember !== null &&
                                patientInsuranceMember?.length > 0 &&
                                (patientInsuranceMember[0]?.phone === null ||
                                  patientInsuranceMember[0]?.phone === "" ||
                                  patientInsuranceMember[0]?.phone ===
                                    undefined) ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={checked}
                                        onChange={(e: any) =>
                                          handleChange(e, setFieldValue)
                                        }
                                      />
                                    }
                                    label={`${"Use "}${
                                      insurance_member_dependents !== null &&
                                      insurance_member_dependents !==
                                        undefined &&
                                      insurance_member_dependents.length > 0
                                        ? "Family Member"
                                        : "Principal Member"
                                    }${" phone no."}`}
                                  />
                                ) : (
                                  <div style={{ display: "none" }}></div>
                                )}
                              </Grid>
                            ) : (insurance_member_dependents === null ||
                                insurance_member_dependents === undefined) &&
                              !isPrincipal ? (
                              <Grid item xs={12} sm={6} md={3}>
                                {insurance_member_dependents !== undefined &&
                                insurance_member_dependents !== null &&
                                insurance_member_dependents.length > 0 ? (
                                  <Typography>
                                    {"Family Member Phone Number"}
                                  </Typography>
                                ) : !isPrincipal ? (
                                  <Typography>
                                    {"Principal Member Phone Number"}
                                  </Typography>
                                ) : (
                                  <div style={{ display: "none" }}></div>
                                )}
                                <TextField
                                  type="tel"
                                  className="w-100"
                                  name="principalPhoneNumber"
                                  size="small"
                                  placeholder={"Principal Member Phone Number"}
                                  value={principalPhoneNumber}
                                  inputProps={{
                                    maxLength: 10,
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment
                                        position="start"
                                        disablePointerEvents={true}
                                      >
                                        +{patientCountryCode}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                &nbsp;&nbsp;
                                {patientFamilyMemberData !== null &&
                                patientFamilyMemberData !== undefined &&
                                patientFamilyMemberData?.length > 0 &&
                                patientInsuranceMember !== undefined &&
                                patientInsuranceMember !== null &&
                                patientInsuranceMember?.length > 0 &&
                                (patientInsuranceMember[0]?.phone === null ||
                                  patientInsuranceMember[0]?.phone === "" ||
                                  patientInsuranceMember[0]?.phone ===
                                    undefined) ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={checked}
                                        onChange={(e: any) =>
                                          handleChange(e, setFieldValue)
                                        }
                                      />
                                    }
                                    label={`${"Use "}${
                                      insurance_member_dependents !== null &&
                                      insurance_member_dependents !==
                                        undefined &&
                                      insurance_member_dependents.length > 0
                                        ? "Family Member"
                                        : "Principal Member"
                                    }${" phone no."}`}
                                  />
                                ) : (
                                  <div style={{ display: "none" }}></div>
                                )}
                              </Grid>
                            ) : (
                              <div style={{ display: "none" }}></div>
                            )}
                          </>
                        ) : (
                          <div style={{ display: "none" }}></div>
                        )}

                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>Patient Name</Typography>
                          <TextField
                            name="PatientName"
                            // label="Patient Name"
                            disabled={true}
                            size="small"
                            value={patientName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>Gender</Typography>
                          <TextField
                            type="text"
                            // className="w-100"
                            name="gender"
                            size="small"
                            placeholder={"gender"}
                            disabled={true}
                            value={gender}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} className="mb-20">
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>Patient DOB</Typography>
                          <DateTimePicker
                            name="patientDob"
                            // label="Patient DOB"
                            disabled={true}
                            size="small"
                            value={patientDoB}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>Claim Date</Typography>
                          <DateTimePicker
                            name="claimDate"
                            size="small"
                            value={claimDate}
                            disabled={true}
                          />
                        </Grid>
                        {ethicalSubstitueDone != 1 && (
                          <Grid>
                            <div
                              style={{
                                paddingTop: "25px",
                                paddingLeft: "25px",
                                fontWeight: "bold",
                              }}
                            >
                              <span
                                style={{
                                  animation: notValidEthical
                                    ? "glowing 2500ms infinite"
                                    : "",
                                  color: notValidEthical ? "white" : "",
                                  padding: notValidEthical ? "5px" : "",
                                  borderRadius: "25px",
                                }}
                              >
                                Please ask Patient if they accept Ethical
                                Substitution ?
                              </span>{" "}
                              <br />
                              <FormControlLabel
                                sx={{
                                  mt: 1,
                                }}
                                control={
                                  <Checkbox
                                    disabled={ethicalSubsVerify}
                                    name="checked"
                                    checked={
                                      ethicalSubstitue == true ? true : false
                                    }
                                    value={1}
                                    onChange={() => handleChangeEthical(true)}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                sx={{
                                  mt: 1,
                                }}
                                control={
                                  <Checkbox
                                    disabled={ethicalSubsVerify}
                                    name="checked"
                                    checked={
                                      ethicalSubstitue == 0 ? true : false
                                    }
                                    value={0}
                                    onChange={() => handleChangeEthical(false)}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                                label="No"
                              />
                            </div>
                          </Grid>
                        )}
                      </Grid>

                      {patientFamilyMemberData !== undefined &&
                      patientFamilyMemberData?.length > 0 &&
                      !checked ? (
                        <div>
                          <Typography
                            variant="h6"
                            className="semi-bold mb-20 center"
                            sx={{
                              borderTop: "1px solid #b8b6b6",
                              paddingTop: "20px",
                            }}
                          >
                            Member Information
                          </Typography>
                          <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid item xs={12} sm={6} md={3}>
                              <div className={classes.circularProgressDiv}>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                >
                                  Member's ID Front Photo
                                </Typography>
                                {card1 !== "" && card1 !== undefined && (
                                  <Box className={classes.imgCover}>
                                    <Avatar
                                      variant="rounded"
                                      className={classes.avatarDoc}
                                      alt="Remy Sharp"
                                      src={API_URL + card1}
                                      onClick={() => {
                                        setPreviewImgUrl(API_URL + card1);
                                        previewImgOpen();
                                      }}
                                    />
                                    {card1Added ? (
                                      <img
                                        alt="Remy Sharp"
                                        className={classes.closeIcon}
                                        src={LocalImages.CloseIcon}
                                        title="Remove photo"
                                        onClick={() =>
                                          deleteProfilePhoto(card1, "card1")
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                )}
                                {(card1 === "" || card1 === undefined) && (
                                  <Box>
                                    <InputFile
                                      name="card1"
                                      handleFiles={(files: any) => {
                                        addProfilePicture(files, "card1");
                                      }}
                                    />
                                    {/* <Box sx={{ mt: 0.5 }}>
                                      <Button
                                        onClick={() => {
                                          dispatch(
                                            uploadImageFromApp({
                                              image_type: "photo_id_number_frontend",
                                              claim_id: 1,
                                            })
                                          );
                                        }}
                                        size="small"
                                        variant="outlined"
                                        sx={{ mx: (theme) => theme.spacing(2) }}
                                      >
                                        Upload image from App
                                      </Button>
                                    </Box> */}
                                  </Box>
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <div className={classes.circularProgressDiv}>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                >
                                  Member's ID Back Photo
                                </Typography>
                                {card2 !== "" && card2 !== undefined && (
                                  <Box className={classes.imgCover}>
                                    <Avatar
                                      variant="rounded"
                                      className={classes.avatarDoc}
                                      alt="Remy Sharp"
                                      src={API_URL + card2}
                                      onClick={() => {
                                        setPreviewImgUrl(API_URL + card2);
                                        previewImgOpen();
                                      }}
                                    />
                                    {card2Added ? (
                                      <img
                                        alt="Remy Sharp"
                                        className={classes.closeIcon}
                                        src={LocalImages.CloseIcon}
                                        title="Remove photo"
                                        onClick={() =>
                                          deleteProfilePhoto(card2, "card2")
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                )}
                                {(card2 === "" || card2 === undefined) && (
                                  <Box>
                                    <InputFile
                                      name="card2"
                                      handleFiles={(files: any) => {
                                        addProfilePicture(files, "card2");
                                      }}
                                    />
                                    {/* <Box sx={{ mt: 0.5 }}>
                                      <Button
                                        onClick={() => {
                                          dispatch(
                                            uploadImageFromApp({
                                              image_type: "photo_id_number_backend",
                                              claim_id: 1,
                                            })
                                          );
                                        }}
                                        size="small"
                                        variant="outlined"
                                        sx={{ mx: (theme) => theme.spacing(2) }}
                                      >
                                        Upload image from App
                                      </Button>
                                    </Box> */}
                                  </Box>
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <div className={classes.circularProgressDiv}>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                >
                                  Selfie
                                </Typography>
                                {card3 !== "" && card3 !== undefined && (
                                  <Box className={classes.imgCover}>
                                    <Avatar
                                      variant="rounded"
                                      className={classes.avatarDoc}
                                      alt="Remy Sharp"
                                      src={API_URL + card3}
                                      onClick={() => {
                                        setPreviewImgUrl(API_URL + card3);
                                        previewImgOpen();
                                      }}
                                    />
                                    {card3Added ? (
                                      <img
                                        alt="Remy Sharp"
                                        className={classes.closeIcon}
                                        src={LocalImages.CloseIcon}
                                        title="Remove photo"
                                        onClick={() =>
                                          deleteProfilePhoto(card3, "card3")
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                )}
                                {(card3 === "" || card3 === undefined) && (
                                  <Box>
                                    <InputFile
                                      name="card3"
                                      handleFiles={(files: any) => {
                                        addProfilePicture(files, "card3");
                                      }}
                                    />
                                    {/* <Box sx={{ mt: 0.5 }}>
                                      <Button
                                        onClick={() => {
                                          dispatch(
                                            uploadImageFromApp({
                                              image_type: "insurance_company_selfie",
                                              claim_id: 1,
                                            })
                                          );
                                        }}
                                        size="small"
                                        variant="outlined"
                                        sx={{ mx: (theme) => theme.spacing(2) }}
                                      >
                                        Upload image from App
                                      </Button>
                                    </Box> */}
                                  </Box>
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <div className={classes.circularProgressDiv}>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                >
                                  Membership Card Photo
                                </Typography>
                                {card4 !== "" &&
                                  card4 !== undefined &&
                                  card4 !== null && (
                                    <Box className={classes.imgCover}>
                                      <Avatar
                                        variant="rounded"
                                        className={classes.avatarDoc}
                                        alt="Remy Sharp"
                                        src={API_URL + card4}
                                        onClick={() => {
                                          setPreviewImgUrl(API_URL + card4);
                                          previewImgOpen();
                                        }}
                                      />
                                      {card4Added ? (
                                        <img
                                          alt="Remy Sharp"
                                          className={classes.closeIcon}
                                          src={LocalImages.CloseIcon}
                                          title="Remove photo"
                                          onClick={() =>
                                            deleteProfilePhoto(card4, "card4")
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  )}
                                {(card4 === "" || card4 === undefined) && (
                                  <Box>
                                    <InputFile
                                      name="card4"
                                      handleFiles={(files: any) => {
                                        addProfilePicture(files, "card4");
                                      }}
                                    />
                                    {/* <Box sx={{ mt: 0.5 }}>
                                      <Button
                                        onClick={() => {
                                          if (syncForApp) {
                                            dispatch(syncFromAppImage(userId, entityId, patientInsurance));
                                          } else {
                                            dispatch(
                                              uploadImageFromApp({
                                                image_type: "insurance_company_member_card",
                                                claim_id: 1,
                                              })
                                            );
                                          }
                                        }}
                                        size="small"
                                        variant="outlined"
                                        sx={{ mx: (theme) => theme.spacing(2) }}
                                      >
                                        {syncForApp ? "Is Updated?" : "Upload image from App"}
                                      </Button>
                                    </Box> */}
                                  </Box>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="mb-20">
                        <div className="justify-start mt-20">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="termsConditionChecked"
                                checked={termsConditionChecked}
                                onChange={(e: any) =>
                                  handleTerm(e, setFieldValue)
                                }
                              />
                            }
                            label={""}
                          />
                          <Typography>
                            I here by confirm that the information provided
                            above is correct and true to the best of my
                            knowledge.
                          </Typography>
                        </div>
                        {`${
                          errors["termsConditionChecked"] &&
                          touched["termsConditionChecked"]
                        }` ? (
                          <ErrorMessage
                            name={"termsConditionChecked"}
                            component="div"
                            className={classes.errorMessage}
                          />
                        ) : null}
                      </div>

                      {policyStatus === 1 && fundedBy == "" ? (
                        <div className="justify-end">
                          <CustomButton
                            isSubmitting={isNotValid ? "" : isSubmitting}
                            className="capitalize color-white"
                            sx={{ width: "auto" }}
                          >
                            Proceed
                          </CustomButton>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* {policyStatus === 1 && (
                        authTypeSelected === "0" || (authTypeSelected === "1" && patientInsuranceMember[0]?.face_id) 
                        ? <div className="justify-end">
                            <CustomButton
                              isSubmitting={isSubmitting}
                              className="capitalize color-white"
                              sx={{ width: "auto" }}
                            >
                              Proceed
                            </CustomButton>
                          </div>
                        : ""
                      )} */}
                    </Box>
                  </Form>
                )}
              </Formik>
            )}
          </div>
          {patientFamilyMemberData !== undefined &&
            patientFamilyMemberData?.length > 0 && (
              <BasicModal patientFamilyMemberData={benefits} />
            )}
          {showInfoPopup && (
            <CustomModal
              // title={"Info"}
              content={
                <Typography variant="body1" sx={{ fontSize: 19 }}>
                  This patient does not have face biometric data registered in
                  our database. Kindly proceed to register this patient before
                  providing your services.
                  <br />
                  <br />
                  <b>
                    Once the Face Biometric is linked to the patient card, it
                    cannot be changed. Please ask the patient if the card
                    belongs to them.
                  </b>
                  <br />
                  <br />
                  <b style={{ color: "red" }}>
                    It is fraudulent to link the Face Biometric of a person to
                    whom that card does not belong.
                  </b>
                </Typography>
              }
              open={showInfoPopup}
              handleButtonClick={() => {
                setShowInfoPopup(false);
                navigate("/register-face-biometric", {
                  state: { fromClaim: true },
                });
              }}
            />
          )}
        </div>
        <PreviewImg
          open={openPreview}
          handleClose={handleClosePreview}
          image={previewImgUrl}
        />
        <LocationPopupModal
          open={ProviderDetails.geoTagErrorPopup}
          content={ProviderDetails}
          handleClose={geoTagErrorPopupManage}
        />
      </ThemeProvider>
    </>
  );
}

export default PatientProfile;
