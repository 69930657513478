import React, { useEffect } from "react";

import { createStyles, makeStyles, } from "@mui/styles";
import {
  Typography,
 
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {getTermsConditions} from "./action"
import { ReducersModal } from "../../modal";
const styles = makeStyles((theme: any) =>
  createStyles({
    mainWrapper: {
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      // justifyContent: "center",
      alignItems: "center",
      marginTop: "50px",
      textAlign: "center",
      padding: "1rem",
    },
    // innerWrapper: {
    //   paddingTop: "40px",
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "center",
    //   // justifyContent: "center",
    // },
    titleHeading: {
      fontSize: "40px",

      // top: "40%",
      // [theme.breakpoints.down("xs")]: {
      //   fontSize: "16px",
      // },
    },
    subTitleHeading: {
      fontSize: "24px",
      // [theme.breakpoints.down("xs")]: {
      //   fontSize: "9px",
      // },
    },
    descriptionHeading: {
      fontSize: "16px",
      textAlign: "center",
      // padding: "20px",

      // [theme.breakpoints.down("md")]: {
      //   fontSize: "14px",
      // },
    },
  })
);

function TermsAndCondition() {
  const classes = styles();
  const dispatch=useDispatch();
  const { data } = useSelector(
    (state: ReducersModal) => state.TermsReducer
  );

  useEffect(() => {
    dispatch(getTermsConditions());
  
  }, [dispatch]);
  function createMarkup() {
    //@ts-ignore
    return {__html: data.data};
  }

  return (
    <div className={classes.mainWrapper}>
   
    <Typography className={classes.titleHeading}>{"Terms And Conditions"}</Typography>
    {/* <Typography className={classes.subTitleHeading}>
      {data.subTitle}
    </Typography> */}

    <Typography
      className={classes.descriptionHeading}
      dangerouslySetInnerHTML={createMarkup()}
    />

  </div>
   
  );
}

export default TermsAndCondition