import Utils from "../../utils";
import {
  AddmidicineFormikPrescription,
  CreateClaimPrescription,
  CreateMedicineDetailsListPrescription,
  DoctorCreateClaimSecondSetp,
  DoctorPrescriptionsModal,
} from "../../modal";

export const HospitalPrescriptionsReducer = (
  state: DoctorPrescriptionsModal = new DoctorPrescriptionsModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.HOSPITAL_PRESCRIPTIONS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const createClaimPrescriptionReducer = (
  state: CreateClaimPrescription = new CreateClaimPrescription(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.CREATE_CLAIM_PRESCRIPTION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const createMedicineDetailsListPrescriptionReducer = (
  state: CreateMedicineDetailsListPrescription = new CreateMedicineDetailsListPrescription(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.CREATE_MEDICINE_DETAILS_LIST_PRESCRIPTION:
      const updatedValue = state?.medicineList.filter(
        (item) => item.unqiueId !== action.payload.unqiueId
      );
      // console.log("reducer", updatedValue, action.payload, state);
      return {
        ...state,
        medicineList: [...updatedValue, action.payload],
      };
    case Utils.ActionName.DELETE_MEDICINE_DETAILS_LIST_PRESCRIPTION:
      return {
        medicineList: action.payload,
      };
    case Utils.ActionName.CLEAR_MEDICINE_DETAILS_LIST_PRESCRIPTION:
      return {
        medicineList: [],
      };
    default:
      return state;
  }
};

export const addmidicineFormikPrescriptionReducer = (
  state: AddmidicineFormikPrescription = new AddmidicineFormikPrescription(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.ADD_MEDICINE_FORMIK_PRESCRIPTION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const createDoctorClaimSecondStepReducer = (
  state: DoctorCreateClaimSecondSetp = new DoctorCreateClaimSecondSetp(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.CREATE_CLAIM_SECOND_SETP_DOCTOR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
