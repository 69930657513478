import Utils from "../../utils";
import { DashBoardModal } from "../../modal";

export const DashBoardReducer = (
  state: DashBoardModal = new DashBoardModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GET_DASHBOARD:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
