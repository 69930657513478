import moment from "moment";
class LoadingModal {
  isLoading: boolean = false;
}

class signInModal {
  phoneNumber: any =
    localStorage.getItem("phone") === null ? "" : localStorage.getItem("phone");
  countryCode: any =
    localStorage.getItem("countryCode") === null
      ? "254"
      : localStorage.getItem("countryCode");
  enableResend: boolean = true;
  starts: any = 60;
  signUp: boolean = false;
  countryCodeLength: any = 9;
  userName: string = "";
  userStatus: string = "";
}
class otpModal {
  otp: string = "";
}

// Theme Modal
class ThemeModal {
  selectTheme: any =
    localStorage.getItem("selectTheme") === null
      ? "light"
      : localStorage.getItem("selectTheme");
}

class LogoutModal {
  openConfirmModal: boolean = false;
}
class SetPasswordModal {
  token: string = "";
  newPassword: string = "";
  confirmPassword: string = "";
  recaptcha: string = "";
}

class CountryModal {
  countryData: any = [
    // {
    //   id: "21",
    //   name: "Belarus",
    //   country_code: "by",
    //   status: "1",
    //   phone_country_code: "375",
    //   digits_phone_number: "9",
    //   show_insurance_company: "1",
    //   default_currency: "Belarusian Ruble",
    //   currency_symbol: "BYN",
    //   flag: "/images/flags/32x32/by.png",
    //   list_of_insurance_company: [
    //     {
    //       id: "2",
    //       name: "APA Insurance",
    //       country_id: "21",
    //       country_name: "Belarus",
    //     },
    //   ],
    // },
    {
      id: "116",
      name: "Kenya",
      country_code: "ke",
      status: "1",
      phone_country_code: "254",
      digits_phone_number: "9",
      show_insurance_company: "1",
      default_currency: "Kenyan Shilling",
      currency_symbol: "KES",
      flag: "/images/flags/32x32/ke.png",
      list_of_insurance_company: [
        {
          id: "9",
          name: "Jubilee Insurance",
          country_id: "116",
          country_name: "Kenya",
        },
        {
          id: "13",
          name: "Liaison Insurance Company",
          country_id: "116",
          country_name: "Kenya",
        },
        {
          id: "10",
          name: "Madison Insurance",
          country_id: "116",
          country_name: "Kenya",
        },
        {
          id: "11",
          name: "Test Insurance",
          country_id: "116",
          country_name: "Kenya",
        },
        {
          id: "12",
          name: "UAP Insurance",
          country_id: "116",
          country_name: "Kenya",
        },
      ],
    },
    // {
    //   id: "236",
    //   name: "United States",
    //   country_code: "us",
    //   status: "1",
    //   phone_country_code: "1",
    //   digits_phone_number: "10",
    //   show_insurance_company: "0",
    //   default_currency: "US Dollar",
    //   currency_symbol: "USD",
    //   flag: "/images/flags/32x32/us.png",
    // },
  ];
  currencyData: [] = [];
  limit: any = "400";
  page: any = "";
  search: any = "";
}

class PharmacyCityModal {
  data: any[] = [];
  active: number = 1;
  offset: number = 20;
  limit: number = 20;
}
class GetInsuranceCompanyModel {
  data: any[] = [];
  limit: any = "400";
  page: any = "";
  search: any = "";
  patientInsurance: any = "";
}

class GetManufacturerModel {
  data: any[] = [];
  limit: any = "400";
  page: any = "";
  search: any = "";
  orderManufacturer: any = "";
  manufacturerMedicineList: any = [];
}

class ManufacturerMedicineModel {
  data: any[] = [];
  limit: any = "10";
  totalRecords: any = 0;
  page: any = "1";
  offset: number = 0;
  search: any = "";
  orderManufacturer: any = "";
  manufacturerMedicineList: any = [];
}

class AddToCardModel {
  data: any[] = [];
  OrderCreated: any = "";
}

class GetPatientInsuranceCompanyModel {
  data: any[] = [];
  limit: any = "400";
  page: any = "";
  search: any = "";
  patientInsurance: any = "";
  claimSteps: any = [
    "Patient Information",
    "Transcribe Prescription",
    "Patient Verification",
    "Upload Prescription",
  ];
  activeStep: any = 0;
  // "create claim"
}

class InsurancePatientProfile {
  data: any[] = [];
  subBenefits: any = [];
  sub_benefit_id: any = 0;
  benefit_id: any = 0;
  patientBenefitList: any = [];
  userBenefit: any = "";
  subbenefit_name: any = "";
  benefit_name: any = "";
  benefit_pool_number: any = "";
  userSubBenefit: any = "";
  subBenefitBalance: any = "";
  benefitBalance: any = "";
  benefit_used_Balance: any = "";
  claimDate: any = moment(new Date()).format("yyyy-MM-DD");
  limit: any = "100";
  page: any = "";
  search: any = "";
  // patientInsurance:any="";
  authType: any = [];
  authTypeSelected: any = "";
  cardListArray: any = [];
  insuranceData: any = "";
  patientInsuranceMemberDataList: any = [];
  patientFamilyMemberData: any = [];
  insuranceCardType: any = "";
  patientInsuranceMember: any = [];
  entityId: any = "";
  finalEntityId: any = "";
  insurance_member_dependents: any = [];
  employeeName: any = "";
  gender: any = "";
  patientName: any = "";
  patientDoB: any = "";
  phoneNumber: any = "";
  principalPhoneNumber: any = "";
  familyPhoneNumber: any = "";
  showSearchMemberNo: boolean = false;
  familyMemberPhoneNumber: any = "";
  patientCountryCode: any = "254";
  showMoreBenefits: boolean = false;
  showOtpVerification: any = 0;
  showAuthenticationType: boolean = false;
  checked: boolean = false;
  termsConditionChecked: boolean = false;
  insuranceMemberOptionSelect: boolean = false;
  card1: any = "";
  card2: any = "";
  card3: any = "";
  card4: any = "";
  card1Added: boolean = false;
  card2Added: boolean = false;
  card3Added: boolean = false;
  card4Added: boolean = false;
  userId: any = "";
  userPrincipalId: any = "";
  isPrincipal: any = "";
  age: any = "";
  employeeNumber: any = "";
  beneficiaryId: any = "";
  claimId: any = "";
  benefits: any = [];
  cardType: any = "";
  policyStatus: any = "";
  balanceClaim: any = "";
  syncForApp: boolean = false;
  ethicalSubstitue: any = "";
  ethicalSubstitueDone: any = "";
  ethicalSubsVerify: boolean = false;
  showEthicalVerification: boolean = false;
}
class CreateProfileDataModal {
  physical_address = "";
  admin_name = "";
  country_code = "";
  email = "";
  id_number = "";
  latitude = "";
  license_of_pharmacy = "";
  longitude = "";
  name_prefix = "";
  business_permit = "";
  pharmacy_name = "";
  phone_code = "";
  phone_number = "";
  pin_certificate = "";
  practice_certificate = "";
  user_role = 3;
}
class AddMedicinesModal {
  user_id = "";
  insurance_company_id = "";
  claim_date = "";
  doctor_id = "";
  hospital_id = "";
  patient_name = "";
  gender = "";
  dob = "";
  full_phone_number = "";
  os_type = "";
  phone_id = "";
  amount = "";
  drugs = [
    {
      doctor_comment: "",
      dosage: "",
      food_instruction: "",
      form: "",
      frequency: "",
      duration: "",
      drug_id: "",
      drug_name: "",
      type: "",
    },
  ];
  private_practice = "";
}

class SentClaimsModal {
  // body = '';
  data: any[] = [];
  limit = 10;
  offset: number = 0;
  count: number = 0;
  search = "";
  user_id = -1;
  doctor_id = -1;
  icd_10_code = "";
  status: any = "";
  insurance_company_id = "";
  claim_start_date: any = "";
  claim_end_date: any = "";
  hospital_name = "";
  page: any = 1;
}

class MedicineOrdersModal {
  // body = '';
  data: any[] = [];
  limit = 10;
  offset: number = 0;
  count: number = 0;
  search = "";
  manufacturer_id = "";
  status: any = "";
  order_start_date: any = "";
  order_end_date: any = "";

  page: any = 1;
}

class InsurancePaymentModal {
  // body = '';
  data: any[] = [];
  limit = 10;
  offset: number = 0;
  count: number = 0;
  search = "";
  user_id = -1;
  doctor_id = -1;
  icd_10_code = "";
  status: any = "";
  insurance_company_id = "";
  claim_start_date: any = "";
  claim_end_date: any = "";
  hospital_name = "";
  page: any = 1;
  isClaimCheck: Array<[]> = [];
  option: number = 1;
  totalAmount: any = 0;
  inurancePayReviewList: any = [];
  branch_pharmacy_id: any = "";
}

class DoctorPrescriptionsModal {
  // body = '';
  data: any[] = [];
  limit = 10;
  offset: number = 0;
  count: number = 0;
  search = "";
  user_id = -1;
  doctor_id = -1;
  icd_10_code = "";
  status: any = "";
  insurance_company_id = "";
  claim_start_date: any = "";
  claim_end_date: any = "";
  hospital_name = "";
  showVerification: boolean = false;
  phoneNumber: any = "";
  userId: any = "";
  orderId: any = "";
  draftedClaimId: any = "";
  page: any = 1;
  userName: any = "";
}

class DrugFormularyModal {
  // body = '';
  data1: any[] = [];
  limit = 10;
  offset: number = 0;
  count: number = 0;
  search = "";
  user_id = -1;
  doctor_id = -1;
  icd_10_code = "";
  status: any = "";
  insurance_company_id = "";
  claim_start_date: any = "";
  claim_end_date: any = "";
  hospital_name = "";
  showVerification: boolean = false;
  page: any = 1;
}

class TermsModal {
  // body = '';
  data: any[] = [];
}

class TemplateClaimsModal {
  // body = '';
  data: any[] = [];
  limit = 10;
  offset: number = 0;
  count: number = 0;
  search = "";
  user_id = -1;
  doctor_id = -1;
  icd_10_code = "";
  status: any = "";
  insurance_company_id = "";
  claim_start_date: any = "";
  claim_end_date: any = "";
  hospital_name = "";
  statuss = "";
  page: any = 1;
}

class MyClaimSearchMedicineDoctorHospitalModal {
  data = "";
  search_type: any = "";
  offset: number = 0;
  limit: number = 20;
  code: any = "cere";
  insurance_company_id: any = 9;
}

class MedicineDetailsModal {
  api = "";
  id = "";
  name = "";
  price = "";
  quantity_measuring = "";
  quantity_per_bottle = "";
  is_reviewed = 0;
  formulation = 0;
}
class CreateMedicineDetailsList {
  medicineList: any[] = [];
}

class AddmidicineFormitModal {
  medicineName = {};
  formulation = "";
  dosage = "";
  frequency = "";
  duration = "";
  foodInstractions = "";
  supplyfrequency = "";
  repetitionCycle = "";
  totalQuantity = "";
  pricePerUnit = "";
  totalPrice = "";
  unqiueId = "";
  quntityMeasuring = "";
  quantityPerBottle = "";
}

class CreateClaimSecondSetp {
  drafted_claim_id = "";
  drugs_new_prices = [];
  form_step = "";
  is_price_updated = "";
  phone_code = "";
  phone_number = "";
  type_id = "";
}

class MyProfilePersonalInfoModal {
  id = "";
  avatar = "";
  email = "";
  phone_number = "";
  phone_code = "";
  pharmacy_name = "";
  city_name = "";
  physical_address = "";
  license_of_pharmacy = "";
  pin_certificate = "";
  business_permit = "";
  practice_certificate = "";
  admin_name = "";
  branch_url = "";
  pharmacy_branches: any = [];
  pharmacy_devices: any = [];
}

class EditMyProfilePersonalInfoModal {
  data: any = "";
  // physical_address = "";
  // admin_name = "";
  // id_number = "";
  // avatar = "";
  // country_Code = "";
  // email = "";
  // latitude = -4.052549;
  // license_of_pharmacy = "";
  // longitude = 39.661152;
  // name_prefix= "";
  // business_permit = "";
  // pharmacy_name = "";
  // phone_code = 375;
  // phone_number = 293080890;
  // pin_certificate = "";
  // practice_certificate = "";
  // user_role = 3;
}

class ClaimDetails {
  id = "";
  user_avatar = "";
  user_name = "";
  user_gender = "";
  user_date_birth = "";
  user_phone = "";
  user_id = "";
  insurance_name = "";
  insurance_avatar = "";
  doctor_name = "";
  doctor_phone = "";
  status = "";
  entity_id = "";
  beneficiary: any = "";
  hospital_name = "";
  eTimsFile= "";
  documents: {
    claim: any[];
    prescription: any[];
  } = {
    claim: [],
    prescription: [],
  };
  doctor_id = "";
  hospital_id = "";
  phone_list = "";
  private_practice = false;
  icd10_code = "";
  icd10_code_description = "";
  icd10_list: any[] = [];
  order_data: any = "";
  form_step: number = 1;
  type_id: number = 0;
  insurance_company_id: any = "";
  insurance_company_name: any = "";
  user_claim_id: any = "";
  auth_type_selected = "";
  doctor_phone_code: any = "";
  user_phone_number: any = "";
  user_phone_code: any = "";
  claimBenefitsDetails: any = "";
  global_medicine_search: any = "";
}

class PaymentDetails {
  mpesa: any = null;
  insurance_payment_option_required: number = 0;
  insurance_payment_option: number = 0;
  isApiLoadingOnAddPayment: boolean = false;
  isGetPMApiLoading: boolean = false;
  isUpdatePaymentOptionApiLoading: any;
}

class SentClaimDetails {
  id = "";
  user_avatar = "";
  user_name = "";
  user_gender = "";
  user_date_birth = "";
  user_phone = "";
  insurance_name = "";
  insurance_avatar = "";
  doctor_name = "";
  doctor_phone = "";
  status = "";
  entity_id = "";
  beneficiary: any = "";
  hospital_name = "";
  documents: {
    claim: any[];
    prescription: any[];
  } = {
    claim: [],
    prescription: [],
  };
  phone_list = "";
  medicine_information: any[] = [];
  total_amount_full = "";
  icd10_list: any[] = [];
  benefit_name: any = "";
  sub_benefit_name: any = "";
  order_id: any = "";
  show_complete_button: any = "";
  eTimsFile: any = "";
}

class MedicineOrderDetailsModal {
  medicine_information: any[] = [];
  total_amount_full = "";
}

class TabModal {
  value = "1";
}

class DashBoardModal {
  data: any = "";
}

class WorkTimeModal {
  data: any = "";
}
class CreateClaimPrescription {
  data: any[] = [];
  limit: any = "400";
  page: any = "";
  search: any = "";
  patientInsurance: any = "";
  claimSteps: any[] = [
    "Patient Information",
    "Transcribe Prescription",
    "Confirm",
  ];
  activeStep: number = 1;
}

class CreateMedicineDetailsListPrescription {
  medicineList: any[] = [];
}

class AddmidicineFormikPrescription {
  medicineName = {};
  formulation = "";
  dosage = "";
  frequency = "";
  duration = "";
  foodInstractions = "";
  supplyfrequency = "";
  repetitionCycle = "";
  totalQuantity = "";
  pricePerUnit = "";
  totalPrice = "";
  unqiueId = "";
}

class DoctorCreateClaimSecondSetp {
  drafted_claim_id = "";
  drugs_new_prices = [];
  form_step = "";
  is_price_updated = "";
  phone_code = "";
  phone_number = "";
  type_id = "";
}

class GetSignupProfileDetails {
  id_number = "";
  pharmacy_name = "";
  name_prefix = "";
  admin_name = "";
  city_id = "";
  city_name = "";
  email = "";
  practice_certificate = "";
  practice_certificate_expiry = "";
  license_of_pharmacy = "";
  license_of_pharmacy_expiry = "";
  business_permit = "";
  business_permit_expiry = "";
  pin_certificate = "";
  cr_12 = "";
  tax_compliance = "";
  tax_compliance_expiry = "";
  physical_address = "";
  latitude = "";
  longitude = "";
  phone_number = "";
}

class CreateClaimStepTwoDoctorAndHospitalAndICDData {
  doctorDetail: any = {};
  hospitalDetail: any = {};
  finalDiagnosisDetail: any[] = [];
  onlyRead: boolean = false;
  whenMoveTo: boolean = false;
}

class WebsiteManagement {
  banner_data: any = {
    banner_bg_image: "",
    bg_color: "",
    description: "",
    heading: "",
    heading_color: "",
    heading_fz: "",
    sub_heading_color: "",
    sub_heading_fz: "",
  };
  logo = "";
}

class ProviderDetailsModal {
  data = {
    provider_lat: "",
    provider_lng: "",
    provider_radius_range: "",
    currentLat: "",
    currentLng: "",
    isLocationEnabled: true,
    locationAccuracy: "",
    geoTagErrorPopup: false,
    isProviderInRange: false,
    is_geoTag_skipped: false,
  };
}

class SubscriptionDetails {
  subscriptionDetails: any = [];
  planList: any = [];
  SubscriptionSetting: any = [];
}
class SubscriptionHistory {
  data: any[] = [];
  limit: any = 10;
  page: any = 1;
  search: any = "";
  count: any = 0;
  payment_status: any = "";
  subscription_status: any = "";
  from_date: any = "";
  to_date: any = "";
  type: any = 1;
  id: any = "";
  count_needed: any = 1;
}

class SubscriptionStatus {
  subscriptionDetails: any = [];
  subscriptionErrorMessage: any = "";
}

class ReducersModal {
  SubscriptionStatusReducer: SubscriptionStatus = new SubscriptionStatus();
  ProviderDetailsReducer: ProviderDetailsModal = new ProviderDetailsModal();
  otpReducer: otpModal = new otpModal();
  setPassReducer: SetPasswordModal = new SetPasswordModal();
  countryReducer: CountryModal = new CountryModal();
  globalLoaderReducer: LoadingModal = new LoadingModal();
  pharmacyCityReducer: PharmacyCityModal = new PharmacyCityModal();
  signInReducer: signInModal = new signInModal();
  createProfileData: CreateProfileDataModal = new CreateProfileDataModal();
  AddMedicinesReducer: AddMedicinesModal = new AddMedicinesModal();
  InsuranceCompanyReducer: GetInsuranceCompanyModel =
    new GetInsuranceCompanyModel();
  ManufacturerMedicineReducer: ManufacturerMedicineModel =
    new ManufacturerMedicineModel();
  ManufacturerReducer: GetManufacturerModel = new GetManufacturerModel();
  AddToCardReducer: AddToCardModel = new AddToCardModel();
  PatientInsuranceCompanyReducer: GetPatientInsuranceCompanyModel =
    new GetPatientInsuranceCompanyModel();
  SentClaimsReducer: SentClaimsModal = new SentClaimsModal();
  MedicineOrdersReducer: MedicineOrdersModal = new MedicineOrdersModal();
  TemplateClaimsReducer: TemplateClaimsModal = new TemplateClaimsModal();
  InsurancePatientProfileReducer: InsurancePatientProfile =
    new InsurancePatientProfile();
  medicineDetailsReducer: MedicineDetailsModal = new MedicineDetailsModal();
  createMedicineDetailsListReducer: CreateMedicineDetailsList =
    new CreateMedicineDetailsList();
  MyClaimSearchMedicineDoctorHospitalReducer: MyClaimSearchMedicineDoctorHospitalModal =
    new MyClaimSearchMedicineDoctorHospitalModal();
  addmidicineFormitModalReduce: AddmidicineFormitModal =
    new AddmidicineFormitModal();
  createClaimSecondStepReducer: CreateClaimSecondSetp =
    new CreateClaimSecondSetp();
  claimDetailReducer: ClaimDetails = new ClaimDetails();
  MyProfilePersonalInfoReducer: MyProfilePersonalInfoModal =
    new MyProfilePersonalInfoModal();
  EditMyProfilePersonalInfoReducer: EditMyProfilePersonalInfoModal =
    new EditMyProfilePersonalInfoModal();
  DashBoardReducer: DashBoardModal = new DashBoardModal();
  sentClaimReducer: SentClaimDetails = new SentClaimDetails();
  MedicineOrderDetailsReducer: MedicineOrderDetailsModal =
    new MedicineOrderDetailsModal();
  DrugFormularyReducer: DrugFormularyModal = new DrugFormularyModal();
  WorkTimeReducer: WorkTimeModal = new WorkTimeModal();
  PaymentDetailsReducer: PaymentDetails = new PaymentDetails();
  DoctorPrescriptionsReducer: DoctorPrescriptionsModal =
    new DoctorPrescriptionsModal();
  HospitalPrescriptionsReducer: DoctorPrescriptionsModal =
    new DoctorPrescriptionsModal();
  createClaimPrescriptionReducer: CreateClaimPrescription =
    new CreateClaimPrescription();
  createMedicineDetailsListPrescriptionReducer: CreateMedicineDetailsListPrescription =
    new CreateMedicineDetailsListPrescription();
  addmidicineFormikPrescriptionReducer: AddmidicineFormikPrescription =
    new AddmidicineFormikPrescription();
  createDoctorClaimSecondStepReducer: DoctorCreateClaimSecondSetp =
    new DoctorCreateClaimSecondSetp();
  getSignupCreateProfleDetailsReducer: GetSignupProfileDetails =
    new GetSignupProfileDetails();
  insurancePaymentReducer: InsurancePaymentModal = new InsurancePaymentModal();
  TabModelReducer: TabModal = new TabModal();
  TermsReducer: TermsModal = new TermsModal();
  createClaimStepTwoDoctorAndHospitalAndICDDataReducer: CreateClaimStepTwoDoctorAndHospitalAndICDData =
    new CreateClaimStepTwoDoctorAndHospitalAndICDData();
  websiteManagementReducer: WebsiteManagement = new WebsiteManagement();
  SubscriptionReducer: SubscriptionDetails = new SubscriptionDetails();
  SubscriptionHistoryReducer: SubscriptionHistory = new SubscriptionHistory();
}

export {
  SubscriptionStatus,
  SubscriptionHistory,
  SubscriptionDetails,
  otpModal,
  signInModal,
  LoadingModal,
  ReducersModal,
  // Exporting theme modal
  ThemeModal,
  LogoutModal,
  SetPasswordModal,
  CountryModal,
  PharmacyCityModal,
  CreateProfileDataModal,
  AddMedicinesModal,
  GetInsuranceCompanyModel,
  GetManufacturerModel,
  ManufacturerMedicineModel,
  AddToCardModel,
  SentClaimsModal,
  MedicineOrdersModal,
  InsurancePatientProfile,
  GetPatientInsuranceCompanyModel,
  MedicineDetailsModal,
  CreateMedicineDetailsList,
  MyClaimSearchMedicineDoctorHospitalModal,
  AddmidicineFormitModal,
  CreateClaimSecondSetp,
  ClaimDetails,
  TemplateClaimsModal,
  MyProfilePersonalInfoModal,
  EditMyProfilePersonalInfoModal,
  PaymentDetails,
  DashBoardModal,
  SentClaimDetails,
  MedicineOrderDetailsModal,
  DoctorPrescriptionsModal,
  DrugFormularyModal,
  WorkTimeModal,
  CreateClaimPrescription,
  CreateMedicineDetailsListPrescription,
  AddmidicineFormikPrescription,
  DoctorCreateClaimSecondSetp,
  GetSignupProfileDetails,
  TabModal,
  TermsModal,
  CreateClaimStepTwoDoctorAndHospitalAndICDData,
  InsurancePaymentModal,
  WebsiteManagement,
  ProviderDetailsModal,
};
