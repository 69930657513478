import Utils from "../../utils";
import Axios from "axios";
import CommonFunctions, { ifStringThenTrim } from "../../utils/commonFunctions";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";
import FormField from "../../schema/formField";
/**
 * action to get pharmacy city list
 */

// cancel token api call for search
let call: any;
const search_once = (config: any = {}) => {
  if (call) {
    call.cancel({
      statusCode: 100,
      message: "Only one request allowed at a time.",
    });
  }
  call = Axios.CancelToken.source();

  config.cancelToken = call.token;
  return Axios(config);
};

export const getPharmacyCity = (search: string) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }

    const { limit, active, offset } = getState().pharmacyCityReducer;
    let params = `?active=${active}&offset=${offset}&limit=${limit}${search.length > 0 ? `&search=${search}` : ""}`;

    search_once(CommonFunctions.axiosCancelTokenConfig(params, Utils.endPoints.city))
      .then((response: any) => {
        let { data } = response;

        dispatch({
          type: Utils.ActionName.PHARMACY_CITY,
          payload: {
            data: data.body,
          },
        });
      })
      .catch((error: any) => {
        let { data } = error;
        Utils.showAlert(2, data?.message);
      });

    // Utils.api.getApiCall(
    //   Utils.endPoints.city,
    //   params,
    //   (respData: any) => {

    //   },
    //   (error: any) => {
    //     let { data } = error;
    //     Utils.showAlert(2, data?.message);
    //   }
    // );
  };
};

export const createProfiles = (value: any, setActiveStep: any, resetForm: any) => {
  return (dispatch: Function, getState: Function) => {
    const {
      profilePic,
      pharmacyName,
      surname,
      pharmacyAdminName,
      searchPharmacyCity,
      emailId,
      practiceCertificate,
      practice_certificate_expiry,
      licensePharmacy,
      license_of_pharmacy_expiry,
      businessPermit,
      business_permit_expiry,
      pinCertificate,
      crDocument,
      taxCompliance,
      tax_compliance_expiry,
      physical_address,
      latitude,
      longitude,
      domain,
    } = value;
    const { phoneNumber, countryCode } = getState().signInReducer;
    const createProfileData = {
      practice_certificate_expiry,
      license_of_pharmacy_expiry,
      business_permit_expiry,
      tax_compliance_expiry,
      physical_address: ifStringThenTrim(physical_address),
      admin_name: ifStringThenTrim(pharmacyAdminName),
      country_code: ifStringThenTrim("ke"),
      email: ifStringThenTrim(emailId),
      id_number: ifStringThenTrim(profilePic),
      latitude: ifStringThenTrim(latitude),
      license_of_pharmacy: ifStringThenTrim(licensePharmacy),
      longitude: ifStringThenTrim(longitude),
      name_prefix: ifStringThenTrim(surname),
      business_permit: ifStringThenTrim(businessPermit),
      pharmacy_name: ifStringThenTrim(pharmacyName),
      phone_code: ifStringThenTrim(countryCode),
      phone_number: ifStringThenTrim(phoneNumber),
      pin_certificate: ifStringThenTrim(pinCertificate),
      practice_certificate: ifStringThenTrim(practiceCertificate),
      cr_12: ifStringThenTrim(crDocument),
      tax_compliance: ifStringThenTrim(taxCompliance),
      user_role: 3,
      avatar: ifStringThenTrim(profilePic),
      url: domain + process.env.REACT_APP_DOMAIN,
    };

    dispatch(globalLoaderTrue());
    const params = { ...createProfileData };
    Utils.constants.getAccessToken();
    Utils.api.patchApiCall(
      Utils.endPoints.login,
      params,
      (respData: any) => {
        const { status } = respData;
        if (status === Utils.constants.api_success_code.success) {
          // console.log("selectdata", respData);
          Utils.api.putApiCall(
            Utils.endPoints.city,
            { city_id: searchPharmacyCity },
            (res: any) => {
              // console.log(res, "set city");
              if (status === Utils.constants.api_success_code.success) {
                Utils.showAlert(1, "Success");
                dispatch(globalLoaderFalse());
                resetForm();
                dispatch({
                  type: Utils.ActionName.GET_SIGNUP_CREATE_PROFILE_DETAILS,
                  payload: { email: "", phone_number: "" },
                });
                setActiveStep((preStep: any) => {
                  const nextStep = preStep + 1;
                  localStorage.setItem("activeStep", nextStep);
                  localStorage.setItem("createProfileData", JSON.stringify(params));
                  return nextStep;
                });
              }
            },
            (error: any) => {
              let { data } = error;
              // console.log("selectdataerror", error);
              Utils.showAlert(2, data?.messages[0]);
              dispatch(globalLoaderFalse());
            }
          );
        }
      },
      (error: any) => {
        let { data } = error;
        // console.log("selectdataerror", error);
        Utils.showAlert(2, data?.messages[0]);
        dispatch(globalLoaderFalse());
      }
    );
  };
};

export const setWorkTime = (params: any, setActiveStep: any, handleOpen: any) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      Utils.endPoints.workingHours + "?from_signup=1",
      params,
      (respData: any) => {
        const { status } = respData;
        if (status === Utils.constants.api_success_code.success) {
          // console.log("setworktime", respData);
          dispatch(globalLoaderFalse());
          Utils.showAlert(1, respData.data?.messages?.[0]);
          Utils.constants.removeSession();
          handleOpen();
          // setActiveStep((preStep: any) => preStep + 1);
        }
      },
      (error: any) => {
        let { data } = error;
        // console.log("setworktimeerror", error);
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const selecteLocation = (value: any, setActiveStep: any) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    const { ...otherData } = getState().createProfileDataReducer;

    const params = { ...otherData, ...value };
    // console.log(params);
    Utils.constants.getAccessToken();
    Utils.api.patchApiCall(
      Utils.endPoints.login,
      params,
      (respData: any) => {
        const { status } = respData;
        if (status === Utils.constants.api_success_code.success) {
          // console.log("selectdata", respData);
          Utils.showAlert(1, "Success");
          dispatch(globalLoaderFalse());
          setActiveStep((preStep: any) => preStep + 1);
        }
      },
      (error: any) => {
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.message);
      }
    );
  };
};

export const getSignupCreateProfleDetails = () => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());

    const params = "";
    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      Utils.endPoints.user,
      params,
      (respData: any) => {
        // console.log(respData);
        const { status } = respData;
        if (status === Utils.constants.api_success_code.success) {
          dispatch({
            type: Utils.ActionName.GET_SIGNUP_CREATE_PROFILE_DETAILS,
            payload: { ...respData?.data },
          });
          dispatch(globalLoaderFalse());

          localStorage.setItem("roleId", respData?.data?.is_branch_pharmacy);
        }

        localStorage.setItem("frUniqueId", respData?.data?.fr_unique_id);
      },
      (error: any) => {
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.message);
      }
    );
  };
};

export const createProfileDataAction = (type: string, payload: any) => {
  return {
    type,
    payload,
  };
};
