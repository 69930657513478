import Utils from "../../utils";
import {
  DrugFormularyModal,
} from "../../modal";



export const DrugFormularyReducer = (
  state: DrugFormularyModal = new DrugFormularyModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.DRUG_FORMULARY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

