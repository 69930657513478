import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  FormControl,
  Button,
  DialogActions,
  MenuItem,
} from "@mui/material";
import { Formik, Form } from "formik";
import { makeStyles, createStyles } from "@mui/styles";
import { useDispatch, useSelector, batch } from "react-redux";
import Utils from "../../../utils";
// import Schema from "../../../schema";
import Textfield from "../../../components/formsUi/textFiled";
import SelectWrap from "../../../components/formsUi/select";
import FormField, {
  // drugRouteadmin,
  duration,
  food,
  formulation,
  frequency,
  repetitionCycleOptions,
  supplyfrequency,
} from "../../../schema/formField";
import MedicineTextField from "./medicineTextField";
import {
  calculationTotalFormulation,
  countTotalQuantity,
  uid,
} from "../../../utils/commonFunctions";
import { ReducersModal } from "../../../modal";

const useStyles = makeStyles(() =>
  createStyles({
    dialogContainer: {
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "7px",
      "&.MuiGrid-root": {
        marginTop: "none !important",
      },
    },
  })
);

function PopUp({ handleClose }: any) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [formulationState, setFormulationState] = useState("");
  const [dosageState, setDosageState] = useState("");
  const [frequencyState, setFrequencyState] = useState("");
  const [durationState, setDurationState] = useState("");
  const [foodInstractionsState, setFoodInstractionsState] = useState("");
  const [supplyfrequencyState, setSupplyfrequencyState] = useState("");
  const [repetitionCycleState, setRepetitionCycleState] = useState("");
  const [totalQuantityState, setTotalQuantityState] = useState("");
  const [calculatedFormulationState, setCalculatedFormulationState] =
    useState("");
  const { quantity_measuring, price } = useSelector(
    (state: ReducersModal) => state.medicineDetailsReducer
  );
  const initialValuesFormik = useSelector(
    (state: ReducersModal) => state.addmidicineFormitModalReduce
  );

  // console.log(
  //   "formulationState",
  //   formulationState,
  //   "dosage",
  //   dosageState,
  //   "frequencyState",
  //   frequencyState,
  //   "durationState",
  //   durationState,
  //   "foodInstractionsState",
  //   foodInstractionsState,
  //   "supplyfrequencyState",
  //   supplyfrequencyState,
  //   "repetitionCycleState",
  //   repetitionCycleState,
  //   "totalQuantityState",
  //   totalQuantityState,
  //   "initialValuesFormik",
  //   initialValuesFormik
  // );
  const handleClearClose = () => {
    dispatch({
      type: Utils.ActionName.ADD_MEDICINE_FORMIK,
      payload: FormField.addMedicine,
    });
    // handleClose();
  };
  useEffect(() => {
    let totalQuantity = 0;
    let formulationUpdate;
    if (
      (dosageState || initialValuesFormik?.dosage) &&
      (frequencyState || initialValuesFormik?.frequency) &&
      (durationState || initialValuesFormik?.duration) &&
      (formulationState || initialValuesFormik?.formulation)
      // (foodInstractionsState || initialValuesFormik?.foodInstractions) &&
      // (supplyfrequencyState || initialValuesFormik?.supplyfrequency) &&
      // (repetitionCycleState || initialValuesFormik?.repetitionCycle)
    ) {
      formulationUpdate = formulation.find(
        (item: any) =>
          item.value === formulationState || initialValuesFormik?.formulation
      );
      if (formulationUpdate) {
        totalQuantity = countTotalQuantity(
          formulationUpdate?.text,
          Number(dosageState || initialValuesFormik?.dosage) *
          Number(frequencyState || initialValuesFormik?.frequency) *
          Number(durationState || initialValuesFormik?.duration)
        );
        const calculatedFormulation = calculationTotalFormulation(
          formulationUpdate?.text
        );
        setCalculatedFormulationState(calculatedFormulation);
        // console.log(
        //   totalQuantity,
        //   "totalQuantity dispatch",
        //   formulationUpdate?.text,
        //   calculatedFormulation
        // );
        dispatch({
          type: Utils.ActionName.ADD_MEDICINE_FORMIK,
          payload: {
            ...initialValuesFormik,
            medicineName: initialValuesFormik?.medicineName,
            formulation: formulationUpdate?.value,
            dosage: dosageState || initialValuesFormik?.dosage,
            frequency: frequencyState || initialValuesFormik?.frequency,
            duration: durationState || initialValuesFormik?.duration,
            foodInstractions:
              foodInstractionsState || initialValuesFormik?.foodInstractions,
            supplyfrequency:
              supplyfrequencyState || initialValuesFormik?.supplyfrequency,
            repetitionCycle:
              repetitionCycleState || initialValuesFormik?.repetitionCycle,
            totalQuantity: String(totalQuantity),
            pricePerUnit: price || initialValuesFormik?.pricePerUnit,
            totalPrice:
              Number(totalQuantity) *
              Number(price || initialValuesFormik?.pricePerUnit) ||
              initialValuesFormik?.totalPrice,
          },
        });
      }
    }
    if (price) {
      dispatch({
        type: Utils.ActionName.ADD_MEDICINE_FORMIK,
        payload: {
          ...initialValuesFormik,
          medicineName: initialValuesFormik?.medicineName,
          formulation: formulationUpdate?.value,
          dosage: dosageState || initialValuesFormik?.dosage,
          frequency: frequencyState || initialValuesFormik?.frequency,
          duration: durationState || initialValuesFormik?.duration,
          foodInstractions:
            foodInstractionsState || initialValuesFormik?.foodInstractions,
          supplyfrequency:
            supplyfrequencyState || initialValuesFormik?.supplyfrequency,
          repetitionCycle:
            repetitionCycleState || initialValuesFormik?.repetitionCycle,
          totalQuantity:
            String(totalQuantity) || initialValuesFormik?.totalQuantity,
          pricePerUnit: price || initialValuesFormik?.pricePerUnit,
          totalPrice:
            Number(totalQuantity) *
            Number(price || initialValuesFormik?.pricePerUnit) ||
            initialValuesFormik?.totalPrice,
        },
      });
    }
  }, [
    quantity_measuring,
    price,
    formulationState,
    dosageState,
    frequencyState,
    durationState,
    foodInstractionsState,
    supplyfrequencyState,
    repetitionCycleState,
  ]);

  const createMedicineDetailsList = (value: any) => {
    const {
      dosage,
      duration,
      foodInstractions,
      formulation,
      frequency,
      medicineName,
      pricePerUnit,
      repetitionCycle,
      supplyfrequency,
      totalPrice,
      totalQuantity,
    } = value;

    // const fromuValue = filterData(value.formulation, formulation);
    // const freqValue = filterData(value.frequency, frequency);
    // const durValue = filterData(value.duration, duration);
    // const foodValue = filterData(value.foodInstractions, food);
    const updatedValue = {
      medicineName: initialValuesFormik?.medicineName || medicineName,
      dosage,
      pricePerUnit,
      repetitionCycle,
      supplyfrequency,
      totalPrice,
      totalQuantity,
      formulation,
      frequency,
      duration,
      foodInstractions,
      // formulation: fromuValue?.[0]?.text,
      // frequency: freqValue?.[0]?.text,
      // duration: durValue?.[0]?.text,
      // foodInstractions: foodValue?.[0]?.text,
      unqiueId: initialValuesFormik?.unqiueId || uid(),
    };
    // console.log(value, updatedValue, uid());
    batch(() => {
      dispatch({
        type: Utils.ActionName.CREATE_MEDICINE_DETAILS_LIST,
        payload: updatedValue,
      });
      dispatch({
        type: Utils.ActionName.ADD_MEDICINE_FORMIK,
        payload: FormField.addMedicine,
      });
      dispatch({
        type: Utils.ActionName.MEDICINE_DETAILS,
        payload: {
          price: "",
          quantity_measuring: "",
        },
      });
    });

    handleClose();
  };

  return (
    <>
      <Formik
        initialValues={initialValuesFormik}
        // validationSchema={Schema.AddMedicineSchema}
        onSubmit={createMedicineDetailsList}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid item xs={12}>
              <div className={classes.dialogContainer}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <h5>Medicine Name</h5>
                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                      <MedicineTextField name="medicineName" size="small" />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h5>Formulation</h5>
                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                      <FormControl fullWidth>
                        <SelectWrap
                          extraProp={{ check: true, setFormulationState }}
                          name="formulation"
                          options={formulation.map((item: any) => {
                            return (
                              <MenuItem key={item.key} value={item.value}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                    <h5>Dosage</h5>
                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                      <FormControl fullWidth>
                        <FormControl fullWidth>
                          <Textfield
                            extraProp={{ check: true, setDosageState }}
                            name="dosage"
                            size="small"
                            autoComplete="off"
                          />
                        </FormControl>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                    <h5>Frequency</h5>
                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                      <FormControl fullWidth>
                        <SelectWrap
                          extraProp={{ check: true, setFrequencyState }}
                          name="frequency"
                          options={frequency.map((item: any) => {
                            return (
                              <MenuItem key={item.key} value={item.value}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                    <h5>Duartion</h5>
                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                      <FormControl fullWidth>
                        <SelectWrap
                          extraProp={{ check: true, setDurationState }}
                          name="duration"
                          options={duration.map((item: any) => {
                            return (
                              <MenuItem key={item.key} value={item.value}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                    <h5>Food Instructions</h5>
                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                      <FormControl fullWidth>
                        <SelectWrap
                          extraProp={{
                            check: true,
                            setFoodInstractionsState,
                          }}
                          name="foodInstractions"
                          options={food.map((item: any) => {
                            return (
                              <MenuItem key={item.key} value={item.value}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                    <h5>Supplying frequency</h5>
                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                      <FormControl fullWidth>
                        <SelectWrap
                          extraProp={{
                            check: true,
                            setSupplyfrequencyState,
                          }}
                          name="supplyfrequency"
                          options={supplyfrequency.map((item: any) => {
                            return (
                              <MenuItem key={item.key} value={item.value}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                    <h5>Repetition cycle</h5>
                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                      <FormControl fullWidth>
                        <SelectWrap
                          extraProp={{
                            check: true,
                            setRepetitionCycleState,
                          }}
                          name="repetitionCycle"
                          options={repetitionCycleOptions.map(
                            (item: any) => {
                              return (
                                <MenuItem key={item.key} value={item.value}>
                                  {item.text}
                                </MenuItem>
                              );
                            }
                          )}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                    <h5>Total Quantity</h5>
                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                      <FormControl fullWidth>
                        <Textfield
                          extraProp={{
                            check: true,
                            setTotalQuantityState,
                          }}
                          name="totalQuantity"
                          size="small"
                          inputProps={{ readOnly: true }}
                        />
                      </FormControl>
                      <Box
                        sx={{
                          mt: 0.5,
                          color: (theme) => theme.palette.error.main,
                        }}
                      >
                        {calculatedFormulationState
                          ? calculatedFormulationState
                          : ""}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                    <h5></h5>
                    <Grid item xs={12} sx={{ mt: 0.5 }}></Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                    <h5>Price per unit(in ksh)</h5>
                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                      <FormControl fullWidth>
                        <Textfield
                          name="pricePerUnit"
                          size="small"
                          inputProps={{ readOnly: true }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 1 }}>
                    <h5>Total Price(in ksh)</h5>
                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                      <FormControl fullWidth>
                        <Textfield
                          name="totalPrice"
                          size="small"
                          inputProps={{ readOnly: true }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <DialogActions>
              <Button
                sx={{ fontWeight: "bold" }}
                autoFocus
                onClick={handleClearClose}
              >
                Clear
              </Button>
              <Button
                type="submit"
                sx={[
                  {
                    color: "var(--white)",
                  },
                ]}
                variant="contained"
                color="primary"
              >
                Add
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default PopUp;

// const {
//   medicineName,
//   formulation,
//   dosage,
//   frequency,
//   duration,
//   foodInstractions,
//   // supplyfrequency,
//   // repetitionCycle,
//   // totalQuantity,
//   // pricePerUnit,
//   // totalPrice,
// } = value;
// const createAddMedicineData = {
//   user_id: 5341,
//   insurance_company_id: 9,
//   claim_date: "2020-03-04 20:05:20",
//   doctor_id: 5343,
//   hospital_id: 1234,
//   patient_name: "abc",
//   gender: "Male",
//   dob: "1995-03-05",
//   full_phone_number: 375293080851,
//   os_type: 1,
//   phone_id: "abc123",
//   amount: 100,
//   drugs: [
//     {
//       doctor_comment: "qqewq",
//       dosage: dosage,
//       food_instruction: foodInstractions,
//       form: formulation,
//       frequency: frequency,
//       duration: duration,
//       drug_id: 7815,
//       drug_name: medicineName,
//       type: 1,
//     },
//     {
//       doctor_comment: "wqeqewqew",
//       dosage: 2,
//       food_instruction: foodInstractions,
//       form: formulation,
//       frequency: frequency,
//       duration: duration,
//       drug_id: 7816,
//       drug_name: medicineName,
//       type: 1,
//     },
//     {
//       doctor_comment: "wwq23",
//       dosage: 1,
//       food_instruction: foodInstractions,
//       form: formulation,
//       frequency: frequency,
//       duration: duration,
//       drug_id: 9009,
//       drug_name: medicineName,
//       type: 1,
//     },
//   ],
//   private_practice: 1,
// };
