import { makeStyles } from "@mui/styles";
import { Box, useMediaQuery } from "@mui/material";
import RightScreen from "./RightScreen";
import { useSelector, useDispatch } from "react-redux";
import { getCountry } from "./action";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import { ReducersModal } from "../../modal";
import { useTheme } from "@mui/material";
import Utils from "../../utils";
import { Navigate } from "react-router-dom";

const useStyles = makeStyles({
  paper: {
    height: "100vh",
    backgroundColor: "#EDF9F4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    backgroundColor: "lightpink",
    height: "65%",
    maxWidth: "1000px",
    width: '90%',
    borderRadius: "24px",
    overflow: "hidden",
  },
  leftScreenCover: {
    width: "50%",
    backgroundColor: "white",
    minHeight: "100%",
    position: "relative",
    padding: "50px 4%",
    overflow: "auto",
    maxHeight: "66vh",
  },
  leftScreenSingle: {
    width: "90%",
    margin: '20px auto',
    backgroundColor: "white",
    padding: '30px 20px',
    borderRadius: "24px",
    overflow: 'auto'
  },
  rightScreen: {
    width: "50%",
    height: "100%",
  },
  backdrop: ({ theme }: any) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  }),
});

function Login(props: any) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const screen = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state: ReducersModal) => state.globalLoaderReducer
  );
  // useEffect(() => {
  //   dispatch(getCountry());
  // }, [dispatch]);

  const token = Utils.constants.getUserIdToken();
  const status = localStorage.getItem("user_status");

  if (token !== undefined && token !== null) {
    if (status !== null || status !== undefined) {
      //@ts-ignore
      if ((status === "1" || status === 1)) {
        return <Navigate to={Utils.routes.dashboard} />;
      }
           //@ts-ignore
      if ((status === "6" || status===6)) {
        return <Navigate to={Utils.routes.signUp} />;
      }
    }
  }

  const loader = () => {
    return (
      <Backdrop
        className={classes.backdrop}
        open={isLoading ? isLoading : false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <>
      {screen ? (
        <Box className={classes.paper}>
          {loader()}
          <Box>
            <Box className={classes.leftScreenSingle}>{props.children}</Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.paper}>
          {loader()}
          <Box className={classes.container}>
            <Box className={classes.leftScreenCover}>{props.children}</Box>
            <Box className={classes.rightScreen}>
              <RightScreen />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Login;
