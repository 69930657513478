import {
  Box,
  Grid,
  FormControl,
  MenuItem,
  Theme,
  Avatar,
} from "@mui/material";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { makeStyles, createStyles } from "@mui/styles";
import Utils from "../../utils";
import CustomSelect from "../../components/formsUi/select/customSelect";
import { getDrugFormulary } from "./action";

const API_URL = Utils.constants.API_URL;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterActiveButton: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "20px",
    },
    insuranceCompany: {
      marginTop: "13px",
    },
    status: {
      marginTop: "29px",
      marginBottom: "5px",
    },
    customContentStyle: {
      width: "100%",
      maxWidth: "none",
    },
    typography: {
      fontSize: "1.2rem",
      "@media (min-width:600px)": {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2.4rem",
      },
    },
    flexBox: {
      display: "flex",
      "& .MuiAvatar-root": {
        height: "25px",
        width: "20px",
      },
    },
    marginFlag: {
      marginLeft: "10px",
      textTransform: "uppercase",
      fontSize: "14px",
    },
  })
);

function FilterComponent({ data }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialValues = {
    startDate: "",
    endDate: "",
    patient_drug: "",
    status: "",
  };
  const handleClear = () => {
    dispatch({
      type: Utils.ActionName.DRUG_FORMULARY,
      payload: {
        claim_start_date: "",
        claim_end_date: "",
        insurance_company_id: "",
        status: "",
      },
    });
    dispatch(getDrugFormulary());
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        // validationSchema={Schema.sentClaimFilterSchema}
        onSubmit={(values) => {
          dispatch({
            type: Utils.ActionName.DRUG_FORMULARY,
            payload: {
              claim_start_date: values.startDate,
              claim_end_date: values.endDate,
              insurance_company_id: values.patient_drug,
              status: values.status,
            },
          });
          dispatch(getDrugFormulary());
        }}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <Box className={classes.filterActiveButton}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={2.6}>
                  <Box className={classes.insuranceCompany}>
                    <FormControl fullWidth>
                      <CustomSelect
                        name="patient_drug"
                        label="Select Patient Insurance"
                        size="small"
                        alue={values.patient_drug}
                        // value={insurance_company_id}
                        options={data.map((option: any, index: any) => (
                          <MenuItem key={index} value={option.id}>
                            <div className={classes.flexBox}>
                              <Avatar
                                src={`${API_URL}${option.avatar}`}
                                variant="square"
                              ></Avatar>

                              <div className={classes.marginFlag}>
                                {option.name}
                              </div>
                            </div>
                          </MenuItem>
                        ))}
                      />
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={2.2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="reset"
                    size="large"
                    sx={{
                      fontWeight: "bold",
                      minWidth: "auto",
                      marginTop: "13px",
                    }}
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FilterComponent;
