import { WebsiteManagement } from "../../modal";
import Utils from "../../utils";

export const websiteManagementReducer = (
  state: WebsiteManagement = new WebsiteManagement(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.WEBSITE_MANAGEMENT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
