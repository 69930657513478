import { FC } from "react";
import { Provider } from "react-redux";
import Store from "../store/index"
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";

export const Providers: FC = ({ children }) => {
  // const store = useMemo(() => getAppStore(), []);
  return (
    <Provider store={Store}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Provider>
  );
};
