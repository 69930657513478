import React, { useState } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme, Link, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Otp from "../login/Otp/OtpField/index";
import { ReducersModal } from "../../modal";
import { useDispatch, useSelector } from "react-redux";
import { onSubmitOtp, onSendOtp, onVerifyPopup } from "./action";
import Utils from "../../utils";
import Timer from "../../components/timer";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles({
  leftScreen: {
    maxWidth: "400px",
    margin: "auto",
  },
  logo: {
    display: "flex",
    alignItems: "flex-start",
  },
  ResendBtn: ({ theme }: any) => ({
    fontSize: "14px",
    border: "none",
    backgroundColor: "#ffffff",
    cursor: "pointer",
    color: "var(--bright-purple-mini)",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  }),
  ResendBtnGrey: ({ theme }: any) => ({
    fontSize: "14px",
    backgroundColor: "#ffffff",

    border: "none",
    color: "#707070 !important",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  }),
});

function HospitalPrescriptionVerification({ userId, phoneNumber, orderId, userName }: any) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { otp } = useSelector((state: ReducersModal) => state.otpReducer);
  const { isLoading } = useSelector((state: ReducersModal) => state.globalLoaderReducer);
  const navigate = useNavigate();
    const { enableResend } = useSelector(
    (state: ReducersModal) => state.signInReducer
  );

  const dispatch = useDispatch();
  const handleSubmit = (event: any, type: any) => {
    event.preventDefault();
    if (otp.length === 4 && type === 1) {
      dispatch(onSubmitOtp(userId, phoneNumber, otp, orderId, navigate));
    } else if (type === 2) {
      dispatch(onSendOtp(userId, phoneNumber));
    } else {
      Utils.showAlert(2, "OTP required");
    }
  };

  const [timeText, setTimeText] = React.useState(0);

  const callbackFunction = (childData: any) => {
    setTimeText(childData);
  };

  const handleClose = () => {
    dispatch({
      type: Utils.ActionName.HOSPITAL_PRESCRIPTIONS,
      payload: {
        showVerification: false,
      },
    });
  };
  const onProceed = ()=>{
    
    dispatch(onVerifyPopup(orderId, navigate))
  }

  return (
    <div className="page-patient-verification">
      <div className="bg-white mb-20 center">
        {/* <h2 className="mb-15">OTP Verification</h2> */}
        <h3>Are you sure you want to open prescription?</h3>

        {/* <div style={{ width: "250px", margin: "auto" }}>
          <Otp />
        </div> */}
{/* 
        <p className="mt-20">
          Did not receive OTP?{" "}
            <button
            type="button"
            disabled={enableResend}
            className={timeText > 0 ? classes.ResendBtnGrey : classes.ResendBtn}
            onClick={(event: any) => handleSubmit(event, 2)}
          >
            {"Resend Code "} {timeText > 0 ? "in" : ""}{" "}
            {timeText > 0 ? timeText : ""} {timeText > 0 ? "Sec" : ""}
          </button>
          <Timer parentCallback={callbackFunction} />
        </p> */}

        <div className="flex-center mt-20 mb-20">
          <Button
            variant="contained"
            className={isLoading ? 'capitalize color-white button loading': 'capitalize color-white'}
            sx={{ width: "auto" }}
            //

            onClick={onProceed}
          >
            Yes
          </Button>&nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            className="capitalize color-white"
            sx={{ width: "auto" }}
            //

            onClick={handleClose}
          >
            No
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HospitalPrescriptionVerification;
