import { Theme, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getMyPersonalProfileInfo } from "../../screens/myProfile/action";
import Utils from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "var(--white)",
    },
    container: {
      backgroundColor: "var(--white)",
      padding: theme.spacing(1.25, 3),
    },
  })
);

function BottomHeader() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { linked_hospital_id } = useSelector(
    (state: any) => state.MyProfilePersonalInfoReducer
  );


  useEffect(() => {
    dispatch(getMyPersonalProfileInfo());
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("linked_hospital_id", linked_hospital_id);
  }, [linked_hospital_id]);

  const getHeader = () => {
    if (pathname === Utils.routes.dashboard) {
      return "Dashboard";
    } else if (pathname === Utils.routes.myClaims) {
      return "My Claims";
    } else if (pathname === Utils.routes.doctorPrescriptions) {
      return "Doctor Prescriptions";
    } else if (pathname === Utils.routes.hospitalPrescriptions) {
      return "Hospital Prescriptions";
    } else if (pathname === Utils.routes.drugFormulary) {
      return "Drug Formulary";
    } else if (pathname === Utils.routes.parentCompany) {
      return "Payments";
    } else if (pathname === Utils.routes.reviewInsPay) {
      return "Payments / Review";
    } else if (pathname === Utils.routes.registerFaceBiometric) {
      return "Register Face Biometric";
    }else if (pathname === Utils.routes.medicineOrderRequest) {
      return "Medicine Orders";
    } else if (pathname === Utils.routes.myProfile) {
      return "My Profile";
    } else if (pathname === Utils.routes.manageSubscription) {
      return "My Profile";
    } else if (pathname === Utils.routes.exclusions) {
      return "Exclusions";
    } else if (pathname === Utils.routes.websiteManagement) {
      return "Website Management";
    }else if (pathname === Utils.routes.createMedicineOrderRequest) {
      return "Medicine Orders";
    } else if (
      pathname === Utils.routes.createClaimPrescription ||
      pathname === Utils.routes.createClaim ||
      pathname === Utils.routes.createClaimPrescriptionForHospital
    ) {
      return "Create Claim";
    } else {
      return "";
    }
  };
  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.container}>
        <Typography sx={{ fontSize: 17, fontWeight: "bold" }}>
          {getHeader()}
        </Typography>
      </Container>
    </div>
  );
}

export default BottomHeader;
