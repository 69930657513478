import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useDebounce from "../../../hooks/useDebounce";
import { ReducersModal } from "../../../modal";
import Utils from "../../../utils";

function SearchAutoComplete({
  name,
  label,
  edit,
  doc,
  hos,
  ...otherProps
}: any) {
  const [value, setValue] = useState<any>();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [field, mata] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { city_name, city_id } = useSelector(
    (state: any) => state.MyProfilePersonalInfoReducer
  );

  const {
    phone_number,
    email,
    city_id: city_idSignupEdit,
    city_name: city_nameSignupEdit,
  } = useSelector(
    (state: ReducersModal) => state.getSignupCreateProfleDetailsReducer
  );

  const debouncedSearchTerm: string = useDebounce<string>(
    inputValue && inputValue,
    500
  );
  const { active } = useSelector(
    (state: ReducersModal) => state.pharmacyCityReducer
  );

  // for signUp edit
  useEffect(() => {
    if (city_idSignupEdit && city_nameSignupEdit && phone_number && email) {
      setValue({
        id: city_idSignupEdit,
        name: city_nameSignupEdit,
      });
      setFieldValue(name, city_idSignupEdit);
    }
    if (!(city_idSignupEdit && city_nameSignupEdit && phone_number && email)) {
      setValue(null);
      setFieldValue(name, "");
    }
  }, [phone_number, email, city_idSignupEdit, city_nameSignupEdit]);

  useEffect(() => {
    if (city_name && edit) {
      setValue({
        id: city_id,
        name: city_name,
      });
      setFieldValue(name, city_id);
    }
    if (!city_name && edit) {
      setValue(null);
      setFieldValue(name, "");
    }
  }, [city_id, city_name, edit]);

  // {id: '185702', name: 'Mumias', country: 'Kenya', country_id: '116', country_code: 'ke'}
  useEffect(() => {
    let actives = true;

    if (debouncedSearchTerm === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let params = `?active=${active}&offset=${0}&limit=${20}${
      debouncedSearchTerm.length > 0 ? `&search=${debouncedSearchTerm}` : ""
    }`;

    Utils.constants.axios
      .get(Utils.endPoints.city + params)
      .then((response: any) => {
        if (response.status === 200) {
          const results: any = [...response?.data.body];
          if (actives) {
            let newOptions: any = [];

            if (value) {
              newOptions = [value];
            }

            if (results) {
              newOptions = [...newOptions, ...results];
            }

            setOptions(newOptions);
          }
        }
      });

    return () => {
      actives = false;
    };
  }, [value, debouncedSearchTerm, active]);

  useEffect(() => {
    if (value) {
      setFieldValue(name, value.id);
    } else {
      setFieldValue(name, "");
    }
  }, [value, name, setFieldValue]);

  const handleChange = (event: any, newValue: any) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    if (newValue === null) {
      setValue(null);
    }
  };

  const configTextfield = {
    ...field,
    ...otherProps,
    onChange: handleChange,
  };
  return (
    <Autocomplete
      id="autocompleteLocations"
      {...configTextfield}
      fullWidth
      options={options}
      loading={options.length > 0 ? false : true}
      loadingText={
        doc
          ? "Doctor city searching..."
          : hos
          ? "Hospital city searching..."
          : "Pharmacy city searching..."
      }
      // loadingText="Pharmacy city searching..."
      getOptionLabel={(option: any) => option.name}
      autoHighlight={true}
      autoSelect={true}
      clearOnEscape
      value={value ? value : null}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option: any) => {
        return (
          <li {...props} key={Math.random()}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...configTextfield}
          label={label}
          {...params}
          error={Boolean(mata && mata.touched && mata.error)}
          helperText={mata && mata.touched && mata.error}
          fullWidth
        />
      )}
    />
  );
}

export default SearchAutoComplete;
