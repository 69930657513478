import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";
import Utils from "../../utils";
import { convertBannerWebsiteManagementPayload } from "../../utils/commonFunctions";

export const getWebsiteManagement = () => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    const params = "/1";
    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      Utils.endPoints.websitManagement,
      params,
      (respData: any) => {
        console.log(respData);
        const { status, data } = respData;
        if (status === Utils.constants.api_success_code.success) {
          dispatch({
            type: Utils.ActionName.WEBSITE_MANAGEMENT,
            payload: { ...data },
          });
        }
        dispatch(globalLoaderFalse());
      },
      (error: any) => {
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.message);
      }
    );
  };
};

export const updateWebsiteManagement = (
  params: ReturnType<typeof convertBannerWebsiteManagementPayload>,
  setSubmitting: (isSubmitting: boolean) => void
) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      Utils.endPoints.websitManagement,
      params,
      (respData: any) => {
        const { status, data } = respData;
        if (status === Utils.constants.api_success_code.success) {
          setSubmitting(false);
          dispatch(globalLoaderFalse());
          Utils.showAlert(1, data?.messages?.[0]);
        }
      },
      (error: any) => {
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};
