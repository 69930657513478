import { Step, StepLabel, Stepper, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useMediaQuery from "@mui/material/useMediaQuery";
import TranscribePrescription from "./transcribePrescription";
import PatientProfile from "./patientProfile";
import PatientVerification from "./patientVerification";
import UploadPrescription from "./uploadPrescription";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReducersModal } from "../../modal";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { Form, Formik } from "formik";
import Utils from "../../utils";
import { getClaimDetails } from "./action";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fixedSteps: {
      position: "fixed",
      top: "120px",
      left: 0,
      width: "100%",
      zIndex: 1000,
      backgroundColor: "#edf0f2",
      padding: "0px 120px",
    },
    backDiv: {
      display: "flex",
      flexBasis: "15%",
      alignItems: "center",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        flexBasis: "auto",
      },
    },
    arrowBackIcon: {
      color: theme.palette.secondary.main,
    },
    back: {
      "&.MuiTypography-root": {
        font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(2)} Poppins, sans-serif`,
        color: theme.palette.secondary.main,
        paddingLeft: theme.spacing(0.5),
      },
    },
    stepperDiv: {
      flexBasis: "85%",
      [theme.breakpoints.down("sm")]: {
        flexBasis: "auto",
        marginTop: theme.spacing(1),
      },
    },
    nextDiv: {
      flexBasis: "15%",
    },
    nextBtn: {
      "&.MuiButton-root": {
        float: "right",
        color: "var(--white)",
        padding: theme.spacing(1, 2.5),
        font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(1.5)} Poppins, sans-serif`,
      },
    },
    mainFormCard: {
      position: "relative",
      top: "80px",
    },
  })
);

function CreateClaim() {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { urlClaimId } = useParams();
  const { pathname } = useLocation();

  const isEdit = pathname.includes("edit") ? true : false;

  const { claimSteps, activeStep } = useSelector((state: ReducersModal) => state.PatientInsuranceCompanyReducer);

  const { onlyRead } = useSelector(
    (state: ReducersModal) => state.createClaimStepTwoDoctorAndHospitalAndICDDataReducer
  );

  const { claimId } = useSelector((state: ReducersModal) => state.InsurancePatientProfileReducer);

  const { auth_type_selected, form_step } = useSelector((state: ReducersModal) => state.claimDetailReducer);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <PatientProfile />;
      case 1:
        return <TranscribePrescription />;
      case 2:
        return <PatientVerification />;
      case 3:
        return <UploadPrescription />;
      default:
        return "unknown step";
    }
  }

  const handleButtonStep = (index: any) => {
    console.log("handleButtonStep", index);
    if (
      claimId !== null &&
      claimId !== undefined &&
      claimId !== "" &&
      (activeStep === 1 || activeStep === 0) &&
      index <= 1
    ) {
      dispatch({
        type: Utils.ActionName.GET_PATIENT_DATA,
        payload: {
          activeStep: index,
        },
      });
    } else {
      // Utils.showAlert(2, "Not possible");
    }
    // new changes
    if (
      claimId !== null &&
      claimId !== undefined &&
      claimId !== "" &&
      (activeStep === 1 || activeStep === 0 || activeStep === 2) &&
      index <= 2 &&
      onlyRead
    ) {
      dispatch({
        type: Utils.ActionName.GET_PATIENT_DATA,
        payload: {
          activeStep: index,
        },
      });
    }
  };

  useEffect(() => {
    if (urlClaimId) {
      dispatch(getClaimDetails(urlClaimId, "claimDeatil"));
    }
    if (claimId) {
      dispatch(getClaimDetails(claimId, "claimDeatil"));
    }
  }, [urlClaimId, claimId, dispatch]);

  useEffect(() => {
    if (isEdit) {
      dispatch({
        type: Utils.ActionName.GET_PATIENT_DATA,
        payload: {
          activeStep: Number(form_step),
        },
      });
    }
  }, [form_step]);

  return (
    <div className="page-create-claim">
      <div className={classes.fixedSteps}>
        <div className={`bg-white mt-20 mb-20 space-between`} style={{ margin: "auto" }}>
          <div className={classes.backDiv} onClick={() => navigate(Utils.routes.myClaims)}>
            <ArrowBackIcon className={classes.arrowBackIcon} />
            <Typography className={classes.back}>Back</Typography>
          </div>
          <div className={classes.stepperDiv}>
            <Stepper activeStep={activeStep} alternativeLabel={matches}>
              {claimSteps.map((label: any, index: any) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                return (
                  <Step {...stepProps} key={index}>
                    <StepLabel {...labelProps} style={{ cursor: "pointer" }} onClick={() => handleButtonStep(index)}>
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          {/* <div className={classes.nextDiv}>
          <Button className={classes.nextBtn} variant="contained">
            {activeStep === steps.length - 1 ? "Confirm" : "Next"}
          </Button>
        </div> */}
        </div>
      </div>
      {activeStep === claimSteps.length ? (
        <div>
          <h1>finished</h1>
        </div>
      ) : (
        <div className={classes.mainFormCard}>
          <Formik
            initialValues={{ pharmacyName: "" }}
            onSubmit={() => {
              console.log("hi");
            }}
          >
            {() => {
              return (
                <Form>
                  {getStepContent(activeStep)}
                  {/* <div className="justify-end bg-white mb-20">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{
                          color: "var(--white)",
                          fontWeight: "bold",
                        }}
                      >
                        {activeStep === claimSteps.length - 1
                          ? "Confirm"
                          : "Next"}
                      </Button>
                    </div> */}
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default CreateClaim;
