import { makeStyles, createStyles } from "@mui/styles";
import {
  Avatar,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Theme,
  Typography,
  TextField as TextFieldMui,
  InputAdornment,
  MenuItem,
  FormLabel,
} from "@mui/material";
//@ts-ignore
import ReactFileReader from "react-file-reader";
import TextField from "../../../components/formsUi/textFiled";
import InputFile from "../../../components/formsUi/inputFile";
import { useEffect, useState } from "react";
import CircularLoader from "../../../components/circularLoader";
import SelectWrapper from "../../../components/formsUi/select";
import { useField, useFormikContext } from "formik";
import {
  checkFileSize,
  checkFileType,
  checkImageWAH,
  deleteDocumentImage,
  getFileType,
  // deleteDocumentImage,
  uploadDocumentImage,
} from "../../../utils/commonFunctions";
import { toastIdsConstant } from "../../../utils/toastIdsConstant";
import { toast } from "react-toastify";
import SearchAutoComplete from "../../../components/formsUi/searchAutoComplete";
import { Box } from "@mui/system";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import SelectLocation from "./selectLocation";
import { globalLoaderFalse, globalLoaderTrue } from "../../../reducer/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import FormField from "../../../schema/formField";
import { LocalImages } from "../../../utils/images";
import Utils from "../../../utils";
import DateTimePicker from "../../../components/formsUi/dateTimePicker";
import moment from "moment";
import ImgCrop from "../../../components/imgCrop";
import { getEditMyPersonalProfileInfo, getMyPersonalProfileInfo } from "../action";

const API_URL = Utils.constants.API_URL;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiFormLabel-asterisk": {
        color: "red",
      },
    },
    gridItem: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
    },
    avatarCover: {
      position: "relative",
      marginTop: "5px",
      display: "inline-block",
    },
    avatar: {
      "&.MuiAvatar-root": {
        width: theme.spacing(6.5),
        height: theme.spacing(6.5),
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
      },
    },
    closeIconProfile: {
      position: "absolute",
      top: -10,
      right: -1,
      left: 50,
      cursor: "pointer",
      // border: "1px solid red",
    },
    closeIconProfileImg: {
      position: "absolute",
      top: -10,
      right: -1,
      left: 50,
      cursor: "pointer",
      // border: '1px solid red',
      backgroundColor: "red",
    },
    closeIcon: {
      position: "absolute",
      top: -10,
      right: -10,
      cursor: "pointer",
    },
    singleCard: {
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "7px",
      boxShadow:
        "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
      height: "100%",
      width: "400px",
      maxWidth: "100%",
      textAlign: "center",
      margin: "auto",
    },
    singleCardtwo: {
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "7px",
      boxShadow:
        "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
      height: "100%",
      width: "400px",
      maxWidth: "100%",
      textAlign: "center",
      // border: '1px solid red',
    },
    typography: {
      "&.MuiTypography-root": {
        marginTop: theme.spacing(2),
      },
    },
    gridItemTwo: {
      height: "100%",
      justifyContent: "space-between",
    },
    circularProgressDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    avatarDoc: {
      boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
      marginBottom: "20px",
      "&.MuiAvatar-root": {
        width: theme.spacing(10),
        height: theme.spacing(10),
        margin: "0px auto 20px auto",
        backgroundColor: theme.palette.secondary.light,
        position: "relative",
      },
    },
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      backgroundColor: "var(--white)",
      boxShadow: "24",
    },
  })
);

function EditProfileFields() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [previewImgUrl, setPreviewImgUrl] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [openImgCrop, setOpenImgCrop] = useState(false);
  const [openImgCropSrc, setOpenImgCropSrc] = useState("");
  // useEffect(() => {
  //   dispatch(getMyPersonalProfileInfo());
  // }, [dispatch]);
  const {
    avatar,
    license_of_pharmacy,
    pin_certificate,
    business_permit,
    practice_certificate,
    cr_12,
    tax_compliance,
    business_permit_expiry,
    license_of_pharmacy_expiry,
    practice_certificate_expiry,
    tax_compliance_expiry,
  } = useSelector((state: any) => state.MyProfilePersonalInfoReducer);

  const [physicalAddressField, physicalAddressMata] = useField("physical_address");
  // const [searchValue, setSearchValue] = useState('');
  const [imageUploadProgress, setImageUploadProgress] = useState({
    profilePic: false,
    practiceCertificate: false,
    licensePharmacy: false,
    businessPermit: false,
    pinCertificate: false,
    crDocument: false,
    taxCompliance: false,
    businessPermitExpiry: false,
    practiceCertificateExpiry: false,
    licenseOfPharmacyExpiry: false,
    taxComplianceExpiry: false,
  });
  const [viewImage, setViewImage] = useState<any>({
    practiceCertificate: practice_certificate,
    profilePic: avatar,
    licensePharmacy: license_of_pharmacy,
    businessPermit: business_permit,
    pinCertificate: pin_certificate,
    crDocument: cr_12,
    taxCompliance: tax_compliance,
    businessPermitExpiry: business_permit_expiry,
    practiceCertificateExpiry: practice_certificate_expiry,
    licenseOfPharmacyExpiry: license_of_pharmacy_expiry,
    taxComplianceExpiry: tax_compliance_expiry,
  });
  useEffect(() => {
    setViewImage({
      profilePic: avatar,
      practiceCertificate: practice_certificate,
      licensePharmacy: license_of_pharmacy,
      businessPermit: business_permit,
      pinCertificate: pin_certificate,
      crDocument: cr_12,
      taxCompliance: tax_compliance,
      businessPermitExpiry: business_permit_expiry,
      practiceCertificateExpiry: practice_certificate_expiry,
      licenseOfPharmacyExpiry: license_of_pharmacy_expiry,
      taxComplianceExpiry: tax_compliance_expiry,
    });
  }, [tax_compliance_expiry]);
  const [open, setOpen] = useState(false);
  const { setFieldValue } = useFormikContext();
  const [field, mata] = useField("profilePic");

  // useEffect(()=>{
  //   dispatch(getEditMyPersonalProfileInfo(value));
  // },[dispatch])

  const configSelect: any = {
    ...physicalAddressField,
    // onChange: handleChange,
  };
  var todayDate = moment().format("YYYY MM DD");
  if (physicalAddressMata && physicalAddressMata.touched && physicalAddressMata.error) {
    configSelect.error = true;
    configSelect.helperText = physicalAddressMata.error;
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const addressFieldOnClickHandler = () => {
    handleOpen();
    if ("navigator" in window) {
      navigator.permissions
        .query({
          name: "geolocation",
        })
        .then((result) => {
          if (result.state === "denied") {
            if (!toast.isActive(toastIdsConstant.userDeniedGeoLocationPermission)) {
              toast.warn("User denied the request for Geolocation.", {
                toastId: toastIdsConstant.userDeniedGeoLocationPermission,
                className: "toast-warning",
              });
            }
          } else {
            handleOpen();
          }
        });
    }
  };
  const getMinDate = () => {
    return moment(new Date()).format("yyyy-MM-DD");
  };

  const getMaxDate = () => {
    return moment(new Date()).add(1, "years").add(1, "months").format("yyyy-MM-DD");
  };

  const setImageIconOrUrl = (url: string): string => {
    if (getFileType(url) === "doc") {
      return LocalImages.DocIcon;
    }
    if (getFileType(url) === "pdf") {
      return LocalImages.PdfIcon;
    }
    if (getFileType(url) === "xlsx") {
      return LocalImages.ExcelIcon;
    }
    return API_URL + url;
  };
  const handleOpenPreview = () => {
    setOpenPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const previewImgOpen = () => {
    handleOpenPreview();
  };
  const handleOpenImgCrop = () => {
    setOpenImgCrop(true);
  };
  const handleCloseImgCrop = () => {
    setOpenImgCrop(false);
  };

  const addCropperProfilePicture = (files: any, imageName: string) => {
    return new Promise((resolve, reject) => {
      if (imageName === "profilePic") {
        dispatch(globalLoaderTrue());
        uploadDocumentImage(files.split(",")[1])
          .then((res: any) => {
            if (res?.status === 200) {
              setViewImage({ ...viewImage, [imageName]: res?.data?.image });
              setFieldValue(imageName, res?.data?.image);
              setImageUploadProgress({
                ...imageUploadProgress,
                [imageName]: false,
              });
              resolve("upload profile picture");
              dispatch(globalLoaderFalse());
            }
          })
          .catch((err: any) => {
            if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
              toast.error(err?.response?.data?.messages && err?.response?.data?.messages[0], {
                toastId: toastIdsConstant.loginApiFailure,
                className: "toast-error",
              });
            }
            reject(err?.response?.data?.messages && err?.response?.data?.messages[0]);
            dispatch(globalLoaderFalse());
          });
      }
    });
  };

  const addProfilePicture = (files: any, imageName: string) => {
    if (checkFileSize(files.fileList[0])) return;
    console.log("files.fileList[0].type.split", files.fileList[0].type.split("/")[1]);
    if (checkFileType(files)) return;
    if (files.base64) {
      dispatch(globalLoaderTrue());
      // setImageUploadProgress({ ...imageUploadProgress, [imageName]: true });
      // console.log(files.base64.split(",")[1]);
      uploadDocumentImage(files.base64.split(",")[1])
        .then((res: any) => {
          if (res?.status === 200) {
            setViewImage({ ...viewImage, [imageName]: res?.data?.image });
            setFieldValue(imageName, res?.data?.image);
            setImageUploadProgress({
              ...imageUploadProgress,
              [imageName]: false,
            });
            dispatch(globalLoaderFalse());
          }
        })
        .catch((err: any) => {
          if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
            toast.error(err?.response?.data?.messages && err?.response?.data?.messages[0], {
              toastId: toastIdsConstant.loginApiFailure,
              className: "toast-error",
            });
          }
          // setImageUploadProgress({
          //   ...imageUploadProgress,
          //   [imageName]: false,
          // });
          dispatch(globalLoaderFalse());
        });
    }
  };

  const deleteProfilePhoto = (imageName: any) => {
    if (!imageName) return;
    if (!viewImage[imageName]) return;
    // setImageUploadProgress({ ...imageUploadProgress, [imageName]: true });
    // dispatch(globalLoaderTrue());
    setViewImage({ ...viewImage, [imageName]: "" });
    setFieldValue(imageName, "");
    console.log(viewImage[imageName]);
    // Utils.constants.getAccessToken();

    // deleteDocumentImage(viewImage[imageName])
    //   .then((res: any) => {
    //     console.log(res);
    //     if (res?.data?.code === 200) {
    //       // setImageUploadProgress({
    //       //   ...imageUploadProgress,
    //       //   [imageName]: false,
    //       // });
    //       setViewImage({ ...viewImage, [imageName]: '' });
    //       dispatch(globalLoaderFalse());
    //     }
    //   })
    //   .catch((err: any) => {
    //     if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
    //       toast.error(
    //         err?.response?.data?.messages && err?.response?.data?.messages[0],
    //         {
    //           toastId: toastIdsConstant.loginApiFailure,
    //           className: 'toast-error',
    //         }
    //       );
    //     }
    //     setImageUploadProgress({ ...imageUploadProgress, [imageName]: false });
    //     dispatch(globalLoaderFalse());
    //   });
  };

  return (
    <div className={classes.root}>
      {open && <SelectLocation editMap handleClose={handleClose} />}
      <div className="bg-white mb-20">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            {imageUploadProgress.profilePic && (
              <Avatar alt="Image" className={classes.avatar} src="/static/images/avatar/1.jpg">
                <CircularProgress size={20} color="primary" />
              </Avatar>
            )}
            {viewImage.profilePic && (
              <Box sx={{ display: "flex", position: "relative" }}>
                <Avatar className={classes.avatar} alt="User" src={setImageIconOrUrl(viewImage.profilePic)} />

                <Box sx={{ display: "flex" }}>
                  <img
                    alt="Close"
                    className={classes.closeIconProfile}
                    src={LocalImages.CloseIcon}
                    title="Remove photo"
                    onClick={() => deleteProfilePhoto("profilePic")}
                  />
                  {!license_of_pharmacy ||
                  !pin_certificate ||
                  !business_permit ||
                  !practice_certificate ||
                  !cr_12 ||
                  !tax_compliance ||
                  moment(todayDate).isSameOrAfter(practice_certificate_expiry) ||
                  moment(todayDate).isSameOrAfter(license_of_pharmacy_expiry) ||
                  moment(todayDate).isSameOrAfter(business_permit_expiry) ||
                  moment(todayDate).isSameOrAfter(tax_compliance_expiry)
                    ? // <span className="dot"></span>
                      ""
                    : ""}
                </Box>
              </Box>
            )}
            {/* {!viewImage.profilePic && !imageUploadProgress.profilePic && (
              <Box sx={{ display: 'flex' }}>
                <Avatar className={classes.avatar} alt="" src="" />
                <span className="dot2"></span>
              </Box>
            )} */}
            {!license_of_pharmacy ||
            !pin_certificate ||
            !business_permit ||
            !practice_certificate ||
            !cr_12 ||
            !tax_compliance ||
            moment(todayDate).isSameOrAfter(practice_certificate_expiry) ||
            moment(todayDate).isSameOrAfter(license_of_pharmacy_expiry) ||
            moment(todayDate).isSameOrAfter(business_permit_expiry) ||
            moment(todayDate).isSameOrAfter(tax_compliance_expiry) ? (
              <FormLabel
                sx={{
                  fontSize: 16,
                  marginBottom: "10px",
                  fontWeight: "500",
                  color: "black",
                }}
                required
              >
                Upload Pharmacy's Profile Pic
              </FormLabel>
            ) : (
              <Typography className={classes.typography}>Upload Pharmacy's Profile Pic</Typography>
            )}
            {mata && mata.touched && mata?.error && (
              <>
                <FormHelperText
                  sx={{
                    color: "error.main",
                    fontSize: (theme) => theme.spacing(1.8),
                  }}
                >
                  {mata.error}
                </FormHelperText>
              </>
            )}
            <ImgCrop
              src={openImgCropSrc}
              open={openImgCrop}
              handleClose={handleCloseImgCrop}
              uploadImage={addCropperProfilePicture}
              type="profilePic"
            />
            <ReactFileReader
              handleFiles={(files: any) => {
                // checkImageWAH(files).then(() => {
                setOpenImgCropSrc(files);
                handleOpenImgCrop();
                // });
              }}
              base64={true}
              multipleFiles={false}
              fileTypes={[".jpg", ".jpeg", ".png", ".gif"]}
              disabled={!!viewImage.profilePic}
            >
              <Button
                disabled={!!viewImage.profilePic}
                {...field}
                variant="contained"
                sx={{
                  color: "var(--white)",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                Choose File
              </Button>
            </ReactFileReader>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth name="pharmacyName" label="Pharmacy Name" size="small" />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <SelectWrapper
                  name="surname"
                  options={Object.keys(FormField.optionsSurname).map((item, pos) => {
                    return (
                      <MenuItem key={pos} value={FormField.optionsSurname[item]}>
                        {FormField.optionsSurname[item]}
                      </MenuItem>
                    );
                  })}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={7} md={9}>
                <TextField
                  name="pharmacyAdminName"
                  label="Pharmacy Admin Name"
                  size="small"
                  // value={admin_name}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SearchAutoComplete name="searchPharmacyCity" label="Search Pharmacy City" size="small" edit />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="emailId"
                  label="Email Id"
                  size="small"
                  // value={email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldMui
                  fullWidth
                  {...configSelect}
                  label="Address"
                  size="small"
                  onClick={() => {
                    addressFieldOnClickHandler();
                  }}
                  onKeyUp={() => {
                    addressFieldOnClickHandler();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AddLocationIcon color="primary" />
                      </InputAdornment>
                    ),
                    // readOnly: true,
                  }}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Typography className="mb-20 bold center" sx={{ fontSize: "26px" }}>
        Attach Documents
      </Typography>
      <Grid container spacing={3} className="mb-30">
        <Grid item xs={12} sm={6} md={4}>
          <div
            className={
              moment(todayDate).isSameOrAfter(practice_certificate_expiry) || !practice_certificate
                ? classes.singleCardtwo
                : classes.singleCard
            }
          >
            {moment(todayDate).isSameOrAfter(practice_certificate_expiry) || !practice_certificate ? (
              <FormLabel
                sx={{
                  fontSize: 16,
                  marginBottom: "10px",
                  fontWeight: "500",
                  color: "black",
                }}
                required
              >
                Practice Certificate of Pharmacist in Charge
                {!practice_certificate || moment(todayDate).isSameOrAfter(practice_certificate_expiry) ? (
                  <span className="dotProfile"></span>
                ) : (
                  ""
                )}
              </FormLabel>
            ) : (
              <h4 className="mb-10">
                Practice Certificate of Pharmacist in Charge
                {!practice_certificate || moment(todayDate).isSameOrAfter(practice_certificate_expiry) ? (
                  <span className="dotProfile"></span>
                ) : (
                  ""
                )}
              </h4>
            )}
            <div className={classes.circularProgressDiv}>
              {imageUploadProgress.practiceCertificate && (
                <CircularLoader loading={imageUploadProgress.practiceCertificate} />
              )}
              {
                <>
                  {viewImage.practiceCertificate && (
                    <div style={{ margin: "auto" }}>
                      <div className={classes.avatarCover}>
                        <Avatar
                          variant="square"
                          className={classes.avatarDoc}
                          alt="DocImage"
                          onClick={() => {
                            setPreviewImgUrl(setImageIconOrUrl(viewImage.practiceCertificate));
                            previewImgOpen();
                          }}
                          src={setImageIconOrUrl(viewImage.practiceCertificate)}
                        >
                          <embed
                            src={API_URL + viewImage.practiceCertificate}
                            type="application/pdf"
                            height="100%"
                            width="100%"
                          ></embed>
                        </Avatar>
                        <img
                          alt="Close"
                          className={classes.closeIcon}
                          src={LocalImages.CloseIcon}
                          title="Remove photo"
                          onClick={() => deleteProfilePhoto("practiceCertificate")}
                        />
                      </div>
                      <DateTimePicker
                        name="practiceCertificateExpiry"
                        label="Expiry Date"
                        type="date"
                        size="small"
                        inputProps={{
                          min: getMinDate(),
                          max: getMaxDate(),
                        }}
                      />
                    </div>
                  )}
                </>
              }
              {!viewImage.practiceCertificate && !imageUploadProgress.practiceCertificate && (
                <Box>
                  <InputFile
                    name="practiceCertificate"
                    handleFiles={(files: any) => {
                      addProfilePicture(files, "practiceCertificate");
                    }}
                  />
                  <DateTimePicker
                    name="practiceCertificateExpiry"
                    label="Expiry Date"
                    type="date"
                    size="small"
                    className="mt-20"
                    style={{ width: "276px", maxWidth: "100%" }}
                    inputProps={{
                      min: getMinDate(),
                      max: getMaxDate(),
                    }}
                  />
                </Box>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div
            className={
              moment(todayDate).isSameOrAfter(license_of_pharmacy_expiry) || !license_of_pharmacy
                ? classes.singleCardtwo
                : classes.singleCard
            }
          >
            {moment(todayDate).isSameOrAfter(license_of_pharmacy_expiry) || !license_of_pharmacy ? (
              <FormLabel
                sx={{
                  fontSize: 16,
                  marginBottom: "10px",
                  fontWeight: "500",
                  color: "black",
                }}
                required
              >
                License of Pharmacy
                {!license_of_pharmacy || moment(todayDate).isSameOrAfter(license_of_pharmacy_expiry) ? (
                  <span className="dotProfile"></span>
                ) : (
                  ""
                )}
              </FormLabel>
            ) : (
              <h4 className="mb-10">
                License of Pharmacy
                {!license_of_pharmacy || moment(todayDate).isSameOrAfter(license_of_pharmacy_expiry) ? (
                  <span className="dotProfile"></span>
                ) : (
                  ""
                )}
              </h4>
            )}
            <div className={classes.circularProgressDiv}>
              {imageUploadProgress.licensePharmacy && <CircularLoader loading={imageUploadProgress.licensePharmacy} />}
              {viewImage.licensePharmacy && (
                <div style={{ margin: "auto" }}>
                  <div className={classes.avatarCover}>
                    <Avatar
                      variant="square"
                      className={classes.avatarDoc}
                      alt="DocImage"
                      onClick={() => {
                        setPreviewImgUrl(setImageIconOrUrl(viewImage.licensePharmacy));
                        previewImgOpen();
                      }}
                      src={setImageIconOrUrl(viewImage.licensePharmacy)}
                    />
                    <img
                      alt="Close"
                      className={classes.closeIcon}
                      src={LocalImages.CloseIcon}
                      title="Remove photo"
                      onClick={() => deleteProfilePhoto("licensePharmacy")}
                    />
                  </div>
                  <DateTimePicker
                    name="licenseOfPharmacyExpiry"
                    label="Expiry Date"
                    type="date"
                    size="small"
                    inputProps={{
                      min: getMinDate(),
                      max: getMaxDate(),
                    }}
                  />
                </div>
              )}
              {!viewImage.licensePharmacy && !imageUploadProgress.licensePharmacy && (
                <div style={{ margin: "auto" }}>
                  <InputFile
                    name="licensePharmacy"
                    handleFiles={(files: any) => {
                      addProfilePicture(files, "licensePharmacy");
                    }}
                  />
                  <DateTimePicker
                    name="licenseOfPharmacyExpiry"
                    label="Expiry Date"
                    type="date"
                    className="mt-20"
                    style={{ width: "276px", maxWidth: "100%" }}
                    size="small"
                    inputProps={{
                      min: getMinDate(),
                      max: getMaxDate(),
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div
            className={
              moment(todayDate).isSameOrAfter(business_permit_expiry) || !business_permit
                ? classes.singleCardtwo
                : classes.singleCard
            }
          >
            {moment(todayDate).isSameOrAfter(business_permit_expiry) || !business_permit ? (
              <FormLabel
                sx={{
                  fontSize: 16,
                  marginBottom: "10px",
                  fontWeight: "500",
                  color: "black",
                }}
                required
              >
                Business Permit
                {!business_permit || moment(todayDate).isSameOrAfter(business_permit_expiry) ? (
                  <span className="dotProfile"></span>
                ) : (
                  ""
                )}
              </FormLabel>
            ) : (
              <h4 className="mb-10">
                Business Permit
                {!business_permit || moment(todayDate).isSameOrAfter(business_permit_expiry) ? (
                  <span className="dotProfile"></span>
                ) : (
                  ""
                )}
              </h4>
            )}
            <div className={classes.circularProgressDiv}>
              {imageUploadProgress.businessPermit && <CircularLoader loading={imageUploadProgress.businessPermit} />}
              {viewImage.businessPermit && (
                <div style={{ margin: "auto" }}>
                  <div className={classes.avatarCover}>
                    <Avatar
                      variant="square"
                      className={classes.avatarDoc}
                      alt="DocImage"
                      onClick={() => {
                        setPreviewImgUrl(setImageIconOrUrl(viewImage.businessPermit));
                        previewImgOpen();
                      }}
                      src={setImageIconOrUrl(viewImage.businessPermit)}
                    />
                    <img
                      alt="Close"
                      src={LocalImages.CloseIcon}
                      className={classes.closeIcon}
                      title="Remove photo"
                      onClick={() => deleteProfilePhoto("businessPermit")}
                    />
                  </div>
                  <DateTimePicker
                    name="businessPermitExpiry"
                    label="Expiry Date"
                    type="date"
                    size="small"
                    inputProps={{
                      min: getMinDate(),
                      max: getMaxDate(),
                    }}
                  />
                </div>
              )}
              {!viewImage.businessPermit && !imageUploadProgress.businessPermit && (
                <Box>
                  <InputFile
                    name="businessPermit"
                    handleFiles={(files: any) => {
                      addProfilePicture(files, "businessPermit");
                    }}
                  />
                  <DateTimePicker
                    name="businessPermitExpiry"
                    label="Expiry Date"
                    type="date"
                    size="small"
                    style={{ width: "276px", maxWidth: "100%" }}
                    className="mt-20"
                    inputProps={{
                      min: getMinDate(),
                      max: getMaxDate(),
                    }}
                  />
                </Box>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={!pin_certificate ? classes.singleCardtwo : classes.singleCard}>
            {!pin_certificate ? (
              <FormLabel
                sx={{
                  fontSize: 16,
                  marginBottom: "10px",
                  fontWeight: "500",
                  color: "black",
                }}
                required
              >
                PIN (TAX) Certificate
                {!pin_certificate ? <span className="dotProfile"></span> : ""}
              </FormLabel>
            ) : (
              <h4 className="mb-10">
                PIN (TAX) Certificate {!pin_certificate ? <span className="dotProfile"></span> : ""}
              </h4>
            )}
            <div className={classes.circularProgressDiv}>
              {imageUploadProgress.pinCertificate && <CircularLoader loading={imageUploadProgress.pinCertificate} />}
              {viewImage.pinCertificate && (
                <div className={classes.avatarCover}>
                  <Avatar
                    variant="square"
                    className={classes.avatarDoc}
                    alt="DocImage"
                    onClick={() => {
                      setPreviewImgUrl(setImageIconOrUrl(viewImage.pinCertificate));
                      previewImgOpen();
                    }}
                    src={setImageIconOrUrl(viewImage.pinCertificate)}
                  />
                  <img
                    alt="Close"
                    src={LocalImages.CloseIcon}
                    className={classes.closeIcon}
                    title="Remove photo"
                    onClick={() => deleteProfilePhoto("pinCertificate")}
                  />
                </div>
              )}
              {!viewImage.pinCertificate && !imageUploadProgress.pinCertificate && (
                <InputFile
                  name="pinCertificate"
                  handleFiles={(files: any) => {
                    addProfilePicture(files, "pinCertificate");
                  }}
                />
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={!cr_12 ? classes.singleCardtwo : classes.singleCard}>
            {!cr_12 ? (
              <FormLabel
                sx={{
                  fontSize: 16,
                  marginBottom: "10px",
                  fontWeight: "500",
                  color: "black",
                }}
                required
              >
                CR12
                {!cr_12 ? <span className="dotProfile"></span> : ""}
              </FormLabel>
            ) : (
              <h4 className="mb-10">CR12 {!cr_12 ? <span className="dotProfile"></span> : ""}</h4>
            )}
            <div className={classes.circularProgressDiv}>
              {imageUploadProgress.crDocument && <CircularLoader loading={imageUploadProgress.crDocument} />}
              {viewImage.crDocument && (
                <div className={classes.avatarCover}>
                  <Avatar
                    variant="square"
                    className={classes.avatarDoc}
                    alt="DocImage"
                    onClick={() => {
                      setPreviewImgUrl(setImageIconOrUrl(viewImage.crDocument));
                      previewImgOpen();
                    }}
                    src={setImageIconOrUrl(viewImage.crDocument)}
                  />
                  <img
                    alt="Close"
                    src={LocalImages.CloseIcon}
                    className={classes.closeIcon}
                    title="Remove photo"
                    onClick={() => deleteProfilePhoto("crDocument")}
                  />
                </div>
              )}
              {!viewImage.crDocument && !imageUploadProgress.crDocument && (
                <InputFile
                  name="crDocument"
                  handleFiles={(files: any) => {
                    addProfilePicture(files, "crDocument");
                  }}
                />
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div
            className={
              moment(todayDate).isSameOrAfter(tax_compliance_expiry) || !tax_compliance
                ? classes.singleCardtwo
                : classes.singleCard
            }
          >
            {moment(todayDate).isSameOrAfter(tax_compliance_expiry) ? (
              <FormLabel
                sx={{
                  fontSize: 16,
                  marginBottom: "10px",
                  fontWeight: "500",
                  color: "black",
                }}
                required
              >
                Tax Compliance
                {!tax_compliance ? <span className="dotProfile"></span> : ""}
              </FormLabel>
            ) : (
              <h4 className="mb-10">Tax Compliance {!tax_compliance ? <span className="dotProfile"></span> : ""}</h4>
            )}
            <div className={classes.circularProgressDiv}>
              {imageUploadProgress.taxCompliance && <CircularLoader loading={imageUploadProgress.taxCompliance} />}
              {viewImage.taxCompliance && (
                <div style={{ margin: "auto" }}>
                  <div className={classes.avatarCover}>
                    <Avatar
                      variant="square"
                      className={classes.avatarDoc}
                      alt="DocImage"
                      onClick={() => {
                        setPreviewImgUrl(setImageIconOrUrl(viewImage.taxCompliance));
                        previewImgOpen();
                      }}
                      src={setImageIconOrUrl(viewImage.taxCompliance)}
                    />
                    <img
                      alt="Close"
                      src={LocalImages.CloseIcon}
                      className={classes.closeIcon}
                      title="Remove photo"
                      onClick={() => deleteProfilePhoto("taxCompliance")}
                    />
                  </div>
                  <DateTimePicker
                    name="taxComplianceExpiry"
                    id="date"
                    label="Expiry Date"
                    type="date"
                    size="small"
                    inputProps={{
                      min: getMinDate(),
                      max: getMaxDate(),
                    }}
                  />
                </div>
              )}
              {!viewImage.taxCompliance && !imageUploadProgress.taxCompliance && (
                <Box>
                  <InputFile
                    name="taxCompliance"
                    handleFiles={(files: any) => {
                      addProfilePicture(files, "taxCompliance");
                    }}
                  />
                  <DateTimePicker
                    name="practiceCertificateExpiry"
                    id="date"
                    label="Expiry Date"
                    type="date"
                    size="small"
                    style={{ width: "276px", maxWidth: "100%" }}
                    className="mt-20"
                    inputProps={{
                      min: getMinDate(),
                      max: getMaxDate(),
                    }}
                  />
                </Box>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default EditProfileFields;
