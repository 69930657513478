import Utils from "../../utils";
import {
  otpModal,
  LogoutModal,
  ThemeModal,
  CountryModal,
  signInModal
} from "../../modal";



export const signInReducer = (
  state: signInModal = new signInModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.SIGN_IN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const otpReducer = (state: otpModal = new otpModal(), action: any) => {
  switch (action.type) {
    case Utils.ActionName.UPDATE_OTP_VAL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const countryReducer = (
  state: CountryModal = new CountryModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.UPDATE_COUNTRY_VAL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const logoutReducer = (
  state: LogoutModal = new LogoutModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.UPDATE_CONFIRM_MODAL_VAL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

//Theme Reducer
export const themeReducer = (
  state: ThemeModal = new ThemeModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.UPDATE_THEME_VALUE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};


