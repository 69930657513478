import Header from "../components/header";
import { makeStyles, createStyles } from "@mui/styles";
import { Backdrop, CircularProgress, Theme } from "@mui/material";
import { Navigate } from "react-router-dom";
import Utils from "../utils";
import { useSelector } from "react-redux";
import { ReducersModal } from "../modal";

type ContainerProps = {
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light,
      minHeight: "100vh",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "var(--white)",
    },
  })
);

function Container({ children }: ContainerProps) {
  const classes = useStyles();
  const { isLoading } = useSelector(
    (state: ReducersModal) => state.globalLoaderReducer
  );

  const token = Utils.constants.getUserIdToken();
  const status = localStorage.getItem("user_status");
  if (token === undefined || token === null) {
    return <Navigate to="/" />;
  }

  if (status !== null || status !== undefined) {
    if (status == "1") {
      return <Navigate to={Utils.routes.dashboard} />;
    }
  }

  if (status === null || status === undefined) {
    return <Navigate to="/" />;
  }

  const loader = () => {
    return (
      <Backdrop
        className={classes.backdrop}
        open={isLoading ? isLoading : false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <>
      {loader()}
      <div className={classes.root}>
        <Header />
        {children}
      </div>
    </>
  );
}

export default Container;
