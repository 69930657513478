import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Theme,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, createStyles } from "@mui/styles";

import { useSelector, useDispatch } from "react-redux";
import {
  claimCreateErrMsg,
  numberCommaFormat,
  userDeniedLocationErrMsg,
} from "../../utils/commonFunctions";

import Utils from "../../utils";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogActions: {
      "&.MuiDialogActions-root": {
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      },
    },
    dialogTitle: {
      display: "flex",
      alignItems: "center",
      justtifyContent: "space-between",
      "&.MuiDialogTitle-root": {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    dialogContent: {
      "&.MuiDialogContent-root": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "black",
        fontSize: "22px",
      },
    },
    buttonPrimary: {
      color: "white !important",
      textTransform: "capitalize",
    },
  })
);
const PaymentType: any = [
  { key: 1, text: "Payment From Claim/Order", value: 1 },
  { key: 2, text: "MPESA", value: 2 },
];

function PaymentTypeModal(props: any) {
  const {
    title,
    content: selectedSubscription,
    open,
    handleClose,
    handlePayNowModal,
    subscriptionSetting,
    payNowModal,
    subscriptionId,
    refreshData,
  } = props;
  console.log("selectedSubscription", subscriptionSetting);
  const dispatch = useDispatch();

  const [subscription_id, setSubscriptionId] = useState(
    payNowModal || open == 2
      ? selectedSubscription.subscription_plan_id
      : selectedSubscription.id
  );
  const [disablePayNow, setDisablePayNow] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const theme = useTheme();
  const classes = useStyles({ theme });

  const currentPaymentType =
    selectedSubscription?.payment_type == 1
      ? "Payment From Claim/Order"
      : "MPESA";
  const changePaymentType =
    selectedSubscription?.payment_type == 2
      ? "Payment From Claim/Order"
      : "MPESA";

  const handlePaymentTypeChange = () => {
    const payload = {
      type: "payment_type",
      payment_type: selectedSubscription?.payment_type == 1 ? 2 : 1,
      id: selectedSubscription?.id,
    };
    handleApiCall(payload);
  };

  const handleApiCall = (payload: any) => {
    dispatch(globalLoaderTrue());
    setDisablePayNow(true);
    Utils.constants.getAccessToken();
    Utils.api.putApiCall(
      `${Utils.endPoints.subscription}/${payload.id}`,
      payload,
      (respData: any) => {
        dispatch(globalLoaderFalse());
        setDisablePayNow(false);
        let { data } = respData;
        handleClose(false);
        refreshData();
        Utils.showAlert(1, "Payment type changed successfully");
        if (data?.code == 200) {
          //
        } else {
          Utils.showAlert(2, data?.messages[0]);
        }
      },
      (error: any) => {
        setDisablePayNow(false);
        dispatch(globalLoaderFalse());
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth={payNowModal ? "md" : "lg"}
        onClose={handleClose}
      >
        <DialogTitle
          className={classes.dialogTitle}
          style={{ fontSize: "25px" }}
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
          <span>{`Payment Type Change`}</span>
          {handleClose && (
            <CloseIcon
              sx={{
                ml: 2,
                color: (theme) => theme.palette.grey[500],
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          )}
        </DialogTitle>

        <DialogContent
          className={classes.dialogContent}
          style={{ fontSize: "18px", display: "block" }}
        >
          <div style={{ padding: "10px", fontSize: "20px" }}>
            <p>
              Are you sure you want to change the payment type from{" "}
              {currentPaymentType} to {changePaymentType} ?
            </p>
            <br />
            <div style={{ textAlign: "end" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleClose(false)}
                style={{ marginRight: 10 }}
              >
                No
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={disablePayNow}
                onClick={() => {
                  handlePaymentTypeChange();
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default PaymentTypeModal;
