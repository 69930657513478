import { Grid, Theme, Typography, Box } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useField } from 'formik';
import InputTimeCard from '../../../components/inputTimeCard';

type WorkingDaysProps = {
  days: string;
  workingSwitchLabel: string;
  workingSwitchName: string;
  workingTimeNameTo: string;
  workingTimeNameFrom: string;
  workingDefaultTimeTo?: any;
  workingDefaultTimeFrom?: any;
  lunchSwitchLabel: string;
  lunchSwitchName: string;
  lunchTimeNameTo: string;
  lunchTimeNameFrom: string;
  lunchDefaultTimeTo?: any;
  lunchDefaultTimeFrom?: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayDiv: {
      backgroundColor: theme.palette.primary.light,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      borderBottom: '1px dashed #60a1cf',
    },
    daySchedule: {
      padding: '0px 10px 10px',
      borderBottom: '1px solid #d3d3d3',
      '&.MuiTypography-root': {
        fontWeight: '500',
      },
    },
  })
);

const getDateTime = (value: string) => {
  return new Date(value);
};

function WorkingDays({
  days,
  workingSwitchLabel,
  workingSwitchName,
  workingTimeNameTo,
  workingTimeNameFrom,
  workingDefaultTimeTo,
  workingDefaultTimeFrom,
  lunchSwitchLabel,
  lunchSwitchName,
  lunchTimeNameTo,
  lunchTimeNameFrom,
  lunchDefaultTimeTo,
  lunchDefaultTimeFrom,
}: WorkingDaysProps) {
  const classes = useStyles();
  const [fieldWor, mataWor] = useField(workingSwitchName);
  const [fieldLun, mataLun] = useField(lunchSwitchName);

  return (
    <Grid container>
      <Grid item xs={12} sm={2} md={2}>
        <div className={classes.dayDiv}>
          <Typography variant="h6" sx={{ fontWeight: '600' }}>
            {days}
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={10}
        className={`day-schedule ${classes.daySchedule}`}
      >
        <Box>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ p: 1 }}>
                <InputTimeCard
                  disabledTime={!mataWor.value}
                  switchLabel={workingSwitchLabel}
                  switchName={workingSwitchName}
                  timeNameTo={workingTimeNameTo}
                  timeNameFrom={workingTimeNameFrom}
                  defaultTimeTo={
                    workingDefaultTimeTo
                      ? workingDefaultTimeTo
                      : getDateTime('December 17, 1995 08:00:00')
                  }
                  defaultTimeForm={
                    workingDefaultTimeFrom
                      ? workingDefaultTimeFrom
                      : getDateTime('December 17, 1995 16:00:00')
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ p: 1 }}>
                <InputTimeCard
                  disabledSwitchLun={!mataWor.value}
                  disabledTime={!mataLun.value || !mataWor.value}
                  switchLabel={lunchSwitchLabel}
                  switchName={lunchSwitchName}
                  timeNameTo={lunchTimeNameTo}
                  timeNameFrom={lunchTimeNameFrom}
                  defaultTimeTo={
                    lunchDefaultTimeTo
                      ? lunchDefaultTimeTo
                      : getDateTime('December 17, 1995 12:00:00')
                  }
                  defaultTimeForm={
                    lunchDefaultTimeFrom
                      ? lunchDefaultTimeFrom
                      : getDateTime('December 17, 1995 13:00:00')
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default WorkingDays;
