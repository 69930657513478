import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getClaimDetails } from "./action";
import moment from "moment";
import Utils from "../../utils";
import {
  calculateTotalAmountDetails,
  checkStatus,
  uid,
} from "../../utils/commonFunctions";
import { Box } from "@mui/system";
import { Avatar, Button, Chip, Typography } from "@mui/material";
import { ReducersModal } from "../../modal";
import MedicalInformation from "./sentClaims/medicalInformation";
import PersonIcon from "@mui/icons-material/Person";
import PreviewImg from "../../components/previewImg";
import { LocalImages } from "../../utils/images";

function ClaimDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { claimId } = useParams();
  const [openPreview, setOpenPreview] = useState(false);
  const [previewImgUrl, setPreviewImgUrl] = useState("");
  const initialValuesFormik = useSelector(
    (state: ReducersModal) => state.addmidicineFormitModalReduce
  );
  const claimDetail = useSelector(
    (state: ReducersModal) => state.claimDetailReducer
  );
  const {
    id,
    user_avatar,
    user_name,
    user_gender,
    user_date_birth,
    user_phone,
    insurance_name,
    insurance_avatar,
    doctor_name,
    doctor_phone,
    status,
    entity_id,
    beneficiary,
    hospital_name,
    documents,
    order_data,
    phone_list,
    form_step,
    type_id,
    insurance_company_id,
    auth_type_selected,
    doctor_id,
    doctor_phone_code,
    hospital_id,
    icd10_list,
    user_phone_number,
    user_phone_code,
    claimBenefitsDetails,
  } = claimDetail;

  useEffect(() => {
    if (claimId) {
      dispatch(getClaimDetails(claimId, "claimDeatil"));
    }
  }, [claimId, dispatch]);

  const checkPhoneNumber = () => {
    if (beneficiary?.entity_id === entity_id) {
      return user_phone;
    } else {
      return beneficiary?.login_phone ? beneficiary?.login_phone : user_phone;
    }
  };

  const checkPhoneLabel = () => {
    if (beneficiary?.entity_id === entity_id) {
      return "Patient Phone Number";
    } else {
      return "Family Phone Number";
    }
  };

  const checkMemberNumberLabel = () => {
    let cardLabel = "Member number";
    if (beneficiary?.card_type === "2") {
      cardLabel = "Smart card number ";
    } else if (beneficiary?.card_type === "3") {
      cardLabel = "LCT member number";
    }
    return cardLabel;
  };

  const checkMemberNumber = () => {
    let beneficiaryName = "";
    if (beneficiary?.card_type === "1") {
      beneficiaryName = beneficiary?.entity_id;
    } else if (beneficiary?.card_type === "2") {
      beneficiaryName = beneficiary?.employee_number;
    } else if (beneficiary?.card_type === "3") {
      beneficiaryName = beneficiary?.employee_number;
    } else {
      beneficiaryName = beneficiary?.entity_id;
    }
    return beneficiaryName;
  };

  console.log("claimDetail", claimDetail);

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const previewImgOpen = () => {
    handleOpenPreview();
  };

  return (
    <div className="page-claim-detail">
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={12}>
          <div className="bg-white space-between">
            <h4>E-Claim: {id ? id : "N/A"}</h4>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {Number(status) === 1 && (
                <Button
                  size="small"
                  sx={[
                    {
                      color: "var(--white)",
                      mr: 0.5,
                    },
                  ]}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (form_step) {
                      console.log("form step", form_step);
                      dispatch({
                        type: Utils.ActionName.GET_INSURANCE_COMPANY,
                        payload: {
                          patientInsurance: insurance_company_id,
                        },
                      });
                      dispatch({
                        type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
                        payload: {
                          draftedClaimId: id,
                        },
                      });
                      dispatch({
                        type: Utils.ActionName.CLAIM_DETAIL,
                        payload: {
                          id: id,
                        },
                      });
                      dispatch({
                        type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
                        payload: {
                          doctorDetail: {},
                          hospitalDetail: {},
                          finalDiagnosisDetail: [],
                          onlyRead: false,
                        },
                      });
                      dispatch({
                        type: Utils.ActionName.CLEAR_MEDICINE_DETAILS_LIST,
                      });
                      dispatch({
                        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                        payload: {
                          authTypeSelected: auth_type_selected,
                          entityId: entity_id,
                        },
                      });
                      if (Number(type_id) === 5) {
                        dispatch({
                          type: Utils.ActionName.GET_PATIENT_DATA,
                          payload: {
                            activeStep: Number(form_step),
                          },
                        });
                        // navigate(Utils.routes.createClaim, {
                        //   state: { edit: true },
                        // });
                        navigate(`${Utils.routes.editClaim}/${id}`, {
                          state: { edit: true },
                        });
                      } else if (Number(type_id) === 2) {
                        dispatch({
                          type: Utils.ActionName.CREATE_CLAIM_PRESCRIPTION,
                          payload: {
                            activeStep: Number(form_step),
                          },
                        });
                        navigate(
                          `${Utils.routes.createClaimPrescription}/${id}`,
                          {
                            state: { editDoctor: true },
                          }
                        );
                      } else {
                        Utils.showAlert(2, "Type id is not found");
                      }
                    } else {
                      Utils.showAlert(2, "Form step is not found");
                    }
                  }}

                  //   navigate(`${Utils.routes.editClaim}/${claimId}`);
                >
                  Edit
                </Button>
              )}
              <h4>Status: {checkStatus(Number(status))}</h4>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            sx={{ flexGrow: 1, alignItems: "stretch" }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <div className="bg-white h-100">
                <h3 className="mb-10">Patient Information</h3>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>Profile Picture</h4>
                    {user_avatar || beneficiary?.avatar ? (
                      <Avatar
                        src={
                          beneficiary?.entity_id === entity_id
                            ? Utils.constants.API_URL + user_avatar
                            : beneficiary?.avatar
                            ? Utils.constants.API_URL + beneficiary?.avatar
                            : ""
                        }
                        alt=""
                        sx={{
                          width: "110px",
                          height: "110px",
                          borderRadius: "10px",
                          backgroundColor: (theme) => theme.palette.grey[200],
                        }}
                      >
                        <PersonIcon
                          color="primary"
                          sx={{
                            fontSize: "100px",
                          }}
                        />
                      </Avatar>
                    ) : (
                      <Avatar
                        variant="square"
                        sx={{
                          width: "110px",
                          height: "110px",
                          backgroundColor: (theme) => theme.palette.grey[200],
                        }}
                      >
                        <PersonIcon
                          color="primary"
                          sx={{
                            fontSize: "100px",
                          }}
                        />
                      </Avatar>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                      <Grid item xs={12}>
                        <h4>Name of the Patient</h4>
                        <p>
                          {/* beneficiary?.entity_id === entity_id
                            ? user_name
                            : */}
                          {beneficiary?.beneficiary_name
                            ? beneficiary?.beneficiary_name
                            : "N/A"}
                        </p>
                      </Grid>

                      <Grid item xs={12}>
                        <h4>Gender</h4>
                        <p>
                          {beneficiary?.gender ? beneficiary?.gender : "Male"}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>Date of Birth</h4>
                    <p>
                      {beneficiary?.entity_id === entity_id
                        ? moment(user_date_birth).format("MMMM Do YYYY")
                        : beneficiary?.date_of_birth
                        ? moment(beneficiary?.date_of_birth).format(
                            "MMMM Do YYYY"
                          )
                        : "N/A"}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>{checkMemberNumberLabel()}</h4>
                    <p>{checkMemberNumber()}</p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>{checkPhoneLabel()}</h4>
                    <p>
                      {checkPhoneNumber() ? `+${checkPhoneNumber()}` : "N/A"}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>Employer Name</h4>
                    <p>
                      {beneficiary?.policy_holder_name
                        ? beneficiary?.policy_holder_name
                        : "N/A"}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>Benefit Name</h4>
                    <p>
                      {claimBenefitsDetails?.benefit_name ? claimBenefitsDetails?.benefit_name : "N/A"}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <h4>Sub Benefit Name</h4>
                    <p>
                      {claimBenefitsDetails?.sub_benefit_name
                        ? claimBenefitsDetails?.sub_benefit_name
                        : "N/A"}
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={12}>
                  <div className="bg-white" style={{ marginTop: "-10px" }}>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                      <Grid item xs={12} sm={6} md={6}>
                        <h3 className="mb-10">Insurance Company</h3>
                        <p>{insurance_name ? insurance_name : ""}</p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <img
                          src={
                            insurance_avatar
                              ? Utils.constants.API_URL + insurance_avatar
                              : LocalImages.DefaultImage
                          }
                          alt=""
                          style={{
                            width: "110px",
                            height: "110px",
                            borderRadius: "10px",
                          }}
                        />
                      </Grid>
                    </Grid>
                    {icd10_list?.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <h3>ICD10 code(s):</h3>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            mt: 0.3,
                          }}
                        >
                          {icd10_list.map((icd) => {
                            return (
                              <Box key={icd.code} sx={{ my: 0.5 }}>
                                <Chip
                                  sx={{ color: "var(--white)", ml: 0.5 }}
                                  color="primary"
                                  label={`${icd?.code} (${icd?.code_description})`}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="bg-white">
                    <h3 className="mb-10">Clinic Information</h3>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                      <Grid item xs={12} sm={6} md={6}>
                        <h4>Clinic Name</h4>
                        <p>{hospital_name ? hospital_name : "N/A"}</p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <h4>Clinic Phone Number</h4>
                        <p>{phone_list ? `+${phone_list}` : "N/A"}</p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="bg-white">
                    <h3 className="mb-10">Doctor Information</h3>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                      <Grid item xs={12} sm={6} md={6}>
                        <h4>Prescribing Doctor's Name</h4>
                        <p>{doctor_name ? doctor_name : "N/A"}</p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <h4>Doctor Phone Number</h4>
                        <p>{doctor_phone ? `+${doctor_phone}` : "N/A"}</p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="bg-white">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3 className="mb-10">Medical Information</h3>
              {order_data?.drugs && (
                <h3 className="mb-10">
                  Total Amount: {calculateTotalAmountDetails(order_data?.drugs)}{" "}
                  KES{" "}
                </h3>
              )}
            </Box>
            <MedicalInformation medicineList={order_data?.drugs} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="bg-white">
            <h3 className="mb-10">Documents</h3>
            <Box>
              <Typography>Prescription Photo</Typography>
              <Box sx={{ display: "flex" }}>
                {documents.prescription?.length > 0 ? (
                  documents.prescription.map((item: any, i) => (
                    <Box key={i} sx={{ m: 0.4 }}>
                      <Avatar
                        onClick={() => {
                          setPreviewImgUrl(Utils.constants.API_URL + item);
                          previewImgOpen();
                        }}
                        sx={{
                          width: (theme) => theme.spacing(10),
                          height: (theme) => theme.spacing(10),
                          cursor: "pointer",
                        }}
                        variant="square"
                        // className={classes.avatarDoc}
                        alt="Remy Sharp"
                        src={Utils.constants.API_URL + item}
                      />
                    </Box>
                  ))
                ) : (
                  <Typography>No Prescription photo available</Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Typography>Claim Photo</Typography>
              <Box sx={{ display: "flex" }}>
                {documents.claim?.length > 0 ? (
                  documents.claim.map((item: any, i) => (
                    <Box key={i} sx={{ m: 0.4 }}>
                      <Avatar
                        onClick={() => {
                          setPreviewImgUrl(Utils.constants.API_URL + item);
                          previewImgOpen();
                        }}
                        sx={{
                          width: (theme) => theme.spacing(10),
                          height: (theme) => theme.spacing(10),
                          cursor: "pointer",
                        }}
                        variant="square"
                        // className={classes.avatarDoc}
                        alt="Remy Sharp"
                        src={Utils.constants.API_URL + item}
                      />
                    </Box>
                  ))
                ) : (
                  <Typography>No claim photo available</Typography>
                )}
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>
      <PreviewImg
        open={openPreview}
        handleClose={handleClosePreview}
        image={previewImgUrl}
      />
    </div>
  );
}

export default ClaimDetail;
