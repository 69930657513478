import React, { useState } from "react";
import AWS from "aws-sdk";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_S3_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const uploadToS3 = async (file, fileName, type) => {
  return new Promise((resolve, reject) => {
    const params = {
      Key: fileName,
      Body: file,
      ACL: "public-read",
      ContentType: type,
    };

    myBucket.upload(params, (err, data) => {
      if (err) {
        console.log(err, "s3  error");
        reject(err);
      }
      resolve(data.Location);
    });
  });
};

export const deleteFromS3 = async (fileName) => {
  return new Promise((resolve, reject) => {
    const params = {
      Key: fileName,
    };
    myBucket.deleteObject(params, (err, data) => {
      if (err) {
        reject(err);
      }
      resolve(data);
    });
  });
};
