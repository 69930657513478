import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import ClearIcon from "@mui/icons-material/Clear";
import Utils from "../../../utils";
import { getMedicineDetails } from "../action";
import { useDispatch, useSelector } from "react-redux";
import { ReducersModal } from "../../../modal";

function MedicineTextField({ name, ...otherProps }: any) {
  const [value, setValue] = useState<any>();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [field, mata] = useField(name);
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const debouncedSearchTerm: string = useDebounce<string>(
    inputValue && inputValue,
    500
  );

  const { patientInsurance } = useSelector(
    (state: ReducersModal) => state.InsuranceCompanyReducer
  );

  const initialValuesFormik = useSelector(
    (state: ReducersModal) => state.addmidicineFormitModalReduce
  );

  useEffect(() => {
    let actives = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (inputValue.length >= 3) {
      let params = `?chemist_web_data_type=${"getList"}&insurance_company_id=${patientInsurance}&search=${debouncedSearchTerm}`;

      Utils.constants.getAccessToken();
      Utils.constants.axios
        .get(Utils.endPoints.medicineList + params)
        .then((response: any) => {
          if (response.status === 200) {
            // console.log(response?.data.body);
            const results: any = [...response?.data.body];
            if (actives) {
              let newOptions: any = [];

              if (value) {
                newOptions = [value];
              }

              if (results) {
                newOptions = [...newOptions, ...results];
              }

              setOptions(newOptions);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      actives = false;
    };
  }, [value, debouncedSearchTerm]);

  useEffect(() => {
    if (value) {
      setFieldValue(name, value.name);
      dispatch({
        type: Utils.ActionName.ADD_MEDICINE_FORMIK,
        payload: {
          ...initialValuesFormik,
          medicineName: value,
        },
      });
    } else {
      setFieldValue(name, "");
      dispatch({
        type: Utils.ActionName.ADD_MEDICINE_FORMIK,
        payload: {
          ...initialValuesFormik,
          medicineName: "",
        },
      });
    }
  }, [value]);

  const handleChange = (event: any, newValue: any) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
  };

  const onHighlightChange = async (event: any, newInputValue: any) => {
    if (newInputValue && newInputValue.id) {
      setOptions(newInputValue ? [newInputValue, ...options] : options);
      setValue(newInputValue);
      dispatch(getMedicineDetails(newInputValue.id));
    }
  };

  const configTextfield = {
    ...field,
    ...otherProps,
    onChange: handleChange,
  };
  return (
    <Autocomplete
      id="autocompleteLocations"
      {...configTextfield}
      fullWidth
      loading={
        options.length > 0 ? false : inputValue.length >= 3 ? true : false
      }
      loadingText="Medicine loading..."
      clearIcon={<ClearIcon color="primary" fontSize="small" />}
      options={options}
      getOptionLabel={(option: any) => option.name}
      autoHighlight={true}
      autoSelect={true}
      clearOnEscape
      value={
        value
          ? value
          : initialValuesFormik?.medicineName
          ? initialValuesFormik?.medicineName
          : null
      }
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={onHighlightChange}
      renderOption={(props, option: any) => {
        return (
          <li {...props} key={Math.random()}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...configTextfield}
          {...params}
          error={Boolean(mata && mata.touched && mata.error)}
          helperText={mata && mata.touched && mata.error}
          fullWidth
        />
      )}
    />
  );
}

export default MedicineTextField;
