import { Box } from "@mui/system";
import { Formik, Form } from "formik";

import { MenuItem, Avatar, useTheme} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { LocalImages } from "../../../utils/images";
import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button";
import CustomButton from "../../../components/formsUi/button/customButton";
// import Buttons from "../../../components/formsUi/reusableButton";
import Textfield from "../../../components/formsUi/textFiled";
import Select from "../../../components/formsUi/select/customSelect";
import { useSelector, useDispatch } from "react-redux";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";
import Schema from "../../../schema";
import { onSubmit } from "../action";
import { useNavigate } from "react-router-dom";

import Utils from "../../../utils";
import { ReducersModal } from "../../../modal";
import { useEffect } from "react";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    leftScreen: {
      maxWidth: "400px",
      margin: "auto",
    },
    phoneText: {
      display: "flex",
      justifyContent: "space-between",
      // backgroundColor:"yellow",
      marginTop: "16px",
      width: "100%",
    },
    countryCode: {
      width: "90px",
    },
    phoneNumber: {
      marginLeft: "8px",
      width: "70%",
    },
    flexBox: {
      display: "flex",
      alignItems: 'center',
      "& .MuiAvatar-root": {
        height: "15px",
        width: "20px",
      },
    },
    marginFlag: {
      marginLeft: "10px",
      fontSize: "13px",
    },
  })
);



function LeftScreen() {
  const theme = useTheme();
  const classes = useStyles({ theme });
 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { countryData } = useSelector(
    (state: ReducersModal) => state.countryReducer
  );


  const { countryCode,countryCodeLength,phoneNumber } = useSelector(
    (state: ReducersModal) => state.signInReducer
  );

  const INITIAL_VALUE = {
    phoneNumber: phoneNumber,
    countryCode: countryCode,
  };

  useEffect(()=>{

    if( countryData!==undefined && countryData.length>0 && countryCode!==undefined){
      const codeLength: any = countryData.find((a: any) => a.phone_country_code === countryCode); 
     dispatch({
       type:Utils.ActionName.SIGN_IN,
       payload:{
        countryCodeLength: Number(codeLength.digits_phone_number)
       }
     })
    }
  },[countryCode,dispatch,countryData])

  return (
    <Box className={classes.leftScreen}>
      <Box className="mb-20">
        <img src={LocalImages.Logo} alt="logo" />
      </Box>
      <h2 className="color-blue mb-5">Welcome to Livia</h2>
      <p className="color-text mb-30">Please enter your Mobile Number</p>
      <Formik
      enableReinitialize
        initialValues={{
          ...INITIAL_VALUE,
        }}
        validationSchema={Schema.LoginSchema(countryCodeLength)}
        onSubmit={(values, { setSubmitting }) => {
          // if(signUp){
          //   const { phoneNumber, countryCode } = values;
          //   localStorage.setItem("phone", phoneNumber);
          //   localStorage.setItem("countryCode", countryCode);
          //     navigate(Utils.routes.otp)
          // } else{
            dispatch(onSubmit(values, setSubmitting, navigate));
          // }
         
        }}
      >
        {({ isSubmitting,setFieldValue }) => (
          <Form>
            {/* {setFieldValue("countryCode",countryCode)} */}
            <Grid container spacing={2} className="mb-30" sx={{ flexGrow: 1 }}>
              <Grid item xs={5} sm={5} md={4}>
                <Select
                  className="w-100"
                  name="countryCode"
                  label="Code"
                  size="small"
                  value={countryCode}
                  options={countryData.map((option: any, index: any) => (
                    <MenuItem key={index} value={option.phone_country_code}>
                      <div className={classes.flexBox}>
                        <Avatar
                          src={option.flag}
                          variant="square"
                        >
                          <ReactCountryFlag
                          svg
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          title={option.country_code}
                          countryCode={option.country_code}
                        />
                        </Avatar>

                        <div className={classes.marginFlag}>
                         {"+"}{option.phone_country_code}
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={7} sm={7} md={8}>
                <Textfield
                  name="phoneNumber"
                  label="Mobile number"
                  size="small"
                  type="tel"
                  inputProps={{
                    maxLength: countryCodeLength,
                  }}
                  
                />
              </Grid>
            </Grid>
            <CustomButton
              isSubmitting={isSubmitting}
              className="w-100 semi-bold capitalize color-white"
            >
              Submit
            </CustomButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default LeftScreen;
