import { Box, Modal, Theme, Card, CardMedia } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import IconButton from "@mui/material/IconButton";

type PreviewImgTypes = {
  open: boolean;
  image: string;
  handleClose?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      //   width: "500px",
      backgroundColor: "var(--white)",
      boxShadow: "24",
      padding: theme.spacing(0.5),
      textAlign: "center",
      maxHeight: "98vh",
    },
  })
);

function PreviewImg({ open, handleClose, image }: PreviewImgTypes) {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modalStyle}>
        <Box sx={{ position: "relative" }}>
          <CloseIcon
            onClick={handleClose}
            fontSize="medium"
            sx={{
              position: "absolute",
              backgroundColor: (theme) => theme.palette.primary.main,
              top: 0,
              right: 0,
              cursor: "pointer",
              zIndex: 1,
            }}
          />
          {/* <Card sx={{ height: "100%" }}> */}
          {/* <CardMedia
              component="img"
              sx={{ maxHeight: "95vh" }}
              image={image}
              alt="Paella dish"
            /> */}
          <Box sx={{ position: "relative" }}>
            <TransformWrapper
            // initialScale={1}
            // initialPositionX={200}
            // initialPositionY={100}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
                return (
                  <Fragment>
                    <Box>
                      <IconButton
                        sx={{ py: 0 }}
                        color="primary"
                        size="small"
                        onClick={() => zoomIn()}
                      >
                        <AddIcon />
                      </IconButton>
                      <IconButton
                        sx={{ py: 0 }}
                        color="primary"
                        size="small"
                        onClick={() => zoomOut()}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <IconButton
                        sx={{ py: 0 }}
                        color="primary"
                        size="small"
                        onClick={() => resetTransform()}
                      >
                        <RotateLeftIcon />
                      </IconButton>
                    </Box>
                    <Box>
                      <TransformComponent>
                        <img
                          src={image}
                          alt="test"
                          style={{
                            maxWidth: "100vh",
                            maxHeight: "89vh",
                            objectFit: "cover",
                          }}
                        />
                      </TransformComponent>
                    </Box>
                  </Fragment>
                );
              }}
            </TransformWrapper>
          </Box>
          {/* </Card> */}
        </Box>
      </Box>
    </Modal>
  );
}

export default PreviewImg;
