import { Box, Modal, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

type ModalWrapperTypes = {
  open: boolean;
  children: React.ReactNode;
  handleClose?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      backgroundColor: "var(--white)",
      boxShadow: "24",
      padding: theme.spacing(2),
      textAlign: "center",
    },
  })
);

function ModalWrapper({ open, handleClose, children }: ModalWrapperTypes) {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modalStyle}>{children}</Box>
    </Modal>
  );
}

export default ModalWrapper;
