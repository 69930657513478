import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { createStyles, makeStyles } from "@mui/styles";

import { getRoleIdFromLS } from "../../utils/commonFunctions";
import TemplateManagement from "./templateManagement";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: "100%",
      typography: "body1",
    },
    tabLabel: {
      textTransform: "none",
      color: "black",
      "& .css-mgilup-MuiButtonBase-root-MuiTab-root.Mui-selected": {
        fontWeight: 550,
        backgroundColor: "red",
      },
    },
  })
);

function WebsiteManagement() {
  const classes = useStyles();
  const [value, setValue] = useState("1");

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box className="page-my-profile">
      <TabContext value={value}>
        <TabList onChange={handleChange} className="bg-white p-0 mb-20">
          <Tab
            label="Template Management"
            className={classes.tabLabel}
            value="1"
          />
        </TabList>
        <TabPanel value="1" sx={{ padding: "0px" }}>
          <TemplateManagement />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default WebsiteManagement;
