import Utils from "../../utils";
import { generatePhoneId } from "../../utils/commonFunctions";
/**
 * action to get Country list
 */
export const getCountry = () => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      //check if user is online or not
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }

    const { limit, page, search } = getState().countryReducer;
    let data = `?limit=${limit}&page=${page}${search.length > 0 ? `&search=${search}` : ""}`;

    Utils.api.getApiCall(
      Utils.endPoints.settings,
      data,
      (respData: any) => {
        let { data } = respData;

        dispatch({
          type: Utils.ActionName.UPDATE_COUNTRY_VAL,
          payload: {
            countryData: data.list_of_countries,
            currencyData: data.list_of_currency,
          },
        });
      },
      (error: any) => {
        let { data } = error;

        Utils.showAlert(2, data?.message);
      }
    );
  };
};

export const onSubmit = (values: any, setSubmitting: any, navigate: any) => {
  return (dispatch: Function) => {
    const { phoneNumber, countryCode } = values;
    const phoneId = generatePhoneId();
    const dataToSend = {
      os_type: 3,
      phone_code: countryCode,
      phone_id: phoneId,
      phone_number: phoneNumber,
      user_role: 3,
      full_phone_number: `${countryCode}${phoneNumber}`,
    };
    Utils.api.postApiCall(
      Utils.endPoints.login,
      dataToSend,
      (respData: any) => {
        let { data } = respData;
        localStorage.setItem("phone", phoneNumber);
        localStorage.setItem("countryCode", countryCode);
        localStorage.setItem("phoneId", phoneId);
        dispatch({
          type: Utils.ActionName.SIGN_IN,
          payload: {
            countryCode: countryCode,
            phoneNumber: phoneNumber,
            userName: data?.user_name,
            userStatus: data?.user_status,
          },
        });

        if (data.user_status.toString() === Utils.constants.user_status.IN_REGISTRATION_PROCESS) {
          dispatch({
            type: Utils.ActionName.SIGN_IN,
            payload: { signUp: true },
          });
          navigate(Utils.routes.otp);
        } else if (data.user_status.toString() === Utils.constants.user_status.ACTIVE) {
          navigate(Utils.routes.otp);
        } else if (data.user_status.toString() === Utils.constants.user_status.BAN) {
          Utils.showAlert(2, "Account Ban");
        } else if (data.user_status.toString() === Utils.constants.user_status.MODERATION) {
          Utils.showAlert(2, "Your account is under verification process. Please contact to Livia.");
        } else if (data.user_status.toString() === Utils.constants.user_status.NOT_ACTIVE) {
          Utils.showAlert(2, "Account not Active");
        } else if (data.user_status.toString() === Utils.constants.user_status.REFUSAL_TO_REGISTRATION) {
          Utils.showAlert(2, "Refuse for registration");
        }
        setSubmitting(false);
      },
      (error: any) => {
        setSubmitting(false);
        let { data } = error;

        Utils.showAlert(2, data?.messages[0]);
        // setSubmitting(true);
      }
    );
  };
};

export const resendOtpPhone = (event: any) => {
  return (dispatch: Function, getState: Function) => {
    const { phoneNumber, countryCode } = getState().signInReducer;
    const dataToSend = {
      os_type: 1,
      phone_code: countryCode,
      phone_id: localStorage.getItem("phoneId"),
      phone_number: phoneNumber,
      user_role: 3,
      full_phone_number: `${countryCode}${phoneNumber}`,
    };
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { isLoading: true },
    });
    Utils.api.postApiCall(
      Utils.endPoints.login,
      dataToSend,
      () => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        dispatch({
          type: Utils.ActionName.SIGN_IN,
          payload: { enableResend: true, starts: 60 },
        });
        // let { data } = respData;
        // localStorage.setItem("phone", phoneNumber);
        // localStorage.setItem("countryCode", countryCode);
        // dispatch({
        //   type: Utils.ActionName.SIGN_IN,
        //   payload: { countryCode: countryCode,phoneNumber: phoneNumber},
        // });

        // navigate(Utils.routes.otp);
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        let { data } = error;
        dispatch({
          type: Utils.ActionName.SIGN_IN,
          payload: { enableResend: true, starts: 60 },
        });
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const onSubmitOtp = (value: any, navigate: any) => {
  return (dispatch: Function, getState: Function) => {
    const { phoneNumber, countryCode, signUp } = getState().signInReducer;

    const dataToSend = {
      app_version: "0.150.5069",
      auth_code: value,
      os_type: 1,
      phone_code: countryCode,
      phone_id: localStorage.getItem("phoneId"),
      phone_number: phoneNumber,
      user_role: 3,
      full_phone_number: `${countryCode}${phoneNumber}`,
    };

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { isLoading: true },
    });
    Utils.api.putApiCall(
      Utils.endPoints.login,
      dataToSend,
      (respData: any) => {
        let { data } = respData;
        console.log(" login dta", data);
        localStorage.setItem("accessToken", data.access_token);
        localStorage.setItem("refreshToken", data.refresh_token);
        localStorage.setItem("user_status", data.user_status);
        localStorage.setItem("userId", data.id);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        if (signUp) {
          navigate(Utils.routes.signUp);
        } else {
          navigate(Utils.routes.dashboard);
        }
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
        // if (signUp) {
        //   navigate(Utils.routes.signUp);
        // } else {
        //   navigate(Utils.routes.dashboard);
        // }
      }
    );
  };
};
