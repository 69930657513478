const createProfile = {
  // *test purpose
  // profilePic: "/images/users/620271e98069b1644327401.png",
  // pharmacyName: "",
  // surname: "Dr.",
  // pharmacyAdminName: "",
  // searchPharmacyCity: "",
  // emailId: "",
  // practiceCertificate: "/images/users/620271e98069b1644327401.png",
  // licensePharmacy: "/images/users/620271e98069b1644327401.png",
  // businessPermit: "/images/users/620271e98069b1644327401.png",
  // pinCertificate: "/images/users/620271e98069b1644327401.png",
  // crDocument: "/images/users/620271e98069b1644327401.png",
  // taxCompliance: "/images/users/620271e98069b1644327401.png",
  // physical_address: "",
  // latitude: "",
  // longitude: "",

  // *real use
  profilePic: "",
  pharmacyName: "",
  surname: "Dr.",
  pharmacyAdminName: "",
  searchPharmacyCity: "",
  emailId: "",
  practiceCertificate: "",
  practice_certificate_expiry: "",
  licensePharmacy: "",
  license_of_pharmacy_expiry: "",
  businessPermit: "",
  business_permit_expiry: "",
  pinCertificate: "",
  crDocument: "",
  taxCompliance: "",
  tax_compliance_expiry: "",
  physical_address: "",
  latitude: "",
  longitude: "",
  termConditions: false,
  checkAge: false,
  domain: "",
};

const selecteLocation = {
  physical_address: "",
  latitude: "",
  longitude: "",
};

const workTime = {
  // mon
  workingSwitchNameMon: true,
  workingTimeNameToMon: "",
  workingTimeNameFromMon: "",
  lunchSwitchNameMon: true,
  lunchTimeNameToMon: "",
  lunchTimeNameFromMon: "",
  // tue
  workingSwitchNameTue: true,
  workingTimeNameToTue: "",
  workingTimeNameFromTue: "",
  lunchSwitchNameTue: true,
  lunchTimeNameToTue: "",
  lunchTimeNameFromTue: "",
  // wed
  workingSwitchNameWed: true,
  workingTimeNameToWed: "",
  workingTimeNameFromWed: "",
  lunchSwitchNameWed: true,
  lunchTimeNameToWed: "",
  lunchTimeNameFromWed: "",
  // thu
  workingSwitchNameThu: true,
  workingTimeNameToThu: "",
  workingTimeNameFromThu: "",
  lunchSwitchNameThu: true,
  lunchTimeNameToThu: "",
  lunchTimeNameFromThu: "",
  // fri
  workingSwitchNameFri: true,
  workingTimeNameToFri: "",
  workingTimeNameFromFri: "",
  lunchSwitchNameFri: true,
  lunchTimeNameToFri: "",
  lunchTimeNameFromFri: "",
  // sat
  workingSwitchNameSat: true,
  workingTimeNameToSat: "",
  workingTimeNameFromSat: "",
  lunchSwitchNameSat: true,
  lunchTimeNameToSat: "",
  lunchTimeNameFromSat: "",
  // sun
  workingSwitchNameSun: true,
  workingTimeNameToSun: "",
  workingTimeNameFromSun: "",
  lunchSwitchNameSun: true,
  lunchTimeNameToSun: "",
  lunchTimeNameFromSun: "",
};

const addMedicine = {
  medicineName: "",
  formulation: "",
  dosage: "",
  frequency: "",
  duration: "",
  foodInstractions: "",
  supplyfrequency: "",
  repetitionCycle: "",
  totalQuantity: "",
  pricePerUnit: "",
  totalPrice: "",
  unqiueId: "",
};

const transcribePrescription = {
  doctor: "",
  docphone: "",
  hospital: "",
  hosphone: "",
  finalDiagnosis: "",
  checked: false,
};
const transcribe = {
  doctor: "",
  docphone: "",
  hospital: "",
  hosphone: "",
  finalDiagnosis: "",
  checked: false,
};

const optionsSurname: any = { Dr: "Dr.", Mr: "Mr.", Ms: "Ms." };
const optionsPhoneCode: any = { 254: "254", 375: "375", 1: "1" };

// const addDoctorDetails = {
//   country: "Kenya",
//   city_id: "",
//   phone_code: "254",
//   phone_number: "",
//   name_prefix: "Mr.",
//   first_name: "",
//   last_name: "",
// };

const addHospitalDetails = {
  country: "Kenya",
  city_id: "",
  phone_code: "254",
  phone_number: "",
  hospital_name: "",
  address: "",
  latitude: "",
  longitude: "",
};

const bannerManagement = {
  bgImg: "",
  bgHeading: "",
  bgDiscription: "",
  bgColor: "#0d0d0d",
  bgHeadingColor: "#0d0d0d",
  bgSubheadingColor: "#0d0d0d",
  headingFontSize: "1px",
  subheadingFontSize: "2px",
  logoImg: "",
};

const FormField = {
  createProfile,
  workTime,
  selecteLocation,
  addMedicine,
  transcribePrescription,
  optionsSurname,
  // addDoctorDetails,
  optionsPhoneCode,
  addHospitalDetails,
  transcribe,
  bannerManagement,
};

const createFontSizeKeyValue = (val: number) => {
  return {
    key: `${val}px`,
    value: `${val}px`,
  };
};

const generateArr = (item: number) => {
  let arr = [];
  for (let i = 1; i <= item; i++) {
    arr.push(createFontSizeKeyValue(i));
  }
  return arr;
};

export const fontSizeData = generateArr(100);

export const formulation = [
  { key: "Tablet", value: "1", text: "Tablet" },
  { key: "Capsule", value: "2", text: "Capsule" },
  { key: "Vial", value: "3", text: "Vial" },
  // { key: "Teaspoon", value: "4", text: "Teaspoon" },
  { key: "Drop", value: "5", text: "Drop" },
  { key: "Lotion", value: "6", text: "Lotion application" },
  { key: "Gel", value: "7", text: "Gel application" },
  { key: "Spray", value: "8", text: "Spray" },
  { key: "Cream", value: "9", text: "Cream application" },
  { key: "Ointment", value: "10", text: "Ointment application" },
  // { key: "Tablespoon", value: "11", text: "Tablespoon" },
  { key: "Suppository", value: "12", text: "Suppository" },
  { key: "Mouth rinse", value: "13", text: "Mouth rinse" },
  { key: "Shampoo", value: "14", text: "Shampoo application" },
  { key: "Puff", value: "15", text: "Puff" },
  { key: "Lozenge", value: "16", text: "Lozenge" },
  { key: "Sachet", value: "17", text: "Sachet" },
  { key: "Gram", value: "18", text: "Gram" },
  { key: "Mls", value: "19", text: "Mls" },
  { key: "International Units (IU)", value: "20", text: "International Units (IU)" },
  { key: "Strip", value: "21", text: "Strip" },
  { key: "Piece", value: "22", text: "Piece" },
  { key: "Milligram", value: "23", text: "Milligram" },
];

export const frequency = [
  { key: "1", value: "1", text: "Once a day" },
  { key: "2", value: "2", text: "Twice a day" },
  { key: "3", value: "3", text: "Thrice a day" },
  { key: "4", value: "4", text: "Four times a day" },
  { key: "5", value: "5", text: "Once a week" },
  { key: "6", value: "6", text: "Once a month" },
];

export const duration = [
  { key: "1", value: "1", text: "1 day" },
  { key: "2", value: "2", text: "2 days" },
  { key: "3", value: "3", text: "3 days" },
  { key: "4", value: "4", text: "4 days" },
  { key: "5", value: "5", text: "5 days" },
  { key: "6", value: "6", text: "6 days" },
  { key: "7", value: "7", text: "7 days" },
  { key: "8", value: "8", text: "8 days" },
  { key: "9", value: "9", text: "9 days" },
  { key: "10", value: "10", text: "10 days" },
  { key: "11", value: "11", text: "11 days" },
  { key: "12", value: "12", text: "12 days" },
  { key: "13", value: "13", text: "13 days" },
  { key: "14", value: "14", text: "14 days" },
  { key: "15", value: "15", text: "15 days" },
  { key: "16", value: "16", text: "16 days" },
  { key: "17", value: "17", text: "17 days" },
  { key: "18", value: "18", text: "18 days" },
  { key: "19", value: "19", text: "19 days" },
  { key: "20", value: "20", text: "20 days" },
  { key: "21", value: "21", text: "21 days" },
  { key: "22", value: "22", text: "22 days" },
  { key: "23", value: "23", text: "23 days" },
  { key: "24", value: "24", text: "24 days" },
  { key: "25", value: "25", text: "25 days" },
  { key: "26", value: "26", text: "26 days" },
  { key: "27", value: "27", text: "27 days" },
  { key: "28", value: "28", text: "28 days" },
  { key: "29", value: "29", text: "29 days" },
  { key: "30", value: "30", text: "30 days" },
  { key: "31", value: "31", text: "31 day" },
  { key: "32", value: "32", text: "32 days" },
  { key: "33", value: "33", text: "33 days" },
  { key: "34", value: "34", text: "34 days" },
  { key: "35", value: "35", text: "35 days" },
  { key: "36", value: "36", text: "36 days" },
  { key: "37", value: "37", text: "37 days" },
  { key: "38", value: "38", text: "38 days" },
  { key: "39", value: "39", text: "39 days" },
  { key: "40", value: "40", text: "40 days" },
  { key: "41", value: "41", text: "41 days" },
  { key: "42", value: "42", text: "42 days" },
  { key: "43", value: "43", text: "43 days" },
  { key: "44", value: "44", text: "44 days" },
  { key: "45", value: "45", text: "45 days" },
  { key: "46", value: "46", text: "46 days" },
  { key: "47", value: "47", text: "47 days" },
  { key: "48", value: "48", text: "48 days" },
  { key: "49", value: "49", text: "49 days" },
  { key: "50", value: "50", text: "50 days" },
  { key: "51", value: "51", text: "51 days" },
  { key: "52", value: "52", text: "52 days" },
  { key: "53", value: "53", text: "53 days" },
  { key: "54", value: "54", text: "54 days" },
  { key: "55", value: "55", text: "55 days" },
  { key: "56", value: "56", text: "56 days" },
  { key: "57", value: "57", text: "57 days" },
  { key: "58", value: "58", text: "58 days" },
  { key: "59", value: "59", text: "59 days" },
  { key: "60", value: "60", text: "60 days" },
  //till 90 days
  { key: "61", value: "61", text: "61 days" },
  { key: "62", value: "62", text: "62 days" },
  { key: "63", value: "63", text: "63 days" },
  { key: "64", value: "64", text: "64 days" },
  { key: "65", value: "65", text: "65 days" },
  { key: "66", value: "66", text: "66 days" },
  { key: "67", value: "67", text: "67 days" },
  { key: "68", value: "68", text: "68 days" },
  { key: "69", value: "69", text: "69 days" },
  { key: "70", value: "70", text: "70 days" },
  { key: "71", value: "71", text: "71 days" },
  { key: "72", value: "72", text: "72 days" },
  { key: "73", value: "73", text: "73 days" },
  { key: "74", value: "74", text: "74 days" },
  { key: "75", value: "75", text: "75 days" },
  { key: "76", value: "76", text: "76 days" },
  { key: "77", value: "77", text: "77 days" },
  { key: "78", value: "78", text: "78 days" },
  { key: "79", value: "79", text: "79 days" },
  { key: "80", value: "80", text: "80 days" },
  { key: "81", value: "81", text: "81 days" },
  { key: "82", value: "82", text: "82 days" },
  { key: "83", value: "83", text: "83 days" },
  { key: "84", value: "84", text: "84 days" },
  { key: "85", value: "85", text: "85 days" },
  { key: "86", value: "86", text: "86 days" },
  { key: "87", value: "87", text: "87 days" },
  { key: "88", value: "88", text: "88 days" },
  { key: "89", value: "89", text: "89 days" },
  { key: "90", value: "90", text: "90 days" },

];
export const food = [
  { key: "No food instruction", value: "1", text: "No food instruction" },
  { key: "Before food", value: "2", text: "Before food" },
  { key: "With food", value: "3", text: "With food" },
  { key: "After food", value: "4", text: "After food" },
];

export const supplyfrequency = [
  { key: "1", value: "1", text: "Every 2 weeks" },
  { key: "2", value: "2", text: "Every 4 weeks" },
  { key: "3", value: "3", text: "Every 30 days" },
  { key: "4", value: "4", text: "One time only" },
];

export const repetitionCycleOptions = [
  { key: "1", value: "1", text: "Once" },
  { key: "2", value: "2", text: "Twice" },
  { key: "3", value: "3", text: "Thrice" },
  { key: "4", value: "4", text: "4 times" },
  { key: "5", value: "5", text: "5 times" },
  { key: "6", value: "6", text: "6 times" },
  { key: "7", value: "7", text: "7 times" },
  { key: "8", value: "8", text: "8 times" },
  { key: "9", value: "9", text: "9 times" },
  { key: "10", value: "10", text: "10 times" },
  { key: "11", value: "11", text: "11 times" },
  { key: "12", value: "12", text: "12 times" },
];

export const drugRouteadmin = [
  { key: "p.o (per oral)", value: "1", text: "p.o (per oral)" },
  { key: "i.v (intravenous)", value: "2", text: "i.v (intravenous)" },
  { key: "p.r (per rectal)", value: "3", text: "p.r (per rectal)" },
  { key: "p.v (per vaginal)", value: "4", text: "p.v (per vaginal)" },
  { key: "i.m (intramuscular)", value: "5", text: "i.m (intramuscular)" },
  { key: "s.c (subcutaneous)", value: "6", text: "s.c (subcutaneous)" },
  {
    key: "topical (applied to skin)",
    value: "7",
    text: "topical (applied to skin)",
  },
  {
    key: "ophthalmic (eye drops)",
    value: "8",
    text: "ophthalmic (eye drops)",
  },
  { key: "otic (ear drops)", value: "9", text: "otic (ear drops)" },
  {
    key: "sublingual (held under the tongue)",
    value: "10",
    text: "sublingual (held under the tongue)",
  },
  {
    key: "transdermal (skin patch)",
    value: "11",
    text: "transdermal (skin patch)",
  },
  { key: "inhalation", value: "12", text: "inhalation" },
  {
    key: "nasal (given into nose)",
    value: "13",
    text: "nasal (given into nose)",
  },
];

export default FormField;
