import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
} from "@mui/material";
import { useEffect, useState } from "react";
// import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { ReducersModal } from "../../modal";
import Utils from "../../utils";
import { getDrugFormulary } from "./action";
// import { useNavigate } from "react-router-dom";

function TableComponent() {
  // const classes = useStyle();
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const { data1, limit, count, page } = useSelector(
    (state: ReducersModal) => state.DrugFormularyReducer
  );

  const handleChangePage = (value: any) => {
    dispatch({
      type: Utils.ActionName.DRUG_FORMULARY,
      payload: {
        page: value + 1,
      },
    });
    // setPage(value + 1);
  };
  useEffect(() => {
    dispatch({
      type: Utils.ActionName.DRUG_FORMULARY,
      payload: {
        claim_start_date: "",
        claim_end_date: "",
        insurance_company_id: "",
        status: "",
        search: "",
        page: 1,
      },
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.DRUG_FORMULARY,
      payload: {
        offset: page * limit - limit,
      },
    });
    dispatch(getDrugFormulary());
  }, [page]);

  const handleChangeRowsPerPage = (evt: any) => {
    dispatch({
      type: Utils.ActionName.DRUG_FORMULARY,
      payload: {
        limit: +evt.target.value,
        offset: 1,
      },
    });
    dispatch(getDrugFormulary());
    // setRowsPerPage(10)
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Drug ID</TableCell>
              <TableCell>Brand Name</TableCell>
              <TableCell>API</TableCell>
              <TableCell>API Strength</TableCell>
              <TableCell>Insurance Company</TableCell>
              <TableCell>Manufacturer Company</TableCell>
              <TableCell>Local Agent (MAH)</TableCell>
              <TableCell>Livia Price</TableCell>
              <TableCell>Quantity Measuring</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data1?.length > 0 ? (
              data1.map((item: any, index: any) => (
                // return (
                <TableRow
                  key={`${index}${item.id}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{item.id ? item.id : "N/A"}</TableCell>
                  <TableCell>
                    {item.brand_name ? item.brand_name : "N/A"}
                  </TableCell>
                  <TableCell>{item.name ? item.name : "N/A"}</TableCell>
                  <TableCell>
                    {item.dosage_units ? item.dosage_units : "N/A"}
                  </TableCell>
                  <TableCell>
                    {item.insurance_company_name
                      ? item.insurance_company_name
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {item.manufacturer_company
                      ? item.manufacturer_company
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {item.local_agent ? item.local_agent : "N/A"}
                  </TableCell>
                  <TableCell>
                    {item.pharmacy_price ? item.pharmacy_price : "N/A"}
                  </TableCell>
                  <TableCell>
                    {item.quantity_measuring ? item.quantity_measuring : "N/A"}
                  </TableCell>
                </TableRow>
                // );
              ))
            ) : (
              <TableRow
                key={1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {" "}
                <TableCell>{"No Drug Found"}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {data1?.length > 0 && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={count}
              rowsPerPage={limit}
              page={page - 1}
              onPageChange={(e, value): any => handleChangePage(value)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={<span>Rows:</span>}
              sx={{
                ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
                  fontWeight: "bold",
                  color: "primary",
                },
              }}
            />
          </TableRow>
        </TableFooter>
      )}
    </>
  );
}

export default TableComponent;
