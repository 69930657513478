import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#D2EFE3",
      main: "#53c392",
    },
    secondary: {
      light: "#f5f6f9",
      main: "#152567",
    },
  },
  spacing: 10,
});

export default theme;
