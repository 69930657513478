import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useField, useFormikContext } from "formik";

function SwitchWrapper({ name, ...otherProps }: any) {
  const [field, mata] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (e: any) => {
    setFieldValue(name, e.target.checked);
  };

  const configTextfield = {
    ...field,
    ...otherProps,
    onChange: handleChange,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }
  return (
    <FormControl component="fieldset">
      <FormControlLabel
        sx={{ ml: 0, fontWeight: "800" }}
        {...configTextfield}
        control={
          <Switch {...configTextfield} checked={mata.value} color="primary" />
        }
        labelPlacement="start"
      />
    </FormControl>
  );
}
export default SwitchWrapper;
