import TextField from "@mui/material/TextField";
import { useField, useFormikContext } from "formik";
import Utils from "../../../utils";
import { useDispatch } from "react-redux";
const TextfieldWrapper = ({ name, extraProp, ...otherProps }: any) => {
  const dispatch = useDispatch();
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (evt: any) => {
    const { value } = evt.target;
    if (extraProp?.check && name === "dosage") {
      extraProp.setDosageState(value);
    }
    if (extraProp?.check && name === "totalQuantity") {
      extraProp.setTotalQuantity(value);
    }

    if (name === "search") {
      otherProps.setInitialValue({
        ...otherProps.initialValue,
        phoneNumber: "",
        termsConditionChecked: false,
      });
      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          search: value,
          patientFamilyMemberData: [],
          patientInsuranceMember: [],
          insuranceMemberOptionSelect: false,
          patientInsuranceMemberDataList: [],
          checked: false,
          insurance_member_dependents: [],
          familyMemberPhoneNumber: "",
          termsConditionChecked: false,
          userPrincipalId: "",
          card1Added: false,
          card2Added: false,
          card3Added: false,
          card4Added: false,
          policyStatus: "",
          entityId: "",
          employeeNumber: "",
        },
      });
    }
    if (name === "phoneNumber") {
      console.log(value);
      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          phoneNumber: value !== null && value !== "" ? value.match(/\d+/g).join("") : value,
        },
      });
    }
    if (name === "principalPhoneNumber") {
      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          // principalPhoneNumber: value,
          principalPhoneNumber: value !== null && value !== "" ? value.match(/\d+/g).join("") : value,
          checked: false,
        },
      });
    }
    if (name === "phoneNumber" && value !== null && value !== "") {
      setFieldValue(name, value.match(/\d+/g).join(""));
    } else if (name === "principalPhoneNumber" && value !== null && value !== "") {
      setFieldValue(name, value.match(/\d+/g).join(""));
    } else {
      setFieldValue(name, value);
    }
  };

  const configTextfield = {
    ...field,
    ...otherProps,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  return (
    <TextField
      // type={name === "phoneNumber" ? "number" : "text"}
      {...configTextfield}
    />
  );
};

export default TextfieldWrapper;
