import { LoadingModal } from "../modal";
import Utils from "../utils";

/**
 * Global Loading reducer
 */
export const globalLoaderReducer = (
  state: LoadingModal = new LoadingModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.LOADING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const globalLoaderTrue = () => {
  return {
    type: Utils.ActionName.LOADING,
    payload: { isLoading: true },
  };
};

export const globalLoaderFalse = () => {
  return {
    type: Utils.ActionName.LOADING,
    payload: { isLoading: false },
  };
};
