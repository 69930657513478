import { styled } from '@mui/material/styles';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import PeopleIcon from '@mui/icons-material/People';
import { fontSize } from '@mui/system';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      width: 250,
      height: 45,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
    },
    value: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '16px',
      width: 250,
      height: 45,
      fontWeight: 'bold',
    },
    text: {
    fontWeight: 'bold',
    fontSize: "600px",
    },
    icon: {
      width: 30,
      height: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
    },
    red: {
      backgroundColor: 'var(--bright-red)',
    },
    lightRed: {
      backgroundColor: 'var(--bright-red-mini)',
    },
    blue: {
      backgroundColor: 'var(--bright-purple)',
    },
    lightBlue: {
      backgroundColor: 'var(--bright-purple-mini)',
    },
    persian: {
      backgroundColor: 'var(--persian-green)',
    },
    lightPersian: {
      backgroundColor: 'var(--persian-green-mini)',
    },
    greenish: {
      backgroundColor: 'var(--greenish-brown)',
    },
    lightGreenish: {
      backgroundColor: 'var(--greenish-brown-mini)',
    },
  })
);

const StyledCard = styled((props) => <Card {...props} />)(({ theme }) => ({
  maxWidth: 250,
  margin: '.5em',
  borderRadius: '10px',
}));

const CardComponent = ({ title, content, value, color }: any) => {
  const classes = useStyles();

  const getColor = (color: string): string => {
    if (color === 'red') {
      return classes.red;
    } else if (color === 'blue') {
      return classes.blue;
    } else if (color === 'persian') {
      return classes.persian;
    } else if (color === 'greenish') {
      return classes.greenish;
    } else {
      return '';
    }
  };

  const getColorMini = (color: string): string => {
    if (color === 'red') {
      return classes.lightRed;
    } else if (color === 'blue') {
      return classes.lightBlue;
    } else if (color === 'persian') {
      return classes.lightPersian;
    } else if (color === 'greenish') {
      return classes.lightGreenish;
    } else {
      return '';
    }
  };

  return (
    <>
      <StyledCard sx={{ width: 275, color: 'var(--white)', maxWidth: '100%' }}>
        <Box className={getColor(color)}>
          <CardHeader
            avatar={
              <Box className={[classes.icon, getColorMini(color)].join(' ')}>
                <PeopleIcon />
              </Box>
            }
            title={<Typography sx={{fontWeight:"bold", fontSize:"17.36px"}}>{title}</Typography>}
          />
          <CardContent>
            <Box className={classes.value}>{value}</Box>
            <Box className={[classes.content, getColorMini(color)].join(' ')}>
            <Typography sx={{fontWeight:"bold", fontSize:"17.36px"}}>
              {content}
              </Typography>
            </Box>
          </CardContent>
        </Box>
      </StyledCard>
    </>
  );
};

export default CardComponent;
