import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { ReducersModal } from "../../modal";
import { Chip } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Utils from "../../utils";
import PatientEthicalVerification from "../../screens/myClaims/verification";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 0,
};

export default function VerificationEthicalModal({
  userId,
  phoneNumber,
  orderId,
  userName,
}: any) {
  const dispatch = useDispatch();
  const handleClose = (val:any) => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        showEthicalVerification: false,
        ...(( val ? {ethicalSubstitue:null}:'' ))
      },
    });
  };
  const { showEthicalVerification } = useSelector(
    (state: ReducersModal) => state.InsurancePatientProfileReducer
  );

  return (
    <div>
      <Modal
        open={showEthicalVerification}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="page-verification-modal"
      >
        <Box sx={style}>
          <div className="close-icon-cover">
            <CloseIcon
              onClick={() => handleClose(1)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <PatientEthicalVerification
           
          />
        </Box>
      </Modal>
    </div>
  );
}
