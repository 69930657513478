import api from "./api";
import showAlert from "./alert";
import endPoints from "./endPoints";
import constants from "./constants";
import ActionName from "./actionName";
import CommonFunctions from "./commonFunctions";
import routes from "./routes";
import { LocalImages } from "./images";

const Utils = {
  routes,
  images: LocalImages,
  api: api,
  showAlert: showAlert,
  endPoints: endPoints,
  constants: constants,
  ActionName: ActionName,
  CommonFunctions: CommonFunctions,
};

export default Utils;
