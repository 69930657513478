import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  Radio,
  Button,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";

import { getSubscriptionDetails, getSubscriptionPlans } from "./action";

import { LocalImages } from "../../utils/images";
import {
  calculateStartEndDate,
  calculateSubscriptionAmount,
  numberCommaFormat,
} from "../../utils/commonFunctions";
import { roles } from "../../utils/constants";
import { ReducersModal } from "../../modal";
// import "../subscription.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import SubscriptionPayModal from "../../components/modal/SubscriptionPayModal";
import Utils from "../../utils";
import { within } from "@testing-library/react";
import PaymentTypeModal from "../../components/modal/PaymentTypeModal";

function SubscriptionDetails() {
  const {
    // isApiLoadingOnAddPayment,
    isGetPMApiLoading,
    mpesa,
    isUpdatePaymentOptionApiLoading,
  } = useSelector((state: ReducersModal) => state.PaymentDetailsReducer);

  const {
    subscriptionDetails,
    planList: subscriptionList,
    SubscriptionSetting: subscriptionSetting,
  } = useSelector((state: ReducersModal) => state.SubscriptionReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("subscriptionSetting", subscriptionSetting);
  // dispatch functions
  // clearProfileApiError,
  // clearDataOnSuccessfullyUpdatingInsuranceOption,

  const [isPendingPaymentStatus, setPendingPaymentStatus] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [otherPhoneMpesa, setOtherPhoneMpesa] = useState(false);
  const [isChoosePlanModel, setIsChoosePlanModel]: any = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState([]);
  const [updateSubscription, setUpdateSubscription] = useState(false);
  const [IsDetailModalOpen, setIsDetailModalOpen]: any = useState(false);
  const [payNowModal, setPayNowModal] = useState(false);
  const [subscriptionInvoiceId, setSubscriptionInvoiceId] = useState("");
  const [isCheckSubscriptionStatus, setCheckSubscriptionStatus] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [isPaymentTypeChange, setIsPaymentTypeChange] = useState(false);

  useEffect(() => {
    if (isCheckSubscriptionStatus) {
      const fetchSubscriptionPaymentStatus = () => {
        Utils.constants.getAccessToken();
        let params = "";
        Utils.api.getApiCall(
          `${Utils.endPoints.subscription}?list_type=invoice_status&invoice_id=` +
            subscriptionDetails.upcoming_subsciption.id,
          params,
          (respData: any) => {
            const { data } = respData;
            if (data?.subscription?.mpesa_status == 0) {
              let successMessage =
                "Your subscription payment request has been proceed successfully";
              Utils.showAlert(1, successMessage);
              setPendingPaymentStatus(false);
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            } else {
              if (data?.subscription?.mpesa_status != null) {
                setPendingPaymentStatus(false);
                let successMessage =
                  "Payment Unsuccessful: There are some issue. Please try again later.";
                Utils.showAlert(2, successMessage);

                setTimeout(() => {
                  window.location.reload();
                }, 5000);
              }
            }
          },
          (error: any) => {
            let { data } = error;

            Utils.showAlert(2, data?.messages[0]);
          }
        );
      };
      dispatch(getSubscriptionDetails());
      const interID: any = setInterval(fetchSubscriptionPaymentStatus, 15000);
      setIntervalId(interID);
      return () => clearInterval(interID);
    }
  }, [isCheckSubscriptionStatus]);

  useEffect(() => {
    dispatch(getSubscriptionDetails());
    dispatch(getSubscriptionPlans());
  }, [dispatch]);

  const handleChooseModal = () => {
    setUpdateSubscription(false);
  };

  const handleChooseDetailModal = () => {
    setIsDetailModalOpen(false);
  };

  const handleUpdateSubscription = (subscription_id: any) => {
    // setPaymentType("");
    // setIsLoading(false);
    setSubscriptionId(subscription_id);
    // setIsLoading(true);
  };

  useEffect(() => {
    if (!updateSubscription) {
      setSelectedSubscription([]);
      setSubscriptionId("");
    }
  }, [updateSubscription]);

  useEffect(() => {
    if (subscriptionId) {
      let selected = subscriptionList.filter((item: any) => {
        return item.id === subscriptionId;
      });
      const startEndDate = calculateStartEndDate(
        selected[0].timeline,
        selected[0].duration,
        subscriptionDetails.active.end_date
      );
      selected[0].start_date = startEndDate.startdate;
      selected[0].end_date = startEndDate.end_date;
      selected[0].insurance_company_data =
        subscriptionSetting.insurance_company_data;
      setSelectedSubscription(selected[0]);
    }
  }, [subscriptionId]);

  useEffect(() => {
    if (selectedSubscription?.length != 0) {
      setUpdateSubscription(true);
    }
  }, [selectedSubscription]);

  const handleDetailsModal = (val: any) => {
    setIsDetailModalOpen(val);
  };

  const handlePayNowModal = (val: any) => {
    setPayNowModal(val);
    setSubscriptionInvoiceId(val);
    dispatch(getSubscriptionDetails());
  };

  const handleSubscriptionStatus = (val: any) => {
    setCheckSubscriptionStatus(val);
    dispatch(getSubscriptionDetails());
  };

  const handleChangePayment = () => {
    setIsPaymentTypeChange(!isPaymentTypeChange);
  };

  const refreshData = () => {
    dispatch(getSubscriptionDetails());
  };

  return (
    <div className="page-subscription-details">
      {isPaymentTypeChange && (
        <PaymentTypeModal
          open={isPaymentTypeChange}
          refreshData={refreshData}
          content={subscriptionDetails.active}
          handleClose={handleChangePayment}
          subscriptionSetting={subscriptionSetting}
          subscriptionId={subscriptionId}
        />
      )}
      {payNowModal && (
        <SubscriptionPayModal
          open={payNowModal}
          payNowModal={payNowModal}
          content={subscriptionDetails.upcoming_subsciption}
          handleClose={() => {
            setPayNowModal(false);
            setSubscriptionId("");
            setSelectedSubscription([]);
          }}
          subscriptionSetting={subscriptionSetting}
          subscriptionId={subscriptionInvoiceId}
          handlePayNowModal={handlePayNowModal}
          handleSubscriptionStatus={handleSubscriptionStatus}
        />
      )}

      {IsDetailModalOpen == 3 && (
        <SubscriptionPayModal
          open={IsDetailModalOpen}
          content={subscriptionDetails.active}
          handleClose={handleChooseDetailModal}
          subscriptionSetting={subscriptionSetting}
          subscriptionId={subscriptionId}
        />
      )}
      {IsDetailModalOpen == 2 && (
        <SubscriptionPayModal
          open={IsDetailModalOpen}
          content={subscriptionDetails.upcoming_subsciption}
          handleClose={handleChooseDetailModal}
          subscriptionSetting={subscriptionSetting}
          subscriptionId={subscriptionId}
        />
      )}
      {updateSubscription && (
        <SubscriptionPayModal
          open={updateSubscription}
          content={selectedSubscription}
          handleClose={handleChooseModal}
          subscriptionSetting={subscriptionSetting}
          handlePayNowModal={handlePayNowModal}
          subscriptionId={subscriptionId}
          handleSubscriptionStatus={handleSubscriptionStatus}
          // setPaymentType={setPaymentType}
        />
      )}
      <div className="bg-white mt-20">
        <>
          {subscriptionDetails?.active && (
            <div style={{ display: "flex" }}>
              <div style={{ marginBottom: "10px" }}>
                <b>Payment Type</b> :{" "}
                {subscriptionDetails?.active?.payment_type == 1
                  ? "Payment From Claim/Order"
                  : "Payment From MPESA"}
              </div>
              <div style={{ marginTop: "-8px", paddingLeft: "50px" }}>
                <Button onClick={handleChangePayment}>
                  Change Payment Type
                </Button>
              </div>
            </div>
          )}
        </>
        <Grid container spacing={4}>
          {isGetPMApiLoading ? (
            <Grid item xs={12}>
              <div className="flex-center">
                <CircularProgress />
              </div>
            </Grid>
          ) : subscriptionDetails?.active ? (
            <Grid item xs={12} lg={6}>
              <div className="subscription-card active">
                <div className="space-between">
                  <div>
                    <div className="flex-start">
                      <p className="planStatus">Current Plan</p>
                      {subscriptionDetails?.active?.status == "active" ? (
                        <p className="planStatus active">Active</p>
                      ) : (
                        <p className="planStatus inactive">
                          {subscriptionDetails?.active?.status == "disable"
                            ? "Inactive"
                            : "Expired"}
                        </p>
                      )}
                    </div>
                    <h3 className="planName m-0">
                      {subscriptionDetails?.active?.subscription_name}
                    </h3>
                  </div>
                  <Button
                    className="nowrap"
                    style={{
                      backgroundColor: "#4183c4",
                      fontSize: "18px",
                      color: "white",
                      borderRadius: "10px",
                      marginLeft: "10px",
                      height: "44px",
                    }}
                    onClick={() => {
                      handleDetailsModal(3);
                    }}
                  >
                    View Detail
                  </Button>
                </div>
                <h4 className="planPrice">
                  {subscriptionDetails?.active?.price
                    ? numberCommaFormat(subscriptionDetails?.active?.price) +
                      " KES"
                    : "N/A"}{" "}
                  <span>
                    / {subscriptionDetails?.active?.duration}{" "}
                    {subscriptionDetails?.active?.timeline}
                  </span>
                </h4>
                <div className="space-between startDate mb-10">
                  <p>Start Date</p>
                  <p className="semi-bold">
                    {moment(subscriptionDetails?.active?.start_date).format(
                      "Do MMM YYYY"
                    )}
                  </p>
                </div>
                <div className="space-between startDate">
                  <p>Next Renewal Date</p>
                  <p className="semi-bold">
                    {moment(subscriptionDetails?.active?.end_date).format(
                      "Do MMM YYYY"
                    )}
                  </p>
                </div>
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} lg={6}>
              <div className="subscription-card flex-center">
                <h1
                  style={{
                    fontSize: "20px",
                    color: "red",
                    alignItems: "center",
                  }}
                >
                  No Active Subscription plan
                </h1>
              </div>
            </Grid>
          )}
          {subscriptionDetails?.upcoming_subsciption ? (
            <Grid item xs={12} lg={6}>
              <div className="subscription-card renewel">
                <div className="space-between">
                  <div>
                    <div className="flex-start">
                      <p className="planStatus">Renewal Plan</p>
                      {isPendingPaymentStatus ||
                        (subscriptionDetails?.upcoming_subsciption
                          ?.transaction_in_process == 1 && (
                          <p className="planStatus">
                            <i className="loading spinner icon"></i> Waiting for
                            Payment approval
                          </p>
                        ))}
                    </div>
                    <h3 className="planName m-0">
                      {subscriptionDetails?.upcoming_subsciption
                        ? subscriptionDetails?.upcoming_subsciption
                            ?.subscription_name
                        : "N/A"}
                    </h3>
                  </div>
                  <div className="flex-end nowrap">
                    {(subscriptionDetails?.upcoming_subsciption &&
                      !isCheckSubscriptionStatus) ||
                    subscriptionDetails?.upcoming_subsciption
                      ?.transaction_in_process != 1 ? (
                      <Button
                        disabled={disabled}
                        style={{
                          backgroundColor: "#21ba45",
                          color: "#fff",
                          textShadow: "none",
                          borderRadius: "10px",
                          fontSize: "18px",
                          height: "44px",
                        }}
                        onClick={() => {
                          setPayNowModal(true);
                          setSubscriptionInvoiceId(
                            subscriptionDetails?.upcoming_subsciption.id
                          );
                          setOtherPhoneMpesa(false);
                        }}
                      >
                        <i className="fa fa-spinner fa-spin"></i> PAY NOW{" "}
                      </Button>
                    ) : null}
                    <Button
                      className="ml-10"
                      style={{
                        backgroundColor: "#4183c4",
                        fontSize: "18px",
                        color: "white",
                        borderRadius: "10px",
                        marginLeft: "10px",
                        height: "44px",
                      }}
                      onClick={() => {
                        handleDetailsModal(2);
                      }}
                    >
                      View Detail
                    </Button>
                  </div>
                </div>
                <h4 className="planPrice">
                  {numberCommaFormat(
                    subscriptionDetails?.upcoming_subsciption?.price
                  ) + " KES"}{" "}
                  <span>
                    / {subscriptionDetails?.upcoming_subsciption?.duration}{" "}
                    {subscriptionDetails?.upcoming_subsciption?.timeline}
                  </span>
                </h4>
                {subscriptionDetails?.upcoming_subsciption?.paid_price > 0 && (
                  <div className="space-between startDate mb-10">
                    <p>Paid Amount</p>
                    <p className="semi-bold">
                      {subscriptionDetails?.upcoming_subsciption?.paid_price > 0
                        ? subscriptionDetails?.upcoming_subsciption?.paid_price
                        : "N/A"}
                    </p>
                  </div>
                )}

                {subscriptionDetails?.upcoming_subsciption?.pending_price >
                  0 && (
                  <div className="space-between startDate mb-10">
                    <p>Pending Amount</p>
                    <p className="semi-bold">
                      {subscriptionDetails?.upcoming_subsciption
                        ?.pending_price > 0
                        ? subscriptionDetails?.upcoming_subsciption
                            ?.pending_price
                        : "N/A"}
                    </p>
                  </div>
                )}
                <div className="space-between startDate mb-10">
                  <p>Start Date</p>
                  <p className="semi-bold">
                    {subscriptionDetails?.upcoming_subsciption
                      ? moment(
                          subscriptionDetails?.upcoming_subsciption?.start_date
                        ).format("Do MMM YYYY")
                      : ""}
                  </p>
                </div>
                <div className="space-between startDate ">
                  <p>Next Renewal Date</p>
                  <p className="semi-bold">
                    {subscriptionDetails?.upcoming_subsciption
                      ? moment(
                          subscriptionDetails?.upcoming_subsciption?.end_date
                        ).format("Do MMM YYYY")
                      : ""}
                  </p>
                </div>
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} lg={6}>
              <div className="subscription-card flex-center">
                <h1 style={{ fontSize: "20px" }}>No Active Renewal Date</h1>
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <div className="see-more-plans-cover">
              <h3>See More Plans</h3>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} className="row all-pricingPlans">
              {subscriptionList?.length > 0 ? (
                <>
                  {subscriptionList.map((subs: any) => {
                    return (
                      <>
                        <Grid item xs={12} md={6} lg={4}>
                          <div className="subscription-card morePlans">
                            <div className="subs-upper-info">
                              <div className="space-between">
                                <h3 className="planName m-0">
                                  {subs?.subscription_name}
                                </h3>
                              </div>
                              {subs?.description && (
                                <p className="planOffer">{subs?.description}</p>
                              )}
                              <h4 className="planPrice">
                                {
                                  calculateSubscriptionAmount(
                                    subs?.timeline,
                                    subs?.duration,
                                    subs?.price,
                                    subscriptionSetting
                                  )?.total
                                }{" "}
                                KES{" "}
                                <span>
                                  / {subs?.duration} {subs?.timeline}
                                </span>
                              </h4>
                              <hr />
                              <div className="space-between startDate">
                                <p>Portal Access Fee</p>
                                <p className="semi-bold">
                                  {numberCommaFormat(subs?.price)} KES
                                </p>
                              </div>
                              <div className="space-between startDate">
                                <p>
                                  Linkage Fee <br /> (For{" "}
                                  {subscriptionSetting?.insurance_company_count}{" "}
                                  insurance)
                                </p>
                                <p className="semi-bold">
                                  {
                                    calculateSubscriptionAmount(
                                      subs?.timeline,
                                      subs?.duration,
                                      subs?.price,
                                      subscriptionSetting
                                    )?.linkage_fee
                                  }{" "}
                                  KES
                                </p>
                              </div>
                              <div className="space-between startDate">
                                <p>VAT({subscriptionSetting?.vat}%)</p>
                                <p className="semi-bold">
                                  {
                                    calculateSubscriptionAmount(
                                      subs?.timeline,
                                      subs?.duration,
                                      subs?.price,
                                      subscriptionSetting
                                    )?.vat_amount
                                  }{" "}
                                  KES
                                </p>
                              </div>
                              {subscriptionSetting?.insurance_companies_names >
                                0 && (
                                <>
                                  <hr className="mt-0" />
                                  <div className="insurance-companies">
                                    <p className="semi-bold">
                                      Insurance Companies
                                    </p>
                                    <p>
                                      {
                                        subscriptionSetting?.insurance_companies_names
                                      }
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                            <Button
                              className="w-100 mt-10"
                              onClick={() => handleUpdateSubscription(subs?.id)}
                              style={{
                                backgroundColor: "#4183c4",
                                color: "#fff",
                                textShadow: "none",
                                backgroundImage: "none",
                                lineHeight: "1.5",
                                fontSize: "18px",
                              }}
                            >
                              CHOOSE PLAN
                            </Button>
                          </div>
                        </Grid>
                      </>
                    );
                  })}
                </>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default SubscriptionDetails;
