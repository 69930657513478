const Logo = "../images/Logo.svg";
const PersonIcon = "../images/PersonIcon.svg";
const LogoWhite = "../images/liviaLogo.png";
// const LogoWhite = "../images/logoWhite.png";
const GoogleMarker = "../images/mapmarker.png";
const CloseIcon = "../images/close-icon.svg";
const DefaultImage = "../images/liv.png";
const Aiims = "../images/safdarjung-gett.jpg";
const Safdarjung = "../images/Aiims.jpeg";
const NoImageFound = "../images/NoImagePlaceholder.png";
const ExcelIcon = "../images/excelIcon.png";
const PdfIcon = "../images/pdfIcon.jpeg";
const DocIcon = "../images/docIcon.jpeg";
const OnlineLogo = "../images/onlinelogo.png";
const Favicon = "../favicon.ico";
const PaymentImage = "../images/m-pesa-img.png";
const NoPaymentImage = "../images/no-payment.svg";
const EarlyPaymentImage = "../images/early-payment.svg";
const RegularPaymentImage = "regular-payment.svg";
const PageNotFound = "../images/404-error.svg"
const ChromeGIF = "../images/Chrome.png"
const FirefoxGIF = "../images/Firefox.png"
const EdgeGIF = "../images/Edge.png"
const OperaGIF = "../images/Opera.png"
const SafariGIF = "../images/Safari.png"

export const LocalImages = {
  Logo,
  PersonIcon,
  LogoWhite,
  GoogleMarker,
  CloseIcon,
  DefaultImage,
  Aiims,
  Safdarjung,
  NoImageFound,
  ExcelIcon,
  PdfIcon,
  DocIcon,
  OnlineLogo,
  Favicon,
  PaymentImage,
  NoPaymentImage,
  EarlyPaymentImage,
  RegularPaymentImage,
  PageNotFound,
  ChromeGIF,
  FirefoxGIF,
  EdgeGIF,
  OperaGIF,
  SafariGIF
};
