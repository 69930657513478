import { Step, StepLabel, Stepper, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { ReducersModal } from "../../../modal";
import Transcribe from "./transcribe";
import Confirm from "./confirm";
import { useNavigate } from "react-router-dom";
import Utils from "../../../utils";
import { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepperDiv: {
      flexBasis: "85%",
      [theme.breakpoints.down("sm")]: {
        flexBasis: "auto",
        marginTop: theme.spacing(1),
      },
    },
  })
);

function CreateClaimHospitalPrescription() {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const { claimSteps, activeStep } = useSelector(
    (state: ReducersModal) => state.createClaimPrescriptionReducer
  );

  // const { draftedClaimId } = useSelector(
  //   (state: ReducersModal) => state.DoctorPrescriptionsReducer
  // );

  // const draftedClaimIdSession = localStorage.getItem("draftedClaimId");
  // useEffect(() => {
  //   if (draftedClaimIdSession === null && !draftedClaimId) {
  //     navigate(Utils.routes.doctorPrescriptions);
  //   }
  // }, [draftedClaimIdSession, draftedClaimId]);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
      // return <PatientProfile />;
      case 1:
        return <Transcribe/>;
      case 2:
        return <Confirm />;
      default:
        return "unknown step";
    }
  }

  const handleButtonStep = (index: any) => {};

  return (
    <div className="page-create-claim">
      <div className="bg-white mb-20 space-between">
        <div className={classes.stepperDiv}>
          <Stepper activeStep={activeStep} alternativeLabel={matches}>
            {claimSteps.map((label: any, index: any) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step {...stepProps} key={index}>
                  <StepLabel
                    {...labelProps}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleButtonStep(index)}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
      </div>

      <div>
        {activeStep === claimSteps.length ? (
          <div>
            <h1>finished</h1>
          </div>
        ) : (
          <div>{getStepContent(activeStep)} </div>
        )}
      </div>
    </div>
  );
}

export default CreateClaimHospitalPrescription;
