import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  getOrderDetails,
  chemistOrderStatusUpdate,
  getMedicineOrders,
} from "../action";

import {
  // calculateTotalAmountDetails,
  checkStatus,
} from "../../../utils/commonFunctions";
import { Box } from "@mui/system";
import {
  Avatar,
  Button,
  Chip,
  Typography,
  TableCell,
  Input,
  TextField,
} from "@mui/material";


import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { ReducersModal } from "../../../modal";
import MedicalInformation from "./medicalInformation";

import PreviewImg from "../../../components/previewImg";

import { OrderStatus } from "../../../utils/appData";
import showAlert from "../../../utils/alert";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: "#fff";
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 450,
  height: 250,
  bgcolor: "background.paper",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 2,
  px: 4,
  pb: 3,
};

function SentClaimDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { claimId } = useParams();
  const [claim_id, setClaimId] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [previewImgUrl, setPreviewImgUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [actionType, setActionType] = useState("");
  const [isHide, setIsHide] = useState(0);
  const [cancelreason, setCancelReason] = useState("");

  const claimDetail = useSelector(
    (state: ReducersModal) => state.sentClaimReducer
  );
  const MedicineOrderDetails = useSelector(
    (state: any) => state.MedicineOrderDetailsReducer
  );
  const {
    user_phone,

    entity_id,
    beneficiary,

    order_id,
    body: medicineDetails,
  } = MedicineOrderDetails;

  const handleOpen = (e: any, id: any, action: any) => {
    setActionType(action);
    setOrderId(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleCompleteOrder = () => {
    if (claim_id) {
      let status =
        actionType === "accept"
          ? 3
          : actionType === "decline"
          ? 6
          : actionType === "cancel"
          ? 5
          : actionType === "complete"
          ? 4
          : 0;
      const params = {
        order_id: claim_id,
        OrderStatus: status,
        actionType: actionType,
        cancelreason: cancelreason,
      };

      if (actionType === "cancel" && cancelreason === "") {
        showAlert(2, "Please enter cancel reason");
        return;
      }
      if (actionType === "decline" && cancelreason === "") {
        showAlert(2, "Please enter decline reason");
        return;
      }
      setIsHide(1);
      dispatch(chemistOrderStatusUpdate(params));
      dispatch(getOrderDetails(claim_id));
      dispatch(getMedicineOrders());
      setTimeout(() => {
        navigate("/medicine-order-request");
      }, 3000);
    }

    setOpen(!open);
  };

  useEffect(() => {
    if (claimId) {
      setClaimId(claimId);
      dispatch(getOrderDetails(claimId));
    }
  }, [claimId, dispatch]);

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleChange = (e: any) => {
    setCancelReason(e.target.value);
  };

  return (
    <div className="page-claim-detail">
      {medicineDetails?.length > 0 && (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12}>
            <div className="bg-white space-between">
              <h4>Order Id: {claimId ? claimId : "N/A"}</h4>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color:
                    medicineDetails[0]?.OrderStatus == 5 ||
                    medicineDetails[0]?.OrderStatus == 6
                      ? "red"
                      : "green",
                }}
              >
                <h4>
                  Status:{" "}
                  {OrderStatus?.length > 0 &&
                    OrderStatus.map((option: any, index: any) => {
                      if (
                        medicineDetails?.length > 0 &&
                        option.id == medicineDetails[0]?.OrderStatus
                      )
                        return <> {option.name ? option.name : "N/A"}</>;
                      return null;
                    })}
                </h4>
              </Box>
              {medicineDetails[0]?.OrderStatus == 1 ? (
                <>
                  <Button
                    size="small"
                    sx={[
                      {
                        color: "var(--white)",
                      },
                    ]}
                    variant="contained"
                    color="warning"
                    onClick={(e) => handleOpen(e, order_id, "cancel")}
                  >
                    Cancel Order
                  </Button>
                </>
              ) : (
                ""
              )}
              {medicineDetails[0]?.OrderStatus == 2 ? (
                <>
                  <Button
                    size="small"
                    sx={[
                      {
                        color: "var(--white)",
                      },
                    ]}
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleOpen(e, order_id, "accept")}
                  >
                    Accept Order
                  </Button>
                  <Button
                    size="small"
                    sx={[
                      {
                        color: "var(--white)",
                      },
                    ]}
                    variant="contained"
                    color="warning"
                    onClick={(e) => handleOpen(e, order_id, "decline")}
                  >
                    Decline Order
                  </Button>
                  <Button
                    size="small"
                    sx={[
                      {
                        color: "var(--white)",
                      },
                    ]}
                    variant="contained"
                    color="warning"
                    onClick={(e) => handleOpen(e, order_id, "cancel")}
                  >
                    Cancel Order
                  </Button>
                </>
              ) : (
                ""
              )}
              {medicineDetails[0]?.OrderStatus == 3 ? (
                <>
                  <Button
                    size="small"
                    sx={[
                      {
                        color: "var(--white)",
                      },
                    ]}
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleOpen(e, order_id, "complete")}
                  >
                    Mark Order Complete
                  </Button>
                </>
              ) : (
                ""
              )}
              
            </div>
          
          </Grid>

          <Grid item xs={12}>
            <div className="bg-white">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 className="mb-10">Medical Information</h3>
                {medicineDetails && (
                  <h3 className="mb-10">
                    {/* Total Amount: {total_amount_full ? total_amount_full : "N/A"} */}
                  </h3>
                )}
              </Box>
              <MedicalInformation sentClaim medicineList={medicineDetails} />
            </div>
          </Grid>
        </Grid>
      )}
      <PreviewImg
        open={openPreview}
        handleClose={handleClosePreview}
        image={previewImgUrl}
      />
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to {actionType} this Order? <br /> <br />
            {actionType === "cancel" || actionType == "decline" ? (
              <Typography variant="caption" sx={{ mt: 2 }}>
                <TextField
                  minRows={5}
                  inputProps={{
                    maxLength: 300,
                  }}
                  placeholder={`Enter ${actionType} reason`}
                  name="cancelreason"
                  value={cancelreason}
                  onChange={handleChange}
                />
              </Typography>
            ) : (
              ""
            )}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: 6, ml: 19 }}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 6, ml: 4, color: "#fff" }}
            onClick={() => handleCompleteOrder()}
          >
            Yes
          </Button>
        </Box>
      </StyledModal>
    </div>
  );
}

export default SentClaimDetail;
