import Utils from "../../utils";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";
import { getUserIdFromLS } from "../../utils/commonFunctions";
export const getHospitalPrescriptions = () => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());

    const {
      limit,
      offset,
      search,
      insurance_company_id,
      claim_start_date,
      claim_end_date,
      // is_from_desktop,
      // is_count_needed,
    } = getState().HospitalPrescriptionsReducer;
    // console.log("status",status,"insurance_company_id",insurance_company_id,"claim_start_date",claim_start_date,"claim_end_date",claim_end_date)
    let data = `?limit=${limit}&offset=${offset || 0}${
      search.length > 0 ? `&search=${search}` : ""
    }&insurance_company_id=${insurance_company_id || ""}&claim_start_date=${claim_start_date || ""}&claim_end_date=${
      claim_end_date || ""
    }&is_from_desktop=${1}&is_count_needed=${1}`;
    // ;

    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      Utils.endPoints.HospitalPrescriptions,
      data,
      (respData: any) => {
        let { data } = respData;

        dispatch({
          type: Utils.ActionName.HOSPITAL_PRESCRIPTIONS,
          payload: {
            data: data.body,
            count: data.count,
          },
        });
        // ;
        dispatch(globalLoaderFalse());
      },
      (error: any) => {
        dispatch(globalLoaderFalse());
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const onSendOtp = (id: any, phoneNumber: any) => {
  return (dispatch: Function, getState: Function) => {
    const dataToSend = {
      user_phone: phoneNumber,
      order_id: id,
    };
    Utils.constants.getAccessToken();
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { isLoading: true },
    });

    Utils.api.putApiCall(
      `${Utils.endPoints.DoctorPrescriptions}/${id}`,
      dataToSend,
      (respData: any) => {
        // let { data } = respData;
        Utils.showAlert(1, "Otp sent successfully");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const onSubmitOtp = (id: any, phoneNumber: any, otp: any, orderId: any, navigate: any) => {
  return (dispatch: Function, getState: Function) => {
    const dataToSend = {
      user_phone: phoneNumber,
      order_id: orderId,
      otp: otp,
    };

    Utils.constants.getAccessToken();
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { isLoading: true },
    });

    Utils.api.putApiCall(
      `${Utils.endPoints.DoctorPrescriptions}/${id}?verify_otp=1`,
      dataToSend,
      (respData: any) => {
        let { data } = respData;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        Utils.showAlert(1, "Otp verified successfully");
        dispatch({
          type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
          payload: {
            draftedClaimId: data.draft_claim_id,
            showVerification: false,
          },
        });

        localStorage.setItem("draftedClaimId", data.draft_claim_id);
        navigate(`${Utils.routes.createClaimPrescriptionForHospital}/${data.draft_claim_id}`, {
          state: { editDoctor: false },
        });
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const declinePrescription = (ID: any, userId: any) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    let params = {
      order_id: ID,
    };
    Utils.constants.getAccessToken();
    const pharmId = getUserIdFromLS();
    Utils.api.patchApiCall(
      `${Utils.endPoints.DoctorPrescriptions}/${pharmId}`,
      params,
      (respData: any) => {
        const { data } = respData;
        dispatch(getHospitalPrescriptions());
        console.log("data", data);
        //
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};
export const onVerifyPopup = (orderId:any, navigate:any)=> {
  return (dispatch:Function, getState:Function) => {
    Utils.constants.getAccessToken();
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { isLoading: true },
    });
    Utils.api.putApiCall(`${Utils.endPoints.HospitalPrescriptions}/${orderId}`, {order_id: orderId}, 
    (response:any)=>{
      let { data } = response;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        dispatch({
          type: Utils.ActionName.HOSPITAL_PRESCRIPTIONS,
          payload: {
            draftedClaimId: data.draft_claim_id,
            showVerification: false,
          },
        });
        localStorage.setItem("draftedClaimId", data.draft_claim_id);
        navigate(`${Utils.routes.createClaimPrescriptionForHospital}/${data.draft_claim_id}`, {
          state: { editDoctor: false },
        });
    }, (error:any)=>{
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: { isLoading: false },
      });
      let { data } = error;
      if(data?.messages[0]==="Order Already Proceed")
        {
          Utils.showAlert(2, `Request ID: ${orderId}, prescription is already processed.`)
          dispatch(getHospitalPrescriptions());
          dispatch({
            type: Utils.ActionName.HOSPITAL_PRESCRIPTIONS,
            payload: {
              showVerification: false,
            },
          });
        }
        else Utils.showAlert(2, data?.messages[0]);
    })

  }
}
export const addTranscribePrescriptions = (value: any, claimId: string) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    let params = value;
    Utils.constants.getAccessToken();
    Utils.api.putApiCall(
      Utils.endPoints.addMedicine + "/" + claimId,
      params,
      (respData: any) => {
        //console.log(respData, "respData");

        if (respData.status === Utils.constants.api_success_code.success) {
          const { data } = respData;
          console.log(data);
          dispatch({
            type: Utils.ActionName.CREATE_CLAIM_SECOND_SETP_DOCTOR,
            payload: { ...data },
          });
          dispatch({
            type: Utils.ActionName.CLEAR_MEDICINE_DETAILS_LIST,
          });
          dispatch({
            type: Utils.ActionName.CREATE_CLAIM_PRESCRIPTION,
            payload: {
              activeStep: 2,
            },
          });
        }
        dispatch(globalLoaderFalse());
      },
      (error: any) => {
        let { data } = error;
        // console.log(data);
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const confirmPrescription = (value: any, navigate: any, type: number) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    let params = value;
    // console.log("uploadPrescription params", params);
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      Utils.endPoints.uploadPrescription,
      params,
      (respData: any) => {
        if (respData.status === Utils.constants.api_success_code.success) {
          const { data } = respData;
          // console.log({ respData });
          dispatch({
            type: Utils.ActionName.CREATE_CLAIM_PRESCRIPTION,
            payload: {
              activeStep: 1,
            },
          });
          dispatch({
            type: Utils.ActionName.CLEAR_MEDICINE_DETAILS_LIST,
          });
          if (type === 1) {
            dispatch({
              type: Utils.ActionName.Tab_DETAILS,
              payload: {
                value: "2",
              },
            });
          }
          if (type === 2) {
            dispatch({
              type: Utils.ActionName.Tab_DETAILS,
              payload: {
                value: "1",
              },
            });
            let respMessage = `E-Claim sent to insurance. Claim ID: ${data?.final_claim_id}`
            if(data?.claim_status==2){
              respMessage = `E-Claim sent to insurance for pre-authorization. kindly hold delivery until it gets authorized. Claim ID: ${data?.final_claim_id}`
            }

            Utils.showAlert(1, respMessage);
          }
          // console.log(navigate, "navai");
          localStorage.removeItem("draftedClaimId");
          navigate(Utils.routes.myClaims);
          dispatch(globalLoaderFalse());
        }
      },
      (error: any) => {
        let { data } = error;
        // console.log(data);
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

/**
 * action to get balance
 */
export const getClaimBalance = (claimId: any) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      //check if user is online or not
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    let data = `?type=GET_MEMBER_BALANCE`;
    Utils.api.getApiCall(
      `${Utils.endPoints.claimBalance}/${claimId}`,
      data,
      (respData: any) => {
        let { data } = respData;

        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            balanceClaim: data.user_current_balance,
          },
        });
      },
      (error: any) => {
        let { data } = error;

        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};
