
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Radio, Button, Typography, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { createStyles, makeStyles } from "@mui/styles";
import CustomModal from "../../components/modal/customModal";
import AddPaymentMethod from "./subcomponents/addPaymentMethod";
import BankAccountDetail from "./subcomponents/bankAccountDetail"
import EditPaymentPopup from "./subcomponents/editPaymentPopup";
import {
	addPaymentMethod,
	deletePaymentMethod,
	getAllPaymentMethod,
	resetAddPmAPIData,
} from "./action";

import { LocalImages } from "../../utils/images";
import { getRoleIdFromLS, isObjectEmpty } from "../../utils/commonFunctions";
import { roles } from "../../utils/constants"
import { ReducersModal } from "../../modal";

function Payments() {
    const {
		// isApiLoadingOnAddPayment,
		isGetPMApiLoading,
		mpesa,
		isUpdatePaymentOptionApiLoading,
	} = useSelector((state: ReducersModal) => state.PaymentDetailsReducer);

    const dispatch = useDispatch();

	// dispatch functions
		// clearProfileApiError,
		// clearDataOnSuccessfullyUpdatingInsuranceOption,

	const paymentType = {
		bankaccount: "Bank Account",
		paybill: "Pay Bill",
		phone: "Mobile Number",
		tillnumbers: "Till Number",
	};
	const [showAddPmModal, setShowAddPmModal] = useState(false);
	const [showSelectedModal, setShowSelectedModal] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const [paymentMethodModaltext, setPaymentMethodModaltext] = useState("");
	const [showUpdatePmModal, setShowUpdatePmModal] = useState(false);
	const [whichActionForPm, setWhichActionForPm] = useState("");
	const [whichPaymentMethodSelected, setWhichPaymentMethodSelected] = useState("");
	const [deleteModalState, setdeleteModalState] = useState(false);
	const [currentPm, setcurrentPm] = useState("");

    useEffect(() => {		
		dispatch(getAllPaymentMethod());
	}, [dispatch]);

    const selectModalHandler = (id: string) => {
		setShowAddPmModal(false);
		setShowSelectedModal(id);
		setIsEditing(false);
	};

	const addPMHandler = (payload: any) => {		
		dispatch(addPaymentMethod(payload));
		setIsEditing(false);
	};

	const editBtnHandler = (pm: string) => {
		setShowAddPmModal(false);
		setIsEditing(true);
		setShowSelectedModal(
			pm === "paybill"
				? "payBill"
				: pm === "bankaccount"
				    ? "bankAccount"
				    : pm === "phone"
				        ? "mobileNumber"
				        : "tillNo"
		);
	};

	const deleteBtnHandler = (pm: string) => {
		console.log(pm)
		setIsEditing(false);
		setdeleteModalState(true);
		if (pm) {
			setcurrentPm(pm);
		}
	};

	const deletePMConfirm = () => {
		if (currentPm) {
			dispatch(deletePaymentMethod({
				"pay_type": currentPm,
				"system": "mpesa"
			}));				
		}
		setdeleteModalState(false);
	};

	const onHideHandler = () => {
		setShowSelectedModal("");
		setIsEditing(false);
	};

	const isChecked = (pm: string) => {
		return mpesa[pm].priority == 1 ? true : false;
	};

	const checkBoxHander1 = () => {
		if (whichActionForPm === "updatePmType") {
			if (
				mpesa[whichPaymentMethodSelected]?.priority == 0 ||
				mpesa[whichPaymentMethodSelected]?.priority == null
			) {
				const copyOfPayloadToUpdateDefaultPm =
					mpesa[whichPaymentMethodSelected];
				dispatch(addPaymentMethod({
					...copyOfPayloadToUpdateDefaultPm,
					priority: 1,
					pay_type: whichPaymentMethodSelected,
					system: "mpesa",
				}));
			}
		} else if (whichActionForPm === "deletePm") {
			deleteBtnHandler(whichPaymentMethodSelected);
		} else if (
			whichActionForPm === "early-payment" ||
			whichActionForPm === "regular-payment"
		) {
			// radioBtnHandler();
		}
	};

	const checkBoxHander = (pm: string) => {
		if (!mpesa[pm]?.priority) {
			const copyOfPayloadToUpdateDefaultPm = mpesa[pm];
			dispatch(addPaymentMethod({
				...copyOfPayloadToUpdateDefaultPm,
				priority: 1,
				pay_type: pm,
				system: "mpesa",
			}));
		}
	};

	const paymentMethodHandler = (pm: string, type: string) => {
		if (type === "deletePm") {
			setPaymentMethodModaltext(
				"Are you sure you want to delete this payment method? Please note, this action can not be undone."
			);
		} else if (type === "early-payment" || type === "regular-payment") {
			setPaymentMethodModaltext(
				"Please confirm if you want to update the Insurance type."
			);
		} else
			setPaymentMethodModaltext(
				"Please confirm if you want to update the Payment type."
			);
		setWhichActionForPm(type);
		setShowUpdatePmModal(true);
		setWhichPaymentMethodSelected(pm);
	};

	const CancelPaymentMethodHandler = () => {
		setWhichActionForPm("");
		setShowUpdatePmModal(false);
		setWhichPaymentMethodSelected("");
		setPaymentMethodModaltext("");
	};

  
  return (
    <div className="page-my-profile">
      <div className="payment-detail-cover border">
			{getRoleIdFromLS() === roles.PARENT && (
				<div className="space-between payment-detail-header">
					<Typography variant="h6">Payment Details</Typography>
					<Button 
						variant="contained"
						className={`capitalize color-white`}
						sx={{ width: "auto" }}
						onClick={() => {
							console.log("clicked")
							setShowAddPmModal(true)
						}}>
						Add Payment Details
					</Button>
				</div>
			)}
			
			<div className="account-info mt-20">
				<div className="table-cover mt-10 payment-detail-table">
					{isGetPMApiLoading 
						?	<CircularProgress /> 
						: (mpesa && !isObjectEmpty(mpesa) ? (
							<table>
								<thead>
									<tr>
										<th style={{fontSize: "16px"}}>Default</th>
										<th style={{fontSize: "16px"}} className="left">Payment Type</th>
										{getRoleIdFromLS() === roles.PARENT && (
											<th style={{fontSize: "16px"}} className="center">Action</th>
										)}
									</tr>
								</thead>
								<tbody>
									{Object.keys(mpesa).map((pm, index) => (
										<tr key={index + pm}>
											<td style={{ width: "30px" }}>
												<Checkbox
													disabled={
														isGetPMApiLoading ||
														getRoleIdFromLS() !== roles.PARENT
													}
													onClick={() => checkBoxHander(pm)}
													checked={isChecked(pm)}
												/>
											</td>
											<td className="left">
												{paymentType[pm as keyof Object] ? paymentType[pm as keyof Object] : pm}
											</td>
											{getRoleIdFromLS() === roles.PARENT && (
												<td className="right">
													<div
														className="table-btn-action-cover"
														style={{ justifyContent: "center" }}
													>
														<Button
															variant="outlined"
															className={`capitalize`}
															sx={{ width: "auto", margin: "0 10px" }}
															disabled={isGetPMApiLoading}
															onClick={() => editBtnHandler(pm)}
														>
															Edit
														</Button>
														<Button
															variant="contained"
															className={`capitalize color-white`}
															sx={{ width: "auto", margin: "0 10px" }}
															disabled={isGetPMApiLoading}
															onClick={() => deleteBtnHandler(pm)}
														>
															Delete
														</Button>
													</div>
												</td>
											)}{" "}
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<div className="for-no-data">
								<img src={LocalImages.NoPaymentImage} alt="no payment"/>
								<p className="bold">
									Please add a payment method. This is where we will send you
									your money to.
								</p>
							</div>
						))}
				</div>
			</div>

			{showAddPmModal && (
				<AddPaymentMethod
					selectModal={selectModalHandler}
					pmList={mpesa}
					showAddPmModal={showAddPmModal}
					onHide={() => setShowAddPmModal(false)}
				/>
			)}
			{showSelectedModal === "payBill" && (
				<EditPaymentPopup
					areWeEditing={isEditing}
					popFor="payBill"
					onHide={onHideHandler}
					addPm={addPMHandler}
					// isApiLoadingOnAddPayment={isLoading}
					priority={mpesa?.paybill?.priority}
					payBillNo={isEditing ? mpesa?.paybill?.number : ""}
				/>
			)}
			{showSelectedModal === "tillNo" && (
				<EditPaymentPopup
					areWeEditing={isEditing}
					popFor="tillNo"
					onHide={onHideHandler}
					addPm={addPMHandler}
					priority={mpesa?.tillnumbers?.priority}
					// isApiLoadingOnAddPayment={isLoading}
					tillNo={isEditing ? mpesa?.tillnumbers?.number : ""}
				/>
			)}
			{showSelectedModal === "mobileNumber" && (
				<EditPaymentPopup
					areWeEditing={isEditing}
					popFor="mobileNumber"
					onHide={onHideHandler}
					addPm={addPMHandler}
					priority={mpesa?.phone?.priority}
					// isApiLoadingOnAddPayment={isLoading}
					phone={isEditing ? mpesa?.phone?.number : ""}
				/>
			)}
			{showSelectedModal === "bankAccount" && (
				<BankAccountDetail
					areWeEditing={isEditing}
					popFor="bankAccount"
					onHide={onHideHandler}
					addBankAcccount={addPMHandler}
					// isApiLoadingOnAddPayment={isLoading}
					bankDetail={isEditing ? mpesa?.bankaccount : null}
				/>
			)}
			{showUpdatePmModal && (				
                <CustomModal    //PaymentConfirm
                    title={"Payment Method"}
                    content={
						<Typography>
							{paymentMethodModaltext}
						</Typography>
					}
					// open={showUpdatePmModal}
                    handleClose={CancelPaymentMethodHandler}
					addCustomActions={true}
					actionItems={
						<>
							<Button
								variant="outlined"
								className={"capitalize"}
								sx={{ width: "auto" }}
								onClick={CancelPaymentMethodHandler}
							>
								Cancel
							</Button>
							<LoadingButton
								loading={isGetPMApiLoading || isUpdatePaymentOptionApiLoading}
								loadingPosition="start"
								variant="contained"
								color="primary"
								className={`capitalize color-white`}
								sx={{ width: "auto" }}
								onClick={checkBoxHander1}
							>
								Confirm
							</LoadingButton>
						</>
					}
                />
			)}
			<CustomModal
				title={"Delete Payment Method"}
				content={
					<Typography>
						"Are you sure you want to delete this payment method? Please note, this action can not be undone."
					</Typography>
				}
				open={deleteModalState}
				handleClose={() => setdeleteModalState(false)}
				addCustomActions={true}
				actionItems={
					<>
						<Button
							variant="outlined"
							className={"capitalize"}
							sx={{ width: "auto" }}
							onClick={() => setdeleteModalState(false)}
						>
							Cancel
						</Button>
						<LoadingButton
							loading={isGetPMApiLoading}
							loadingPosition="start"
							variant="contained"
							color="primary"
							className={`capitalize color-white`}
							sx={{ width: "auto" }}
							onClick={deletePMConfirm}
						>
							Confirm
						</LoadingButton>
					</>
				}
			/>
		</div>
    </div>
  );
}

export default Payments;
