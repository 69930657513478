import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReducersModal } from "../../modal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from "@mui/material";
import { getUserIdFromLS } from "../../utils/commonFunctions"


function MyBranches() {
  const [ count, setCount ] = useState(0);
  const [ limit, setLimit ] = useState(10);
  const [ page, setPage ] = useState(0);
  const { pharmacy_branches } =  useSelector(
    (state: ReducersModal) => state.MyProfilePersonalInfoReducer
  );

  useEffect(() => {
    setCount(pharmacy_branches.length);

    // offset: page * limit - limit,
  }, [pharmacy_branches]);

  const handleChangeRowsPerPage = (event: any) => {
    setLimit(+event.target.value);

    // offset: 1,
  }

  const handleChangePage = (value: any) => {
    setPage(value + 1);
  };
  
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              <TableCell sx={{fontSize: "16px"}}>Branch Name</TableCell>
              <TableCell sx={{fontSize: "16px"}}>Address</TableCell>
              <TableCell sx={{fontSize: "16px"}}>Phone</TableCell>
              <TableCell sx={{fontSize: "16px"}}>Email</TableCell>
              {/* <TableCell sx={{fontSize: "16px"}}>Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {pharmacy_branches.length > 0 ? (
              pharmacy_branches.map((pharmacy_branches: any) => {
                return (
                  pharmacy_branches.id !== getUserIdFromLS() &&
                  <TableRow
                    key={pharmacy_branches.id}
                  >
                    <TableCell>
                      {pharmacy_branches.name ? pharmacy_branches.name : "N/A"}
                    </TableCell>
                    <TableCell>
                      {pharmacy_branches.address ? pharmacy_branches.address : "N/A"}
                    </TableCell>
                    <TableCell>
                      {pharmacy_branches.login_phone ? `+${pharmacy_branches.login_phone}` : "N/A"}
                    </TableCell>
                    <TableCell>
                      {pharmacy_branches.login_email ? pharmacy_branches.login_email : "N/A"}
                    </TableCell>
                    {/* <TableCell>{"N/A"}</TableCell> */}
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                key={1}
              >
                {" "}
                <TableCell>{"No Branches Found"}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pharmacy_branches.length > 0 && (
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={count - 1}
            rowsPerPage={limit}
            page={page}
            onPageChange={(e, value): any => handleChangePage(value)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={<span>Rows:</span>}
            sx={{
              ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
                fontWeight: "bold",
                color: "primary",
              },
            }}
          />
        </TableRow>
      )}
    </>
  );
}

export default MyBranches