import { CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import ClearIcon from "@mui/icons-material/Clear";
import Utils from "../../../utils";
import { useSelector } from "react-redux";
import { ReducersModal } from "../../../modal";

function DoctorSearch({ name, setDoctorData }: any) {
  // const [open, setOpen] = useState(false);
  const [value, setValue] = useState<any>("");
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [mata]: any = useField(name);
  const { setFieldValue } = useFormikContext();
  const debouncedSearchTerm: string = useDebounce<string>(
    inputValue && inputValue,
    500
  );

  const { onlyRead, doctorDetail } = useSelector(
    (state: ReducersModal) =>
      state.createClaimStepTwoDoctorAndHospitalAndICDDataReducer
  );

  useEffect(() => {
    if (doctorDetail?.id) {
      setValue(doctorDetail);
      setFieldValue(name, doctorDetail);
      setDoctorData(doctorDetail);
    }
  }, [doctorDetail?.id]);
  // const loading = open && options.length === 0;

  useEffect(() => {
    let actives = true;

    // if (!loading) {
    //   return undefined;
    // }

    if (debouncedSearchTerm === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    if (debouncedSearchTerm.length > 0) {
      let params = `?search=${debouncedSearchTerm}`;

      Utils.constants.getAccessToken();

      Utils.api.getApiCall(
        Utils.endPoints.searchDoctorHospital,
        params,
        (response: any) => {
          if (response.status === 200) {
            const results: any = [...response?.data.body];
            if (actives) {
              let newOptions: any = [];

              if (results) {
                newOptions = [...newOptions, ...results];
              }

              setOptions(newOptions);
            }
          }
        },
        (err: any) => {
          console.error(err);
        }
      );

      // Utils.constants.axios
      //   .get(Utils.endPoints.searchDoctorHospital + params)
      //   .then((response: any) => {
      //     if (response.status === 200) {
      //       const results: any = [...response?.data.body];
      //       if (actives) {
      //         let newOptions: any = [];

      //         if (results) {
      //           newOptions = [...newOptions, ...results];
      //         }

      //         setOptions(newOptions);
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
    }

    return () => {
      actives = false;
    };
  }, [value, debouncedSearchTerm]);

  // useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  const onHighlightChange = (event: any, newValue: any) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    if (newValue && newValue?.id) {
      setFieldValue(name, newValue);
      setDoctorData(newValue);
    }
    if (newValue === null) {
      setFieldValue(name, null);
      setDoctorData(null);
    }
  };
  // console.log("value", value);
  return (
    <Autocomplete
      sx={{
        mr: 1,
      }}
      id="autocompleteLocations"
      fullWidth
      size="small"
      // open={open}
      // loading={options.length > 0 ? false : true}
      // loadingText="Doctor loading..."
      // onOpen={() => {
      //   setOpen(true);
      // }}
      // onClose={() => {
      //   setOpen(false);
      // }}
      autoHighlight={true}
      autoSelect={true}
      clearOnEscape
      onChange={onHighlightChange}
      clearIcon={<ClearIcon color="primary" fontSize="small" />}
      inputValue={inputValue}
      value={value ? value : null}
      options={options}
      getOptionLabel={(option: any) => option?.text}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option: any) => {
        return (
          <li {...props} key={Math.random()}>
            {option.text}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          InputProps={{
            ...params.InputProps,
            // endAdornment: (
            //   <>
            //     {loading && inputValue.length > 0 ? (
            //       <CircularProgress color="inherit" size={20} />
            //     ) : null}
            //     {params.InputProps.endAdornment}
            //   </>
            // ),
          }}
          label="Prescribing Doctor's Name"
          error={Boolean(mata && mata.touched && mata.error)}
          helperText={mata && mata.touched && mata.error}
          fullWidth
        />
      )}
    />
  );
}

export default DoctorSearch;
