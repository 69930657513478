import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Theme,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogActions: {
      "&.MuiDialogActions-root": {
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      },
    },
    dialogTitle: {
      display: "flex",
      alignItems: "center",
      justtifyContent: "space-between",
      "&.MuiDialogTitle-root": {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    dialogContent: {
      "&.MuiDialogContent-root": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    buttonPrimary: {
      color: "white !important",
      textTransform: "capitalize",
    },
  })
);

function CustomModal(props: any) {
  const {
    title,
    content,
    open,
    handleClose,
    handleButtonClick,
    isApiLoading,
    addCustomActions,
    actionItems,
  } = props;
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          className={classes.dialogTitle}
          style={{ fontSize: "25px" }}
        >
          <span>{title ? title : ""}</span>
          {handleClose && (
            <CloseIcon
              sx={{
                // position: "absolute",
                // top: 5,
                ml: 2,
                color: (theme) => theme.palette.grey[500],
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          )}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {content}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {!addCustomActions && (
            <Button
              variant="contained"
              className={classes.buttonPrimary}
              onClick={handleButtonClick}
            >
              OK
            </Button>
          )}
          {addCustomActions && actionItems}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CustomModal;
