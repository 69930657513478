import { useEffect, useState } from "react";
import { Box, Button, Modal, Step, StepLabel, Stepper, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { steps } from "../../utils/appData";
import useMediaQuery from "@mui/material/useMediaQuery";
import CreateProfile from "./createProfile";
// import SelectLocation from "./selectLocation";
import WorkTime from "./workTime";
import { Form, Formik } from "formik";
import FormField from "../../schema/formField";
import { useDispatch, useSelector } from "react-redux";
import {
  createProfiles,
  getSignupCreateProfleDetails,
  setWorkTime,
  //  selecteLocation
} from "./action";
import { convertPayloadData } from "../../utils/commonFunctions";
import { useNavigate } from "react-router-dom";
import Schema from "../../schema";
import Utils from "../../utils";
import Popup from "./popup";
import CheckboxWrapper from "../../components/formsUi/checkbox";
import { ReducersModal } from "../../modal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backDiv: {
      display: "flex",
      flexBasis: "15%",
      alignItems: "center",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        flexBasis: "auto",
      },
    },
    arrowBackIcon: {
      color: theme.palette.secondary.main,
    },
    back: {
      "&.MuiTypography-root": {
        font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(2)} Poppins, sans-serif`,
        color: theme.palette.secondary.main,
        paddingLeft: theme.spacing(0.5),
      },
    },
    stepperDiv: {
      flexBasis: "85%",
      [theme.breakpoints.down("sm")]: {
        flexBasis: "auto",
        marginTop: theme.spacing(1),
      },
    },
    nextDiv: {
      flexBasis: "15%",
    },
    nextBtn: {
      "&.MuiButton-root": {
        float: "right",
        color: "var(--white)",
        padding: theme.spacing(1, 2.5),
        font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(1.5)} Poppins, sans-serif`,
      },
    },
    mainFormCard: {},
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      backgroundColor: "var(--white)",
      boxShadow: "24",
    },
  })
);

function SignUp() {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const localStep = localStorage.getItem("activeStep");
  const [activeStep, setActiveStep] = useState(Number(localStep) || 0);
  const [open, setOpen] = useState(false);
  const [createProfileInitialValue, setCreateProfileInitialValue] = useState(FormField.createProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createProfileDetails = useSelector((state: ReducersModal) => state.getSignupCreateProfleDetailsReducer);
  const {
    id_number,
    pharmacy_name,
    name_prefix,
    admin_name,
    city_id,
    city_name,
    email,
    practice_certificate,
    practice_certificate_expiry,
    license_of_pharmacy,
    license_of_pharmacy_expiry,
    business_permit,
    business_permit_expiry,
    pin_certificate,
    cr_12,
    tax_compliance,
    tax_compliance_expiry,
    physical_address,
    latitude,
    longitude,
    phone_number,
  } = createProfileDetails;
  const createProfileDetailsValue = {
    profilePic: id_number,
    pharmacyName: pharmacy_name,
    surname: name_prefix,
    pharmacyAdminName: admin_name,
    searchPharmacyCity: city_id,
    emailId: email,
    practiceCertificate: practice_certificate,
    practice_certificate_expiry: practice_certificate_expiry,
    licensePharmacy: license_of_pharmacy,
    license_of_pharmacy_expiry: license_of_pharmacy_expiry,
    businessPermit: business_permit,
    business_permit_expiry: business_permit_expiry,
    pinCertificate: pin_certificate,
    crDocument: cr_12,
    taxCompliance: tax_compliance,
    tax_compliance_expiry: tax_compliance_expiry,
    physical_address: physical_address,
    latitude: latitude,
    longitude: longitude,
    termConditions: false,
    checkAge: false,
    domain: "",
  };

  useEffect(() => {
    dispatch(getSignupCreateProfleDetails());
    return () => {
      dispatch({
        type: Utils.ActionName.GET_SIGNUP_CREATE_PROFILE_DETAILS,
        payload: { email: "", phone_number: "" },
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (phone_number && email) {
      setCreateProfileInitialValue({ ...createProfileDetailsValue });
    }
  }, [phone_number, email]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (open: any) => {
    setOpen(false);
  };

  const createProfile = () => {
    return (
      <div>
        <Formik
          initialValues={createProfileInitialValue}
          validationSchema={Schema.createProfileSchema}
          onSubmit={createProfileHandleSubmit}
          enableReinitialize
        >
          {() => {
            return (
              <Form>
                <CreateProfile />
                <div className="bg-white mb-20">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <CheckboxWrapper
                          size="small"
                          name="checkAge"
                          label={<Typography>I am over 18 years old</Typography>}
                        />
                        <CheckboxWrapper
                          // checked={phone_number && email && true}
                          size="small"
                          name="termConditions"
                          label={
                            <Typography>
                              I agree with{" "}
                              <a
                                href={Utils.routes.termsAndCondition}
                                style={{ textDecoration: "underline" }}
                                target="_blank"
                              >
                                Terms and Conditions
                              </a>
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          color: "var(--white)",
                          fontWeight: "bold",
                        }}
                      >
                        {activeStep === steps.length - 1 ? "Confirm" : "Next"}
                      </Button>
                    </Box>
                  </Box>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  };

  // const selectLocation = () => {
  //   return (
  //     <Formik
  //       initialValues={FormField.selecteLocation}
  //       validationSchema={Schema.selecteLocation}
  //       onSubmit={slecteLoctionHandleSubmit}
  //     >
  //       {() => {
  //         return (
  //           <Form>
  //             <SelectLocation />
  //             <div className="justify-end bg-white mb-20">
  //               <Button
  //                 variant="contained"
  //                 type="submit"
  //                 sx={{
  //                   color: "var(--white)",
  //                   fontWeight: "bold",
  //                 }}
  //               >
  //                 {activeStep === steps.length - 1 ? "Confirm" : "Next"}
  //               </Button>
  //             </div>
  //           </Form>
  //         );
  //       }}
  //     </Formik>
  //   );
  // };

  const workTime = () => {
    return (
      <Formik
        initialValues={FormField.workTime}
        validationSchema={Schema.workTimeSchema}
        onSubmit={workTimeHandleSubmit}
      >
        {() => {
          return (
            <Form>
              <WorkTime />
              <div className="justify-end bg-white mb-20">
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    color: "var(--white)",
                    fontWeight: "bold",
                  }}
                >
                  {activeStep === steps.length - 1 ? "Confirm" : "Next"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const createProfileHandleSubmit = (value: any, { resetForm }: any) => {
    // console.log(value);
    if (!value?.checkAge) {
      Utils.showAlert(2, "Please check Age consent");
      return;
    }
    if (!value.termConditions) {
      Utils.showAlert(2, "Please accept Terms and Conditions to continue");
      return;
    }
    dispatch(createProfiles(value, setActiveStep, resetForm));
  };

  // const slecteLoctionHandleSubmit = (value: any) => {
  //   dispatch(selecteLocation(value, setActiveStep));
  // };

  const workTimeHandleSubmit = (value: any) => {
    const payload = convertPayloadData(value);
    // 793000188
    console.log(`workTimeHandleSubmit`, value, "payload", payload);
    dispatch(setWorkTime(payload, setActiveStep, handleOpen));
  };

  return (
    <div className="page-signup" style={{ padding: "20px 30px" }}>
      <div className={`bg-white mb-20 space-between`}>
        <div
          className={classes.backDiv}
          onClick={() => {
            Utils.constants.removeSession();
            navigate("/");
          }}
        >
          {activeStep !== steps.length - 1 && (
            <>
              <ArrowBackIcon className={classes.arrowBackIcon} />
              <Typography className={classes.back}>Back</Typography>
            </>
          )}
        </div>
        <div className={classes.stepperDiv}>
          <Stepper activeStep={activeStep} alternativeLabel={matches}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step {...stepProps} key={index}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
            <h1>finished</h1>
          </div>
        ) : (
          <div className={classes.mainFormCard}>
            {activeStep === 0 && createProfile()}
            {activeStep === 1 && workTime()}
          </div>
        )}
      </div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className={classes.modalStyle}>
          <Popup handleClose={handleClose} />
        </Box>
      </Modal>
    </div>
  );
}

export default SignUp;
