import { Avatar, Box, FormLabel, Grid, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Textfield from "../../../components/formsUi/textFiled";
import { Form, Formik } from "formik";
import SelectWrapper from "../../../components/formsUi/select";
import Schema from "../../../schema";
import { makeStyles, createStyles } from "@mui/styles";
import { Dialog, DialogTitle } from "@mui/material";
import { LocalImages } from "../../../utils/images";
import FormField from "../../../schema/formField";
import SearchAutoComplete from "../../../components/formsUi/searchAutoComplete";
import GoogleMapSearch from "../../../components/formsUi/googleMapSearch";
import { addHospitalInfo } from "../action";
import { useDispatch, useSelector } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import { ReducersModal } from "../../../modal";
import { useEffect, useState } from "react";

type InstitutionHopitalClinicProps = {
  open: boolean;
  handleClose: () => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    dialogContainer: {
      width: "100%",
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "7px",
      "&.MuiGrid-root": {
        marginTop: "none !important",
      },
      "& .MuiFormLabel-asterisk": {
        color: "red",
      },
      "& .css-1s58mw9-MuiFormLabel-root": {
        fontSize: 13,
      },
    },
    flexBox: {
      display: "flex",
      alignItems: "center",
      "& .MuiAvatar-root": {
        height: "15px",
        width: "20px",
      },
    },
    marginFlag: {
      marginLeft: "2px",
      fontSize: "13px",
    },
  })
);

export default function InstitutionHopitalClinic({
  open,
  handleClose,
}: InstitutionHopitalClinicProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState();
  // const countryCode = useRef();
  const [selectedDelete, setSelectedDelete] = useState(9);
  const onSubmit = (values: any, action: any) => {
    // console.log(values);
    dispatch(addHospitalInfo(values, handleClose, action));
  };

  const { countryData } = useSelector(
    (state: ReducersModal) => state.countryReducer
  );
  useEffect(() => {
    let number: any = countryData.filter(
      (el: any) => el.phone_country_code === countryCode
    );
    if (number.length > 0) {
      setSelectedDelete(number[0].digits_phone_number);
    }
  }, [countryCode, countryData]);
  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={{ display: "flex" }}>
          <DialogTitle
            sx={{
              padding: 0,
              textAlign: "center",
              fontWeight: "bold",
              flexBasis: "92%",
            }}
            id="responsive-dialog-title"
          >
            {"Institute/Hospital/Clinic Information(Optional)"}
          </DialogTitle>
          <Box sx={{ flexBasis: "8%", textAlign: "center", cursor: "pointer" }}>
            <img src={LocalImages.CloseIcon} alt="" onClick={handleClose} />
          </Box>
        </Box>

        <Formik
          initialValues={FormField.addHospitalDetails}
          validationSchema={Schema.InstitutionHopitalClinicSchema(
            selectedDelete
          )}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className={classes.dialogContainer}>
                <Grid item xs={12}>
                  <Grid container sx={{ flexGrow: 1 }}>
                    <Grid item xs={6} sm={6} md={3.5}>
                      <FormLabel required>Country</FormLabel>
                    </Grid>
                    <Grid item xs={6} sm={6} md={8.5}>
                      <Textfield
                        name="country"
                        placeholder="Country"
                        size="small"
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 1, flexGrow: 1 }}>
                    <Grid item xs={6} sm={6} md={3.5}>
                      <FormLabel required>City</FormLabel>
                    </Grid>
                    <Grid item xs={6} sm={6} md={8.5}>
                      <SearchAutoComplete
                        hos
                        name="city_id"
                        label="City"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 1, flexGrow: 1 }}>
                    <Grid item xs={6} sm={6} md={3.5}>
                      <FormLabel required>Phone Code </FormLabel>
                    </Grid>
                    <Grid item xs={6} sm={6} md={8.5}>
                      <SelectWrapper
                        //  ref={countryCode}
                        name="phone_code"
                        options={countryData.map((option: any, index: any) => (
                          <MenuItem
                            key={index}
                            value={option.phone_country_code}
                          >
                            <div className={classes.flexBox}>
                              {option.name}
                              <div className={classes.marginFlag}>
                                {`(+${option.phone_country_code})`}
                              </div>
                            </div>
                          </MenuItem>
                        ))}
                        size="small"
                        setCountryCode={setCountryCode}
                      />
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mt: 1, flexGrow: 1 }}>
                    <Grid item xs={6} sm={6} md={3.5}>
                      <FormLabel required>Phone Number </FormLabel>
                    </Grid>
                    <Grid item xs={6} sm={6} md={8.5}>
                      <Textfield
                        name="phone_number"
                        placeholder="Phone Number"
                        size="small"
                        type="number"
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 1, flexGrow: 1 }}>
                    <Grid item xs={6} sm={6} md={3.5}>
                      <FormLabel required>
                        Institution/Hospital/Clinic
                      </FormLabel>
                    </Grid>

                    <Grid item xs={6} sm={6} md={8.5}>
                      <Textfield
                        name="hospital_name"
                        placeholder="Institution/Hospital/Clinic Name"
                        size="small"
                      />
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mt: 1, flexGrow: 1 }}>
                    <Grid item xs={6} sm={6} md={3.5}>
                      <FormLabel>Address</FormLabel>
                    </Grid>
                    <Grid item xs={6} sm={6} md={8.5}>
                      {/* <Textfield
                        name="address"
                        placeholder="Address"
                        size="small"
                      /> */}
                      <GoogleMapSearch
                        name="address"
                        placeholder="Address"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 1.5 }}
                >
                  <Button
                    sx={{ fontWeight: "bold", mr: 0.5 }}
                    autoFocus
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    sx={[
                      {
                        color: "var(--white)",
                      },
                    ]}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Box>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
