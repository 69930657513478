import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../screens/login";
import RequireAuth from "./RequireAuth";
import Utils from "../utils";
import Container from "../templates/Container";
import LeftScreen from "../screens/login/LeftScreen";
import OtpScreen from "../screens/login/Otp";
import SignUp from "../screens/signUp";
import TermsAndCondition from "../screens/termsCondition/index";
import NotFound from "../components/notfound";
import Dashboard from "../screens/dashboard";
import MyClaims from "../screens/myClaims";
import MedicineOrders from "../screens/medicineOrders";
import CreateOrder from "../screens/medicineOrders/createOrder";
import CreateClaim from "../screens/myClaims/createClaim";
import ClaimDetail from "../screens/myClaims/claimDetail";
import DoctorPrescriptions from "../screens/doctorPrescriptions";
import HospitalPrescriptions from "../screens/hospitalPrescription";
import DrugFormulary from "../screens/drugFormulary";
import MyProfile from "../screens/myProfile";
import EditMyProfile from "../screens/myProfile/editMyProfile";
import SentClaimDetail from "../screens/myClaims/sentClaims/sentClaimDetail";
import OrderDetail from "../screens/medicineOrders/sentClaims/sentClaimDetail";
import CreateClaimPrescription from "../screens/doctorPrescriptions/createClaimPrescription";
import ParentCompany from "../screens/payment";
import PaymentReview from "../screens/payment/review";
import WebsiteManagement from "../screens/websitManagement";
// import PaymentReview from "../screens/payment/review"
import RegisterFaceBiomteric from "../screens/registerFaceBiometric";
import CreateClaimHospitalPrescription from "../screens/hospitalPrescription/createClaimPrescription";
import Exclusions from "../screens/exclusions";
import ManageSubscription from "../screens/myProfile/manageSubscription";

function Router() {
  const linked_hospital_id = localStorage.getItem("linked_hospital_id");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login children={<LeftScreen />} />} />
        <Route
          path={Utils.routes.otp}
          element={<Login children={<OtpScreen />} />}
        />

        <Route
          path={Utils.routes.signUp}
          element={
            <Container>
              <SignUp />
            </Container>
          }
        />
        <Route
          path={Utils.routes.termsAndCondition}
          element={
            <>
              <TermsAndCondition />
            </>
          }
        />
        <Route
          path={Utils.routes.dashboard}
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.myClaims}
          element={
            <RequireAuth>
              <MyClaims />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.medicineOrderRequest}
          element={
            <RequireAuth>
              <MedicineOrders />
            </RequireAuth>
          }
        />
        <Route
          path={`${Utils.routes.createMedicineOrderRequest}`}
          element={
            <RequireAuth>
              <CreateOrder />
            </RequireAuth>
          }
        />
        <Route
          path={`${Utils.routes.createClaim}`}
          element={
            <RequireAuth>
              <CreateClaim />
            </RequireAuth>
          }
        />
        <Route
          path={`${Utils.routes.editClaim}/:urlClaimId`}
          element={
            <RequireAuth>
              <CreateClaim />
            </RequireAuth>
          }
        />
        <Route
          path={`${Utils.routes.myProfile}/:tabID`}
          element={
            <RequireAuth>
              <MyProfile />
            </RequireAuth>
          }
        />
        <Route
          path={`${Utils.routes.sentClaimDetail}/:claimId`}
          element={
            <RequireAuth>
              <SentClaimDetail />
            </RequireAuth>
          }
        />
        <Route
          path={`${Utils.routes.medicineOrderDetail}/:claimId`}
          element={
            <RequireAuth>
              <OrderDetail />
            </RequireAuth>
          }
        />
        <Route
          path={`${Utils.routes.claimDetail}/:claimId`}
          element={
            <RequireAuth>
              <ClaimDetail />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.doctorPrescriptions}
          element={
            <RequireAuth>
              <DoctorPrescriptions />
            </RequireAuth>
          }
        />
        {linked_hospital_id && (
          <Route
            path={Utils.routes.hospitalPrescriptions}
            element={
              <RequireAuth>
                <HospitalPrescriptions />
              </RequireAuth>
            }
          />
        )}
        <Route
          path={`${Utils.routes.createClaimPrescription}/:draftedClaimId`}
          element={
            <RequireAuth>
              <CreateClaimPrescription />
            </RequireAuth>
          }
        />
        <Route
          path={`${Utils.routes.createClaimPrescriptionForHospital}/:draftedClaimId`}
          element={
            <RequireAuth>
              <CreateClaimHospitalPrescription />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.drugFormulary}
          element={
            <RequireAuth>
              <DrugFormulary />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.websiteManagement}
          element={
            <RequireAuth>
              <WebsiteManagement />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.registerFaceBiometric}
          element={
            <RequireAuth>
              <RegisterFaceBiomteric />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.exclusions}
          element={
            <RequireAuth>
              <Exclusions />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.myProfile}
          element={
            <RequireAuth>
              <MyProfile />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.parentCompany}
          element={
            <RequireAuth>
              <ParentCompany />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.myProfileEdit}
          element={
            <RequireAuth>
              <EditMyProfile />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.reviewInsPay}
          element={
            <RequireAuth>
              <PaymentReview />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.subscriptionHistory}
          element={
            <RequireAuth>
              <ManageSubscription />
            </RequireAuth>
          }
        />
        {/* <Route
          path={Utils.routes.parentCompany}
          element={
            <RequireAuth>
              <ParentCompany />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.registerFaceBiometric}
          element={
            <RequireAuth>
              <RegisterFaceBiomteric />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.myProfile}
          element={
            <RequireAuth>
              <MyProfile />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.reviewInsPay}
          element={
            <RequireAuth>
              <PaymentReview />
            </RequireAuth>
          }
        />
        <Route
          path={Utils.routes.myProfileEdit}
          element={
            <RequireAuth>
              <EditMyProfile />
            </RequireAuth>
          }
        />*/}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
