import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableFooter,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ReducersModal } from "../../../modal";
import Utils from "../../../utils";
import { getInscurancePaymentClaims } from "../action";
import { statusArray } from "../../../utils/appData";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { formatPaymentMethod } from "../../../utils/commonFunctions";

function TableComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);

  const { data, limit, count, page, isClaimCheck, option } = useSelector(
    (state: ReducersModal) => state.insurancePaymentReducer
  );
  // const [page, setPage] = useState(1);

  const handleChangePage = (value: any) => {
    setSelectAll(false);
    dispatch({
      type: Utils.ActionName.INSURANCE_PAYMENT,
      payload: {
        page: value + 1,
        isClaimCheck: [],
      },
    });
    // setPage(value + 1);
  };

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PAYMENT,
      payload: {
        offset: page * limit - limit,
      },
    });
    dispatch(getInscurancePaymentClaims());
  }, [page]);

  const handleChangeRowsPerPage = (evt: any) => {
    setSelectAll(false);
    dispatch({
      type: Utils.ActionName.INSURANCE_PAYMENT,
      payload: {
        limit: +evt.target.value,
        offset: 0,
        isClaimCheck: [],
      },
    });
    dispatch(getInscurancePaymentClaims());
    // setRowsPerPage(10)
  };

  const readyToPayment = (item: typeof data) => {
    return item.filter((ele) => +ele?.status_id === 7);
  };

  const handleSelectAll = (e: any, item: typeof data) => {
    setSelectAll(e.target.checked);
    if (!e.target.checked) {
      dispatch({
        type: Utils.ActionName.INSURANCE_PAYMENT,
        payload: {
          isClaimCheck: [],
        },
      });
      return;
    }
    const readyToPay = readyToPayment(item);
    dispatch({
      type: Utils.ActionName.INSURANCE_PAYMENT,
      payload: {
        isClaimCheck: readyToPay.map((el: any) =>
          +option === 1 ? el?.claim_id : el?.order_id
        ),
      },
    });
  };
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              <TableCell>
                <FormControlLabel
                  sx={{ ml: 1.5, mr: 0.5 }}
                  control={
                    <Checkbox
                      sx={{ p: 0 }}
                      name="selectAll"
                      checked={selectAll}
                      disabled={
                        !(data.length > 0) || !(readyToPayment(data).length > 0)
                      }
                      onChange={(e: any) => {
                        handleSelectAll(e, data);
                      }}
                    />
                  }
                  label={""}
                />
                Select All
              </TableCell>
              <TableCell>{option == 2 ? "Order ID" : "Claim ID"}</TableCell>
              {option == 1 && <TableCell>Order ID</TableCell>}
              <TableCell>
                {option == 2 ? "Order Amount" : "Claim Amount"}{" "}
              </TableCell>
              <TableCell> Pharmacy Name </TableCell>
              <TableCell>Payment Status </TableCell>
              <TableCell>Payment Method </TableCell>
              <TableCell>Insurance Company </TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((data: any) => {
                return (
                  <TableRow
                    hover
                    key={data.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ position: "relative" }}>
                      {+data.status_id === 7 && (
                        <img
                          style={{
                            width: "30px",
                            position: "absolute",
                            top: 17,
                            left: 2,
                          }}
                          src={
                            "https://mdportal-livia.appskeeper.in/80fff05601f6a046a51f4aa32a5906a2.gif"
                          }
                          alt=""
                        />
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ marginLeft: 17 }}
                            id={+option === 1 ? data.claim_id : data.order_id}
                            name="termsConditionChecked"
                            checked={isClaimCheck.includes(
                              +option === 1 ? data?.claim_id : data.order_id
                            )}
                            disabled={+data.status_id !== 7}
                            onChange={(e: any) => {
                              dispatch({
                                type: Utils.ActionName.INSURANCE_PAYMENT,
                                payload: {
                                  isClaimCheck: isClaimCheck.includes(
                                    e.target.id
                                  )
                                    ? isClaimCheck.filter(
                                        (res) => res != e.target.id
                                      )
                                    : [...isClaimCheck, e.target.id],
                                },
                              });
                            }}
                          />
                        }
                        label={" "}
                      />
                    </TableCell>
                    <TableCell>
                      {+option === 1 ? data.claim_id : data.order_id}
                      {/* ? moment(data.claim_date).format("DD/MM/YYYY")
                        : "N/A"} */}
                    </TableCell>
                    {option == 1 && (
                      <TableCell>
                        {data.order_id ? data.order_id : "N/A"}
                      </TableCell>
                    )}
                    <TableCell>
                      {data.full_amount ? data.full_amount + " KES" : "N/A"}
                    </TableCell>
                    <TableCell>
                      {data?.pharmacy_name ? data?.pharmacy_name : "N/A"}
                    </TableCell>
                    <TableCell>
                      {data.status_name ? data.status_name : "N/A"}
                    </TableCell>
                    {/* pay_method === "insurance" ? "Cash" : data.pay_method === "cash" ? "cash" : data.pay_method === "flutter_wave" ? "Debit/Credit card" : data.pay_method === "mpesa" ? "mpesa" : "N/A" */}
                    <TableCell>
                      {data?.pharmacy_payment_method
                        ? formatPaymentMethod(data?.pharmacy_payment_method)
                        : "N/A"}{" "}
                    </TableCell>
                    <TableCell>
                      {data.insurance_company_name
                        ? data.insurance_company_name
                        : "-"}{" "}
                    </TableCell>

                    <TableCell>
                      {data.payment_done_id ? (
                        <>
                          {data.payment_done_id}{" "}
                          <div>
                            (
                            {moment(data.payment_done_date).format(
                              "DD/MM/YYYY"
                            )}
                            )
                          </div>
                        </>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      {data?.createDate
                        ? moment(data.createDate).format("DD/MM/YYYY")
                        : "-"}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                key={1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {" "}
                <TableCell>{"No Data Found"}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length > 0 && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={count}
              rowsPerPage={limit}
              page={page - 1}
              onPageChange={(e, value): any => handleChangePage(value)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={<span>Rows:</span>}
              sx={{
                ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
                  fontWeight: "bold",
                  color: "primary",
                },
              }}
            />
          </TableRow>
        </TableFooter>
      )}
    </>
  );
}

export default TableComponent;
