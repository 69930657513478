import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { useField } from "formik";

type InputColorProps = {
  name: string;
} & InputBaseProps;

const StyledInputBase = styled(InputBase)<InputColorProps>(({ theme }) => ({
  width: theme.spacing(5),
  "& .MuiInputBase-input": {
    padding: 0,
  },
}));

function InputColor({ name, ...otherProps }: InputColorProps) {
  const [field] = useField(name);

  const configInputBase = {
    ...field,
    ...otherProps,
  };

  return <StyledInputBase {...configInputBase} type="color" />;
}

export default InputColor;
