import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import { ReducersModal } from "../../modal";

function PharmacyDevices() {
  const { pharmacy_devices } = useSelector(
    (state: ReducersModal) => state.MyProfilePersonalInfoReducer
  );
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "16px" }}>Unique Code </TableCell>
              <TableCell sx={{ fontSize: "16px" }}>Device Name</TableCell>
              <TableCell sx={{ fontSize: "16px" }}>Associated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pharmacy_devices?.length > 0 ? (
              pharmacy_devices.map((device: any) => {
                return (
                  <TableRow key={device.id}>
                    <TableCell>
                      {device?.pharmacy_unique_code
                        ? device.pharmacy_unique_code
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {device?.device_name ? device.device_name : "N/A"}
                    </TableCell>
                    <TableCell>
                      {device?.is_associated == "1" ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow key={1}>
                <TableCell colSpan={3}>{"No Devices Found"}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default PharmacyDevices;
