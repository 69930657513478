import { makeStyles, createStyles } from "@mui/styles";
import {
  Avatar,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Theme,
  Typography,
  TextField as TextFieldMui,
  InputAdornment,
  MenuItem,
} from "@mui/material";
//@ts-ignore
import ReactFileReader from "react-file-reader";
import TextField from "../../components/formsUi/textFiled";
import InputFile from "../../components/formsUi/inputFile";
import { useEffect, useState } from "react";
// import CircularLoader from "../../components/circularLoader";
import SelectWrapper from "../../components/formsUi/select";
import { useField, useFormikContext } from "formik";
import {
  checkFileSize,
  checkFileType,
  checkImageWAH,
  deleteDocumentImage,
  getFileType,
  // deleteDocumentImage,
  uploadDocumentImage,
} from "../../utils/commonFunctions";
import CheckIcon from "@mui/icons-material/Check";
import { toastIdsConstant } from "../../utils/toastIdsConstant";
import { toast } from "react-toastify";
// import GoogleMapSearch from "../../components/formsUi/googleMapSearch";
import SearchAutoComplete from "../../components/formsUi/searchAutoComplete";
// import { LocalImages } from "../../utils/images";
import { Box } from "@mui/system";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import SelectLocation from "./selectLocation";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import FormField from "../../schema/formField";
import { LocalImages } from "../../utils/images";
import Utils from "../../utils";
import ImgCrop from "../../components/imgCrop";
import PreviewImg from "../../components/previewImg";
import DateTimePicker from "../../components/formsUi/dateTimePicker";
import moment from "moment";
import { ReducersModal } from "../../modal";
// import Utils from "../../utils";
// import AsynSearchTextField from "../../components/formsUi/asyncSearchTextField";

const API_URL = Utils.constants.API_URL;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    gridItem: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
    },
    avatarDiv: {
      paddingLeft: theme.spacing(3),
    },
    avatar: {
      boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.2)",
      "&.MuiAvatar-root": {
        width: theme.spacing(6.5),
        height: theme.spacing(6.5),
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
      },
    },
    closeIcon: {
      position: "absolute",
      top: -10,
      right: -10,
      cursor: "pointer",
    },
    singleCard: {
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "7px",
      boxShadow:
        "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
      height: "100%",
      width: "400px",
      maxWidth: "100%",
      textAlign: "center",
      margin: "auto",
    },
    avatarCover: {
      position: "relative",
      margin: "auto",
      display: "inline-block",
    },
    typography: {
      "&.MuiTypography-root": {
        marginTop: theme.spacing(2),
      },
    },
    gridItemTwo: {
      height: "100%",
      justifyContent: "space-between",
    },
    avatarDoc: {
      "&.MuiAvatar-root": {
        width: theme.spacing(10),
        height: theme.spacing(10),
        backgroundColor: theme.palette.secondary.light,
        position: "relative",
        cursor: "pointer",
      },
    },
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      backgroundColor: "var(--white)",
      boxShadow: "24",
    },
    urlSuggestions: {
      listStyle: "none",
      cursor: "pointer",
      fontSize: "12px",
      width: "45%",
      padding: "7px !important",
      border: "1px solid #53c392",
      borderRadius: "9px",
      textAlign: "center",
      margin: "5px",
      color: "#53c392",
      fontWeight: "bold",
    },
    activeSuggestion: {
      backgroundColor: "#53c392 !important",
      color: "white !important",
    },
  })
);

function CreateProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [physicalAddressField, physicalAddressMata] = useField("physical_address");
  const [imageUploadProgress, setImageUploadProgress] = useState({
    profilePic: false,
    practiceCertificate: false,
    licensePharmacy: false,
    businessPermit: false,
    pinCertificate: false,
    crDocument: false,
    taxCompliance: false,
  });
  const [viewImage, setViewImage] = useState<any>({
    profilePic: "",
    practiceCertificate: "",
    licensePharmacy: "",
    businessPermit: "",
    pinCertificate: "",
    crDocument: "",
    taxCompliance: "",
  });
  const [open, setOpen] = useState(false);
  const [openImgCrop, setOpenImgCrop] = useState(false);
  const [openImgCropSrc, setOpenImgCropSrc] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [previewImgUrl, setPreviewImgUrl] = useState("");
  const { setFieldValue } = useFormikContext();
  const [urlPostFix, setPostFix] = useState<any>(process.env.REACT_APP_DOMAIN);
  const [preDomain, setPreDomain] = useState("");
  const [fullDomain, setFullDomain] = useState("");
  const [field, mata] = useField("profilePic");
  const [domainField, meta] = useField("domain");
  const [pharmacyNameField, pharmacyNameError] = useField("pharmacyName");
  const [isVerified, setVerified] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionsList, setSuggestionList] = useState([]);
  const {
    phone_number,
    email,
    id_number,
    practice_certificate,
    license_of_pharmacy,
    business_permit,
    pin_certificate,
    cr_12,
    tax_compliance,
  } = useSelector((state: ReducersModal) => state.getSignupCreateProfleDetailsReducer);

  // useEffect(() => {
  //   setPostFix(process.env.DOMAIN);
  // }, []);
  useEffect(() => {
    setVerified(false);
  }, [fullDomain]);
  useEffect(() => {
    console.log(domainField?.value);
    setFullDomain(domainField?.value + urlPostFix);
  }, [domainField]);
  useEffect(() => {
    if (phone_number && email) {
      setViewImage({
        profilePic: id_number,
        practiceCertificate: practice_certificate,
        licensePharmacy: license_of_pharmacy,
        businessPermit: business_permit,
        pinCertificate: pin_certificate,
        crDocument: cr_12,
        taxCompliance: tax_compliance,
      });
    }
  }, [phone_number, email]);

  const configSelect: any = {
    ...physicalAddressField,
    // onChange: handleChange,
  };

  if (physicalAddressMata && physicalAddressMata.touched && physicalAddressMata.error) {
    configSelect.error = true;
    configSelect.helperText = physicalAddressMata.error;
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenImgCrop = () => {
    setOpenImgCrop(true);
  };
  const handleCloseImgCrop = () => {
    setOpenImgCrop(false);
  };

  const addressFieldOnClickHandler = () => {
    if ("navigator" in window) {
      navigator.permissions
        .query({
          name: "geolocation",
        })
        .then((result) => {
          if (result.state === "denied") {
            if (!toast.isActive(toastIdsConstant.userDeniedGeoLocationPermission)) {
              toast.warn("User denied the request for Geolocation.", {
                toastId: toastIdsConstant.userDeniedGeoLocationPermission,
                className: "toast-warning",
              });
            }
          } else {
            handleOpen();
            // setState({
            //   lat: null,
            //   lng: null,
            //   defaultLat: null,
            //   defaultLon: null,
            //   currentAddress: this.state.address,
            // });
            // getLocation();
            // setState({ openMap: true });
          }
        });
    }
  };

  const addCropperProfilePicture = (files: any, imageName: string) => {
    return new Promise((resolve, reject) => {
      if (imageName === "profilePic") {
        dispatch(globalLoaderTrue());
        uploadDocumentImage(files.split(",")[1])
          .then((res: any) => {
            if (res?.status === 200) {
              setViewImage({ ...viewImage, [imageName]: res?.data?.image });
              setFieldValue(imageName, res?.data?.image);
              setImageUploadProgress({
                ...imageUploadProgress,
                [imageName]: false,
              });
              resolve("upload profile picture");
              dispatch(globalLoaderFalse());
            }
          })
          .catch((err: any) => {
            if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
              toast.error(err?.response?.data?.messages && err?.response?.data?.messages[0], {
                toastId: toastIdsConstant.loginApiFailure,
                className: "toast-error",
              });
            }
            reject(err?.response?.data?.messages && err?.response?.data?.messages[0]);
            dispatch(globalLoaderFalse());
          });
      }
    });
  };

  const addProfilePicture = (files: any, imageName: string) => {
    if (checkFileSize(files.fileList[0])) return;
    console.log("files.fileList[0].type.split", files.fileList[0].type.split("/")[1]);
    if (checkFileType(files)) return;
    if (files.base64) {
      dispatch(globalLoaderTrue());
      // setImageUploadProgress({ ...imageUploadProgress, [imageName]: true });
      // console.log(files.base64.split(",")[1]);
      uploadDocumentImage(files.base64.split(",")[1])
        .then((res: any) => {
          if (res?.status === 200) {
            setViewImage({ ...viewImage, [imageName]: res?.data?.image });
            setFieldValue(imageName, res?.data?.image);
            setImageUploadProgress({
              ...imageUploadProgress,
              [imageName]: false,
            });
            dispatch(globalLoaderFalse());
          }
        })
        .catch((err: any) => {
          if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
            toast.error(err?.response?.data?.messages && err?.response?.data?.messages[0], {
              toastId: toastIdsConstant.loginApiFailure,
              className: "toast-error",
            });
          }
          // setImageUploadProgress({
          //   ...imageUploadProgress,
          //   [imageName]: false,
          // });
          dispatch(globalLoaderFalse());
        });
    }
  };

  const deleteProfilePhoto = (imageName: any) => {
    if (!imageName) return;
    if (!viewImage[imageName]) return;
    if (phone_number && email) {
      setViewImage({ ...viewImage, [imageName]: "" });
      setFieldValue(imageName, "");
    } else {
      // *setImageUploadProgress({ ...imageUploadProgress, [imageName]: true });
      dispatch(globalLoaderTrue());
      // *console.log(viewImage[imageName]);
      Utils.constants.getAccessToken();
      deleteDocumentImage(viewImage[imageName])
        .then((res: any) => {
          // console.log(res);
          if (res?.data?.code === 200) {
            // setImageUploadProgress({
            //   ...imageUploadProgress,
            //   [imageName]: false,
            // });
            setFieldValue(imageName, "");
            setViewImage({ ...viewImage, [imageName]: "" });
            dispatch(globalLoaderFalse());
          }
        })
        .catch((err: any) => {
          if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
            toast.error(err?.response?.data?.messages && err?.response?.data?.messages[0], {
              toastId: toastIdsConstant.loginApiFailure,
              className: "toast-error",
            });
          }
          // setImageUploadProgress({ ...imageUploadProgress, [imageName]: false });
          dispatch(globalLoaderFalse());
        });
    }
  };

  const setImageIconOrUrl = (url: string): string => {
    if (getFileType(url) === "doc") {
      return LocalImages.DocIcon;
    }
    if (getFileType(url) === "pdf") {
      return LocalImages.PdfIcon;
    }
    if (getFileType(url) === "xlsx") {
      return LocalImages.ExcelIcon;
    }
    return API_URL + url;
  };

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const previewImgOpen = () => {
    handleOpenPreview();
  };

  const getMinDate = () => {
    return moment(new Date()).format("yyyy-MM-DD");
  };

  const getMaxDate = () => {
    return moment(new Date()).add(1, "years").add(1, "months").format("yyyy-MM-DD");
  };

  useEffect(() => {
    if (pharmacyNameField.value.trim() && showSuggestions) {
      getSuggestions();
    }
  }, [pharmacyNameField.value]);

  const verifyDomain = () => {
    dispatch(globalLoaderTrue());
    Utils.api.putApiCall(
      Utils.endPoints.domainVerify,
      { url: fullDomain, option: 1 },
      (response: any) => {
        dispatch(globalLoaderFalse());
        console.log("response", response);
        if (response?.data?.available === true) {
          setVerified(true);
          setSuggestionList([]);
        } else {
          setVerified(false);
          Utils.showAlert(2, "Sorry, the URL is already taken. Please use another URL.");
          if (pharmacyNameField?.value.trim()) {
            getSuggestions();
          } else {
            pharmacyNameError.touched = true;
          }
        }
      },
      (error: any) => {
        setVerified(false);
        dispatch(globalLoaderFalse());
      }
    );
  };

  const getSuggestions = () => {
    dispatch(globalLoaderTrue());
    Utils.api.putApiCall(
      Utils.endPoints.domainVerify,
      {
        pharmacy_name: pharmacyNameField?.value.trim(),
        option: 2,
      },
      (response: any) => {
        setSuggestionList(response?.data?.suggestions);
        setShowSuggestions(true);
        dispatch(globalLoaderFalse());
      },
      (error: any) => {
        dispatch(globalLoaderFalse());
        setSuggestionList([]);
        setShowSuggestions(false);
      }
    );
  };
  return (
    <div className={classes.root}>
      {open && <SelectLocation handleClose={handleClose} />}
      <div className="bg-white mb-20">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            {imageUploadProgress.profilePic && (
              <Avatar alt="Profile image" className={classes.avatar} src="/static/images/avatar/1.jpg">
                <CircularProgress size={20} color="primary" />
              </Avatar>
            )}
            {viewImage.profilePic && (
              <div className={classes.avatarCover}>
                <Avatar
                  onClick={() => {
                    setPreviewImgUrl(API_URL + viewImage.profilePic);
                    previewImgOpen();
                  }}
                  className={classes.avatar}
                  sx={{ cursor: "pointer" }}
                  alt="No Images"
                  src={API_URL + viewImage.profilePic}
                />
                <img
                  alt="Remy Sharp"
                  className={classes.closeIcon}
                  src={LocalImages.CloseIcon}
                  title="Remove photo"
                  onClick={() => deleteProfilePhoto("profilePic")}
                />
              </div>
            )}
            {!viewImage.profilePic && !imageUploadProgress.profilePic && (
              <Avatar className={classes.avatar} alt="" src="" />
            )}
            <Typography className={classes.typography}>Upload Pharmacy's Profile Pic</Typography>
            {mata && mata.touched && mata?.error && (
              <>
                <FormHelperText
                  sx={{
                    color: "error.main",
                    fontSize: (theme) => theme.spacing(1.8),
                  }}
                >
                  {mata.error}
                </FormHelperText>
              </>
            )}
            <ImgCrop
              src={openImgCropSrc}
              open={openImgCrop}
              handleClose={handleCloseImgCrop}
              uploadImage={addCropperProfilePicture}
              type="profilePic"
            />
            <ReactFileReader
              handleFiles={(files: any) => {
                // checkImageWAH(files)
                // .then(() => {
                setOpenImgCropSrc(files);
                handleOpenImgCrop();
                // })
              }}
              base64={true}
              multipleFiles={false}
              fileTypes={[".jpg", ".jpeg", ".png", ".gif"]}
              disabled={!!viewImage.profilePic}
            >
              <Button
                disabled={!!viewImage.profilePic}
                {...field}
                variant="contained"
                sx={{
                  color: "var(--white)",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                Choose File
              </Button>
            </ReactFileReader>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth name="pharmacyName" label="Pharmacy Name" size="small" />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <SelectWrapper
                  name="surname"
                  options={Object.keys(FormField.optionsSurname).map((item, pos) => {
                    return (
                      <MenuItem key={pos} value={FormField.optionsSurname[item]}>
                        {FormField.optionsSurname[item]}
                      </MenuItem>
                    );
                  })}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={7} md={9}>
                <TextField name="pharmacyAdminName" label="Pharmacy Admin Name" size="small" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SearchAutoComplete name="searchPharmacyCity" label="Search Pharmacy City" size="small" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth name="emailId" label="Email Id" size="small" />
              </Grid>
              <Grid item xs={12}>
                <TextFieldMui
                  fullWidth
                  {...configSelect}
                  label="Add Address"
                  size="small"
                  onClick={() => {
                    addressFieldOnClickHandler();
                  }}
                  onKeyUp={() => {
                    addressFieldOnClickHandler();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AddLocationIcon color="primary" />
                      </InputAdornment>
                    ),
                    // readOnly: true,
                  }}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: "flex", width: "100%" }}>
                  {/* <div> */}
                  <Grid item xs={12} md={11}>
                    <TextField
                      fullWidth
                      name="domain"
                      label="URL"
                      size="small"
                      disabled={!pharmacyNameField?.value.trim()}
                    />
                    <span>{fullDomain ? fullDomain : urlPostFix}</span>
                  </Grid>
                  {/* </div> */}
                  &nbsp;&nbsp; &nbsp;{" "}
                  <Grid item xs={12} md={4}>
                    {!isVerified ? (
                      <Button
                        disabled={
                          !domainField.value.trim() //||
                          // !pharmacyNameField?.value.trim()
                        }
                        variant="contained"
                        sx={{
                          color: "var(--white)",
                          fontWeight: "bold",
                          // marginTop: "10px",
                        }}
                        onClick={verifyDomain}
                      >
                        Verify URL
                      </Button>
                    ) : (
                      <div
                        style={{
                          color: "#53c392",
                          display: "flex",
                          fontSize: "15px",
                        }}
                      >
                        <CheckIcon style={{ marginTop: "7px" }} />
                        &nbsp;
                        <span style={{ marginTop: "11px" }}>Verified</span>
                      </div>
                    )}
                  </Grid>
                </div>
              </Grid>
              {suggestionsList.length > 0 && pharmacyNameField?.value.trim() && (
                <Grid item xs={12}>
                  Available urls
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {suggestionsList.map((listItem, index) => (
                      <li
                        className={`${classes.urlSuggestions} ${
                          domainField.value === listItem ? classes.activeSuggestion : ""
                        }`}
                        key={index}
                        onClick={() => {
                          console.log(listItem);
                          setFieldValue("domain", listItem);
                        }}
                      >{`${listItem}${process.env.REACT_APP_DOMAIN}`}</li>
                    ))}
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Typography className="mb-20 bold center" sx={{ fontSize: "26px" }}>
        Attach Documents
      </Typography>
      <Grid container spacing={3} className="mb-30">
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.singleCard}>
            <h4 className="mb-10">Practice Certificate of Pharmacist in Charge</h4>
            {viewImage.practiceCertificate && (
              <div className={classes.avatarCover}>
                <Avatar
                  onClick={() => {
                    setPreviewImgUrl(setImageIconOrUrl(viewImage.practiceCertificate));
                    previewImgOpen();
                  }}
                  variant="square"
                  className={classes.avatarDoc}
                  alt="Remy Sharp"
                  src={setImageIconOrUrl(viewImage.practiceCertificate)}
                ></Avatar>
                <img
                  alt="Remy Sharp"
                  className={classes.closeIcon}
                  src={LocalImages.CloseIcon}
                  title="Remove photo"
                  onClick={() => deleteProfilePhoto("practiceCertificate")}
                />
              </div>
            )}
            {!viewImage.practiceCertificate && !imageUploadProgress.practiceCertificate && (
              <InputFile
                name="practiceCertificate"
                handleFiles={(files: any) => {
                  addProfilePicture(files, "practiceCertificate");
                }}
              />
            )}
            <DateTimePicker
              name="practice_certificate_expiry"
              id="date"
              label="Expiry date"
              type="date"
              size="small"
              className="mt-20"
              style={{ width: "276px", maxWidth: "100%" }}
              inputProps={{
                min: getMinDate(),
                max: getMaxDate(),
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.singleCard}>
            <h4 className="mb-10">License of Pharmacy</h4>
            {viewImage.licensePharmacy && (
              <div className={classes.avatarCover}>
                <Avatar
                  onClick={() => {
                    setPreviewImgUrl(setImageIconOrUrl(viewImage.licensePharmacy));
                    previewImgOpen();
                  }}
                  variant="square"
                  className={classes.avatarDoc}
                  alt="Remy Sharp"
                  src={setImageIconOrUrl(viewImage.licensePharmacy)}
                />
                <img
                  alt="Remy Sharp"
                  className={classes.closeIcon}
                  src={LocalImages.CloseIcon}
                  title="Remove photo"
                  onClick={() => deleteProfilePhoto("licensePharmacy")}
                />
              </div>
            )}
            {!viewImage.licensePharmacy && !imageUploadProgress.licensePharmacy && (
              <InputFile
                name="licensePharmacy"
                handleFiles={(files: any) => {
                  addProfilePicture(files, "licensePharmacy");
                }}
              />
            )}
            <DateTimePicker
              name="license_of_pharmacy_expiry"
              id="date"
              label="Expiry date"
              type="date"
              size="small"
              className="mt-20"
              style={{ width: "276px", maxWidth: "100%" }}
              inputProps={{
                min: getMinDate(),
                max: getMaxDate(),
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.singleCard}>
            <h4 className="mb-10">Business Permit</h4>
            {viewImage.businessPermit && (
              <div className={classes.avatarCover}>
                <Avatar
                  onClick={() => {
                    setPreviewImgUrl(setImageIconOrUrl(viewImage.businessPermit));
                    previewImgOpen();
                  }}
                  variant="square"
                  className={classes.avatarDoc}
                  alt="Remy Sharp"
                  src={setImageIconOrUrl(viewImage.businessPermit)}
                />
                <img
                  alt="Remy Sharp"
                  className={classes.closeIcon}
                  src={LocalImages.CloseIcon}
                  title="Remove photo"
                  onClick={() => deleteProfilePhoto("businessPermit")}
                />
              </div>
            )}
            {!viewImage.businessPermit && !imageUploadProgress.businessPermit && (
              <InputFile
                name="businessPermit"
                handleFiles={(files: any) => {
                  addProfilePicture(files, "businessPermit");
                }}
              />
            )}
            <DateTimePicker
              name="business_permit_expiry"
              id="date"
              label="Expiry date"
              type="date"
              size="small"
              className="mt-20"
              style={{ width: "276px", maxWidth: "100%" }}
              inputProps={{
                min: getMinDate(),
                max: getMaxDate(),
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.singleCard}>
            <h4 className="mb-10">PIN (TAX) Certificate</h4>
            {viewImage.pinCertificate && (
              <div className={classes.avatarCover}>
                <Avatar
                  onClick={() => {
                    setPreviewImgUrl(setImageIconOrUrl(viewImage.pinCertificate));
                    previewImgOpen();
                  }}
                  variant="square"
                  className={classes.avatarDoc}
                  alt="Remy Sharp"
                  src={setImageIconOrUrl(viewImage.pinCertificate)}
                />
                <img
                  alt="Remy Sharp"
                  className={classes.closeIcon}
                  src={LocalImages.CloseIcon}
                  title="Remove photo"
                  onClick={() => deleteProfilePhoto("pinCertificate")}
                />
              </div>
            )}
            {!viewImage.pinCertificate && !imageUploadProgress.pinCertificate && (
              <InputFile
                name="pinCertificate"
                handleFiles={(files: any) => {
                  addProfilePicture(files, "pinCertificate");
                }}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.singleCard}>
            <h4 className="mb-10">CR12</h4>
            {viewImage.crDocument && (
              <div className={classes.avatarCover}>
                <Avatar
                  onClick={() => {
                    setPreviewImgUrl(setImageIconOrUrl(viewImage.crDocument));
                    previewImgOpen();
                  }}
                  variant="square"
                  className={classes.avatarDoc}
                  alt="Remy Sharp"
                  src={setImageIconOrUrl(viewImage.crDocument)}
                />
                <img
                  alt="Remy Sharp"
                  className={classes.closeIcon}
                  src={LocalImages.CloseIcon}
                  title="Remove photo"
                  onClick={() => deleteProfilePhoto("crDocument")}
                />
              </div>
            )}
            {!viewImage.crDocument && !imageUploadProgress.crDocument && (
              <InputFile
                name="crDocument"
                handleFiles={(files: any) => {
                  addProfilePicture(files, "crDocument");
                }}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.singleCard}>
            <h4 className="mb-10">Tax Compliance</h4>
            {viewImage.taxCompliance && (
              <div className={classes.avatarCover}>
                <Avatar
                  onClick={() => {
                    setPreviewImgUrl(setImageIconOrUrl(viewImage.taxCompliance));
                    previewImgOpen();
                  }}
                  variant="square"
                  className={classes.avatarDoc}
                  alt="Remy Sharp"
                  src={setImageIconOrUrl(viewImage.taxCompliance)}
                />
                <img
                  alt="Remy Sharp"
                  className={classes.closeIcon}
                  src={LocalImages.CloseIcon}
                  title="Remove photo"
                  onClick={() => deleteProfilePhoto("taxCompliance")}
                />
              </div>
            )}
            {!viewImage.taxCompliance && !imageUploadProgress.taxCompliance && (
              <InputFile
                name="taxCompliance"
                handleFiles={(files: any) => {
                  addProfilePicture(files, "taxCompliance");
                }}
              />
            )}
            <DateTimePicker
              name="tax_compliance_expiry"
              id="date"
              label="Expiry date"
              type="date"
              size="small"
              className="mt-20"
              style={{ width: "276px", maxWidth: "100%" }}
              inputProps={{
                min: getMinDate(),
                max: getMaxDate(),
              }}
            />
          </div>
        </Grid>
      </Grid>
      <PreviewImg open={openPreview} handleClose={handleClosePreview} image={previewImgUrl} />
      {/* <Modal
        open={false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalStyle}>
          <SelectLocation />
        </Box>
      </Modal> */}
    </div>
  );
}

export default CreateProfile;
