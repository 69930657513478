import { Link } from "react-router-dom";
import { LocalImages } from "../../utils/images";

function NotFound() {
  return (
    <div className="page-not-found" style={{textAlign:'center', display:'flex', alignItems:'center', justifyContent: 'center', flexDirection:'column', minHeight:'100vh', padding: '50px 20px'}}>
            <img src={LocalImages.PageNotFound} style={{width: '100px', marginBottom: ' 50px'}}/>
            <h2 style={{fontWeight:"bold", color:"#152567"}}>404 page not found. <Link to="/">Go to Dashboard</Link> </h2> 
            
          </div>
  );
}

export default NotFound;
