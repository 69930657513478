import { makeStyles, createStyles } from "@mui/styles";
import { Theme, Grid, Box, Typography, Button, Avatar } from "@mui/material";
import ReactFileReader from "react-file-reader";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import WarningIcon from "@mui/icons-material/Warning";
import { useEffect, useState } from "react";
import {
  checkFileSize,
  checkFileType,
  uploadDocumentImage,
  getDocumentImage,
  deleteDocumentImageUploadPrescription,
  getUserIdFromLS,
  validateRadiusRange,
  claimCreateErrMsg,
  getLocation,
  getAddress,
} from "../../utils/commonFunctions";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";
import { toastIdsConstant } from "../../utils/toastIdsConstant";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { ReducersModal } from "../../modal";
import { LocalImages } from "../../utils/images";
import Utils from "../../utils";
import {
  getClaimDetails,
  providerNotInRangeNotification,
  uploadPrescription,
} from "./action";
import { useNavigate, useLocation } from "react-router-dom";
import LocationPopupModal from "../../components/modal/LocationPopupModal";
const API_URL = Utils.constants.API_URL;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    topFormCard: {
      padding: theme.spacing(1.5),
      backgroundColor: "var(--white)",
      marginBottom: theme.spacing(1.5),
    },
    uploadPrescriptionCover: {
      // textAlign: 'center',
      // padding: '15px',
      // boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2)',
      // borderRadius: '5px',
    },
    avatar: {
      "&.MuiAvatar-root": {
        width: theme.spacing(10),
        height: theme.spacing(10),
        backgroundColor: theme.palette.secondary.light,
        position: "relative",
      },
    },
    avatarDoc: {
      "&.MuiAvatar-root": {
        width: theme.spacing(10),
        height: theme.spacing(10),
        backgroundColor: theme.palette.secondary.light,
        position: "relative",
      },
    },
    closeIcon: { position: "absolute", top: -4, left: 77, cursor: "pointer" },
  })
);

function UploadPrescription() {
  const classes = useStyles();
  const [prescriptionArr, setPrescription] = useState<any[]>([]);
  const [claimArr, setClaimArr] = useState<any[]>([]);
  const [getAppPrescriptionArr, setGetAppPrescription] = useState<any[]>([]);
  const [getAppClaimArr, setGetAppClaimArr] = useState<any[]>([]);
  const [uploadPrescriptionArr, setUploadPrescription] = useState<any[]>([]);
  const [uploadClaimArr, setUploadClaimArr] = useState<any[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state }: any = useLocation();

  const { claimId } = useSelector(
    (state: ReducersModal) => state.InsurancePatientProfileReducer
  );
  const { id, documents } = useSelector(
    (state: ReducersModal) => state.claimDetailReducer
  );

  const { subscriptionDetails } = useSelector(
    (state: ReducersModal) => state.SubscriptionStatusReducer
  );

  const { data: ProviderDetails } = useSelector(
    (state: ReducersModal) => state.ProviderDetailsReducer
  );

  const claimNumber = state?.edit ? id : claimId;
  useEffect(() => {
    if (state?.edit && claimNumber) {
      dispatch(getClaimDetails(claimNumber, "claimDeatil"));
    }
  }, [claimNumber, dispatch, state?.edit]);

  useEffect(() => {
    if (state?.edit) {
      if (documents.claim.length > 0) {
        setClaimArr(documents.claim);
        setUploadClaimArr(documents.claim);
      }
      if (documents.prescription.length > 0) {
        setPrescription(documents.prescription);
        setUploadPrescription(documents.prescription);
      }
    }
  }, [state?.edit, documents.claim, documents.prescription]);

  // useEffect(() => {
  //   if (getAppClaimArr.length > 0) {
  //     setUploadClaimArr((preValue: any) => {
  //       let newValue = [...getAppClaimArr];
  //       for (let img of claimArr) {
  //         if (newValue.length < 5) {
  //           newValue.push(img);
  //         }
  //       }
  //       return newValue;
  //     });
  //   } else {
  //     setUploadClaimArr(claimArr);
  //   }

  //   if (getAppPrescriptionArr.length > 0) {
  // setUploadPrescription((preValue: any) => {
  //   let newValue = [...getAppPrescriptionArr];
  //   for (let img of prescriptionArr) {
  //     if (newValue.length < 5) {
  //       newValue.push(img);
  //     }
  //   }
  //   return newValue;
  // });
  //   } else {
  //     setUploadPrescription(prescriptionArr);
  //   }
  //   console.log("claimArr ", getAppClaimArr);
  //   console.log("prescriptionArr ", getAppPrescriptionArr);
  // }, [getAppPrescriptionArr, getAppClaimArr, claimArr, prescriptionArr]);

  console.log({
    claimArr,
    prescriptionArr,
    getAppPrescriptionArr,
    getAppClaimArr,
    uploadPrescriptionArr,
    uploadClaimArr,
  });

  const setImage = (img: string, type: string, opretions: string) => {
    if (type === "claim" && opretions === "add") {
      setUploadClaimArr((preValue: any) => {
        let newValue = [...preValue];
        if (newValue.length < 5) {
          newValue.push(img);
        }
        return newValue;
      });
    }

    if (type === "pres" && opretions === "add") {
      setUploadPrescription((preValue: any) => {
        let newValue = [...preValue];
        if (newValue.length < 5) {
          newValue.push(img);
        }
        return newValue;
      });
    }

    if (type === "claim" && opretions === "delete") {
      const updatedUploadClaimArr = uploadClaimArr.filter((i) => i !== img);
      setUploadClaimArr(updatedUploadClaimArr);

      const updatedGetAppUploadClaimArr = getAppClaimArr.filter(
        (i) => i !== img
      );
      setGetAppClaimArr(updatedGetAppUploadClaimArr);
    }

    if (type === "pres" && opretions === "delete") {
      const updatedUploadPrescriptionArr = uploadPrescriptionArr.filter(
        (i) => i !== img
      );
      setUploadPrescription(updatedUploadPrescriptionArr);

      const updatedGetAppUploadPrescriptionArr = getAppPrescriptionArr.filter(
        (i) => i !== img
      );
      setGetAppPrescription(updatedGetAppUploadPrescriptionArr);
      console.log({
        img,
        type,
        opretions,
        updatedUploadPrescriptionArr,
        updatedGetAppUploadPrescriptionArr,
      });
    }
  };

  // console.log(id, documents, state);
  const validate = (type: number) => {
    if (type === 1) {
      return true;
    }
    if (!(uploadPrescriptionArr.length > 0)) {
      Utils.showAlert(2, "Mininmum one  prescription document required");
      return false;
    }
    if (!(uploadClaimArr.length > 0)) {
      Utils.showAlert(2, "Mininmum one  claim document required");
      return false;
    }
    return true;
  };

  const uploadPrescriptionAndClaim = (type: number) => {
    if (subscriptionDetails.isSubscriptionActive != 1) {
      navigate(Utils.routes.manageSubscription);
    } else {
      dispatch(globalLoaderTrue());
      getLocation(ProviderDetails?.is_geoTag_skipped).then((res: any) => {
        if (res) {
          ProviderDetails.currentLat = res.coords.latitude;
          ProviderDetails.currentLng = res.coords.longitude;
          ProviderDetails.locationAccuracy = res.coords.accuracy;
          validateRadiusRange(
            ProviderDetails.currentLat,
            ProviderDetails.currentLng,
            ProviderDetails.provider_lat,
            ProviderDetails.provider_lng,
            ProviderDetails.provider_radius_range,
            ProviderDetails.locationAccuracy,
            ProviderDetails.is_geoTag_skipped
          )
            .then(() => {
              const val = validate(type);
              if (val) {
                let params;
                if (type === 2) {
                  params = {
                    claim_id: claimNumber,
                    submit_type: type,
                    prescription_arr: uploadPrescriptionArr,
                    claim_arr: uploadClaimArr,
                    type_id: 5,
                  };
                }
                if (type === 1) {
                  params = {
                    claim_id: claimNumber,
                    submit_type: type,
                    prescription_arr: uploadPrescriptionArr,
                    claim_arr: uploadClaimArr,
                    type_id: null,
                  };
                }
                dispatch(uploadPrescription(params, navigate, type));
              }
            })
            .catch(() => {
              dispatch(globalLoaderFalse());
              getAddress(res.coords.latitude, res.coords.longitude).then(
                (address: any) => {
                  const params = {
                    lat: res.coords.latitude,
                    lng: res.coords.longitude,
                    screen: "Claim",
                    action: "Create",
                    address: address,
                    portal: "Chemist",
                  };
                  dispatch(providerNotInRangeNotification(params));
                }
              );
              dispatch({
                type: Utils.ActionName.GET_PROVIDER_DETAILS,
                payload: {
                  data: {
                    ...ProviderDetails,
                    geoTagErrorPopup: true,
                    isLocationEnabled: true,
                  },
                },
              });
              return false;
            });
        }
      });
    }
  };

  const addProfilePicture = (files: any, fileType: string) => {
    if (checkFileSize(files.fileList[0])) return;
    if (checkFileType(files)) return;
    if (files.base64) {
      dispatch(globalLoaderTrue());
      uploadDocumentImage(files.base64.split(",")[1])
        .then((res: any) => {
          if (res?.status === 200) {
            if (fileType === "pres") {
              setPrescription([...prescriptionArr, res?.data?.image]);
              setImage(res?.data?.image, "pres", "add");
            }
            if (fileType === "claim") {
              setClaimArr([...claimArr, res?.data?.image]);
              setImage(res?.data?.image, "claim", "add");
            }
            dispatch(globalLoaderFalse());
          }
        })
        .catch((err: any) => {
          if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
            toast.error(
              err?.response?.data?.messages && err?.response?.data?.messages[0],
              {
                toastId: toastIdsConstant.loginApiFailure,
                className: "toast-error",
              }
            );
          }
          dispatch(globalLoaderFalse());
        });
    }
  };

  const deleteProfilePhoto = (item: string, fileType: string) => {
    if (!item) return;
    dispatch(globalLoaderTrue());
    let payload = {
      image: item,
      claim_id: claimNumber,
      user_id: getUserIdFromLS(),
    };
    // if (fileType === "pres") {
    //   payload = {
    //     image: item,
    //     image_type: "prescription",
    //     claim_id: claimNumber,
    //   };
    // }
    // if (fileType === "claim") {
    //   payload = {
    //     image: item,
    //     image_type: "claim",
    //     claim_id: claimNumber,
    //   };
    // }

    deleteDocumentImageUploadPrescription(payload)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          if (fileType === "pres") {
            const updatedPrescriptionArr = prescriptionArr.filter(
              (i) => i !== item
            );
            // setUploadPrescription(updatedPrescriptionArr);
            setPrescription(updatedPrescriptionArr);

            setImage(item, "pres", "delete");
          }
          if (fileType === "claim") {
            const updatedClaimArr = claimArr.filter((i) => i !== item);
            // setUploadClaimArr(updatedClaimArr);
            setClaimArr(updatedClaimArr);
            setImage(item, "claim", "delete");
          }
          // getDocFromApp();
          // dispatch({
          //   type: Utils.ActionName.CLAIM_DETAIL,
          //   payload: {
          //     documents: {
          //       prescription: [],
          //       claim: [],
          //     },
          //   },
          // });
          dispatch(globalLoaderFalse());
        }
      })
      .catch((err: any) => {
        if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
          toast.error(
            err?.response?.data?.messages && err?.response?.data?.messages[0],
            {
              toastId: toastIdsConstant.loginApiFailure,
              className: "toast-error",
            }
          );
        }
        dispatch(globalLoaderFalse());
      });
  };

  const getDocFromApp = () => {
    dispatch(globalLoaderTrue());
    getDocumentImage(claimNumber)
      .then((res: any) => {
        if (res?.status === 200) {
          let claimDoc = res?.data?.documents?.claim;
          let prescriptionDoc = res?.data?.documents?.prescription;
          if (claimDoc?.length === 0 && prescriptionDoc?.length === 0) {
            Utils.showAlert(2, "No photos are available to sync");
          }
          if (claimDoc) {
            setGetAppClaimArr(claimDoc);
            setUploadClaimArr((preValue: any) => {
              let newValue = [...claimDoc];
              let newClaimArr = [];
              for (let img of claimArr) {
                if (newValue.length < 5) {
                  newValue.push(img);
                  newClaimArr.push(img);
                }
              }
              setClaimArr(newClaimArr);
              return newValue;
            });
          }

          if (prescriptionDoc) {
            setGetAppPrescription(prescriptionDoc);
            setUploadPrescription((preValue: any) => {
              let newValue = [...prescriptionDoc];
              let newPrescriptionArr = [];
              for (let img of prescriptionArr) {
                if (newValue.length < 5) {
                  newValue.push(img);
                  newPrescriptionArr.push(img);
                }
              }
              setPrescription(newPrescriptionArr);
              return newValue;
            });
          }

          dispatch(globalLoaderFalse());
        }
      })
      .catch((err: any) => {
        if (!toast.isActive(toastIdsConstant.loginApiFailure)) {
          toast.error(
            err?.response?.data?.messages && err?.response?.data?.messages[0],
            {
              toastId: toastIdsConstant.loginApiFailure,
              className: "toast-error",
            }
          );
        }
        dispatch(globalLoaderFalse());
      });
  };

  const geoTagErrorPopupManage = () => {
    dispatch({
      type: Utils.ActionName.GET_PROVIDER_DETAILS,
      payload: { data: { ...ProviderDetails, geoTagErrorPopup: false } },
    });
  };

  return (
    <div className="page-upload-prescription">
      <div className="bg-white mb-20">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            Template Claim ID: {claimNumber}
          </Typography>
          <Button
            onClick={getDocFromApp}
            variant="outlined"
            sx={{ mx: (theme) => theme.spacing(2) }}
          >
            Sync from app
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <div className={classes.uploadPrescriptionCover}>
              <h4 className="semi-bold">Upload Prescription</h4>
              <Box
                sx={{
                  my: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {uploadPrescriptionArr?.length < 5 ? (
                  <ReactFileReader
                    handleFiles={(files: any) => {
                      addProfilePicture(files, "pres");
                    }}
                    base64={true}
                    multipleFiles={false}
                    fileTypes={[".jpeg", ".jpg", ".png"]}
                  >
                    <Button
                      className="btn"
                      title="Please upload  image"
                      sx={{ padding: "0px", margin: "3px" }}
                    >
                      <Avatar
                        className={classes.avatar}
                        variant="square"
                        style={{ boxShadow: "0px 1px 2px 1px rgba(0,0,0,0.2)" }}
                      >
                        <UploadFileIcon color="primary" fontSize="large" />
                      </Avatar>
                    </Button>
                  </ReactFileReader>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Avatar
                      className={classes.avatar}
                      variant="square"
                      style={{ boxShadow: "0px 1px 2px 1px rgba(0,0,0,0.2)" }}
                    >
                      <WarningIcon color="warning" fontSize="large" />
                    </Avatar>
                    <Typography
                      sx={{ color: (theme) => theme.palette.warning.main }}
                    >
                      Maximum 5 file upload
                    </Typography>
                  </Box>
                )}
              </Box>
              <h4 className="semi-bold mb-10">
                Only png, jpg and jpeg format supported
              </h4>

              <Box
                sx={{
                  my: 1,
                  display: "flex",
                  // alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                {uploadPrescriptionArr?.length > 0 &&
                  uploadPrescriptionArr?.length <= 5 &&
                  uploadPrescriptionArr.map((item: any, index: number) => {
                    return (
                      <Box sx={{ position: "relative" }} key={index}>
                        <Avatar
                          variant="square"
                          className={classes.avatarDoc}
                          alt="Remy Sharp"
                          src={API_URL + item}
                        />
                        <img
                          className={classes.closeIcon}
                          src={LocalImages.CloseIcon}
                          title="Remove photo"
                          onClick={() => deleteProfilePhoto(item, "pres")}
                        />
                      </Box>
                    );
                  })}
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="upload-prescription-cover">
              <h4 className="semi-bold">Upload Claim Form</h4>
              <Box
                sx={{
                  my: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {uploadClaimArr?.length < 5 ? (
                  <ReactFileReader
                    handleFiles={(files: any) => {
                      addProfilePicture(files, "claim");
                    }}
                    base64={true}
                    multipleFiles={false}
                    fileTypes={[".jpeg", ".jpg", ".png"]}
                  >
                    <Button
                      className="btn"
                      title="Please upload  image"
                      sx={{ padding: "0px", margin: "3px" }}
                    >
                      <Avatar
                        className={classes.avatar}
                        variant="square"
                        style={{ boxShadow: "0px 1px 2px 1px rgba(0,0,0,0.2)" }}
                      >
                        <UploadFileIcon color="primary" fontSize="large" />
                      </Avatar>
                    </Button>
                  </ReactFileReader>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Avatar
                      className={classes.avatar}
                      variant="square"
                      style={{ boxShadow: "0px 1px 2px 1px rgba(0,0,0,0.2)" }}
                    >
                      <WarningIcon color="warning" fontSize="large" />
                    </Avatar>
                    <Typography
                      sx={{ color: (theme) => theme.palette.warning.main }}
                    >
                      Maximum 5 file upload
                    </Typography>
                  </Box>
                )}
              </Box>
              <h4 className="semi-bold mb-10">
                Only png, jpg and jpeg format supported
              </h4>
              <Box
                sx={{
                  display: "flex",
                  // alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                {uploadClaimArr?.length > 0 &&
                  uploadClaimArr?.length <= 5 &&
                  uploadClaimArr.map((item: any, index: number) => {
                    return (
                      <Box sx={{ position: "relative" }} key={index}>
                        <Avatar
                          variant="square"
                          className={classes.avatarDoc}
                          alt="Remy Sharp"
                          src={API_URL + item}
                        />
                        <img
                          className={classes.closeIcon}
                          src={LocalImages.CloseIcon}
                          title="Remove photo"
                          onClick={() => deleteProfilePhoto(item, "claim")}
                        />
                      </Box>
                    );
                  })}
              </Box>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="bg-white mb-20">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Typography>Send This claim to insurance?</Typography>
          <Button
            onClick={() => uploadPrescriptionAndClaim(1)}
            variant="outlined"
            sx={{ mx: (theme) => theme.spacing(2) }}
          >
            Later
          </Button>
          <Button
            sx={[
              {
                color: "var(--white)",
              },
            ]}
            variant="contained"
            color="primary"
            onClick={() => uploadPrescriptionAndClaim(2)}
          >
            Now
          </Button>
        </Box>
      </div>
      <LocationPopupModal
        open={ProviderDetails.geoTagErrorPopup}
        content={ProviderDetails}
        handleClose={geoTagErrorPopupManage}
      />
    </div>
  );
}

export default UploadPrescription;
