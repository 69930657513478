import Utils from ".";
// import { UserData } from "../modal";

/**
 * set access token in axios instance header
 */
export const setAuthorizationToken = (token: string) => {
  if (token) {
    // Utils.constants.axios.defaults.headers.common[
    //   "accesstoken"
    // ] = `bearer ${token}`;

    Utils.constants.axios.defaults.headers.common[
      "LiviaApp-Token"
    ] = `${token}`;
  } else {
    // delete Utils.constants.axios.defaults.headers.common["accesstoken"];
  }
};

/**
 * set user session in browser
 */
export const setSession = (payload: any) => {
  for (var k in payload) {
    var isCurrentValObject = typeof payload[k] === "object";
    if (isCurrentValObject && payload[k] !== null) {
      //if property is object then save it as string
      localStorage.setItem(k, JSON.stringify((payload as any)[k]));
    } else {
      localStorage.setItem(k, (payload as any)[k]);
    }
  }
  setAuthorizationToken(payload.accessToken);
};

export const removeSession = () => {
  localStorage.clear();
  setAuthorizationToken("");
};
/**
 * get user access token form loclastorage
 */
export const getAccessToken = () => {
  let accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    setAuthorizationToken(accessToken);
  }

  return accessToken;
};

/**
 * get user access token form localStorage
 */
export const setAccessToken = (accessToken: any) => {
  if (accessToken) {
    setAuthorizationToken(accessToken);
  }
  return accessToken;
};
/**
 * get user id form localStorage
 */
export const getUserIdToken = () => {
  return localStorage.getItem("accessToken");
};

export const manageUserSession = (history: any) => {
  let { accessToken } = localStorage;
  if (accessToken) {
    //push user directly to dashboard in app
    history.push("/dashboard");
  } else {
    //do nothing reset user here
  }
};
export const getUserData = () => {
  return localStorage.getItem("0");
};

export const removeLocalStorageData = (key: string) => {
  localStorage.removeItem(key);
};
