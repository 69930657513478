import Utils from "../../utils";
import {
  EditMyProfilePersonalInfoModal,
  MyProfilePersonalInfoModal,
  PaymentDetails,
  WorkTimeModal,
  SubscriptionDetails,
  SubscriptionHistory,
  SubscriptionStatus,
} from "../../modal";

export const MyProfilePersonalInfoReducer = (
  state: MyProfilePersonalInfoModal = new MyProfilePersonalInfoModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.MY_PROFILE_PERSONAL_INFO:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const EditMyProfilePersonalInfoReducer = (
  state: EditMyProfilePersonalInfoModal = new EditMyProfilePersonalInfoModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.EDIT_MY_PROFILE_PERSONAL_INFO:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const WorkTimeReducer = (
  state: WorkTimeModal = new WorkTimeModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GET_WORK_TIME:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const PaymentDetailsReducer = (
  state: PaymentDetails = new PaymentDetails(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.PAYMENT_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const SubscriptionReducer = (
  state: SubscriptionDetails = new SubscriptionDetails(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GET_SUBSCRIPTION_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const SubscriptionHistoryReducer = (
  state: SubscriptionHistory = new SubscriptionHistory(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.SUBSCRIPTION_HISTORY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const SubscriptionStatusReducer = (
  state: SubscriptionStatus = new SubscriptionStatus(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.SUBSCRIPTION_STATUS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
