import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import WorkingDays from "./workingDays";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    daysList: {
      'daysList:last-child': {
        'dayDiv': {
          borderBottomWidth: 0,
        }
      }
    },
  })
);

const renderTueC = () => {
  return (
    <WorkingDays
      days="Tuesday"
      workingSwitchLabel="Working Hours"
      workingSwitchName="workingSwitchNameTue"
      workingTimeNameTo="workingTimeNameToTue"
      workingTimeNameFrom="workingTimeNameFromTue"
      workingDefaultTimeTo={""}
      workingDefaultTimeFrom={""}
      lunchSwitchLabel="Lunch"
      lunchSwitchName="lunchSwitchNameTue"
      lunchTimeNameTo="lunchTimeNameToTue"
      lunchTimeNameFrom="lunchTimeNameFromTue"
      lunchDefaultTimeTo={""}
      lunchDefaultTimeFrom={""}
    />
  );
};
const renderWedC = () => {
  return (
    <WorkingDays
      days="Wednesday"
      workingSwitchLabel="Working Hours"
      workingSwitchName="workingSwitchNameWed"
      workingTimeNameTo="workingTimeNameToWed"
      workingTimeNameFrom="workingTimeNameFromWed"
      workingDefaultTimeTo={""}
      workingDefaultTimeFrom={""}
      lunchSwitchLabel="Lunch"
      lunchSwitchName="lunchSwitchNameWed"
      lunchTimeNameTo="lunchTimeNameToWed"
      lunchTimeNameFrom="lunchTimeNameFromWed"
      lunchDefaultTimeTo={""}
      lunchDefaultTimeFrom={""}
    />
  );
};
const renderThuC = () => {
  return (
    <WorkingDays
      days="Thursday"
      workingSwitchLabel="Working Hours"
      workingSwitchName="workingSwitchNameThu"
      workingTimeNameTo="workingTimeNameToThu"
      workingTimeNameFrom="workingTimeNameFromThu"
      workingDefaultTimeTo={""}
      workingDefaultTimeFrom={""}
      lunchSwitchLabel="Lunch"
      lunchSwitchName="lunchSwitchNameThu"
      lunchTimeNameTo="lunchTimeNameToThu"
      lunchTimeNameFrom="lunchTimeNameFromThu"
      lunchDefaultTimeTo={""}
      lunchDefaultTimeFrom={""}
    />
  );
};
const renderFriC = () => {
  return (
    <WorkingDays
      days="Friday"
      workingSwitchLabel="Working Hours"
      workingSwitchName="workingSwitchNameFri"
      workingTimeNameTo="workingTimeNameToFri"
      workingTimeNameFrom="workingTimeNameFromFri"
      workingDefaultTimeTo={""}
      workingDefaultTimeFrom={""}
      lunchSwitchLabel="Lunch"
      lunchSwitchName="lunchSwitchNameFri"
      lunchTimeNameTo="lunchTimeNameToFri"
      lunchTimeNameFrom="lunchTimeNameFromFri"
      lunchDefaultTimeTo={""}
      lunchDefaultTimeFrom={""}
    />
  );
};
const renderSatC = () => {
  return (
    <WorkingDays
      days="Saturday"
      workingSwitchLabel="Working Hours"
      workingSwitchName="workingSwitchNameSat"
      workingTimeNameTo="workingTimeNameToSat"
      workingTimeNameFrom="workingTimeNameFromSat"
      workingDefaultTimeTo={""}
      workingDefaultTimeFrom={""}
      lunchSwitchLabel="Lunch"
      lunchSwitchName="lunchSwitchNameSat"
      lunchTimeNameTo="lunchTimeNameToSat"
      lunchTimeNameFrom="lunchTimeNameFromSat"
      lunchDefaultTimeTo={""}
      lunchDefaultTimeFrom={""}
    />
  );
};
const renderSunC = () => {
  return (
    <WorkingDays
      days="Sunday"
      workingSwitchLabel="Working Hours"
      workingSwitchName="workingSwitchNameSun"
      workingTimeNameTo="workingTimeNameToSun"
      workingTimeNameFrom="workingTimeNameFromSun"
      workingDefaultTimeTo={""}
      workingDefaultTimeFrom={""}
      lunchSwitchLabel="Lunch"
      lunchSwitchName="lunchSwitchNameSun"
      lunchTimeNameTo="lunchTimeNameToSun"
      lunchTimeNameFrom="lunchTimeNameFromSun"
      lunchDefaultTimeTo={""}
      lunchDefaultTimeFrom={""}
    />
  );
};

function WorkTime() {
  const classes = useStyles();
  const [renderTue, setRenderTue] = useState(false);
  const [renderWed, setRenderWed] = useState(false);
  const [renderThu, setRenderThu] = useState(false);
  const [renderFri, setRenderFri] = useState(false);
  const [renderSat, setRenderSat] = useState(false);
  const [renderSun, setRenderSun] = useState(false);

  useEffect(() => {
    const timeoutTue = setTimeout(() => {
      setRenderTue(true);
    }, 0);
    const timeoutWed = setTimeout(() => {
      setRenderWed(true);
    }, 0);
    const timeoutThu = setTimeout(() => {
      setRenderThu(true);
    }, 0);
    const timeoutFri = setTimeout(() => {
      setRenderFri(true);
    }, 0);
    const timeoutSat = setTimeout(() => {
      setRenderSat(true);
    }, 0);
    const timeoutSun = setTimeout(() => {
      setRenderSun(true);
    }, 0);
    return () => {
      clearTimeout(timeoutTue);
      clearTimeout(timeoutWed);
      clearTimeout(timeoutThu);
      clearTimeout(timeoutFri);
      clearTimeout(timeoutSat);
      clearTimeout(timeoutSun);
    };
  }, []);

  return (
    <div className={`bg-white mb-20 p-0 days-list ${classes.daysList}`} style={{overflow: 'hidden'}}>
      <WorkingDays
        days="Monday"
        workingSwitchLabel="Working Hours"
        workingSwitchName="workingSwitchNameMon"
        workingTimeNameTo="workingTimeNameToMon"
        workingTimeNameFrom="workingTimeNameFromMon"
        workingDefaultTimeTo={""}
        workingDefaultTimeFrom={""}
        lunchSwitchLabel="Lunch"
        lunchSwitchName="lunchSwitchNameMon"
        lunchTimeNameTo="lunchTimeNameToMon"
        lunchTimeNameFrom="lunchTimeNameFromMon"
        lunchDefaultTimeTo={""}
        lunchDefaultTimeFrom={""}
      />
      {renderTue && renderTueC()}
      {renderWed && renderWedC()}
      {renderThu && renderThuC()}
      {renderFri && renderFriC()}
      {renderSat && renderSatC()}
      {renderSun && renderSunC()}
    </div>
  );
}

export default WorkTime;
