import moment from "moment";
// import { getUserData } from './../../utils/session';
import Utils from "../../utils";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";
import CommonFunctions, { uid, getPhoneIdFromLS } from "../../utils/commonFunctions";
const quantityMeasuringCate = ["bottle", "kit", "pack"]
/**
 * action to get InsuranceCompany
 */
export const getInsuranceCompany = () => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      //check if user is online or not
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }

    const { limit, page, search } = getState().InsuranceCompanyReducer;
    let data = `?limit=${limit}&page=${page}&type=${"pharmacy_insurance_company"}${search.length > 0 ? `&search=${search}` : ""
      }`;

    Utils.api.getApiCall(
      Utils.endPoints.insuranceCompany,
      data,
      (respData: any) => {
        let { data } = respData;

        dispatch({
          type: Utils.ActionName.GET_INSURANCE_COMPANY,
          payload: {
            data: data.body,
          },
        });
        dispatch({
          type: Utils.ActionName.GET_PROVIDER_DETAILS,
          payload: {
            data: { is_geoTag_skipped: data?.is_geoTag_skipped, provider_lat: data?.provider_lat, provider_lng: data?.provider_lng, provider_radius_range: data?.provider_radius_range },
          },
        });
      },
      (error: any) => {
        let { data } = error;

        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

// Get Manufacturer list

export const getManufacturer = () => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      //check if user is online or not
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }

    //    const { limit, page, search } = getState().ManufacturerReducer;
    let data = `?get_manufacturer_list=1`

    Utils.api.getApiCall(
      Utils.endPoints.manufacturer,
      data,
      (respData: any) => {
        let { data } = respData;

        dispatch({
          type: Utils.ActionName.GET_MANUFACTURER,
          payload: {
            data: data.body,
          },
        });
      },
      (error: any) => {
        let { data } = error;

        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

/**
 * action to get InsuranceCompany
 */

export const getInsuredMember = (
  patientInsuranceId: any,
  // setInsuranceMemberOptionSelect: any,
  setSubmitting: any,
  values: any,
  isFromChemistRegFR: any = false
) => {

  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      //check if user is online or not
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    const { insuranceCardType } = getState().InsurancePatientProfileReducer;
    const { search } = values;
    let searchVal = "";

    if (Utils.constants.insuranceCompany.MAD == patientInsuranceId) {
      searchVal = search;
    } else {

      console.log('patientInsuranceId', Utils.constants.insuranceCompany.UAP)
      searchVal = search.replaceAll("/", "-");
      searchVal = searchVal.replaceAll(".", "-");
      if (insuranceCardType === "1" && !searchVal.includes("-") && !searchVal.includes("USIU") && !(patientInsuranceId == Utils.constants.insuranceCompany.UAP && (searchVal.includes("MEM"))) && !(Utils.constants.insuranceCompany.FIRST_ASSURANCE == patientInsuranceId && searchVal.includes("KMTC"))) {
        searchVal = searchVal + "-";
      }
    }

    let data = ``;
    const isFromChemistRegFRKey = isFromChemistRegFR ? `&isFromChemistRegFR=${isFromChemistRegFR}` : '';
    if (insuranceCardType !== "1") {
      data = `?insurance_company_id=${patientInsuranceId}${isFromChemistRegFRKey}&is_search_by_smartcard=${insuranceCardType === "1" ? 1 : 0
        }&smartcard_provider_name=${""}&from_desktop_portal=${1}${search.length > 0 ? `&search=${searchVal}` : ""
        }&isSearchOnActisure=${true}`;
    } else {

      data = `?insurance_company_id=${patientInsuranceId}&is_search_by_smartcard=${insuranceCardType === "1" ? 1 : 0
        }&smartcard_provider_name=${""}&from_desktop_portal=${1}${search.length > 0 ? `&search=${searchVal}` : ""}${isFromChemistRegFRKey}`;
    }

    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      Utils.endPoints.searchInsuredMember,
      data,
      (respData: any) => {
        let { data } = respData;
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            patientInsuranceMemberDataList: [],
            patientFamilyMemberData: [],
            entityId: "",
            insuranceMemberOptionSelect: false,
            phoneNumber: "",
            checked: false,
            insurance_member_dependents: [],
            familyMemberPhoneNumber: "",
            principalPhoneNumber: "",
            termsConditionChecked: false,
            userPrincipalId: "",
          },
        });
        setSubmitting(false);
        if (data.code !== 400) {
          if (data.body.length > 0) {

            dispatch({
              type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
              payload: {
                patientInsuranceMemberDataList: data.body,
                insuranceMemberOptionSelect: false,
                ethicalSubstitueDone: data?.body?.[0]?.ethical_substitute,
                ethicalSubsVerify: false,
                showEthicalVerification: false,
                ethicalSubstitue: null
              },
            });
          } else {
            Utils.showAlert(2, "No result found");
          }
        } else {
          dispatch({
            type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
            payload: {
              patientInsuranceMemberDataList: [],
              insuranceMemberOptionSelect: false,
            },
          });
          Utils.showAlert(2, data?.messages[0]);
        }

        // setInsuranceMemberOptionSelect(false);
      },
      (error: any) => {
        setSubmitting(false);

        let { data } = error;

        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            patientInsuranceMemberDataList: [],
          },
        });
        //;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

// for editData
export const getInsuredMemberForEdit = (
  patientInsuranceId: any,
  // setInsuranceMemberOptionSelect: any,
  search: any
) => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      dispatch(globalLoaderTrue());
      if (!navigator.onLine) {
        //check if user is online or not
        Utils.showAlert(3, "PLEASE CHECK INTERNET");
        return;
      }
      const { insuranceCardType } = getState().InsurancePatientProfileReducer;
      // console.log(values);
      let searchVal = "";
      if (Utils.constants.insuranceCompany.MAD == patientInsuranceId) {
        searchVal = search;
      } else {
        let searchVal = search.replaceAll("/", "-");
        searchVal = searchVal.replaceAll(".", "-");
        if (insuranceCardType === "1" && !searchVal.includes("-") && !(patientInsuranceId == Utils.constants.insuranceCompany.UAP && (searchVal.includes("MEM"))) && !searchVal.includes("USIU") && !(Utils.constants.insuranceCompany.FIRST_ASSURANCE == patientInsuranceId && (searchVal.includes("MEM") || searchVal.includes("KMTC")))) {
          searchVal = searchVal + "-";
        }
      }

      let data = ``;

      if (insuranceCardType !== "1") {
        data = `?insurance_company_id=${patientInsuranceId}&is_search_by_smartcard=${insuranceCardType === "1" ? 1 : 0
          }&smartcard_provider_name=${""}&from_desktop_portal=${1}${search.length > 0 ? `&search=${searchVal}` : ""
          }&isSearchOnActisure=${true}`;
      } else {
        data = `?insurance_company_id=${patientInsuranceId}&is_search_by_smartcard=${insuranceCardType === "1" ? 1 : 0
          }&smartcard_provider_name=${""}&from_desktop_portal=${1}${search.length > 0 ? `&search=${searchVal}` : ""}`;
      }

      Utils.constants.getAccessToken();
      Utils.api.getApiCall(
        Utils.endPoints.searchInsuredMember,
        data,
        (respData: any) => {
          let { data } = respData;
          dispatch({
            type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
            payload: {
              patientInsuranceMemberDataList: [],
              patientFamilyMemberData: [],
              entityId: "",
              insuranceMemberOptionSelect: false,
              phoneNumber: "",
              checked: false,
              insurance_member_dependents: [],
              familyMemberPhoneNumber: "",
              principalPhoneNumber: "",
              termsConditionChecked: false,
              userPrincipalId: "",
              finalEntityId: "",
            },
          });
          if (data.code !== 400) {
            if (data.body.length > 0) {
              dispatch({
                type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                payload: {
                  patientInsuranceMemberDataList: data.body,
                  insuranceMemberOptionSelect: false,
                },
              });
            } else {
              Utils.showAlert(2, "No result found");
            }
          } else {
            dispatch({
              type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
              payload: {
                patientInsuranceMemberDataList: [],
                insuranceMemberOptionSelect: false,
              },
            });
            Utils.showAlert(2, data?.messages[0]);
          }
          dispatch(globalLoaderFalse());
          resolve(respData);
          // setInsuranceMemberOptionSelect(false);
        },
        (error: any) => {
          let { data } = error;

          dispatch({
            type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
            payload: {
              patientInsuranceMemberDataList: [],
            },
          });
          reject(error);
          //;
          dispatch(globalLoaderFalse());
          Utils.showAlert(2, data?.messages[0]);
        }
      );
    });
  };
};

export const onSubmitMember = (dataSend: any) => {
  return (dispatch: Function, getState: Function) => {
    const { search } = getState().InsurancePatientProfileReducer;
    const { patientInsurance } = getState().InsuranceCompanyReducer;
    const { insuranceCardType } = getState().InsurancePatientProfileReducer;
    let data = `?insurance_company_id=${patientInsurance}&is_search_by_smartcard=${insuranceCardType === "1" ? 1 : 0
      }&smartcard_provider_name=${""}&from_desktop_portal=${1}${search.length > 0 ? `&search=${search}` : ""}`;
    const dataToSend = dataSend;
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      `${Utils.endPoints.searchInsuredMember}${data}`,
      dataToSend,
      (respData: any) => {
        dispatch(globalLoaderFalse());
        let { data } = respData;
        // console.log("linee 135 response", respData);
        if (data?.code === 400) return Utils.showAlert(2, data?.messages[0]);
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            patientInsuranceMember: data.body,
            ethicalSubstitueDone: data?.body?.[0]?.ethical_substitute,
            insuranceMemberOptionSelect: true,
          },
        });

        if (data.body !== undefined && data.body !== null && data.body.length > 0) {
          let age = moment(data.body[0].dob, "YYYYMMDD").fromNow().match(/\d+/g);
          var phone = data.body[0].phone;
          if (data.body[0].phone !== null && data.body[0].phone !== undefined) {
            phone = data.body[0].phone.slice(3, 12);
          }

          let payload = {
            gender: data.body[0].sex,
            patientName: data.body[0].beneficiary_first_name + " " + data.body[0].beneficiary_last_name,
            patientDoB: data.body[0].dob,
            phoneNumber: phone,
            age: age !== null ? Number(age[0]) : 16,
            isPrincipal: data.body[0].is_principle,
            userId: data.body[0].user_id,
            employeeNumber: data.body[0].employee_number,
            cardType: data.body[0].card_type,
            card1: data.body[0].photo_id_number_frontend,
            card2: data.body[0].photo_id_number_backend,
            finalEntityId: data.body[0].ent_dep_parent_on_policy,
          };

          dispatch({
            type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
            payload: payload,
          });
          dispatch(
            getMemberFamilyDetails(
              dataSend.entity_id,
              dataSend.employee_number !== null ? dataSend.employee_number : dataSend.entity_id,
              patientInsurance,
              data.body[0].user_id,
              data.body[0].entity_id
            )
          );
        } else {
          if (data.code === 400) {
            dispatch({
              type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
              payload: {
                patientInsuranceMember: [],
                insuranceMemberOptionSelect: false,
              },
            });
            Utils.showAlert(2, data?.messages[0]);
          }
        }
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        dispatch(globalLoaderFalse());
        //
        // console.log(error, "shashanktest");
        //
        // let { data } = error;
        // Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

/**
 * action to getMemberFamilyDetails
 */

export const getMemberFamilyDetails = (
  entity_id: any,
  search: any,
  patientInsuranceId: any,
  principle_user_id: any,
  finalEntity: any
) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      //check if user is online or not
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    const { insuranceCardType } = getState().InsurancePatientProfileReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { isLoading: true },
    });
    const benefittype = principle_user_id ? "patient_insurance_company_md" : "get_benefits_with_entity";
    // const { search } = getState().InsurancePatientProfileReducer;
    let data = `?limit=1000&offset=0&insurance_company_id=${patientInsuranceId}&entity_id=${entity_id}&is_search_by_smartcard=${insuranceCardType === "1" ? 1 : 0
      }&smartcard_provider_name=${""}&from_desktop_portal=${1}${search.length > 0
        ? `&search=${search}&user_id=${principle_user_id !== null ? principle_user_id : ""
        }&type=${benefittype}&from_md=${"md_portal"}&list_for_md_claims=${true}`
        : ""
      }`;

    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      Utils.endPoints.insuranceCompany,
      data,
      (respData: any) => {
        let { data } = respData;

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });

        if (data.body !== undefined && data.body !== null && data.body.length > 0) {
          dispatch({
            type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
            payload: {
              card4:
                data.body[0].insurance_company_member_card !== null ? data.body[0].insurance_company_member_card : "",
              card3: data.body[0].insurance_company_selfie !== null ? data.body[0].insurance_company_selfie : "",
              benefits: data.body[0].benefits,
              policyStatus: data.body[0].policy_status,
              insurance_member_dependents: data.body[0].insurance_member_dependents,
              patientFamilyMemberData: data.body,
              ...(data.body[0].insurance_member_dependents !== null &&
                data.body[0].insurance_member_dependents !== undefined && {
                finalEntityId: finalEntity,
              }),
            },
          });
        }
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        // let { data } = error;
        //;
        // Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const setAddMedicines = (params: any) => {
  return () => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    Utils.api.postApiCall(
      Utils.endPoints.workingHours,
      params,
      () => { },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const orderMarkComplete = (params: any) => {
  return () => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    Utils.constants.getAccessToken();
    Utils.api.putApiCall(
      Utils.endPoints.orderStatus,
      params,
      () => {
        Utils.showAlert(1, "Order completed Successfully");
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const chemistOrderStatusUpdate = (params: any) => {

  return () => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    let actionType = params.actionType;

    Utils.constants.getAccessToken();
    Utils.api.putApiCall(
      Utils.endPoints.medicineOrders,
      params,
      () => {
        let repMessage = "";
        if (params.OrderStatus == 3) {
          repMessage = "Accepted"
        }else if (params.OrderStatus == 4) {
          repMessage = "Completed"
        } else if (params.OrderStatus == 5) {
          repMessage = "Cancelled"
        } else if (params.OrderStatus == 6) {
          repMessage = "Declined"
        }
        Utils.showAlert(1, `Order ${repMessage} Successfully`);
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const createChemistMedicineOrder = (payload: any) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      Utils.endPoints.medicineOrders,
      payload,
      (respData: any) => {
        const { data } = respData;
        Utils.showAlert(1, "Order created Successfully");
        dispatch({
          type: Utils.ActionName.ADD_TO_CART,
          payload: {
            data: [],
            OrderCreated: true,
          },
        });
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};


export const getMedicineOrders = () => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());

    const {
      limit,
      offset,
      hospital_name,
      status,
      search,
      order_start_date,
      order_end_date,
      manufacturer_id,

    } = getState().MedicineOrdersReducer;

    let data = `?limit=${limit}&offset=${offset || 0}&hospital_name=${hospital_name || ""}&status=${status || 0}${search.length > 0 ? `&search=${search}` : ""
      }&manufacturer_id=${manufacturer_id || ""}&order_start_date=${order_start_date || ""}&order_end_date=${order_end_date || ""
      }&is_from_desktop=${1}&is_count_needed=${1}`;
    // ;

    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      Utils.endPoints.medicineOrders,
      data,
      (respData: any) => {
        let { data } = respData;

        dispatch({
          type: Utils.ActionName.MEDICINE_ORDERS,
          payload: {
            data: data.body,
            count: data.count,
          },
        });

        dispatch(globalLoaderFalse());
      },
      (error: any) => {
        dispatch(globalLoaderFalse());
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const getTemplateClaims = () => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());

    const {
      limit,
      offset,
      hospital_name,
      status,
      search,
      insurance_company_id,
      claim_start_date,
      claim_end_date,
      // user_id,
      // doctor_id,
      // icd_10_code,
      // statuses,
      // is_from_desktop,
      // is_count_needed,
    } = getState().TemplateClaimsReducer;
    // offset=0, limit=20, search=, user_id=-1, doctor_id=-1,  icd_10_code=, statuses=
    // let data1 = `?limit=${limit}&offset=${offset}&search=${search}&user_id=${user_id}&doctor_id=${doctor_id}&icd_10_code=${icd_10_code}&statuses=${statuses}`;

    let data = `?limit=${limit}&offset=${offset || 0}&hospital_name=${hospital_name || ""}&status=${status || 0}${search.length > 0 ? `&search=${search}` : ""
      }&insurance_company_id=${insurance_company_id || ""}&claim_start_date=${claim_start_date || ""}&claim_end_date=${claim_end_date || ""
      }&is_count_needed=${1}`;

    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      Utils.endPoints.createClaim,
      data,
      (respData: any) => {
        let { data } = respData;

        dispatch({
          type: Utils.ActionName.GET_TEMPLATE_CLAIMS,
          payload: {
            data: data.body,
            count: data.count,
          },
        });
        // ;
        dispatch(globalLoaderFalse());
      },
      (error: any) => {
        dispatch(globalLoaderFalse());
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const deleteTemplateClaims = (ID: any) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    let params = `/${ID}`;
    Utils.constants.getAccessToken();
    Utils.api.deleteApiCall(
      `${Utils.endPoints.createClaim}`,
      params,
      (respData: any) => {
        const { data } = respData;
        dispatch(getTemplateClaims());
        console.log("data", data);
        //
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const getMedicineDetails = (drug_id: any, is_global_medicine_search = 0) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    const global_medicine_search = is_global_medicine_search ? 1 : 0;
    const { patientInsurance } = getState().InsuranceCompanyReducer;
    let params = `?chemist_web_data_type=${"medicineDetails"}${global_medicine_search ? '&global_medicine_search=1' : ''}&insurance_company_id=${patientInsurance || 9
      }&drug_id=${drug_id}`;
    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      Utils.endPoints.medicineList,
      params,
      (respData: any) => {
        const { data } = respData;
        console.log('data', data)
        // console.log(data);
        dispatch({
          type: Utils.ActionName.MEDICINE_DETAILS,
          payload: {
            ...data?.body,
          },
        });
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const createAddMedicinesAction = (type: string, payload: any) => {
  return {
    type,
    payload,
  };
};

export const getSearchMedicineDoctorMedicine = (search_type: any) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    let params = {};
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      Utils.endPoints.search,
      params,
      (respData: any) => {
        const { data } = respData;
        // console.log(data);
        dispatch({
          type: Utils.ActionName.MYCLAIM_SEARCH_MEDICINE_DOCTOR_HOSPITAL,
          payload: {
            ...data?.body,
          },
        });
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const addTranscribePrescriptions = (value: any, claimId: string) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    let params = value;
    Utils.constants.getAccessToken();
    Utils.api.putApiCall(
      Utils.endPoints.addMedicine + "/" + claimId,
      params,
      (respData: any) => {
        // console.log(respData, "respData");

        if (respData.status === Utils.constants.api_success_code.success) {
          const { data } = respData;
          // console.log(data);
          dispatch({
            type: Utils.ActionName.CREATE_CLAIM_SECOND_SETP,
            payload: { ...data },
          });
          dispatch({
            type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
            payload: {
              onlyRead: true,
            },
          });
          // dispatch({
          //   type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
          //   payload: {
          //     whenMoveTo: true,
          //   },
          // });
          dispatch({
            type: Utils.ActionName.GET_PATIENT_DATA,
            payload: {
              activeStep: 2,
            },
          });
        }
        dispatch(globalLoaderFalse());
      },
      (error: any) => {
        let { data } = error;
        // console.log(data);
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const uploadPrescription = (value: any, navigate: any, type: number) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    let params = value;
    // console.log("uploadPrescription params", params);
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      Utils.endPoints.uploadPrescription,
      params,
      (respData: any) => {
        if (respData.status === Utils.constants.api_success_code.success) {
          const { data } = respData;

          dispatch({
            type: Utils.ActionName.GET_PATIENT_DATA,
            payload: {
              activeStep: 0,
            },
          });
          dispatch({
            type: Utils.ActionName.CLEAR_MEDICINE_DETAILS_LIST,
          });
          // console.log(navigate, "navai");
          if (type === 1) {
            dispatch({
              type: Utils.ActionName.Tab_DETAILS,
              payload: {
                value: "2",
              },
            });
          }
          if (type === 2) {
            dispatch({
              type: Utils.ActionName.Tab_DETAILS,
              payload: {
                value: "1",
              },
            });
            let respMessage = `E-Claim sent to insurance. Claim ID: ${data?.final_claim_id}`
            if (data?.claim_status == 2) {
              respMessage = `E-Claim sent to insurance for pre-authorization. kindly hold delivery until it gets authorized. Claim ID: ${data?.final_claim_id}`
            }
            Utils.showAlert(1, respMessage);
          }

          navigate("/my-claims");
          dispatch(globalLoaderFalse());
        }
      },
      (error: any) => {
        let { data } = error;
        // console.log(data);
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};



export const onSubmitOtp = (value: any, type: any, claimId: any) => {
  return (dispatch: Function, getState: Function) => {
    const { patientName, patientDoB, gender } = getState().InsurancePatientProfileReducer;
    // const { claimId } = getState().InsurancePatientProfileReducer;
    const { phone_number, phone_code } = getState().createClaimSecondStepReducer;

    const { activeStep, claimSteps } = getState().PatientInsuranceCompanyReducer;

    const { patientInsurance } = getState().InsuranceCompanyReducer;

    const dataToSend = {
      self_claim_created: 1,
      phone_id: localStorage.getItem("phoneId"),
      // auth_code: value,
      ...(type === 1 && { auth_code: value }),

      patient_name: patientName,
      full_phone_number: `${phone_code}${phone_number}`,
      dob: patientDoB,
      gender: gender,
    };
    Utils.constants.getAccessToken();
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { isLoading: true },
    });

    if (type === 1) {
      Utils.api.patchApiCall(
        `${Utils.endPoints.claimOtpVerify}/${claimId}`,
        dataToSend,
        (respData: any) => {
          Utils.showAlert(1, "Success");
          // let { data } = respData;
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { isLoading: false },
          });

          if (type === 1 && activeStep + 1 !== claimSteps.length) {
            dispatch({
              type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
              payload: {
                doctorDetail: {},
                hospitalDetail: {},
                finalDiagnosisDetail: [],
                onlyRead: false,
              },
            });

            dispatch({
              type: Utils.ActionName.GET_PATIENT_DATA,
              payload: {
                activeStep: 3,
              },
            });
          }
        },
        (error: any) => {
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { isLoading: false },
          });
          let { data } = error;
          Utils.showAlert(2, data?.messages[0]);
        }
      );
    } else if (type == 2) {
      Utils.api.postApiCall(
        `${Utils.endPoints.claimOtpVerify}/${claimId}`,
        dataToSend,
        (respData: any) => {
          // let { data } = respData;
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { isLoading: false },
          });

          if (type === 1) {
            dispatch({
              type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
              payload: {
                doctorDetail: {},
                hospitalDetail: {},
                finalDiagnosisDetail: [],
                onlyRead: false,
              },
            });

            dispatch({
              type: Utils.ActionName.GET_PATIENT_DATA,
              payload: {
                activeStep: 3,
              },
            });
          }
        },
        (error: any) => {
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { isLoading: false },
          });
          let { data } = error;
          Utils.showAlert(2, data?.messages[0]);
        }
      );
    } else if (type == 3) {
      const dataToSendD = {
        phone_id: localStorage.getItem("phoneId"),
        full_phone_number: "",
        is_claim_verification: 1,
        user_role: 4,
        os_type: 3,
        phone_number: phone_number,
        phone_code: phone_code,
        insurance_company_id: patientInsurance,
      };

      Utils.api.postApiCall(
        `${Utils.endPoints.login}`,
        dataToSendD,
        (respData: any) => {
          // let { data } = respData;
          Utils.showAlert(1, "Otp sent successfully");
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { isLoading: false },
          });

          if (type === 1) {
            dispatch({
              type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
              payload: {
                doctorDetail: {},
                hospitalDetail: {},
                finalDiagnosisDetail: [],
                onlyRead: false,
              },
            });
            dispatch({
              type: Utils.ActionName.GET_PATIENT_DATA,
              payload: {
                activeStep: 3,
              },
            });
          }
        },
        (error: any) => {
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { isLoading: false },
          });
          let { data } = error;
          Utils.showAlert(2, data?.messages[0]);
        }
      );
    }
  };
};

export const onSubmitFaceId = (params: any, claimId: any) => {
  return (dispatch: Function, getState: Function) => {
    const { activeStep, claimSteps } = getState().PatientInsuranceCompanyReducer;

    Utils.constants.getAccessToken();
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { isLoading: true },
    });

    Utils.api.patchApiCall(
      `${Utils.endPoints.addMedicine}/${claimId}`,
      params,
      (respData: any) => {
        Utils.showAlert(1, "Success");

        if (respData.status === Utils.constants.api_success_code.success) {
          const { data } = respData;
          dispatch({
            type: Utils.ActionName.CLAIM_DETAIL,
            payload: { ...data },
          });
        }
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });

        if (activeStep + 1 !== claimSteps.length) {
          dispatch({
            type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
            payload: {
              doctorDetail: {},
              hospitalDetail: {},
              finalDiagnosisDetail: [],
              onlyRead: false,
            },
          });

          dispatch({
            type: Utils.ActionName.GET_PATIENT_DATA,
            payload: {
              activeStep: 3,
            },
          });
        }
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const addDoctorInfo = (value: any, handleClose: any, actions: any) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    value.country = "ke";
    let params: any = value;
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      Utils.endPoints.searchDoctorHospital,
      params,
      (respData: any) => {
        if (respData.status === Utils.constants.api_success_code.success) {
          const { data } = respData;
          if (data.body.status === Utils.constants.api_success_code.postSuccess) {
            // console.log(data);
            Utils.showAlert(1, data?.body?.msg);
            actions.setSubmitting(false);
            return;
          }
          actions.setSubmitting(false);
          Utils.showAlert(1, respData.data?.body?.success);
          handleClose();
        }
      },
      (error: any) => {
        let { data } = error;
        // console.log(data);
        handleClose();
        actions.setSubmitting(false);
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const addHospitalInfo = (value: any, handleClose: any, actions: any) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    value.country = "ke";
    let params: any = value;
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      Utils.endPoints.searchHospital,
      params,
      (respData: any) => {
        if (respData.status === Utils.constants.api_success_code.success) {
          const { data } = respData;
          if (data.body.status === Utils.constants.api_success_code.postSuccess) {
            // console.log(data);
            Utils.showAlert(1, data?.body?.msg);
            actions.setSubmitting(false);
            return;
          }
          actions.setSubmitting(false);
          Utils.showAlert(1, respData.data?.body?.success);
          handleClose();
        }
      },
      (error: any) => {
        let { data } = error;
        // console.log(data);
        actions.setSubmitting(false);
        handleClose();
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const onSubmitFirstStep = (dataSend: any, setSubmitting: any) => {
  return (dispatch: Function, getState: Function) => {
    const dataToSend = dataSend;
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      `${Utils.endPoints.createClaim}`,
      dataToSend,
      (respData: any) => {
        let { data } = respData;
        // ;
        setSubmitting(false);
        dispatch({
          type: Utils.ActionName.GET_PATIENT_DATA,
          payload: {
            activeStep: 1,
          },
        });
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            claimId: data.drafted_claim_id,
            // authTypeSelected: data.auth_type_selected,
          },
        });

        //
      },
      (error: any) => {
        //
        setSubmitting(false);
        let { data } = error;

        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const addUserDocumentClaim = (dataToSend: any) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }

    let params = dataToSend;
    Utils.constants.getAccessToken();
    Utils.api.putApiCall(
      Utils.endPoints.claimMemberCardUpload,
      params,
      (respData: any) => {
        console.log(respData, "respData");
      },
      (error: any) => {
        let { data } = error;
        console.log(data);
      }
    );
  };
};

export const getClaimDetailsListEdit = (id: string, insurance_company_id: any) => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const { data } = getState().InsuranceCompanyReducer;
      const { patientInsuranceMemberDataList, insurance_member_dependents } = getState().InsurancePatientProfileReducer;

      dispatch({
        type: Utils.ActionName.GET_INSURANCE_COMPANY,
        payload: { patientInsurance: insurance_company_id },
      });

      const insuranceData: any = data.find((a: any) => a?.id == insurance_company_id);

      let cardListArray: any = [];

      if (insuranceData.is_insurance_card === "1") {
        cardListArray.push({
          id: 2,
          name: insuranceData.id === "13" ? "Search with LCT Member No." : "Search with Member No.",
          is_insurance_card: insuranceData.is_insurance_card,
          image: insuranceData.insurance_card_image,
        });
      }
      if (insuranceData.is_smart_card === "1") {
        cardListArray.push({
          id: 1,
          name: "Search with Smart Card",
          is_smart_card: insuranceData.is_smart_card,
          image: insuranceData.smart_card_image,
        });
      }
      const params = "";
      dispatch(globalLoaderTrue());
      Utils.constants.getAccessToken();
      Utils.api.getApiCall(
        `${Utils.endPoints.createClaim}/${id}`,
        params,
        (respData: any) => {
          if (respData.status === Utils.constants.api_success_code.success) {
            dispatch({
              type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
              payload: {
                claimId: id,
                cardListArray: cardListArray,
                authType: JSON.parse(insuranceData.auth_type),
                insuranceData,
                showAuthenticationType: JSON.parse(insuranceData.auth_type).length === 1 ? false : true,
                showOtpVerification: "",
                phoneNumber: "",
                search: respData?.data?.beneficiary?.employee_number,
                insuranceMemberOptionSelect: false,
                patientFamilyMemberData: [],
                authTypeSelected: "",
                checked: false,
                insurance_member_dependents: [],
                termsConditionChecked: true,

                ...(JSON.parse(insuranceData.auth_type).length === 1 && {
                  showOtpVerification: "0",
                }),
                ...(JSON.parse(insuranceData.auth_type).length === 1 && {
                  patientInsuranceMember: [],
                }),
                ...(JSON.parse(insuranceData.auth_type).length === 1 && {
                  patientInsuranceMemberDataList: [],
                }),
                ...(JSON.parse(insuranceData.auth_type).length === 1 && {
                  termsConditionChecked: false,
                }),
                ...(JSON.parse(insuranceData.auth_type).length === 1 && {
                  showSearchMemberNo: true,
                }),
                ...(JSON.parse(insuranceData.auth_type).length === 1 && {
                  insuranceCardType: "1",
                }),
              },
            });

            dispatch(getInsuredMemberForEdit(insurance_company_id, respData?.data?.beneficiary?.employee_number)).then(
              (res: any) => {
                console.log(
                  res,
                  "respData?.data?.entity_id",
                  respData?.data?.entity_id,
                  patientInsuranceMemberDataList
                );
                // ;
                const memberData: any = res?.data.body?.find((a: any) => a.entity_id === respData?.data?.entity_id);
                dispatch({
                  type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                  payload: {
                    entityId: respData?.data?.entity_id,
                    finalEntityId:
                      insurance_member_dependents === null || insurance_member_dependents === undefined
                        ? memberData.ent_dep_parent_on_policy
                        : respData?.data?.entity_id,
                    search:
                      memberData.card_type === 1 || memberData.card_type === "1"
                        ? memberData.entity_id
                        : memberData.employee_number,
                    insuranceMemberOptionSelect: false,
                  },
                });
                dispatch(globalLoaderTrue());
                dispatch(onSubmitMember(memberData));
              }
            );

            dispatch({
              type: Utils.ActionName.CLAIM_DETAIL,
              payload: { ...respData?.data },
            });
            dispatch(globalLoaderFalse());
            resolve("success");
          }
        },
        (error: any) => {
          console.log(error);
          let { data } = error;
          reject(data?.messages[0]);
          dispatch(globalLoaderFalse());
          Utils.showAlert(2, data?.messages[0]);
        }
      );
    });
  };
};

export const getClaimDetailsListEditNormal = (id: string) => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const { initialValuesFormik } = getState().addmidicineFormitModalReduce;
      const params = "";
      dispatch(globalLoaderTrue());
      Utils.constants.getAccessToken();
      Utils.api.getApiCall(
        `${Utils.endPoints.createClaim}/${id}`,
        params,
        (respData: any) => {
          if (respData.status === Utils.constants.api_success_code.success) {
            console.log(respData, "success");
            const { data } = respData;
            dispatch({
              type: Utils.ActionName.CLAIM_DETAIL,
              payload: { ...respData?.data },
            });

            dispatch({
              type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
              payload: {
                claimId: data?.id,
                entityId: data?.entity_id,
                // authTypeSelected: data?.auth_type_selected,
                authTypeSelected: data?.auth_type_selected,
              },
            });


            // for second step
            if (Number(data?.form_step) === 1) {
              dispatch({
                type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
                payload: {
                  doctorDetail: {},
                  hospitalDetail: {},
                  finalDiagnosisDetail: [],
                  onlyRead: false,
                },
              });
              dispatch({
                type: Utils.ActionName.CLEAR_MEDICINE_DETAILS_LIST,
              });
            } else {
              dispatch({
                type: Utils.ActionName.DOCTOR_HOSPITAL_ICD10_DETAILS,
                payload: {
                  doctorDetail: {
                    id: data?.doctor_id,
                    phone_code: data?.doctor_phone_code,
                    phone_number: data?.doctor_phone,
                    text: data?.doctor_name,
                  },
                  hospitalDetail: {
                    id: data?.hospital_id,
                    phone: data?.phone_list,
                    text: data?.hospital_name,
                  },
                  finalDiagnosisDetail: data?.icd10_list,
                  onlyRead: true,
                },
              });
              if (data?.order_data?.drugs?.length > 0) {
                let drugArr: any[] = [];
                for (const med of data?.order_data?.drugs) {
                  var {
                    doctor_comment,
                    dosage,
                    drug_id,
                    drug_name,
                    duration,
                    food_instruction,
                    form,
                    frequency,
                    pharmacy_dosage,
                    pharmacy_quantity,
                    price,
                    quantity,
                    type,
                    new_quantity,
                    quantity_measuring,
                    quantity_per_bottle,
                    supplying_frequency,
                    repetition_cycle,
                  } = med;
                  const bottleCondition = ((quantity_measuring?.toLowerCase() === "bottle" &&
                    quantity_per_bottle) || (quantityMeasuringCate.includes(quantity_measuring?.toLowerCase()))) &&
                    quantity_per_bottle !== "0" &&
                    new_quantity;
                  const drug = {
                    medicineName:
                      bottleCondition
                        ? {
                          name: drug_name,
                          id: drug_id,
                          new_quantity,
                          quantity_measuring,
                          pharmacy_quantity,
                        }
                        : {
                          name: drug_name,
                          id: drug_id,
                        },
                    dosage,
                    pricePerUnit: price,
                    repetitionCycle: repetition_cycle,
                    supplyfrequency: supplying_frequency,
                    totalPrice:
                      bottleCondition
                        ? String(Number(new_quantity) * Number(price))
                        : String(Number(pharmacy_quantity) * Number(price)),
                    totalQuantity:
                      bottleCondition
                        ? String(new_quantity)
                        : String(pharmacy_quantity),
                    formulation: form,
                    frequency,
                    duration,
                    foodInstractions: food_instruction,
                    unqiueId: initialValuesFormik?.unqiueId || uid(),
                  };
                  // dispatch({
                  //   type: Utils.ActionName.CREATE_MEDICINE_DETAILS_LIST,
                  //   payload: drug,
                  // });
                  drugArr.push(drug);
                }
                dispatch({
                  type: Utils.ActionName.DELETE_MEDICINE_DETAILS_LIST,
                  payload: drugArr,
                });
                // console.log(
                //   data?.order_data?.drugs,
                //   "order_data?.drugs",
                //   drugArr
                // );
              }
            }

            // for third step
            if (Number(data?.form_step) === 2) {
              dispatch({
                type: Utils.ActionName.CREATE_CLAIM_SECOND_SETP,
                payload: {
                  phone_number: data?.user_phone_number,
                  phone_code: data?.user_phone_code,
                },
              });
              dispatch({
                type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                payload: {
                  patientName: data?.user_name,
                  patientDoB: data?.user_date_birth,
                  gender: data?.user_gender,
                  claimId: data?.id,
                },
              });
            } else {
              dispatch({
                type: Utils.ActionName.CREATE_CLAIM_SECOND_SETP,
                payload: {
                  phone_number: "",
                  phone_code: "",
                },
              });
              dispatch({
                type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                payload: {
                  patientName: "",
                  patientDoB: "",
                  gender: "",
                  claimId: "",
                },
              });
            }

            dispatch(globalLoaderFalse());
          }
          resolve("succuss");
        },
        (error: any) => {
          console.log(error);
          let { data } = error;
          dispatch(globalLoaderFalse());
          reject(error);
          Utils.showAlert(2, data?.messages[0]);
        }
      );
    });
  };
};

export const getClaimDetails = (id: string, doctorPres: string) => {
  return (dispatch: Function, getState: Function) => {
    const { initialValuesFormik } = getState().addmidicineFormitModalReduce;
    const params = "";
    dispatch(globalLoaderTrue());
    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      `${Utils.endPoints.createClaim}/${id}`,
      params,
      (respData: any) => {
        if (respData.status === Utils.constants.api_success_code.success) {
          const { order_data } = respData?.data;
          dispatch({
            type: Utils.ActionName.CLAIM_DETAIL,
            payload: { ...respData?.data },
          });
          dispatch({
            type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
            payload: {
              authTypeSelected: respData?.data?.auth_type_selected,
            },
          });
          if (doctorPres === "doctorPres") {
            if (order_data?.drugs?.length > 0) {
              let drugArr: any[] = [];
              for (const med of order_data?.drugs) {
                var {
                  doctor_comment,
                  dosage,
                  drug_id,
                  drug_name,
                  duration,
                  food_instruction,
                  form,
                  frequency,
                  pharmacy_dosage,
                  pharmacy_quantity,
                  price,
                  quantity,
                  type,
                  new_quantity,
                  quantity_measuring,
                  quantity_per_bottle,
                  repetition_cycle,
                  supplying_frequency,
                  global_medicine_search
                } = med;
                const bottleCondition = ((quantity_measuring?.toLowerCase() === "bottle" &&
                  quantity_per_bottle) || (quantityMeasuringCate.includes(quantity_measuring?.toLowerCase()))) &&
                  quantity_per_bottle !== "0" &&
                  new_quantity;
                const drug = {
                  medicineName:
                    bottleCondition
                      ? {
                        name: drug_name,
                        id: drug_id,
                        new_quantity,
                        quantity_measuring,
                        pharmacy_quantity,
                      }
                      : {
                        name: drug_name,
                        id: drug_id,
                      },
                  dosage,
                  pricePerUnit: price,
                  global_medicine_search: global_medicine_search,
                  repetitionCycle: repetition_cycle,
                  supplyfrequency: supplying_frequency,
                  totalPrice:
                    bottleCondition
                      ? String(Number(new_quantity) * Number(price))
                      : String(Number(pharmacy_quantity) * Number(price)),
                  totalQuantity:
                    bottleCondition
                      ? String(new_quantity)
                      : String(pharmacy_quantity),
                  formulation: form,
                  frequency,
                  duration,
                  foodInstractions: food_instruction,
                  unqiueId: initialValuesFormik?.unqiueId || uid(),
                };
                // dispatch({
                //   type: Utils.ActionName.CREATE_MEDICINE_DETAILS_LIST,
                //   payload: drug,
                // });
                drugArr.push(drug);
              }
              dispatch({
                type: Utils.ActionName.DELETE_MEDICINE_DETAILS_LIST,
                payload: drugArr,
              });

            }
          }
          dispatch(globalLoaderFalse());
        }
      },
      (error: any) => {
        console.log(error);
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const getSentClaimDetails = (id: string) => {
  return (dispatch: Function, getState: Function) => {
    const params = "";
    dispatch(globalLoaderTrue());
    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      `${Utils.endPoints.sentClaims}/${id}`,
      params,
      (respData: any) => {
        if (respData.status === Utils.constants.api_success_code.success) {
          dispatch({
            type: Utils.ActionName.SENT_CLAIM_DETAIL,
            payload: { ...respData?.data },
          });
          dispatch(globalLoaderFalse());
        }
      },
      (error: any) => {
        console.log(error);
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const getOrderDetails = (id: string) => {
  return (dispatch: Function, getState: Function) => {
    const params = "";
    dispatch(globalLoaderTrue());
    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      `${Utils.endPoints.medicineOrders}/${id}`,
      params,
      (respData: any) => {
        if (respData.status === Utils.constants.api_success_code.success) {
          dispatch({
            type: Utils.ActionName.MEDICINE_ORDER_DETAIL,
            payload: { ...respData?.data },
          });
          dispatch(globalLoaderFalse());
        }
      },
      (error: any) => {
        console.log(error);
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const uploadImageFromApp = (payload: any) => {
  return (dispatch: Function, getState: Function) => {
    const params = payload;
    dispatch(globalLoaderTrue());
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      `${Utils.endPoints.claimMemberCardUpload}`,
      params,
      (respData: any) => {
        if (respData.status === Utils.constants.api_success_code.success) {
          dispatch({
            type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
            payload: {
              syncForApp: true,
            },
          });
          Utils.showAlert(1, respData?.data?.messages[0]);
          dispatch(globalLoaderFalse());
        }
      },
      (error: any) => {
        console.log(error);
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};
export const syncFromAppImage = (user_id: any, entity_id: any, insurance_company_id: any) => {
  return (dispatch: Function, getState: Function) => {
    let params = `?claim_id=${1}`;
    // let params = `?user_id=${user_id}&entity_id=${entity_id}&insurance_company_id=${insurance_company_id}`;
    dispatch(globalLoaderTrue());
    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      `${Utils.endPoints.claimMemberCardUpload}`,
      params,
      (respData: any) => {
        console.log({ respData });
        if (respData.status === Utils.constants.api_success_code.success) {
          // dispatch({
          //   type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          //   payload: {
          //     card3: respData?.data?.body?.photo_id_number_backend,
          //     card3Added: true,
          //   },
          // });
          dispatch(globalLoaderFalse());
        }
      },
      (error: any) => {
        console.log(error);
        let { data } = error;
        dispatch(globalLoaderFalse());
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const onSendOtp = (phoneNumber: any, insurance_company_id: any, entity_id: any, ethical_substitute: any, option: any, otp: any = false, beneficiary: any = false) => {
  return (dispatch: Function, getState: Function) => {
    let dataToSend = {
      phone_no: phoneNumber,
      insurance_company_id,
      phone_id: getPhoneIdFromLS(),
      entity_id,
      ethical_substitute,
      option,
      auth_code: '',
      beneficiary
    };
    if (option != 'send_otp') {
      dataToSend.auth_code = otp
    }
    Utils.constants.getAccessToken();
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { isLoading: true },
    });

    Utils.api.postApiCall(
      `${Utils.endPoints.claimOtpVerify}`,
      dataToSend,
      (respData: any) => {
        // let { data } = respData;
        const succeccAlert = (option == 'send_otp') ? 'OTP sent successfully' : 'User verified successfully'
        const showVerify = (option == 'send_otp') ? true : false
        const ethicalVerify = (option == 'send_otp') ? false : true
        Utils.showAlert(1, succeccAlert);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            showEthicalVerification: showVerify,
            ethicalSubsVerify: ethicalVerify
          },
        });
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
          payload: {
            ethicalSubstitue: null
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const onSubmitEthical = (id: any, phoneNumber: any, otp: any, orderId: any, navigate: any) => {
  return (dispatch: Function, getState: Function) => {
    const dataToSend = {
      user_phone: phoneNumber,
      order_id: orderId,
      otp: otp,
    };

    Utils.constants.getAccessToken();
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { isLoading: true },
    });

    Utils.api.putApiCall(
      `${Utils.endPoints.DoctorPrescriptions}/${id}?verify_otp=1`,
      dataToSend,
      (respData: any) => {
        let { data } = respData;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        Utils.showAlert(1, "Otp verified successfully");
        dispatch({
          type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
          payload: {
            draftedClaimId: data.draft_claim_id,
            showVerification: false,
          },
        });

        localStorage.setItem("draftedClaimId", data.draft_claim_id);
        navigate(`${Utils.routes.createClaimPrescription}/${data.draft_claim_id}`, {
          state: { editDoctor: false },
        });
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { isLoading: false },
        });
        let { data } = error;
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
};

export const providerNotInRangeNotification = (data: any) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    let params = data;
    Utils.constants.getAccessToken();
    Utils.api.postApiCall(
      Utils.endPoints.insuranceCompany,
      params,
      (respData: any) => {
        //
      },
      (error: any) => {
        //
      }
    );
  };
};

export function getSubscriptionStatus() {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }

    dispatch({
      type: Utils.ActionName.SUBSCRIPTION_STATUS,
      payload: {
        isGetPMApiLoading: true,
      },
    });
    Utils.constants.getAccessToken();
    let params = "";
    Utils.api.getApiCall(
      `${Utils.endPoints.subscription}?list_type=subscription_status`,
      params,
      (respData: any) => {
        const { data } = respData;

        dispatch({
          type: Utils.ActionName.SUBSCRIPTION_STATUS,
          payload: {
            subscriptionDetails: { ...data?.body },
            isGetPMApiLoading: false,
          },
        });
      },
      (error: any) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.SUBSCRIPTION_STATUS,
          payload: {
            isGetPMApiLoading: false,
          },
        });
        Utils.showAlert(2, data?.messages[0]);
      }
    );
  };
}
