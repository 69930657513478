import LoadingButton from "@mui/lab/LoadingButton";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import Utils from "../../../utils";

const CustomButtonWrapper = ({ isSubmitting, children, ...otherProps }: any) => {
  const { submitForm } = useFormikContext();
  const dispatch = useDispatch();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch({
      type: Utils.ActionName.SIGN_IN,
      payload: { signUp: false },
    });

    submitForm();
  };

  const configButton = {
    ...otherProps,
    variant: "contained",
    color: "primary",
    fullWidth: true,
    onClick: handleSubmit,
  };

  return isSubmitting ? (
    <LoadingButton loading loadingPosition="start" {...configButton}>
      {children}
    </LoadingButton>
  ) : (
    <LoadingButton position="start" {...configButton}>
      {children}
    </LoadingButton>
  );
  // <Button {...configButton}></Button>;
};

export default CustomButtonWrapper;
