import Utils from "../../utils";
import { globalLoaderFalse, globalLoaderTrue } from "../../reducer/rootReducer";

export const getDrugFormulary = () => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(3, "PLEASE CHECK INTERNET");
      return;
    }
    dispatch(globalLoaderTrue());
    // let search = "muc"
    const { limit, offset, search, insurance_company_id } = getState().DrugFormularyReducer;
    console.log("search", search, "insurance_company_id", insurance_company_id);
    let data = `?limit=${limit}&offset=${offset || 0}${
      search.length > 0 ? `&search=${search}` : ""
    }&insurance_company_id=${insurance_company_id || ""}&forWebPortal=${1}&is_count_needed=1`;
    // debugger;

    Utils.constants.getAccessToken();
    Utils.api.getApiCall(
      Utils.endPoints.DrugFormulary,
      data,
      (respData: any) => {
        let { data } = respData;

        dispatch({
          type: Utils.ActionName.DRUG_FORMULARY,
          payload: {
            data1: data.body,
            count: data.count,
          },
        });
        // debugger;
        dispatch(globalLoaderFalse());
      },
      (error: any) => {
        dispatch(globalLoaderFalse());
        let { data } = error;
        Utils.showAlert(2, data?.messages?.[0]);
      }
    );
  };
};
