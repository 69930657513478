import {
  Box,
  Grid,
  FormControl,
  Typography,
  MenuItem,
  Theme,
  Avatar,
} from "@mui/material";
import { Button } from "@mui/material";
import { OrderStatus } from "../../../utils/appData";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { makeStyles, createStyles } from "@mui/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import DateTimePicker from "../../../components/formsUi/dateTimePicker";
import CustomSelect from "../../../components/formsUi/select/customSelect";
import Utils from "../../../utils";
import { getMedicineOrders } from "../action";
import Schema from "../../../schema";
import { ReducersModal } from "../../../modal";

const API_URL = Utils.constants.API_URL;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterActiveButton: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "20px",
    },
    datePicker: {
      marginTop: "5px",
    },
    insuranceCompany: {
      marginTop: "29px",
    },
    status: {
      marginTop: "29px",
    },
    customContentStyle: {
      width: "100%",
      maxWidth: "none",
    },
    typography: {
      fontSize: "1.2rem",
      "@media (min-width:600px)": {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2.4rem",
      },
    },
    flexBox: {
      display: "flex",
      "& .MuiAvatar-root": {
        height: "25px",
        width: "20px",
      },
    },
    marginFlag: {
      marginLeft: "10px",
      textTransform: "uppercase",
      fontSize: "14px",
    },
  })
);

function FilterComponent({ data }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    data: ManufactuerList,
    orderManufacturer,
    manufacturerMedicineList,
  } = useSelector((state: ReducersModal) => state.ManufacturerReducer);

  const { manufacturer_id, status } = useSelector(
    (state: ReducersModal) => state.MedicineOrdersReducer
  );

  const initialValues = {
    startDate: "",
    endDate: "",
    manufacturer_id: manufacturer_id,
    status: status,
  };
  const handleClear = () => {
    dispatch({
      type: Utils.ActionName.MEDICINE_ORDERS,
      payload: {
        order_start_date: "",
        order_end_date: "",
        manufacturer_id: "",
        status: "",
      },
    });
    dispatch(getMedicineOrders());
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema.sentClaimFilterSchema}
        onSubmit={(values) => {
          dispatch({
            type: Utils.ActionName.MEDICINE_ORDERS,
            payload: {
              order_start_date: values.startDate,
              order_end_date: values.endDate,
              manufacturer_id: values.manufacturer_id,
              status: values.status,
            },
          });
          dispatch(getMedicineOrders());
        }}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <Box className={classes.filterActiveButton}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4.2}>
                  <Typography sx={{ marginBottom: "5px" }}>Date</Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <DateTimePicker
                          name="startDate"
                          size="small"
                          label="From"
                          // value={}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <DateTimePicker
                          name="endDate"
                          size="small"
                          label="To"
                          // value={}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={2.5}>
                  <Box className={classes.insuranceCompany}>
                    <FormControl fullWidth>
                      <CustomSelect
                        name="manufacturers"
                        label="Select Manufacturer"
                        size="small"
                        value={values.manufacturer_id}
                        options={ManufactuerList.map(
                          (option: any, index: any) => (
                            <MenuItem key={index} value={option.id}>
                              <div className={classes.flexBox}>
                                <div className={classes.marginFlag}>
                                  {option.name}
                                </div>
                              </div>
                            </MenuItem>
                          )
                        )}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2.5}>
                  <Box className={classes.status}>
                    <FormControl fullWidth>
                      <CustomSelect
                        name="medicine_order_status"
                        label="Select Status"
                        size="small"
                        value={values.status}
                        options={OrderStatus.map((option: any, index: any) => {
                          return (
                            <MenuItem key={index} value={option.id}>
                              <div className={classes.flexBox}>
                                <div className={classes.marginFlag}>
                                  {option.name}
                                </div>
                              </div>
                            </MenuItem>
                          );
                        })}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={5} sm={4} md={2.8}>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      // onClick={handleClick}
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        minWidth: "auto",
                        marginTop: "26px",
                        marginRight: "16px",
                      }}
                    >
                      Apply
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="reset"
                      size="large"
                      sx={{
                        fontWeight: "bold",
                        minWidth: "auto",
                        marginTop: "26px",
                      }}
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FilterComponent;
