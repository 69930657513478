import { Box, Button, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Popup({ handleClose }: any) {
  const navigate = useNavigate();

  const handleClick = () => {
    handleClose();
    navigate("/");
  };

  return (
    <>
      <Box sx={{ textAlign: "center", ml: 8.5, width: "65%" }}>
        <Box
          sx={{
            color: "var(--white)",
            fontWeight: "bold",
            marginTop: "10px",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.main,
            padding: "10px",
          }}
        >
          Moderation
        </Box>
        <Typography
          variant="h5"
          sx={{ mt: 4, fontSize: "24px", fontWeight: "bold" }}
        >
          Thank you for interest in partnering with Livia
        </Typography>
        <Typography sx={{ mt: 1, fontSize: "14px" }}>
          Your application in under review. For any question please email us on{" "}
          <Link sx={{ color: "blue", textDecoration: "none" }}>
            lpcsupportliviapp.com
          </Link>
        </Typography>
        <Button
          variant="contained"
          sx={{
            color: "var(--white)",
            fontWeight: "bold",
            marginTop: "10px",
            marginBottom: "10px",
            width: "40%",
          }}
          onClick={handleClick}
        >
          OK
        </Button>
      </Box>
    </>
  );
}

export default Popup;
