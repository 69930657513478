import {
  CreateProfileDataModal,
  GetSignupProfileDetails,
  PharmacyCityModal,
} from "../../modal";
import Utils from "../../utils";

export const pharmacyCityReducer = (
  state: PharmacyCityModal = new PharmacyCityModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.PHARMACY_CITY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const createProfileDataReducer = (
  state: CreateProfileDataModal = new CreateProfileDataModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.CREATE_PROFILE_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getSignupCreateProfleDetailsReducer = (
  state: GetSignupProfileDetails = new GetSignupProfileDetails(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GET_SIGNUP_CREATE_PROFILE_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
