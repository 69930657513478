import { TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

const SelectWrapper = ({
  name,
  extraProp,
  setCountryCode,
  options,
  ...otherProps
}: any) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  // console.log("extra name", name, extraProp);
  const handleChange = (evt: any) => {
    const { value } = evt.target;
    if (name === "phone_code") {
      setCountryCode(value);
    }
    if (extraProp?.check && name === "formulation") {
      extraProp.setFormulationState(value);
    }
    if (extraProp?.check && name === "frequency") {
      extraProp.setFrequencyState(value);
    }
    if (extraProp?.check && name === "duration") {
      extraProp.setDurationState(value);
    }
    if (extraProp?.check && name === "foodInstractions") {
      extraProp.setFoodInstractionsState(value);
    }
    if (extraProp?.check && name === "supplyfrequency") {
      extraProp.setSupplyfrequencyState(value);
    }
    if (extraProp?.check && name === "repetitionCycle") {
      extraProp.setRepetitionCycleState(value);
    }
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }
  return (
    <TextField {...configSelect}>
      {options}
      {/* {Object.keys(options).map((item, pos) => {
        return (
          <MenuItem key={pos} value={options[item]}>
            {options[item]}
          </MenuItem>
        );
      })} */}
    </TextField>
  );
};

export default SelectWrapper;
